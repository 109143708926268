<template>
    <!-- Login content -->
    <div
        class="container-fluid px-1 sh-text-primary start"
        style="background-color: #fff;"
    >
        <Navbar />
        <div class="div-row">
            <div class="side p-5 ml-lg-5">
                <div class="mt-5">
                    <h2 class="hero-heading mt-4 pt-5">
                        Reset your<br />
                        Password
                    </h2>
                </div>
                <form class="py-3" @submit.prevent="reset">
                    <div class="row no-gutters">
                        <div class="form-group mb-4 col-12 col-lg-12 p-0">
                            <input
                                type="email"
                                class="form-control py-4"
                                id="email"
                                name="email"
                                placeholder=" "
                                v-model="email"
                                @keyup="v$.email.$touch"
                                @blur="checkEmail"
                            />
                            <label for="email" class="label font-weight-bold"
                                >Email</label
                            >
                            <span
                                v-if="v$.email.$error"
                                class="text-danger font-weight-bold"
                                >{{ v$.email.$errors[0].$message }}</span
                            >
                        </div>
                    </div>

                    <div class="row no-gutters">
                        <div class="form-group col-12 col-lg-12 p-0">
                            <button
                                type="submit"
                                class="btn btn-submit py-2 px-4 btn-block"
                                style="color:#fff; box-shadow:none"
                                v-html="buttonText"
                                :disabled="submitted"
                            ></button>
                            <p class="text-center mt-4 checkcontainer">
                                <router-link :to="{ name: 'Login' }"
                                    >Log in</router-link
                                >
                            </p>
                        </div>
                    </div>
                </form>
            </div>
            <div class="main d-none d-lg-block">
                <!-- <div class="log-container">
                    <div class="log-vertical-center"></div>
                </div> -->
                <div class="col-7 mx-auto mt-5 pt-5">
                    <img
                        class="img-fluid"
                        src="@/assets/img/reg.png"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import axios from "axios";
import { required, email, minLength } from "@vuelidate/validators";
import { mapGetters, mapActions } from "vuex";
import Navbar from "../../../components/inc/SecondaryNavbar.vue";

export default {
    name: "Login",
    components: {
        Navbar,
    },
    data() {
        return {
            icon: "fa fa-eye",
            isPassword: false,
            v$: useValidate(),
            email: "",
            password: "",
            submitted: false,
            isInit: false,
            isSignIn: false,
            buttonText: "Forgot Password",
        };
    },
    validations() {
        return {
            email: { required, email },
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            token: "auth/token",
        }),
    },
    methods: {
        reset() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.submitted = true;
                this.buttonText = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
                let data = { email: this.email };
                axios
                    .post("/reset/password", data)
                    .then((response) => {
                        if (response.data.message === "success") {
                            swal.fire(
                                "Sent",
                                "A reset link has been sent to your email",
                                "success",
                            ).then(() => {
                                this.$router.push({ name: "Login" });
                            });
                        }
                    })
                    .catch(() => {
                        swal.fire(
                            "Invalid Email",
                            "Please the email you entered is not registered with us",
                            "error",
                        );
                        this.submitted = false;
                        this.buttonText = "Forget Password";
                    });
            } else {
                this.submitted = false;
                this.buttonText = "Forget Password";
                swal.fire("Error", "Please check your credentials", "error");
            }
        },
    },

    created() {
        if (this.user) {
            this.$router.push({ name: "WelcomeDashboard" });
        }
    },
};
</script>

<style scoped>
.log-container {
    height: 150px;
    position: relative;
}

.log-vertical-center {
    margin: 0;
    position: absolute;
    top: 40%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.welcome2-text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #242424;
}
.div-row {
    display: flex;
}

.side {
    flex: 40%;
    height: 100vh;
    background-color: #fff;
}
.main {
    flex: 60%;
    padding-bottom: 50px;
    /* height: 100vh; */
    background: #fff;
}
.hero-heading {
    font-family: "GD Boing", sans-serif;
    font-weight: 600;
    font-size: 3.3rem;
    line-height: 4rem;
    color: #52a350;
}
.btn-submit {
    background: #52a350;
    border: 2px solid #52a350;
    border-radius: 10px;
}
.main::after {
    background: url(../../../assets/img/creat.png) no-repeat;
    background-size: auto;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0px;
    right: 0px;
}

.row.country-div {
    border: 2px solid #bdb;
    box-sizing: border-box;
    border-radius: 10px;
}

.flag {
    margin-top: 5px !important;
}

.form-control {
    border: 2px solid #bbddbb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0 15px;
    font-weight: 300;
    font-size: 15px !important;
    line-height: 22px;
    color: #858585 !important;
    background: #fff !important;
}

.form-check-label {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #858585;
}

.custom-select-lg {
    height: 50px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px !important;
    line-height: 22px;
    color: #b6b6b6 !important;
}

#country {
    background: transparent;
    border: 1px solid transparent;
}

.start {
    margin-top: 0px;
}
.in-p {
    margin: 20px 0;
    position: relative;
    display: inline-block;
}
.label {
    padding: 10px;
    pointer-events: none;
    position: absolute;
    left: 15px;
    top: 5px;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 1;
    background: #fff;
    font-style: normal;
    font-weight: 300;
    font-size: 15px !important;
    line-height: 22px;
    color: #b6b6b6 !important;
    background: #fff !important;
}

input:focus + .label,
input:not(:placeholder-shown) + .label {
    opacity: 1;
    transform: scale(0.75) translateY(-70%) translateX(-14px);
    top: 0px;
    color: #52a350 !important;
}

.checkcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.checkcontainer {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    color: #858585;
    margin-left: 13px;
}

.checkcontainer a {
    color: #52a350;
    text-decoration: none;
}

.checkcontainer a:hover {
    text-decoration: none;
}

/* Hide the browser's default checkbox */
.checkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #52a350;
    border-radius: 2px;
    background-color: #fff;
}

/* On mouse-over, add a grey background color */
.checkcontainer:hover input ~ .checkmark {
    border: 2px solid #52a350;
    border-radius: 2px;
    background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkcontainer input:checked ~ .checkmark {
    border: 2px solid #52a350;
    border-radius: 2px;
    background-color: #52a350;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkcontainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkcontainer .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
    .hero-heading {
        font-family: "GD Boing", sans-serif;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2rem;
        color: #52a350;
    }
}
</style>
