<template>
    <nav
        class="navbar px-xlg-100 px-sm-20 pt-lg-3 px-lg-30 navbar-light navbar-expand-lg py-2 fixed-top"
        :class="[changeNavColor ? 'newBg' : 'oldBg']"
    >
        <div
            class="col-12 d-flex justify-content-between flex-column flex-lg-row align-items-lg-center p-0"
        >
            <div
                class="d-flex flex-row justify-content-between align-items-center mob-nav"
            >
                <button
                    class="navbar-toggler float-right"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbar"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <a href="https://lms.terrahq.co/" class="navbar-brand p-0 mr-auto">
                    <img
                        src="@/assets/img/logo-dark.png"
                        alt="logo"
                        class="nav-logo"
                        style="width:150px"
                    />
                </a>
                <div class="d-block d-lg-none" v-if="!user">
                    <router-link
                        :to="{ name: 'Login' }"
                        class="font-weight-bold"
                        >Login</router-link
                    >
                </div>
                <div class="d-block d-lg-none" v-else>
                    <router-link
                        :to="{ name: 'Dashboard' }"
                        class="font-weight-bold"
                        >Dashboard</router-link
                    >
                </div>
            </div>
            <div class="navbar-collapse collapse p-0 " id="navbar">
                <ul
                    class="navbar-nav ml-auto px-0 pl-lg-0 d-flex align-items-lg-center align-items-start mr-auto"
                >
                    <!-- <li class="nav-item mx-lg-5 py-2">
                        <router-link class="nav-bar-links" :class="{ onScroll: changeNavColor }" :to="{name:'Ambassador'}">
                            Become an Ambassador
                        </router-link>
                    </li> -->
                    <li class="nav-item mx-lg-5 py-2">
                        <a
                            href="https://toptalent.africa/"
                            target="_blank"
                            class="nav-bar-links"
                            :class="{ onScroll: changeNavColor }"
                        >
                            For Employers
                        </a>
                    </li>
                    <li class="nav-item mx-lg-5 py-2">
                        <router-link
                            :to="{ name: 'Faqs' }"
                            class="nav-bar-links"
                            :class="{ onScroll: changeNavColor }"
                            >FAQ
                        </router-link>
                    </li>
                </ul>
                <ul
                    class="navbar-nav my-2 my-lg-0 px-0 pl-lg-0 d-flex align-items-lg-center align-items-start"
                >
                    <li class="nav-item mr-5 mr-md-2 py-2" v-if="!user">
                        <router-link :to="{ name: 'Login' }"
                            ><button
                                class="nav-internship-cta px-5 py-2 border border-success"
                            >
                                Login
                            </button></router-link
                        >
                    </li>
                    <li class="nav-item mr-5 mr-md-2 py-2" v-if="!user">
                        <router-link :to="{ name: 'Register' }"
                            ><button class="nav-internship-reg-cta px-4 py-2">
                                Learn For Free
                            </button></router-link
                        >
                    </li>
                    <li class="nav-item mr-5 mr-md-2 py-2" v-else>
                        <router-link :to="{ name: 'Dashboard' }"
                            ><button class="nav-internship-reg-cta px-4 py-2">
                                Go to Dashboard
                            </button></router-link
                        >
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Navbar",
    data() {
        return {
            changeNavColor: false,
            canBeLoggedIn: false,
            navMobile: false,
        };
    },
    beforeMount() {
        window.addEventListener("scroll", this.handleScroll);
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods: {
        handleScroll() {
            if (window.pageYOffset > 20) {
                this.changeNavColor = true;
            } else {
                this.changeNavColor = false;
            }
        },
    },

    mounted() {
        if (this.user.token != undefined || this.user.course_id != undefined) {
            this.canBeLoggedIn = true;
        } else {
            this.canBeLoggedIn = false;
        }
    },
};
</script>

<style scoped>
.container {
    transition: 1000ms ease;
}
.newBg {
    background-color: #ffffff;
    /* background-color: #08386D; */
}
.onScroll {
    color: #52a350 !important;
    font-weight: 500;
}
.oldBg {
    background-color: transparent;
}
.navbar {
    border: none !important;
    transition: 800ms ease;
}
.btnStyled {
    border: 2px solid #21d536 !important;
    font-weight: 500;
}

.nav-link {
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 1.2rem;
    text-align: center;
    color: #52a350 !important;
    cursor: pointer;
}
.nav-internship-cta {
    background: #fff;
    border: 2px solid #52a350 !important;
    color: #52a350;
    font-size: 1rem;
    border-radius: 10px;
    transition: 800ms ease;
}
.nav-internship-cta:hover {
    background: #52a350;
    /* border: 2px solid #52A350 !important; */
    color: #fff;
}
.nav-internship-reg-cta {
    background: #52a350;
    border: 2px solid #52a350 !important;
    color: #fff;
    font-size: 1rem;
    border-radius: 10px;
    transition: 800ms ease;
}
.nav-internship-reg-cta:hover {
    background: #fff;
    color: #52a350;
}
.nav-employer-cta {
    background: #e1f0e0;
    font-size: 1rem;
    border-radius: 10px;
    color: #52a350;
    border: 2px solid rgba(82, 163, 80, 0.2) !important;
}
.nav-bar-cta:hover {
    border: 2px solid transparent;
    color: #08386d;
    background: #b6d86f;
}
@media all and (max-width: 768px) {
    .oldBg {
        background-color: #ffffff;
    }
    .mob-nav {
        width: 100%;
    }
    .nav-bar-links {
        text-align: left;
        margin: 10px 0;
    }
    .navbar {
        border-bottom: 2px solid #b6d86f !important;
    }
}
@media all and (max-width: 840px) {
    .navbar {
        border-bottom: 2px solid #b6d86f !important;
    }
}
</style>
