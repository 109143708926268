<template>
    <div>
        <DNav />
        <div id="root">
            <div class="container-fuild px-lg-5 px-2" style="margin-top: 6rem;">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-12 px-2">
                            <span
                                class="mb-0 col-12 mt-5 text-left text-sm-center d-block greetings p-0 text-muted"
                            >
                                Hi,
                                <span class="text-green font-weight-bold">
                                    {{ user.first_name }}
                                </span>
                            </span>
                            <span
                                class="d-block px-0 mb-3 mb-lg-5 mt-lg-2 mt-2 col-12 text-left text-sm-center intern-text text-muted"
                            >
                                INTERN ID: {{ user.user_id }}
                            </span>
                            <router-view />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- floating whatsapp btn -->
    <a
        class="floating-cta text-center px-2 text-success"
        href="https://wa.me/message/HX6JWNMRVERVO1"
        target="_blank"
        ><i class="fab fa-whatsapp fa-3x" style="font-size:4rem"></i
        ><br />Chat</a
    >
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
import DNav from "../../../components/inc/InfoNavbar.vue";

export default {
    components: { DNav },
    data() {
        return {
            hasMint: false,
            numberOfReferralFiltered: 0,
            numberOfReferral: 0,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Dashboard";
        },
    },

    methods: {
        copier() {
            let text = this.$refs.referralCode.textContent;
            const el = document.createElement("textarea");
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            if (document.execCommand("copy")) {
                swal.fire({
                    title: "Copied",
                    text: "Your refferal code has been copieed",
                    icon: "success",
                });
            }
        },
    },
    mounted() {
        if (!this.user.course_id) {
            this.$router.push({
                name: "First",
            });
        }
        this.$router.push({
            name: "Dashboard",
        });

        // if  nigeria user does't have bloom
        // if (!this.user.bloom && this.user.stipend === 0) {
        //     this.$router.push({
        //         name: "Third",
        //     });
        // }
    },
};
</script>

<style lang="css">
@media screen and (max-width: 768px) {
}
.greetings {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: #414141;
}

.intern-text {
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    line-height: 27px;
    color: #979797;
}

.mint-btn {
    padding: 1.2rem 1rem !important;
    font-size: 16px;
    border-color: #08386d !important;
    border-radius: 10px;
    color: #08386d;
}
</style>
