<template>
    <div class="modal-show">
        <div class="modal-container mx-auto">
            <div class=" col-12">
                <div class="text-center" v-if="loading">
                    <i class="fas fa-spinner fa-spin fa-2x"></i>
                </div>
                <div
                    class="py-3 d-flex flex-column align-items-center"
                    v-if="!airdrop && !loading"
                >
                    <h4 class="text-center empty-changel-text">
                        No available airdrops at the moment.
                        <br />
                        <span class="face">✌</span>
                    </h4>
                    <h6>Airdrops will be available every Thursday</h6>
                </div>
                <div
                    class="py-3"
                    v-if="airdrop && streak.Rank === 'Rookie' && !loading"
                >
                    <h6 class="text-center empty-changel-text">
                        You need to have a rank higher than rookie to claim
                        airdrops
                    </h6>
                </div>
                <div
                    class="py-3 d-flex flex-column align-items-center"
                    v-if="airdrop && streak.Rank !== 'Rookie'"
                >
                    <h4 class="text-center congrat-text">
                        Yaay! Congratulations
                    </h4>
                    <h4 class="text-center empty-changel-text">
                        <div class="text-center mt-3">
                            <img src="@/assets/img/goodies/cash.png" />
                            <p class="mt-2 reward-text">{{ airdrop.type }}</p>
                        </div>
                    </h4>
                    <button
                        class="onboard-btn px-5 py-2 text-center"
                        v-html="buttonText"
                        :disabled="submitted"
                        @click="claimDrop"
                    ></button>
                </div>
                <div class="mt-2" v-if="!airdrop || streak.Rank == 'Rookie'">
                    <div
                        class="onboard-btn px-5 py-2 text-center"
                        style=""
                        @click="$emit('closeModal')"
                    >
                        Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import swal from "sweetalert2";

export default {
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
    },
    data() {
        return {
            airdrop: "",
            buttonText: "Claim Drop",
            submitted: false,
            streak: "",
            loading: true,
        };
    },
    emits: ["closeModal"],
    methods: {
        getStreak() {
            let data = {
                week: this.week,
            };
            axios
                .post("/newStreak", data)
                .then((res) => {
                    this.streak = res.data.payload;
                })
                .catch(() => {
                    //
                });
        },
        getAirdrop() {
            axios
                .get("/goodies/airdrop")
                .then((res) => {
                    this.airdrop = res.data.payload;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.airdrop = "";
                });
        },
        claimDrop() {
            this.buttonText = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
            this.submitted = true;
            let data = {
                airdrop_id: this.airdrop.id,
            };
            axios
                .post("goodies/claimDrop", data)
                .then((res) => {
                    swal.fire(
                        "Claimed",
                        `Congratulations, on claiming this ${this.airdrop.type}, an Admin will reach out to you for your prize.`,
                        "success",
                    ).then(() => {
                        this.airdrop = "";
                        this.$emit("closeModal");
                        this.buttonText = `Claim Drop`;
                        this.submitted = true;
                    });
                })
                .catch((error) => {
                    swal.fire(
                        error.response.statusText,
                        error.response.data.payload,
                        "warning",
                    ).then(() => {
                        this.$emit("closeModal");
                        this.buttonText = `Claim Drop`;
                        this.submitted = true;
                    });
                });
        },
    },
    mounted() {
        this.getStreak();
        this.getAirdrop();
    },
};
</script>
<style scoped>
.congrat-text {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 44px;
    text-align: center;

    color: #044995;
}
.reward-text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;

    color: #242424;
}
.btn-claim {
    background: #0354ad;
    border-radius: 5.1746px;
    padding: 5px 25px;
}
.empty-changel-text {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 44px;
    text-align: center;
    color: #242424;
}
.modal-show {
    min-height: 100vh;
    min-width: 100vw;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
}
.face {
    margin-top: 10px;
}
.modal-container {
    width: 40%;
    height: fit-content;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px 50px;
    background-image: url(./../../../assets/img/onbaord-modal-bg.png);
    border-radius: 25px;
}
.onboard-btn {
    background: #ffffff;
    border: 1px solid #52a350 !important;
    border-radius: 10px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #52a350;
    transition: ease-in-out 500ms;
}
.onboard-btn:hover {
    background: #52a350;
    border: 1px solid #fff !important;
    font-weight: normal;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width: 776px) {
    .empty-changel-text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #242424;
    }

    .congrat-text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;

        color: #044995;
    }
    .modal-container {
        width: 80%;
        padding: 20px;
        background-image: url(./../../../assets/img/onbaord-modal-bg.png);
        border-radius: 25px;
    }
}
</style>
