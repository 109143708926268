<template>
    <div class="col-md-12">
        <h4 class="pb-2">Community</h4>
        <Post @posted="handleLoadMore" @searchPostNewText="search"></Post>
        <div class="mb-4"></div>
        <div
            class="card px-4 pt-3 mb-4 bg-white community-card post "
            v-if="showNoResultText"
        >
            <p class="text-center text-muted">
                No result found for your search "{{ searchText }}"
            </p>
        </div>

        <div
            class="card px-4 py-3 bg-white community-card post "
            v-if="loading"
        >
            <p class="text-center text-muted">
                {{ loadingPostText }}
            </p>
        </div>

        <div v-else>
            <div
                class="card p-4 bg-white community-card post"
                v-if="posts.length > 0"
            >
                <div v-for="post in posts" :key="post.id">
                    <div class="media">
                        <img
                            :src="
                                post.user.avatar
                                    ? post.user.avatar
                                    : '@/assets/img/avatar.png'
                            "
                            class="mr-3 rounded-circle"
                            :alt="post.user.first_name"
                        />
                        <div class="media-body">
                            <div class="d-flex flex-row align-items-baseline">
                                <h5 class="m-0">
                                    {{
                                        post.user.first_name +
                                            " " +
                                            post.user.last_name
                                    }}
                                </h5>
                                <p class="ml-1 mb-0 text-uppercase">
                                    ({{
                                        post.user.role == "intern"
                                            ? post.user.user_id
                                            : post.user.role
                                    }})
                                </p>
                            </div>
                            <p>
                                {{ postDate(post.created_at) }}
                            </p>
                        </div>
                    </div>

                    <div class="post-content">
                        <router-link
                            :to="{
                                name: 'SingleBlog',
                                params: { id: post.id },
                            }"
                        >
                            <h4 class="post-title">{{ post.post }}</h4>
                        </router-link>
                        <div class="post-count mt-4">
                            <span class="contribution-count2">
                                <img src="@/assets/img/icons/chat.svg" />
                                {{ post.comment.length }}</span
                            >
                        </div>

                        <div class="post-comment-box mt-4">
                            <div class="media-body">
                                <input
                                    class="form-control post-input"
                                    readonly
                                    placeholder="Add your answer here"
                                    @click="openPost(post.id)"
                                />
                                <i class="posting pl-2" v-if="loading"
                                    >Posting Comment ...</i
                                >
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <button
                    class="btn btn-default btn-block btn-load-comment mt-4"
                    @click="loadMore"
                    v-if="posts.length > 4"
                >
                    {{ loadText }}
                </button>
            </div>
            <div
                v-else
                class="text-center mt-5"
                style="font-weight: lighter !important; font-size:12px; color: #797979;"
            >
                <h4 style="font-size:15px">No Post Yet</h4>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import swal from "sweetalert2";
import Post from "../../../components/dashboard/post";
import Comment from "../../../components/dashboard/comment";
import postcomment from "../../../components/dashboard/postComment";
import axios from "axios";
import moment from "moment";

// import InfiniteLoading from 'vue-infinite-loading';
export default {
    components: {
        Post,
        Comment,
        postcomment,
        // InfiniteLoading,
    },

    data() {
        return {
            posts: [],
            loading: true,
            loadingPostText: "Loading Community Posts ...",
            page: 1,
            loadText: "Load more",
            showNoResultText: false,
            searchText: "",
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        search(value) {
            this.page = 1;
            if (value.length > 2) {
                this.searchText = value;
                this.loadingPostText = "Searching ...";
                this.loading = true;
                axios
                    .get("/post/search", {
                        params: {
                            s: value,
                            page: this.page,
                        },
                    })
                    .then((response) => {
                        if (response.data.payload.posts.data.length > 0) {
                            this.posts = response.data.payload.posts.data;
                        } else {
                            this.showNoResultText = true;
                        }
                        this.loadingPostText = "No post yet ...";
                        this.loading = false;
                    });
            }

            if (value.length < 1) {
                this.showNoResultText = false;
            }
        },
        openPost(_id) {
            this.$router.push({ name: "SingleBlog", params: { id: _id } });
        },
        handleLoadMore() {
            axios.get("/post/allPost?page=" + this.page).then((response) => {
                this.posts = response.data.payload.posts.data;
                this.loading = false;
            });

            this.page = this.page + 1;
        },

        postDate(postD) {
            return moment(postD).format("h:mma, MMMM Do YYYY");
        },

        loadMore() {
            this.loadText = "Loading...";
            axios.get("/post/allPost?page=" + this.page).then((response) => {
                $.each(response.data.payload.posts.data, (key, value) => {
                    this.posts.push(value);
                });
                this.loadText = "Load more";
            });

            this.page = this.page + 1;
        },
    },

    created() {
        this.handleLoadMore();
    },
};
</script>

<style lang="css" scoped>
/* .community-container{
    margin-top: 150px !important;
} */

.page-title-mobile {
    margin-top: 100px !important;
}
.cat_wrapper {
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
}

.cat_wrapper.active {
    border: 1px solid #044995;
    background: #f5faff;
}
.cat_paragraph {
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #3f3f3f;
}
.cat_heading {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #3f3f3f;
}
.page-title-mobile-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #e7e0e0;
    margin-bottom: 15px;
}
.community-card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.community-con {
    background-color: transparent;
}

.community-con h4 {
    font-style: normal;
    font-weight: 550;
    font-size: 24px;
    line-height: 31px;
    color: #606060;
}

.community-con p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 16px;
    color: #606060;
}

.media-wrapper .media img {
    height: 50px;
    width: 50px;
}

.media-wrapper .media {
    margin-bottom: 20px;
}

.media-wrapper .media .media-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 15px; */
    color: #606060;
}

.media-wrapper .media .media-body p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.community-card.post .media {
    margin-bottom: 20px;
}

.community-card.post .media .media-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
}

.community-card.post .media .media-body p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px !important;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.community-card.post .media img {
    height: 30px;
    width: 30px;
}

.contribution-count {
    float: right;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.04em;

    color: #a7a5a5;
}

.contribution-count img {
    width: 15px !important;
    height: 13px !important;
}

.contribution-count2 {
    font-style: normal;
    /* font-weight: 600; */
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.04em;

    color: #a7a5a5;
}

.contribution-count2 img {
    width: 15px !important;
    height: 13px !important;
}

.community-card.compose h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* line-height: 18px; */
    color: #a7a5a5;
}

.post-input {
    background: #fff !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 25px;
    height: 40px;
    margin-top: 5px;
}

.post-input2 {
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 550;
    box-sizing: border-box;
    border-radius: 25px;
    height: 40px;
    margin-top: 5px;
}
.recent-head {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: #a7a5a5;
    position: relative;
}

.recent-head::before {
    bottom: 7px;
    content: "";
    height: 1px;
    left: 3px;
    position: absolute;
    width: 89%;
    background-color: #e9ecef;
}

.post-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    color: #606060;
}

.comment-box-body {
    background: #f8fbfe;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
}

.comment-box-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
}

.comment-box-body h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.comment-box-body h4.time-ago {
    font-style: normal;
    font-weight: 300;
    font-size: 8px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #08386d;
}

.comment-box-body h4.comment-content {
    font-style: normal;
    font-weight: normal;
    /* margin-top: 3rem; */
    font-size: 10px;
    line-height: 16px;
    color: #606060;
}

.btn-load-comment {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #a7a5a5;
}
</style>
