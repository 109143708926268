<template>
    <nav class="navbar navbar-light navbar-expand-sm py-2 fixed-top" :class="[changeNavColor ? 'newBg' : 'oldBg']">
        <div class="container-fluid px-lg-4 pt-lg-3 mx-lg-5">
            <a href="https://lms.terrahq.co/" class="navbar-brand p-0">
                <img src="@/assets/img/logo-dark.png" alt="logo" class="nav-logo" style="width:150px"/>
            </a>
        </div>
    </nav>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import swal from "sweetalert2";

export default {
    name: "Navbar",
    data() {
        return {
            changeNavColor: false,
            canBeLoggedIn: false,
            navMobile: false,
        };
    },
    beforeMount() {
        window.addEventListener("scroll", this.handleScroll);
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods: {
        handleScroll() {
            if (window.pageYOffset > 20) {
                this.changeNavColor = true;
            } else {
                this.changeNavColor = false;
            }
        },
        ...mapActions({
            signOutAction: "auth/signOut",
        }),

        logout() {
            swal.fire({
                title: "Leaving Already",
                text: "Are you sure, you want to logout?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#dc3545",
                cancelButtonColor: "#353535",
                confirmButtonText: "Logout!",
            }).then((result) => {
                ///send request
                if (result.value) {
                    this.signOutAction().then(() => {
                        this.$router.replace({ name: "Login" });
                    });
                }
            });
        },
    },

    mounted() {
        if (this.user.token != undefined || this.user.course_id != undefined) {
            this.canBeLoggedIn = true;
        } else {
            this.canBeLoggedIn = false;
        }
    },
};
</script>

<style scoped>
.container{
    transition: 1000ms ease;
}
.newBg {
    background-color:transparent;
    background-color: #08386D;
    z-index: 777;
}
.onScroll {
    color: #52A350 !important;
    font-weight: 500;
}
.oldBg {
    background-color: transparent;
}
.navbar{
    border: none !important;
}
.btnStyled {
    border: 2px solid #21d536 !important;
    font-weight: 500;
}

.nav-link{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #52A350 !important;
    cursor: pointer;
}
.nav-internship-cta{
    background: #52A350;
    border: 2px solid #52A350 !important;
    color: #FFFFFF;
    font-size: 1rem;
    border-radius: 10px;
}
.nav-employer-cta{
    background: #E1F0E0;
    font-size: 1rem;
    border-radius: 10px;
    color: #52A350;
    border: 2px solid rgba(82, 163, 80, 0.2) !important;
}
.nav-bar-cta:hover{
    border: 2px solid transparent;
    color: #08386D;
    background: #B6D86F;
}
@media screen and (max-width:776px) {
    .nav-bar-links{
    text-align: left;
    margin:10px 0;
    /* display: none; */
    }
    .navbar{
    border-bottom: 2px solid #b6d86f !important;
}
}
</style>
