<template>
    <div class="modal-container">
        <div
            class="modal-child p-5 d-flex flex-column align-items-center justify-content-between"
        >
            <img
                src="@/assets/img/partners/terrapay.svg"
                alt="Terra Pay"
                class="mb-3"
            />
            <img
                v-if="paymentSuccess"
                src="@/assets/img/gif/confetti.gif"
                width="300"
                alt=""
            />
            <img v-else src="@/assets/img/partners/error.png" alt="" />
            <h5 class="payment-status mb-4">
                Payment <span v-if="paymentSuccess">successful</span>
                <span v-else>failed</span>
            </h5>
            <button class="btn btn-primary" @click="proceed">Proceed</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        paymentSuccess: Boolean,
    },
    methods: {
        proceed() {
            localStorage.removeItem("status");
            this.$router.push({
                name: "Payment",
            });
        },
    },
};
</script>

<style scoped>
.modal-container {
    background-color: rgba(36, 36, 36, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2222;
    min-height: 100vh;
    min-width: 100vw;
}
.modal-child {
    width: 55%;
    background: #ffffff;
    box-shadow: 0px 24px 50px rgba(0, 0, 0, 0.04);
    border-radius: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.payment-status {
    color: #044995;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
}</style
>>
