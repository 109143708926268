<template>
    <div>
        <section class="py-30 start">
            <div class="col-md-4 col-sm-12 offset-md-4 p-0 badges-item">
                <div class="card text-center">
                    <div class="card-body">
                        <img
                            :src="badge.image"
                            class="card-img-top"
                            alt="Badge Image"
                        />
                        <h5 class="card-title mt-3">
                            {{ badge.title }}
                        </h5>
                        <p class="card-text">
                            {{ badge.description }}
                        </p>
                        <p class="card-text">
                            {{ user.first_name + ' '  + user.last_name}} earned this Badge.
                        </p>
                        <h5 class="card-title mt-3">
                            Congratulations ...
                        </h5>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: "RewardBadge",
    data() {
        return {
            badge: {},
            user: {},
            id: this.$route.params.id,
            user_id: this.$route.params.user_id,

        }
    },
    watch: {
        $route(to) {
            this.id = to.params.id;
            this.getBadge();
        },
    },
    methods: {
        getBadge(){
            axios
            .get("/reward/badge/"+this.id+"/"+this.user_id)
            .then((response) => {
                this.badge = response.data.badge;
                this.user = response.data.user;
            });
        }
    },
    mounted(){
        this.getBadge();
    }
};
</script>
<style lang="css" scoped>
a[class^="share-network-"] {
    padding: 10px 15px;
    flex: none;
    color: #FFFFFF;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
    text-decoration: none;
}
a i{
    margin-right: 10px;
}
#share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
}
.badge-card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}
.page-title-mobile{
    margin-top: 100px !important;
}
.page-title-mobile-text{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #E7E0E0;
    margin-bottom: 15px;
}
.badge-card-title{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #606060;
}
.locked .card-body .card-text{
    font-size:12px !important;
    margin-top: 10px;
}
.badges-item h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #a7a5a5;
}

.badges-item p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #a7a5a5;
}

.badges-item .card {
    border: none;
    box-shadow: none;
}

.badges-item .locked {
    opacity: 0.4;
}
.badges-item .card-text{
    font-size: 12px !important;
    font-weight: normal;
}
.badges-item .card-title{
    font-size: 14px !important;
    font-weight: 550;
}
.badges-item img {
    width: 84px;
    height: 90px;
}

.badge-card-title-locked {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}
</style>

