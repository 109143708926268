import Quiz from "@/views/admin/quiz/Quiz";
import AddLesson from "@/views/admin/lesson/addLesson";
import Classes from "@/views/admin/liveClasses/Classes";
import RecordClasses from "@/views/admin/recordClasses/Classes";

const adminRoutes = [
    {
        path: "/admin/quiz/add",
        name: "admin",
        component: Quiz,
        meta: {
            requiresAuth: true,
            isAdminMentor: true,
            isAdmin: true,
            title: "Quiz",
        },
    },
    {
        path: "/admin/live-classes",
        name: "liveClasses",
        component: Classes,
        meta: {
            requiresAuth: true,
            isAdminMentor: true,
            title: "Quiz",
        },
    },
    {
        path: "/admin/recorded-classes",
        name: "RecordClasses",
        component: RecordClasses,
        meta: {
            requiresAuth: true,
            isAdminMentor: true,
            title: "Recorded Classes",
        },
    },
    {
        path: "/admin/lesson/add",
        name: "AddLesson",
        component: AddLesson,
        meta: {
            requiresAuth: true,
            isAdmin: true,
            isAdminMentor: true,
            title: "Add Lesson",
        },
    },
];

export default adminRoutes;
