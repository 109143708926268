<template>
    <!--Lesson Card -->

    <div
        @click="getLesson(lesson)"
        class="col-12 d-flex flex-column lesson-card px-3 py-4 pb-5 mb-4 relative"
        v-for="(lesson, index) in lessons"
        :key="index"
    >
        <div class="lesson-title mt-0 ">
            <h3
                class="mb-3 text-break col-11 p-0"
                style="font-family: Nunito;font-style: normal;font-weight: 500;font-size: 15px;color: #242424;"
            >
                <i
                    class="fa-solid fa-circle-check"
                    v-if="watched && watched.includes(lesson.id)"
                ></i>
                {{ lesson.title }}
            </h3>
            <div
                class="col-11 m-0 p-0"
                style="font-family: Nunito;font-style: normal;font-weight: 300;font-size: 14px;line-height: 18px;color: #242424;"
            >
                <p class="text-break">
                    {{ lesson.description }}
                </p>
            </div>
        </div>
        <button class="lesson-action">
            <i class="fas fa-arrow-right"></i>
        </button>
    </div>
    <!-- End of Quiz Card -->
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    props: {
        lessons: Array,
        watched: Array,
    },
    data() {
        return {
            total_progress: "",
            user_progress: "",
            progress_value: 10,
        };
    },
    computed: {
        ...mapGetters({
            week: "auth/currentWeek",
        }),
    },
    methods: {
        getLesson(_lesson) {
            let lesson = JSON.stringify(_lesson);
            let lessons = JSON.stringify(this.lessons);
            this.$router.push({
                name: "Lesson",
                params: {
                    lesson,
                    lessons,
                },
            });
        },
    },
};
</script>

<style scoped>
.lesson-card {
    background-color: #fff;
    background-image: url("./../../../../assets/img/line/line2.png");
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
    border-radius: 14px;
    /* min-width: 250px; */
    max-width: 46%;
    /* max-height: 300px; */
    min-height: max-content;
    overflow: hidden;
    border: 1px solid #B8B8B8;
}
.lesson-card:last-child {
    margin-right: 0 !important;
}
.relaive {
    position: relative;
}

.lesson-action {
    padding: 5px;
    width: 30px;
    height: 30px;
    background: #d1e7fa;
    border-radius: 50%;
    font-weight: 600;
    font-size: 10px;
    color: #08386d;
    position: absolute;
    bottom: 15px;
}

@media screen and (max-width: 576px) {
    .lesson-card {
        max-width: 100%;
    }
}
</style>
