<template>
    <div class="home-con">
        <NavbarVue />
        <router-view />
        <FooterVue />
    </div>
</template>

<script>
import FooterVue from "../components/inc/Footer.vue";
import NavbarVue from "../components/inc/Navbar.vue";
export default {
    name: "Homepage",
    components: {
        NavbarVue,
        FooterVue,
    },
};
</script>

<style src="@/assets/css/main.css"></style>
<style scoped>
/* .home-con::after {
    background: url('./../assets/img/backgrounds/hero-bg.png') no-repeat;
    background-size: auto;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0px;
    right:0px;
} */
</style>
