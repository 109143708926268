<template>
  <div class="col-md-10 col-11 col-lg-8 m-auto cookie-container justify-content-between align-items-center p-4 d-none">
      <div>
          <div style="font-weight:bold;font-size:1rem;color: #5F5F5F;">Cookie Notification {{cookie}}</div> 
          <div style="font-size: 0.7rem;color: #5F5F5F;">By continuing to use this site, you agreed to our Cookie Policy</div>
      </div>
      <div>
          <button @click="setCookie" class="cookie-btn px-4 py-2">Got it</button>
      </div>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies';

export default {
    name: "Cookie",
    data(){
        return{
            cookie:''
        }
    },
    methods:{
        setCookie(){
         //
        }
    },
}
</script>

<style scoped>
    .cookie-container{
        z-index: 99;
        position: fixed;
        bottom: 30px;
        left: 0%;
        right:0%;
        background: #fff;
        /* background: linear-gradient(99.82deg, #FFFFFF 0%, rgba(255, 255, 255, 0.91) 117.45%, rgba(255, 255, 255, 0.46) 117.45%); */
        box-shadow: 8px 12px 50px rgba(56, 114, 54, 0.09);
        border-radius: 15px;
    }
    .cookie-btn{
        background: #2F2F2F;
        border-radius: 10px;
        color: #ffffff;
        font-weight: bold;
        font-size: 0.7rem;
    }
</style>