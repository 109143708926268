<template>
    <div
        class="col-12 col-md-12 m-0 p-0 course-info-section mb-4 pb-2 d-flex justify-content-between flex-column"
        v-if="!isLoading"
    >
        <div
            class="col-12 p-0 mb-4 d-flex align-items-start justify-content-between"
            v-if="course"
        >
            <div class="">
                <div class="course-titles mb-4">
                    {{ course.title }}
                </div>
                <div class="course-details row m-0 p-0">
                    <h5 class="mb-3 col-12 m-0 p-0">COURSE INFO</h5>
                    <div class="col-12 m-0 p-0">
                        <table class="col-12">
                            <tr>
                                <td class="headings">Duration</td>
                                <td class="subs">6 weeks</td>
                            </tr>
                            <tr>
                                <td class="headings">Prerequisite</td>
                                <td class="subs">
                                    <ul class="pl-3 prerequisite-list">
                                        <li
                                            class="pb-1"
                                            v-for="(prer,
                                            index) in course.key_area"
                                            :key="index"
                                        >
                                            {{ prer }}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="headings">Course</td>
                                <td class="subs">Free</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="course-progress d-flex flex-column align-items-center">
                <circle-progress
                    :percent="Number(progress.course_progress)"
                    background="#fff"
                    :border-width="5"
                    :border-bg-width="5"
                    empty-color="#F8F9FA"
                    class="progressClass font-weight-bold"
                    :size="80"
                    fill-color="#52a350"
                    :show-percent="true"
                    :viewport="true"
                />
                <h5 class="mt-3">COURSE PROGRESS</h5>
            </div>
        </div>
        <div
            class="col-12 p-0 mb-4 d-flex align-items-start justify-content-between"
            v-if="extracourse"
        >
            <div class="">
                <div class="course-titles mb-4">
                    {{ extracourse.title }}
                </div>
                <div class="course-details row m-0 p-0">
                    <h5 class="mb-3 col-12 m-0 p-0">COURSE INFO</h5>
                    <div class="col-12 m-0 p-0">
                        <table class="col-12">
                            <tr>
                                <td class="headings">Duration</td>
                                <td class="subs">6 weeks</td>
                            </tr>
                            <tr>
                                <td class="headings">Prerequisite</td>
                                <td class="subs">
                                    <ul class="pl-3 prerequisite-list">
                                        <li
                                            class="pb-1"
                                            v-for="(prer,
                                            index) in extracourse.key_area"
                                            :key="index"
                                        >
                                            {{ prer }}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="headings">Course</td>
                                <td class="subs">Free</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="course-progress d-flex flex-column align-items-center">
                <circle-progress
                    :percent="Number(progress.extra_course_progress)"
                    background="#fff"
                    :border-width="5"
                    :border-bg-width="5"
                    empty-color="#F8F9FA"
                    class="progressClass font-weight-bold"
                    :size="80"
                    fill-color="#52a350"
                    :show-percent="true"
                    :viewport="true"
                />
                <h5 class="mt-3">COURSE PROGRESS</h5>
            </div>
        </div>
    </div>
    <div
        class="col-12 col-md-12 m-0 p-0 course-info-section mb-4 pb-2 d-flex justify-content-between flex-column"
        v-else
    >
        <p>Loading...</p>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import NProgress from "nprogress";
import axios from "axios";
import CircleProgress from "vue3-circle-progress";

export default {
    data() {
        return {
            userPro: 0,
            course: {},
            extra_course: {},
            isLoading: true,
            userProgress: {},
            totalProgress: {},
            userQuizProgress: {},
            extracourseprogress: 0,
            courseprogress: 0,
            progress: {},
        };
    },
    components: {
        CircleProgress,
    },
    computed: {
        ...mapGetters({
            week: "auth/currentWeek",
        }),
    },

    methods: {
        getUserCourses() {
            axios
                .get("user/course")
                .then((res) => {
                    this.course = res.data.payload;
                    axios.get("user/extracourse").then((res) => {
                        this.extracourse = res.data.payload;
                        this.getUserProgress();
                        this.isLoading = false;
                    });
                })
                .catch(() => {
                    // TODO:
                });
        },

        getUserProgress() {
            axios
                .get("/progress/tracker", {
                    params: {
                        week: this.week,
                    },
                })
                .then((res) => {
                    let total =
                        Number(res.data.payload.course_progress) +
                        Number(res.data.payload.extra_course_progress);
                    this.progress = res.data.payload;
                })
                .catch((response) => {});
        },
    },

    mounted() {
        this.getUserCourses();
    },
};
</script>

<style scoped>
.prerequisite-list {
    line-height: 16px;
}
* {
    font-family: Nunito;
}
table > tr {
    vertical-align: baseline;
}
.course-progress h5 {
    font-family: Nunito;
    font-weight: normal;
    font-size: 11px;
    line-height: 11px;
    color: #949494;
    text-align: center;
}
.course-titles {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #242424;
}
.course-details h5 {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #a8a8a8;
}
.course-details .headings {
    font-weight: 600;
    font-size: 12px;
    line-height: 32px;
    color: #606060;
    padding-right: 10px;
}
.course-details .subs {
    font-size: 12px;
    line-height: 32px;
    color: #606060;
}
.course-info-section {
    border-bottom: 1px solid #eaeeea;
}
.course-info-section:last-child {
    border-bottom: none;
}
</style>
