<template>
    <div
        class="container-fluid px-xlg-100 mt-lg-5 px-sm-20 px-lg-30 py-xlg-100 col-12  d-flex flex-column justify-content-center align-items-center benefit-section"
    >
        <div
            class="col-12 d-flex flex-lg-row flex-column justify-content-between align-items-center p-0"
        >
            <div class="col-lg-4 col-12 p-0 mt-lg-5 align-self-start">
                <h2 class="section-heading mt-lg-4 text-center text-lg-left">
                    What you stand to benefit
                </h2>
                <p
                    class="hero-text col-11 p-0 text-center text-lg-left mt-5 mt-lg-4 px-3 px-lg-0"
                >
                    Since 2020, Side Hustle has trained over 200 thousand
                    African youths in various fields across Engineering,
                    Marketing, Product and Design.
                </p>
                <router-link :to="{ name: 'Register' }">
                    <button
                        class="px-4 py-2 cta-btn d-none d-lg-block mt-5 mt-lg-4"
                    >
                        Apply as an Intern
                    </button>
                </router-link>
            </div>
            <!--  -->
            <BenefitCards />
        </div>
    </div>
</template>

<script>
import BenefitCards from './BenefitCards.vue'
export default {
    name: "Benefit",
    components:{
        BenefitCards,
    }
};
</script>

<style scoped>
.benefit-section{
    background-color: #FFFFFF;
    background-size: cover;
    background-repeat: no-repeat;
}

.section-heading{
    font-weight: 600;
    font-family:'GD Boing', sans-serif;
    font-size: 2.5rem;
    line-height: 3.0rem;
    color: #2F2F2F;
}
.section-text{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    color: #898989;
}

@media only screen and (max-width: 578px) {
    .section-text {
        text-align:center;
    }
}

.cta-btn{
    color: #FFFFFF;
    background: #2F2F2F;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1.5rem;
    border-radius: 10px;
    transition: 800ms ease;
    background-color: #52A350;
}
.cta-btn:hover {
    background-color: #E1F0E0;
    color: #52A350;
}
</style>
