<template>
    <div class="modal-show">
        <div class="modal-container mx-auto">
            <div class="col-12 p-0">
                <div
                    class="py-3 d-flex flex-column align-items-center"
                    v-if="!challenge && !isQualify"
                >
                    <h4 class="text-center empty-changel-text">
                        No Challenge available <br />
                        at the moment
                        <br />
                        <span class="face">😣</span>
                    </h4>
                    <h6>Check back later</h6>
                </div>
                <div class="col-12 p-0" v-else>
                    <div
                        class="py-3 d-flex flex-column align-items-center"
                        v-if="isQualify"
                    >
                        <h4 class="text-center">
                            {{ challenge.name }}
                            <br />
                        </h4>
                        <h6>{{ challenge.description }}</h6>
                        <div
                            class="footer-social-btns my-lg-0 my-4 py-0 col-md-6 col-9 mx-0 px-0 d-flex justify-content-between align-items-center"
                        >
                            <a target="_blank" href="https://www.facebook.com/">
                                <img
                                    src="@/assets/img/icons/social/fb.png"
                                    alt=""
                                />
                            </a>
                            <a target="_blank" href="https://www.linkedin.com">
                                <img
                                    src="@/assets/img/icons/social/linkedin.png"
                                    alt=""
                                />
                            </a>
                            <a target="_blank" href="https://twitter.com">
                                <img
                                    src="@/assets/img/icons/social/twitter.png"
                                    alt=""
                                />
                            </a>
                            <a href="https://instagram.com">
                                <img
                                    src="@/assets/img/icons/social/ig.png"
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                    <h6 class="col-12 my-3" v-else>
                        You are not qualified for this week's challenge, kindly
                        take your lessons and quizzes
                    </h6>
                </div>
                <div class="mt-2">
                    <div
                        class="onboard-btn px-5 py-2 text-center"
                        style=""
                        @click="$emit('closeModal')"
                    >
                        Close
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            challenge: {},
            isQualify: false,
            streak: 0,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
    },
    methods: {
        getChallenges() {
            axios
                .get("/goodies/challenges")
                .then((res) => {
                    this.challenge = res.data.payload;
                })
                .catch(() => {
                    this.challenge = {};
                });
        },
        getStreak() {
            let data = {
                week: this.week,
            };
            axios
                .post("/newStreak", data)
                .then((res) => {
                    this.streak = parseInt(res.data.payload.Streak);
                    switch (this.week) {
                        case 1:
                            this.isQualify =
                                this.streak >= 10 && this.streak <= 17
                                    ? true
                                    : false;
                            break;
                        case 2:
                            this.isQualify =
                                this.streak >= 24 && this.streak <= 40
                                    ? true
                                    : false;
                            break;
                        case 3:
                            this.isQualify =
                                this.streak >= 41 && this.streak <= 57
                                    ? true
                                    : false;
                            break;
                        case 4:
                            this.isQualify =
                                this.streak >= 58 && this.streak <= 74
                                    ? true
                                    : false;
                            break;
                        case 5:
                            this.isQualify =
                                this.streak >= 75 && this.streak <= 100
                                    ? true
                                    : false;
                            break;
                        case 6:
                            this.isQualify =
                                this.streak >= 75 && this.streak <= 100
                                    ? true
                                    : false;
                            break;
                        default:
                            this.isQualify = false;
                            break;
                    }
                    if (this.isQualify) {
                        this.getChallenges();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },

    mounted() {
        this.getStreak();
    },
};
</script>
<style scoped>
/* .modal-show{
position: fixed;
min-width: 100vw;
min-height: 100vh;
background-color: gray;
z-index: 1111;
top:0;
bottom:0;
left:0;
right:0;
} */
.empty-changel-text {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #242424;
}
.modal-show {
    min-height: 100vh;
    min-width: 100vw;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
}
.face {
    margin-top: 10px;
}
.modal-container {
    width: 45%;
    height: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    background-image: url(./../../../assets/img/onbaord-modal-bg.png);
    border-radius: 25px;
}
.onboard-btn {
    background: #ffffff;
    border: 1px solid #52a350 !important;
    border-radius: 10px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #52a350;
    transition: ease-in-out 500ms;
    cursor: pointer;
}
.onboard-btn:hover {
    background: #52a350;
    border: 1px solid #fff !important;
    font-weight: normal;
    cursor: pointer;
    color: #fff;
}

@media screen and (max-width: 776px) {
    .modal-container {
        width: 80%;
        padding: 20px;
        background-image: url(./../../../assets/img/onbaord-modal-bg.png);
        border-radius: 25px;
    }
    .empty-changel-text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #242424;
    }
}
</style>
