<template>
    <footer
        class="container-fluid px-xlg-100 px-sm-20 justify-content-between py-xlg-100"
    >
        <div class="container p-0">
            <div
                class="d-flex flex-column flex-md-row justify-content-between pb-5 px-0"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)"
            >
                <!-- first section -->
                <div class="col-12 col-md-4 col-lg-4 d-flex flex-column px-0">
                    <ul class="navbar-nav" style="color:green !important">
                        <li class="nav-item mb-4">
                            <a href="/"
                                ><img
                                    class="img-fluid"
                                    src="../../assets/img/logo-dark.png"
                                    alt="Side Hustle Logo"
                                    style="width:150px"
                            /></a>
                        </li>
                        <li class="nav-item mb-1 pl-2">
                            <a class="footer-link font-weight-bold"
                                >Get in Touch</a
                            >
                        </li>
                        <li class="nav-item mb-1 pl-2 d-none">
                            <a
                                class="footer-link"
                                href="mailto:hello@sidehustle.ng"
                                target="_blank"
                                >+234-70-6705-9943</a
                            >
                        </li>
                        <li class="nav-item mb-1 pl-2">
                            <a
                                class="footer-link"
                                href="mailto:hello@sidehustle.ng"
                                target="_blank"
                                >hello@sidehustle.ng</a
                            >
                        </li>
                        <li class="nav-item mb-1 pl-2">
                            <a
                                class=" footer-link"
                                href="https://wa.me/message/HX6JWNMRVERVO1"
                                target="_blank"
                                ><i class="fab fa-whatsapp"></i> Chat with us on
                                Whatsapp</a
                            >
                        </li>
                        <li class="nav-item social-icons mt-4 pl-2"></li>
                    </ul>
                </div>

                <!-- Quick link section -->
                <div class="col-12 col-md-3 col-lg-2 text-left px-0">
                    <h4
                        class="font-weight-bold mb-4"
                        style="font-size:14px;color:#08386D;"
                    >
                        QUICK LINKS
                    </h4>
                    <ul class="navbar-nav d-flex flex-column">
                        <!-- <li class="nav-item mb-1">
                            <router-link class="footer-link" :to="{ name: 'Courses' }"
                                >Courses</router-link
                            >
                        </li> -->
                        <li class="nav-item mb-1">
                            <router-link
                                class="footer-link"
                                :to="{ name: 'Faqs' }"
                                >FAQs</router-link
                            >
                        </li>
                        <!-- <li class="nav-item mb-1">
                            <router-link class="footer-link" to="ambassadors"
                                >Become an Ambassador</router-link
                            >
                        </li> -->
                        <li class="nav-item mb-1">
                            <a
                                href="https://toptalent.africa/"
                                class="footer-link"
                                target="_blank"
                                >Hire a Talent</a
                            >
                        </li>
                    </ul>
                </div>
                <!-- Company section -->
                <div class="col-12 col-md-2 text-left">
                    <h4
                        class="font-weight-bold mb-4"
                        style="font-size:14px;color:#08386D;"
                    >
                        COMPANY
                    </h4>
                    <ul
                        class="navbar-nav d-flex flex-column"
                        style="color:green;"
                    >
                        <li class="nav-item mb-1">
                            <router-link
                                class="footer-link"
                                :to="{ name: 'About' }"
                                >About Us</router-link
                            >
                        </li>
                        <!-- <li class="nav-item mb-1">
                            <router-link class="footer-link" to="#"
                                >Careers</router-link
                            >
                        </li> -->
                        <li class="nav-item mb-1">
                            <router-link
                                class="footer-link"
                                :to="{ name: 'TermsConditions' }"
                                >Terms of Use</router-link
                            >
                        </li>
                        <li class="nav-item">
                            <router-link
                                class="footer-link"
                                :to="{ name: 'Privacy' }"
                                >Privacy Policy</router-link
                            >
                        </li>
                    </ul>
                </div>

                <!-- Call to Action -->
                <!-- <div class="col-12 col-md-3 col-lg-4 d-flex flex-lg-row flex-column align-items-center align-items-md-start">
                   <a href="#"><img class="mb-3 mb-lg-0" src="@/assets/img/playstore.svg" alt="" style="transform: scale(0.8);"></a>
                   <a href="#"><img src="@/assets/img/appstore.svg" alt="" style="transform: scale(0.8);"></a>
                </div> -->
            </div>
            <!-- floating whatsapp btn -->
            <a
                class="floating-cta text-center px-2 text-success"
                href="https://wa.me/message/HX6JWNMRVERVO1"
                target="_blank"
                ><i class="fab fa-whatsapp fa-3x" style="font-size:4rem"></i
                ><br />Chat</a
            >
            <div
                class="d-flex flex-column-reverse flex-md-row justify-content-between align-items-center mt-3"
            >
                <p class="mt-3 mb-0 pb-0 footer-note px-0 px-md-0 nav-item">
                    © {{ year }} Side Hustle Internship | All Rights Reserved.
                </p>
                <div
                    class="footer-social-btns my-lg-0 my-4 py-0 col-md-4 col-lg-2 col-6 mx-0 px-0 d-flex justify-content-between align-items-center"
                >
                    <a href="https://www.facebook.com/SideHustleNigeria/">
                        <img src="@/assets/img/icons/social/fb.png" alt="" />
                    </a>
                    <a href="https://www.linkedin.com/company/sidehustleng">
                        <img
                            src="@/assets/img/icons/social/linkedin.png"
                            alt=""
                        />
                    </a>
                    <a href="https://twitter.com/ngsidehustle?s=11">
                        <img
                            src="@/assets/img/icons/social/twitter.png"
                            alt=""
                        />
                    </a>
                    <a
                        href="https://instagram.com/sidehustleinternship?igshid=1sb7ei0owciam"
                    >
                        <img src="@/assets/img/icons/social/ig.png" alt="" />
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            year: new Date().getFullYear(),
        };
    },
};
</script>

<style scoped>
footer {
    font-family: "Nunito", sans-serif;
}
.nav-item .footer-link {
    color: rgba(52, 52, 52, 0.7);
}
/* .footer-social-btns a{
    color: #08386D;
    font-size: 0.6rem;
} */
</style>
