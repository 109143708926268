<template>
    <DashboardNav />
    <div class="row">
        <div class="col-lg-2 sidebar px-5 d-none d-lg-block">
            <Navigationbar />
        </div>
        <div class="col-md-12 col-lg-9 px-lg-3">
            <div class="router-container">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import DashboardNav from "../../components/inc/DashboardNav.vue";
import Navigationbar from "@/components/inc/Navigationbar.vue";
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
import axios from "axios";

export default {
    components: { DashboardNav, Navigationbar },
    name: "Index",
    data() {
        return {
            settings: {},
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
    },
    methods: {
        ...mapActions({
            signOutAction: "auth/signOut",
        }),

        // getSiteSettings() {
        //     axios
        //         .get("site-settings")
        //         .then((res) => {
        //             //if site setting allow access until start date
        //             if (
        //                 res.data.payload.can_access &&
        //                 this.user.role != "admin"
        //             ) {
        //                 this.$router.push({
        //                     name: "WelcomeDashboard",
        //                 });
        //             }
        //         })
        //         .catch((err) => {
        //             this.$router.push({
        //                 name: "Home",
        //             });
        //         });
        // },
    },
    mounted() {
        // this.getSiteSettings();
        if (!this.user.course_id && !this.user.extra_course_id) {
            this.$router.push({
                name: "First",
            });
            // }else if (this.user.stipend !== 1 && this.user.bloom == null ) {
            //     this.$router.push({ name: "Third" });
            //     swal.fire({
            //         title: "Oops",
            //         html: "You are yet to complete your registration. Update your Bloomm account details to complete your registration",
            //         icon: "warning",
            //         confirmButtonText: "Enter Bloomm Details",
            //     })
        } else if (!this.user.taken_baseline) {
            this.$router.push({ name: "Baselinetest" });
            swal.fire({
                title: "Oops",
                html:
                    "You are yet to take your baseline test. Take and pass your baseline test to access your dashboard.",
                icon: "warning",
                confirmButtonText: "Take Baseline",
            });
        } else if (this.user.mode == null) {
            this.$router.push({ name: "Onboard" });
        }
    },
};
</script>
<style src="@/assets/css/dashboard.css"></style>
<style scoped>
.sidebar {
    height: 100vh;
    background: #fafafa;
    max-width: 20%;
    z-index: 99;
    position: sticky;
    bottom: 0;
    top: 0;
}

.router-container {
    position: relative;
}
</style>
