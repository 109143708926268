<template>
    <div class="col-md-10 offset-md-1 mt-lg-5">
        <div class="col-md-4 col-12 mx-md-auto" v-if="user.taken_baseline">
            <div
                class="benefits-card2 pl-3 pt-4 pb-4 pr-3 programming text-left text-sm-center"
            >
                <img
                    class="mb-4 d-none d-lg-block"
                    style="margin: 0 auto"
                    src="@/assets/img/benefits/network2.png"
                />
                <div class="benefits-card-title2 mb-3">Take Baseline Test</div>
                <div class="benefits-card-details2">
                     Congratulations! You have succesfully taken the Side Hustle Internship Cohort 5.0 Baseline Test. You will get a mail regarding your acceptance soon.
                </div>
                <button class="baseline-btn mt-4" disabled>
                    You've already taken this test
                </button>
            </div>
        </div>
        <div class="col-md-4 col-12 mx-md-auto" v-else>
            <router-link :to="{ name: 'Baselinetest' }">
                <div
                    class="benefits-card2 pl-3 pt-4 pb-4 pr-3 programming text-left text-sm-center"
                >
                    <img
                        class="mb-4 d-none d-lg-block"
                        style="margin: 0 auto"
                        src="@/assets/img/benefits/network2.png"
                    />
                    <div class="benefits-card-title2 mb-3">
                        Take Baseline Test
                    </div>
                    <div class="benefits-card-details2">
                         Before you can complete your application, all prospective Interns must take a baseline test during registration to test your general knowledge.
                    </div>
                    <button class="baseline-btn mt-4">Proceed to Test</button>
                </div>
            </router-link>
        </div>

        <div class="col-12 mt--lg-4 mb-5">
            <span class="mb-0 col-12 text-center d-block skip">
                <router-link :to="{ name: 'WelcomeDashboard' }" class="skip"
                    >Back
                </router-link>
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Counter from "../../../components/inc/Counter.vue";

export default {
    components: {
        Counter,
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
    },

    mounted() {
        this.attempt();
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Dashboard";
        },
    },
};
</script>

<style scoped>
.baseline-btn {
    padding: 12px 24px;
    background: #044995;
    border-radius: 10px;
    color: #fff;
    font-family: Nunito;
    font-size: 14px;
}

.baseline-btn:disabled,
.baseline-btn[disabled] {
    background: #e9e9e9;
    color: #4d4d4d !important;
    border: 2px solid #dedede !important;
    cursor: not-allowed;
}
</style>
