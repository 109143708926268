<template>
    <div class="px-3 pb-2">
        <div class="d-flex pl-2">
            <h5 class="page-title">
                Profile
            </h5>
        </div>
        <div>
            <div class="d-flex align-items-center mt-5">
                <div class="d-flex flex-column">
                    <div class="mr-4 d-flex">
                        <img
                            :src="
                                user.avatar
                                    ? user.avatar
                                    : '@/assets/img/avatar.png'
                            "
                            class="rounded-circle profile-pic"
                            alt="user.first_name"
                            width="60"
                            height="60"
                        />
                        <!-- <i
                            class="fa-solid fa-camera text-secondary ml-n3 mb-0"
                        ></i> -->
                    </div>
                    <!-- <span style="font-size:0.5rem">{{ msg }}</span> -->

                    <!-- <input
                        type="file"
                        name="profile_pic"
                        id="profile_pic"
                        class="d-none"
                        ref="profile_pic"
                        accept="image/*"
                        @change="updateProfilePic(e)"
                    /> -->
                </div>
                <div>
                    <div class="user-full-name">
                        {{ user.first_name }} {{ user.last_name }}
                    </div>
                    <div class="user-user-id">{{ user.user_id }}</div>
                </div>
            </div>
            <div v-if="canChangeMode">
                <button
                    @click="selectMode"
                    v-if="user.mode == 'normal'"
                    class="modeclass"
                >
                    Switch To Gamified Mode
                </button>
                <button @click="selectMode" v-else class="modeclass">
                    Switch To Normal Mode
                </button>
            </div>
            <div
                class=" d-flex justify-content-between align-items-center my-5 mx-0 col-lg-7 col-12 p-0"
            >
                <StreakProgress />
                <!-- <div class="text-primary d-none d-lg-block" v-if="user.mode==='gamified'">
                    <i class="fas fa-share-alt mr-2"></i>  Share Profile
                </div> -->
            </div>
            <div class="mt-5 col-lg-7 col-12 p-0">
                <div class="col-12 p-0 m-0">
                    <div class="">
                        <ul
                            class="nav nav-pills mb-3"
                            id="pills-tab"
                            role="tablist"
                        >
                            <li class="mr-5 profile-nav">
                                <a
                                    class="nav-link active px-0"
                                    id="pills-home-tab"
                                    data-toggle="pill"
                                    href="#pills-home"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                    >Profile</a
                                >
                            </li>
                            <li class="course-nav">
                                <a
                                    class="nav-link px-0"
                                    id="pills-profile-tab"
                                    data-toggle="pill"
                                    href="#pills-course"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                    >Course</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="mx-0 p-0 col-12 mt-5">
                    <div class="tab-content" id="pills-tabContent">
                        <div
                            class="tab-pane fade show active profile-details col-12 p-0 m-0"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                        >
                            <userinfo></userinfo>
                        </div>
                        <div
                            class="tab-pane fade col-12 m-0 p-0"
                            id="pills-course"
                            role="tabpanel"
                            aria-labelledby="pills-course-tab"
                        >
                            <courseinfo></courseinfo>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import swal from "sweetalert2";
import NProgress from "nprogress";
import courseinfo from "@/views/dashboard/profile/course-info.vue";
import userinfo from "@/views/dashboard/profile/user-info.vue";
import StreakProgress from "@/components/dashboard/streaks/profileProgress.vue";

export default {
    data() {
        return {
            msg: "",
        };
    },
    components: { courseinfo, userinfo, StreakProgress },
    computed: {
        ...mapGetters({
            user: "auth/user",
            course_ids: "auth/course_ids",
            week: "auth/currentWeek",
            settings: "auth/settings",
        }),
        canChangeMode() {
            if (this.settings && this.settings.can_change_mode == false) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
        selectMode() {
            let data = {
                mode: this.user.mode == "normal" ? "gamified" : "normal",
            };
            axios
                .post("/user/mode", data)
                .then(() => {
                    this.attempt();
                    this.$router.push({
                        name: "Dashboard",
                    });
                })
                .catch((err) => {
                    swal.fire(
                        "Error",
                        "Unable to complete the switch",
                        "error",
                    );
                });
        },
        // updateProfilePic() {
        //     let file = this.$refs.profile_pic.files[0];
        //     let reader = new FileReader();
        //     reader.onload = (e) => {
        //         this.msg = "Uploading...";
        //         axios
        //             .post("user/photo", { img: e.target.result })
        //             .then((res) => {
        //                 this.attempt();
        //                 this.msg = "Profile Picture has been uploaded";
        //                 setTimeout(() => {
        //                     this.msg = "";
        //                 }, 3000);
        //             })
        //             .catch((err) => {
        //                 this.msg =
        //                     "Error while uploading, please try again later";
        //                 setTimeout(() => {
        //                     this.msg = "";
        //                 }, 3000);
        //             });
        //     };
        //     reader.readAsDataURL(file);
        // },
    },
};
</script>

<style lang="css" scoped>
.user-full-name {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #3f3f3f;
}
.user-user-id {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #3f3f3f;
}
.nav-pills {
    border-bottom: 1px solid #eaeeea;
    padding-bottom: 0;
}
.nav-pills .nav-link {
    font-family: Nunito;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: #a9a9a9;
    border-radius: 0;
    padding-bottom: 10px;
    background: transparent;
}
.nav-pills .nav-link.active {
    font-weight: normal;
    color: #044995;
    border-bottom: 1px solid #044995;
}
.profile-pic {
    cursor: pointer;
}
.modeclass {
    border-radius: 8px;
    padding: 5px 10px;
    background-color: #044995;
    color: white;
    margin-top: 10px;
}
</style>
