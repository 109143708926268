<template>
    <div class="px-3">
        <div class="d-flex">
            <div class="mr-2" @click="$router.go(-1)">
                <i class="fas fa-arrow-left"></i>
            </div>
            <h5 class="page-title">Quiz</h5>
        </div>
        <div
            class="col-md-12 col-12 d-flex flex-column flex-md-row align-items-start mt-3 mt-md-4 p-0"
        >
            <div
                class="card-body lesson-card p-0 card col-md-4 col-12 bg-white mr-md-5 mb-4"
                v-for="(item, index) in courses"
                :key="index"
            >
                <div
                    class="px-3 py-4 col-12 d-flex flex-column justify-content-between"
                >
                    <div
                        class="d-flex flex-row align-items-start justify-content-between mb-3 mb-md-0 lesson-title mt-0"
                    >
                        <p
                            style="font-size: 18px !important;color: #242424;"
                            class="mr-3"
                        >
                            {{ item.title }}
                        </p>
                        <button style="" class="py-0 px-3">
                            {{
                                index === 0
                                    ? "1st"
                                    : index === 1
                                    ? "2nd"
                                    : "1st"
                            }}
                        </button>
                    </div>
                    <button
                        @click="quizIntro(item.id, item.title)"
                        class="btn-block mt-auto mb-0"
                        style="padding: 12px 18px;background: #044995;border-radius: 5px;font-weight: 550;font-size: 10px;line-height: 11px;letter-spacing: 0.04em;color: #FFFFFF;"
                        :disabled="
                            index === 0
                                ? takenFirstCourseQUiz
                                : takenSecondCourseQUiz
                        "
                        v-html="
                            `
                            ${
                                index === 0
                                    ? takenFirstCourseQUiz
                                        ? 'Quiz has been taken'
                                        : buttonText
                                    : takenSecondCourseQUiz
                                    ? 'Quiz has been taken'
                                    : buttonText
                            }`
                        "
                    ></button>
                </div>
                <!-- </div> -->
                <!-- End of Quiz Card -->
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            takenFirstCourseQUiz: false,
            takenSecondCourseQUiz: false,
            buttonText: `Take Quiz <i class="fas fa-arrow-right"></i>`,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            course_ids: "auth/course_ids",
            currentWeek: "auth/currentWeek",
            courses: "auth/courses",
        }),
    },
    methods: {
        quizIntro(id, title) {
            this.$router.push({
                name: "SelectWeek",
                params: {
                    c_id: id,
                    track: title,
                },
            });
        },
        checkQuiz() {
            let data = { user_id: this.user.user_id };
            axios
                .post("quiz/scores", data)
                .then((response) => {
                    if (response.data.payload.length > 0) {
                        response.data.payload.forEach((item) => {
                            if (
                                item.course_id === this.course_ids.course_id &&
                                item.week === this.currentWeek
                            ) {
                                this.takenFirstCourseQUiz = false;
                            }
                            if (
                                item.course_id ===
                                    this.course_ids.extra_course_id &&
                                item.week === this.currentWeek
                            ) {
                                this.takenSecondCourseQUiz = false;
                            }
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {
        this.checkQuiz();
    },
};
</script>

<style lang="css" scoped>
.card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}
#uploadJbCert {
    display: flex;
    align-items: center;
}
button:disabled {
    background: #08396da0 !important;
}
.lesson-card {
    background: #f9fafa !important;
    height: 200px;
    border: 1.4966px solid rgba(0, 0, 0, 0.1) !important;
    box-sizing: border-box !important;
    border-radius: 14.966px !important;
    box-shadow: none !important;
}
.grey {
    color: #606060;
}
.ash {
    color: #a7a5a5;
}

.lesson-title p {
    font-weight: 550;
    font-size: 20px !important;
    color: #606060;
}
.lesson-title button {
    padding: 5px !important;
    background: #b3e6b2;
    border-radius: 100%;
    font-weight: 550;
    font-size: 10px;
    letter-spacing: 0.04em;
    color: #52a350;
}

@media screen and (max-width: 768px) {
    .card {
        border-radius: 10px;
    }
    .lesson-title p {
        font-size: 14px !important;
    }
    .lesson-title button {
        padding: 2px 15px;
        min-width: fit-content;
        border-radius: 25px;
        font-size: 6px;
    }
}
</style>
