import axios from "axios";
export default {
    namespaced: true,
    state: {
        token: null,
        user: "",
        course_id: "",
        course_ids: {},
        courses: [],
        referral: "",
        currentWeek: "",
        settings: {},
    },

    getters: {
        user(state) {
            return state.user;
        },

        course_id(state) {
            return state.course_id;
        },

        course_ids(state) {
            return state.course_ids;
        },
        token(state) {
            return state.token;
        },
        courses(state) {
            return state.courses;
        },
        settings(state) {
            return state.settings;
        },
        // lessons(state) {
        //     return state.lessons;
        // },
        // lesson(state) {
        //     return state.lesson;
        // },
        getReferral(state) {
            return state.referral;
        },
        currentWeek(state) {
            return state.currentWeek;
        },
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_COURSE_ID(state, id) {
            state.course_id = id;
        },
        SET_COURSE_IDS(state, ids) {
            state.course_ids = ids;
        },

        SET_COURSES(state, courses) {
            state.courses = courses;
        },
        SET_REFERRAL(state, referral) {
            state.referral = referral;
        },
        SET_CURRENTWEEK(state, week) {
            state.currentWeek = week;
        },
        SET_SETTINGS(state, settings) {
            state.settings = settings;
        },
    },

    actions: {
        async attempt({ commit, state }, token) {
            if (token) {
                commit("SET_TOKEN", token);
            }
            if (!state.token) {
                return;
            }
            try {
                let response = await axios.get("auth/init");
                commit("SET_USER", response.data.payload);
                commit("SET_COURSE_IDS", {
                    course_id: response.data.payload.course_id,
                    extra_course_id: response.data.payload.extra_course_id,
                });
                let courses = [response.data.payload.course];
                if (response.data.payload.extra_course !== null) {
                    courses.push(response.data.payload.extra_course);
                }
                commit("SET_COURSES", courses);
                let res = await axios
                    .get("week")
                    .then((res) => res.data.payload);
                commit("SET_CURRENTWEEK", res);
            } catch (e) {
                commit("SET_TOKEN", null);
                commit("SET_USER", "");
            }
        },
        async fetchCourses({ commit, state }) {
            let courses = await axios
                .get("courses")
                .then((res) => res.data.payload);
            commit("SET_COURSES", courses);
        },

        async getCurrentWeek({ commit, state }) {
            let res = await axios.get("week").then((res) => res.data.week);
            commit("SET_CURRENTWEEK", res);
        },
        getSiteSetting({ commit, state }) {
            axios
                .get("site-settings")
                .then((res) => {
                    commit("SET_SETTINGS", res.data.payload);
                })
                .catch((err) => {});
        },

        signOut({ commit }) {
            return axios.post("auth/logout").then(() => {
                commit("SET_TOKEN", null);
                commit("SET_USER");
            });
        },
        getCourseId({ commit }, id) {
            commit("SET_COURSE_ID", id);
        },

        getReferral({ commit }, referral) {
            commit("SET_REFERRAL", referral);
        },
    },
};
