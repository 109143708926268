<template>
    <div class="px-3 pb-5">
        <div class="d-flex">
            <div class="mr-2" @click="$router.go(-1)">
                <i class="fas fa-arrow-left"></i>
            </div>
            <h5 class="page-title">
                Badges
            </h5>
        </div>
        <div
            class="col-md-10 col-12 d-flex flex-column flex-md-row align-items-start mt-3 mt-md-4 mb-4 mx-0 px-0"
        >
            <div class="card p-3 p-lg-5 bg-white badge-card">
                <div class="card-header p-0 bg-white">
                    <h4 class="card-title badge-card-title pb-2">
                        Keep tabs on earned badges and which you have left to
                        unlock
                    </h4>
                </div>
                <div class="card-body p-0">
                    <div class="card-body p-0">
                        <div class="row no-gutters">
                            <div
                                class="col-md-3 col-sm-6 p-0 badges-item"
                                v-if="awardedBadges.length < 1"
                            >
                                <div class="card text-center locked">
                                    <div class="card-body">
                                        <img
                                            src="@/assets/img/dashboard/LockedA.png"
                                            class="card-img-top"
                                            alt="Sidehustle badge Butterfly"
                                        />
                                        <p class="card-text">
                                            You haven’t earned any badge
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-md-3 col-sm-6 p-0 badges-item"
                                v-for="item in awardedBadges"
                                :key="item.id"
                                v-else
                            >
                                <div
                                    class="nav-link d-flex align-items-center"
                                >
                                    <div class="card text-center">
                                        <div class="card-body">
                                            <img
                                                :src="item.badge.link"
                                                class="card-img-top"
                                                :alt="
                                                    `Sidehustle ${item.badge.title} badge`
                                                "
                                            />
                                            <h5
                                                class="card-title mt-3 font-weight-bold"
                                            >
                                                {{ item.badge.title }}
                                            </h5>
                                            <p class="card-text">
                                                {{ item.badge.description }}
                                            </p>
                                            <!-- <small
                                                >Click to
                                                share</small
                                            > -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-header p-0 bg-white">
                        <h4 class="card-title badge-card-title-locked pb-1">
                            LOCKED
                        </h4>
                    </div>
                    <div
                        class="row no-gutters"
                        v-if="unAwardedBadges.length > 0"
                    >
                        <div
                            class="col-md-4 col-sm-6 p-0 badges-item"
                            v-for="badge in unAwardedBadges"
                            :key="badge.id"
                        >
                            <div class="card text-center">
                                <div class="card-body">
                                    <img
                                        :src="badge.image"
                                        class="card-img-top"
                                        :alt="`Sidehustle ${badge.title} badge`"
                                    />
                                    <h5 class="card-title mt-3">
                                        {{ badge.title }}
                                    </h5>
                                    <p class="card-text">
                                        {{ badge.description }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row no-gutters" v-else>
                        <p class="text-center">
                            No badges available
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { getBadges, getAwardedBadges } from "./../../services/badge";
// import swal from "sweetalert2";
import axios from "axios";
export default {
    data() {
        return {
            allBadges: [],
            awardedBadges: [],
            rightParams: Math.random()
                .toString(36)
                .slice(2, 8),
            leftParams: Math.random()
                .toString(36)
                .slice(2, 8),
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),

        unAwardedBadges() {
            if (this.allBadges.length > 0 && this.awardedBadges.length > 0) {
                let badges = this.allBadges.filter((item1) => {
                    let test = this.awardedBadges.some(
                        (item2) => item1.id === item2.badge_id,
                    );
                    if (!test) {
                        return item1;
                    }
                });
                return badges;
            } else {
                return this.allBadges;
            }
        },
    },

    methods: {
        async getBadges() {
            try {
                const { data } = await axios.get("user/badges/award");
                this.awardedBadges = data.payload;
            } catch (error) {}
        },
        async badges() {
            try {
                const { data } = await axios.get("badges");
                this.allBadges = data.payload;
            } catch (error) {}
        },
    },
    async mounted() {
        // this.allBadges = getBadges();
        // this.awardedBadges = await getAwardedBadges();
        // this.allBadges = this.allBadges.filter((o1) =>
        //     this.awardedBadges.some((o2) => o1.id !== o2.id),
        // );
        this.getBadges();
        this.badges();
    },
};
</script>

<style lang="css" scoped>
.badge-card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.badge-card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #606060;
}
.locked .card-body .card-text {
    font-size: 12px !important;
    margin-top: 10px;
}
.badges-item h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #a7a5a5;
}

.badges-item p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #a7a5a5;
}

.badges-item .card {
    border: none;
    box-shadow: none;
}

.badges-item .locked {
    opacity: 0.4;
}
.badges-item .card-text {
    font-size: 10px !important;
    line-height: 14px !important;
    font-weight: normal;
}
.badges-item .card-title {
    font-size: 14px !important;
    font-weight: 550;
}
.badges-item img {
    width: 84px;
    height: 90px;
}

.badge-card-title-locked {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}
</style>
