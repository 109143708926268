<template>
    <div class="col-md-10 offset-md-1 mt-5 p-0">
        <div class="col-md-7 col-12 p-0 mx-auto">
            <div
                class="benefits-card2 pl-3 pt-4 pb-4 pr-3 course text-center p-lg-5"
            >
                <div class="benefits-card-title2 mb-3">Manage Courses</div>
                <!-- <div class="benefits-card-details2 pl-2">During this internship, you will be tasked with joining teams, which gives you unlimited access to forming strong networks with like minds.</div> -->
                <div class="row mt-5">
                    <div class="col-md-6" v-if="user.course">
                        <img class="mb-4" src="@/assets/img/course.png" />
                        <div class="benefits-card-title2 mb-3">
                            {{ user.course.title }}
                            <i
                                class="fa fa-pen text-success"
                                @click="editCourse('course')"
                            ></i>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="user.extra_course">
                        <img class="mb-4" src="@/assets/img/course.png" />
                        <div class="benefits-card-title2 mb-3">
                            {{ user.extra_course.title }}
                            <i
                                class="fa fa-pen text-success"
                                @click="editCourse('extra_course')"
                            ></i>
                        </div>
                    </div>

                    <div
                        class="col-md-6"
                        v-if="user.extra_course == null"
                        @click="showForm()"
                    >
                        <img class="mb-4" src="@/assets/img/add_course.png" />
                        <div class="benefits-card-title2 mb-3">Add Course</div>
                    </div>
                </div>
                <form @submit.prevent="submit()" v-if="show">
                    <div
                        class="form-group mt-lg-5 col-12 col-lg-10 offset-lg-1 p-0"
                    >
                        <select
                            name="course"
                            id="course"
                            class="custom-select-lg course-select form-control col-12 d-block"
                            v-model="course"
                            @change="selected()"
                        >
                            <option value="See courses" disabled hidden
                                >See courses</option
                            >
                            <option
                                v-for="course in courses"
                                :key="course.id"
                                :value="course.id"
                            >
                                <!-- v-if="course.title != user.course.title" -->
                                {{ course.title }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-12 col-lg-10 offset-lg-1 p-0">
                        <button
                            type="submit"
                            class="btn btn-submit py-lg-2 px-4 btn-block"
                            style="color:#fff; box-shadow:none"
                            :disabled="!submitted"
                            v-html="buttonText"
                        ></button>
                    </div>
                </form>
            </div>

            <div class="col-12 mt--lg-4 mb-5">
                <span class="mb-0 col-12 text-center d-block skip">
                    <router-link :to="{ name: 'WelcomeDashboard' }" class="skip"
                        >Back
                    </router-link>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import swal from "sweetalert2";
import NProgress from "nprogress";

export default {
    data() {
        return {
            buttonText: "Save",
            submitted: false,
            selectedText: "",
            courses: [],
            course: "See courses",
            show: false,
            update_course: false,
            courseToBeUpdated: "",
        };
    },

    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
        selected() {
            this.submitted = true;
        },

        showForm() {
            this.show = true;
        },

        editCourse(type) {
            this.show = true;
            this.update_course = true;
            this.courseToBeUpdated = type;
        },

        submit() {
            NProgress.start();
            this.submitted = true;
            this.buttonText = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
            let data = this.update_course
                ? { course_id: this.course, type: this.courseToBeUpdated }
                : {
                      course_id: this.course,
                  };
            axios
                .post(
                    `${
                        this.update_course
                            ? `user/course/update`
                            : `user/extra-course/add`
                    }`,
                    data,
                )
                .then((res) => {
                    this.attempt();
                    swal.fire("Success", "Course has been added", "success");
                    NProgress.done();
                    this.show = false;
                    this.buttonText = "Save";
                })
                .catch(() => {
                    NProgress.done();
                    this.buttonText = "Proceed";
                    this.submitted = false;
                });
        },
    },

    created() {
        NProgress.start();
        axios
            .get("courses")
            .then((res) => {
                let courses = res.data.payload;
                courses = courses.filter(
                    (item) => item.id !== this.user.course.id,
                );
                this.courses = courses;
                NProgress.done();
            })
            .catch((err) => {
                NProgress.done();
            });
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Dashboard";
        },
    },
};
</script>

<style scoped>
.fa-pen {
    cursor: pointer;
}
.benefits-card2 {
    cursor: inherit;
}

.benefits-card2.course {
    background: #e5e5e5;
}

.benefits-card2.course img {
    height: 100px;
    width: 100px;
}

.btn-submit {
    background: #044995;
    border: 2px solid #044995 !important;
    box-sizing: border-box;
    border-radius: 10px;
    color: #fff !important;
}

/* .benefits-card2.course::after {
    background: url(../../../assets/img/line/line2.png) no-repeat;
    background-size: auto;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right:0px;
} */
</style>
