<template>
   <div class="col-12 subscribe  px-xlg-100 px-sm-20 text-center py-xlg-100">
       <div class="subscribe-heading mr-auto ml-auto my-3 col-md-8 col-12">
           Stay in the loop
       </div>
       <div class="subscribe-text col-md-10 col-lg-6 col-11 col-10 m-auto">
           Be the first to get the latest updates from our fast-growing community of African youths
       </div>
       <div class="mt-md-5 mt-0 py-5">
           <form action="" @submit.prevent="subscribeEmail" class="d-flex flex-column flex-md-row flex-wrap justify-content-center align-items-center">
               <input type="text" v-model="name" placeholder="Your full name">
               <input type="email" v-model="email" placeholder="Email Address">
               <button type="submit" v-html="buttonText"></button>
           </form>
           <div class="p-0 m-0 mt-3" :style="{color:msgColor}" v-html="subscribeMsg"></div>
       </div>
   </div>
</template>

<script>
import axios from 'axios';
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
    name: "Subscribe",
    data(){
        return{
            v$: useValidate(),
            name:"",
            email:"",
            subscribeMsg:'',
            msgColor:'',
            buttonText: 'Subscribe',
        }
    },
     validations() {
        return {
            email: { required, email },
            name: { required },
        };
    },
    methods:{
        subscribeEmail(){
            this.buttonText = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
            this.v$.$validate();
            if (!this.v$.$error){
                let data={
                    name:this.name,
                    email:this.email,
                }
                // console.log(data)
                axios
                    .post('/subscribe', data)
                    .then((response)=>{
                        // console.log(response);
                        this.subscribeMsg= response.data.message;
                        this.name='';
                        this.email='';
                        this.msgColor='#52A350'
                        this.buttonText = 'Subscribe';
                    })
                    .catch((error)=>{
                        // console.log(error);
                        this.buttonText = 'Subscribe';
                        this.subscribeMsg= 'We are currently having issues subscribing you to our newsletter, please check your network and try again.';
                    })
            }else{
                this.subscribeMsg= 'Please enter a valid e-mail address';
                this.msgColor='#D50630'
                this.buttonText = 'Subscribe';
            }
        }
    }
};
</script>

<style scoped>
.subscribe{
    background-color: #1D3754;
    color: #fff;
    background-image: url('./../../assets/img/backgrounds/subscribe-bg.png');
}
.subscribe-heading{
    font-family:'GD Boing', sans-serif;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 2.5rem;
    position: relative;
}
.subscribe-heading::before{
    content: '';
    background-image: url('./../../assets/img/gif/circle-outline.gif');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 30%;
    height: 60px;
    top: -50px;   
     right: 0;
}
.subscribe-text{
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.5rem;

}
.subscribe form input{
    padding: 10px 30px;
    margin: 10px 5px;
    background-color: #1D3754 !important;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 25px;
    color:  #9CA8B4 !important;
}
input[type=text]:focus, input[type=email]:focus {
color:#fff !important ;
}
.subscribe form button{
    background: #E9E9E9;
border-radius: 10px;
 padding: 10px 30px;
    margin: 10px 5px;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 25px;
    color: #08386D;
}
@media all and (max-width:768px){ 
    .subscribe-heading{
        font-size: 24px;
        line-height: 64px;
    }
    .subscribe-text{
        font-size: 16px;
        line-height: 24px;

    }
}

</style>
