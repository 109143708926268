<template>
    <div class="col-12 px-0">
        <!-- Links Section -->
        <div>
            <h5>External Resources</h5>
            <ul
                class="text-left list-group"
                v-if="downloads && downloads.length > 0"
            >
                <li
                    v-for="(link, index) in downloads"
                    :key="index"
                    class="list-group-item d-flex justify-content-start"
                >
                    <span class="mr-1">{{ index + 1 }}.</span>
                    <a :href="link" target="_blank" rel="noopener noreferrer">Resource</a>
                </li>
            </ul>
            <div v-else>
                No download links
            </div>
        </div>
        <!-- Links Section End -->

        <!-- Download Resources -->
        <!-- <div class="mt-5">
            <h5>External Resources</h5>
            <ul
                class="text-left list-group"
                v-if="external && external.length > 0"
            >
                <li
                    v-for="(link, index) in downloads"
                    :key="index"
                    class="list-group-item d-flex justify-content-start"
                >
                    <span class="mr-1">{{ index + 1 }}.</span>
                    <a :href="link" target="_blank" rel="noopener noreferrer">{{
                        link
                    }}</a>
                </li>
            </ul>
            <div v-else>
                No external links
            </div>
        </div> -->
        <!-- Download Resources End -->
    </div>
</template>

<script>
export default {
    props: ["links"],
    computed: {
        link() {
            return this.links;
        },
        downloads() {
            return JSON.parse(this.link.download_links);
        },
        external() {
            return JSON.parse(this.link.external_links);
        },
    },
};
</script>

<style>
li a,
li span {
    font-size: 0.8rem;
}
</style>
