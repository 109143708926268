<template>
    <div class="mt-4 mb-3 course-title">
        <h1>Select a course</h1>
    </div>
    <div
        class="d-flex flex-wrap justify-content-md-start px-2"
        v-if="courses.length > 0"
    >
        <div
            v-for="(c, index) in courses"
            :key="c.id"
            @click="selectCourse(c.id, index)"
            class="col-md-5 col-12 mr-3 mb-4 d-flex flex-md-row justify-content-between align-items-md-center px-3 py-4"
            style="background: #FFFFFF;border: 1px solid rgba(0, 0, 0, 0.1);border-radius: 10px;"
        >
            <div
                class="text-center text-md-left"
                style="font-weight: 500;font-size: 16px;color: #868686;"
            >
                {{ c.title }}
            </div>
            <div
                class="py-1 px-3 d-none d-md-block mx-2"
                style="min-width: max-content;font-weight: 500;font-size: 10px;letter-spacing: 0.04em;color: #A7A5A5;border-radius:25px;border:solid 1px #A7A5A5; cursor:pointer"
            >
                SELECT COURSE
                <i class="fas fa-greater-than"></i>
            </div>
        </div>
    </div>
    <div class="text-center mt-5 col-12" v-else>
        <i class="fas fa-circle-notch fa-spin fa-3x"></i><br />
        Getting your courses....
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import swal from "sweetalert2";
export default {
    name: "NewCourse",
    data() {
        return {
            courses: [],
        };
    },
    props: {
        isNew: Boolean,
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
        selectCourse(selectedCourseId, index) {
            if (selectedCourseId === this.user.course_id && this.isNew) {
                swal.fire({
                    title: "Not allowed",
                    text: "Sorry, you can't take your current course",
                    icon: "warning",
                });
                return;
            }
            swal.fire({
                title: "Hey Champ!",
                text:
                    "In order to take an additional course during this internship, you will need to take a 2 questions survey. Proceed to take the survey. All the best!😁👍🏽",
                showCancelButton: true,
                confirmButtonColor: "#08386D",
                confirmButtonText:
                    "PROCEED <i class='fas fa-greater-than'></i>",
                cancelButtonText: "CANCEL",
            }).then((result) => {
                if (result.isConfirmed) {
                    swal.fire({
                        html: `<strong>Q1: ${this.courses[index].test_question[0]}</strong>`,
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                    }).then((res) => {
                        if (res.isConfirmed) {
                            swal.fire({
                                html: `<strong>Q2: ${this.courses[index].test_question[1]}</strong>`,
                                showCancelButton: true,
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                            }).then((res) => {
                                if (res.isConfirmed) {
                                    //call add new course
                                    this.addExtraCourse(selectedCourseId);
                                } else {
                                    swal.fire({
                                        title: "Not qualify",
                                        text:
                                            "Sorry you didn't meet the requirement for this course",
                                        icon: "error",
                                    });
                                }
                            });
                        } else {
                            swal.fire({
                                title: "Not qualify",
                                text:
                                    "Sorry you didn't meet the requirements for this course",
                                icon: "error",
                            });
                        }
                    });
                }
            });
        },
        async addExtraCourse(id) {
            let response = await axios.post("addNewCourse", {
                course_id: id,
                user_id: this.user.user_id,
                type: this.isNew ? "new course" : "first course",
            });
            try {
                if (response.status === 200) {
                    this.attempt();
                    swal.fire({
                        title: "Course Added",
                        text: "Your course has been updated successfully",
                        icon: "success",
                    }).then((e) => {
                        this.$router.push({
                            name: this.isNew ? "Profile" : "Dashboard",
                        });
                    });
                }
            } catch (error) {
                swal.fire({
                    title: "An error has occured",
                    text: "Please try again later",
                    icon: "error",
                });
            }
        },
    },
    mounted() {
        axios
            .get("/courses")
            .then((response) => (this.courses = response.data.payload));
    },
};
</script>

<style scoped>
.course-title h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #5a5a5a;
}

.course-title h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    color: #a8a8a8;
}
@media screen and (max-width: 700px) {
    .course-title h1 {
        font-size: 16px !important;
        line-height: 18px !important;
    }
}
</style>
