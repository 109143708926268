<template>
    <span @click="$router.go(-1)">Capstone</span> >
    <span @click="$router.go(-1)">{{ course.title }}</span> > Members
    <div
        class="col-md-9 col-12 d-flex flex-column flex-md-row align-items-start mt-3 mt-md-5 p-0"
    >
        <div class="col-md-12" v-if="!isLoading">
            <div class="card-header p-0 bg-white">
                <h4 class="card-title badge-card-title pb-2">{{ course.title + ': '+selectedgroup.name }} Members</h4>
            </div>
            
            <div class="group py-4" v-if="members.length > 0">
                <div
                    class="media"
                    v-for="member in members" 
                    :key="member.id"
                >
                    <img
                        :src="
                            member.avatar === 'null'
                                ? 'https://sidehustle.ng/api/assets/img/profile_pic/avatar.jpg'
                                : member.avatar
                        "
                        class="mr-3 rounded-circle"
                        :alt="member.first_name"
                        height="80"
                        width="80"
                    />

                    <div class="media-body">
                        <h6>Name: {{ member.first_name+ ' '+member.last_name }}</h6>
                        <h6>User Id: {{ member.user_id }}</h6>
                        <h6>Email: {{ member.email }}</h6>
                        <h6>Phone: {{ member.phone }}</h6>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="group py-4" v-else>
                <h5>Sorry No Other Project Members at the Moment</h5>
            </div>
        </div>
        <div class="col-md-12" v-if="isLoading">
            <h3 class="">Loading <i class="fas fa-spinner fa-spin"></i></h3>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import axios from "axios";

export default {
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    data() {
        return {
            members: [],
            isLoading: false,
            course: {},
            selectedgroup: ''
        };
    },
    props: {
        group: {
            type: [Number],
            required: true
        },
    },
    methods: {
        getMembers(){
            this.isLoading = true;
            let data = {
                'group_id' : this.group
            };
            axios.post('/capstone/members', data)
            .then((response) => {
                this.members = response.data.payload.members;
                this.course = response.data.payload.course;
                this.selectedgroup = response.data.payload.group;
                this.isLoading = false;
            })
            .catch((err) => {
                swal.fire(
                    'Error Loading',
                    'Cannot Load Members, try again',
                    'error'
                ).then(() =>{
                    this.isLoading = false;
                })
            });
        }
    },
    mounted() {
        if (this.group === undefined) {
            this.$router.push({ name: "CapstoneIndex" });
        } else {
            this.getMembers();
        }
    },
};
</script>

<style lang="css" scoped>
.card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}
#uploadJbCert {
    display: flex;
    align-items: center;
}
.cert_btn {
    background: #08386d;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 18px;
    margin-right: 15px;
    color: #ffffff;
    border: unset;
    width: max-content;
}
.cert_btn i {
    margin-left: 15px;
}
.grey {
    color: #606060;
}
.ash {
    color: #a7a5a5;
}

@media screen and (max-width: 768px) {
    .card {
        border-radius: 10px;
    }
    .cert_btn {
        font-size: 10px;
        font-weight: 600;
        padding: 5px 10px;
        margin-right: 3px;
        width: 70%;
    }
    .cert_btn i {
        margin-left: 8px;
    }
}

.group ul{
    padding: 0px;
}
.group ul li {
  list-style: none;
}
</style>
