<template>
    <transition :name="transitionEffect">
        <div class="carousel-items d-flex flex-column justify-content-between"  v-if="currentSlide==index" >
            <div class="carousel-items d-flex flex-column justify-content-between" @mouseenter="stopSlideTimer" @mouseout="startSlideTimer">
                <div class="testimonial-text text-left">
                    "{{testimony.testimony}}" 
                </div>
                <div class="d-flex flex-row align-items-center mt-5">
                    <div class="mr-md-4 mr-2">
                            <img :src="testimony.img" class="testimonial-img">
                    </div>
                    <div>
                        <div class="testimonial-name">
                            {{testimony.name}}
                        </div>
                        <div class="testimonial-cohort">
                            Side Hustle Internship {{testimony.cohort}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    emits: ["mouseenter", "mouseout"],
    props: ["testimony", "currentSlide", "index", "direction"],
    computed: {
        transitionEffect() {
        return this.direction === "right" ? "slide-out" : "slide-in";
        },
    },
}
</script>

<style scoped>

.carousel-items{
    position: absolute; 
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
/*  */

.testimonial-img{
    height: 50px;
}
.testimonial-text{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #6A6A6A;
}
.testimonial-name{
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1rem;
    color: #434343;
}
.testimonial-cohort{
    font-style: normal;
    font-weight: 300;
    font-size: 0.6rem;
    line-height: 1rem;
    color: #727272;
}
.slide-in-enter-active,
.slide-in-leave-active,
.slide-out-enter-active,
.slide-out-leave-active {
  transition: all 1s ease-in-out;
}
.slide-in-enter-from {
  transform: translateX(-100%);
}
.slide-in-leave-to {
  transform: translateX(100%);
}
.slide-out-enter-from {
  transform: translateX(100%);
}
.slide-out-leave-to {
  transform: translateX(-100%);
}

@media screen and (max-width:992px){
  
    .testimonial-text{
        font-size: 0.8rem;
        line-height: 1rem;
    }
    .testimonial-name{
        font-size: 0.75rem;
        line-height: 1rem;
    }
    .testimonial-cohort{
        font-size: 0.6rem;
        line-height: 1rem;
    }
}

</style>