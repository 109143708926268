<template>
    <div class="carousel-buttons prev d-flex align-items-center justify-content-center" @click="$emit('prev')"><i class="fas fa-angle-down"></i></div>
    <div class="carousel-buttons next d-flex align-items-center justify-content-center" @click="$emit('next')"><i class="fas fa-angle-down"></i></div>
</template>

<script>
export default {
    emits: ["prev", "next"],
}
</script>

<style scoped>
.carousel-buttons{
    position: absolute;
    top: calc(50% - 25px);
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    padding: 0px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    color: #08386D;
    font-weight: 100;
}

.next{
    right: 25px;
    transform: rotate(-90deg);
}
.prev{
    left: 25px;
    transform: rotate(90deg);
}
@media screen and (max-width:1024px) {
    .next{
        right: -25px;
    }
    .prev{
        left: -25px;
    }
}
@media screen and (max-width:768px) {
    .carousel-buttons{
    top: calc(50% - 25px);
    height: 40px;
    width: 40px;
}

    .next{
        right: -20px;
    }
    .prev{
        left: -20px;
    }
}
</style>