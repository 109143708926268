<template>
    <div class="d-flex align-self-center justify-content-center justify-content-md-start mb-0 mt-4 flex-wrap">
        <img :src="item.img" class="thumbnail-img mr-4 mb-4" :class="{ 'thumbnail-img-active': currentIndex === index }" v-for="(item, index) in total" :key="index" @click="$emit('switch', index)">
    </div>
</template>

<script>
export default {
        emits: ["switch"],
        props: ["total", "currentIndex"],
}
</script>

<style scoped>
.thumbnail-img{
    height: 30px;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
}
.thumbnail-img-active{
    border: 2px solid #08386D;
    padding: 2px;
    filter: drop-shadow(2px 1.48288px 1.48288px rgba(8, 56, 109, 0.3));
}
</style>