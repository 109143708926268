<template>
    <div class="col-12 mb-3 ml-auto mr-auto d-flex">
        <img
            class="mr-3 rounded-circle"
            :src="
                user.avatar === 'null'
                    ? 'https://sidehustle.ng/api/assets/img/profile_pic/avatar.jpg'
                    : user.avatar
            "
            style="height:25px; width:25px"
            :alt="user.first_name"
        />
        <input
            class="py-0 px-2 flex-grow-1"
            style="font-size:8px; border-radius:5px;background: #FFFFFF;border: 1px solid rgba(0, 0, 0, 0.1);"
            type="text"
            placeholder="Answer the question here"
            v-model="answer"
            @keypress.enter="postAnswer"
        />
        <i class="posting pl-2" v-if="loading">Posting Your Answer ...</i>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import swal from "sweetalert2";

export default {
    props: {
        question: [Object],
    },

    data() {
        return {
            answer: "",
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        postAnswer() {
            if (this.answer.length > 0) {
                this.loading = true;
                let data = {
                    answer: this.answer,
                    user_id: this.user.id,
                    course_id: this.user.course_id,
                    question_id: this.question.id,
                };

                axios
                    .post("answer", data)
                    .then((response) => {
                        this.loading = false;
                        this.answer = "";
                        this.$emit("answerposted", response.data.payload);
                    })
                    .catch((error) => {
                        this.loading = false;
                        swal.fire(
                            "Error",
                            "Cannot post your comment now, Try again",
                            "error",
                        );
                    });
                // this.$emit('commentposted', 'jfj');
            }
        },
    },

    emits: ["answerposted"],
};
</script>

<style lang="css" scoped>
img {
    fill: red;
}
p {
    font-size: unset;
    line-height: unset;
}

.lesson-video {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 20px 20px;
}

.video-title {
    font-weight: 550;
    padding-left: 3rem;
    color: #606060;
}

.active-tab {
    font-weight: 550;
    font-size: 12px;
    color: #08386d;
    border-bottom: #08386d 0.6px solid;
    margin-bottom: 0px;
}

.tabs {
    font-size: 12px;
    cursor: pointer;
    color: #a8a8a8;
    border-bottom: 0.6px solid rgba(0, 0, 0, 0.15);
}

.lesson-description {
    font-weight: 550;
    font-size: 20px;
    color: #606060;
}

.lesson-time {
    font-weight: 400;
    font-size: 10px !important;
    color: #606060;
}

.lesson-mentor {
    border-right: 0.6px solid rgba(0, 0, 0, 0.15);
}

.chat-question {
    font-size: 10px;
    line-height: 14px;
}

.comments {
    background: #fdfdfd;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 8px;
}

.intern-name {
    font-weight: 500;
    font-size: 12px !important;
    line-height: 15px;
    color: #606060;
}

.intern-id {
    font-size: 8px !important;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.extra-resources p {
    font-size: 12px !important;
}
/*  */
/*  */
/* Mobile Responsive Styling */
@media screen and (max-width: 768px) {
    .page-title {
        font-weight: 500;
        transform: rotate(0deg);
        writing-mode: horizontal-tb;
        text-align: left;
        font-size: 16px;
    }

    .video-title {
        padding-left: 1rem;
        font-size: 11px;
    }

    .lesson-description {
        font-weight: 500;
        font-size: 16px !important;
        line-height: 18px;
        color: #606060;
    }

    .lesson-mentor {
        border-bottom: 0.6px solid rgba(0, 0, 0, 0.15);
        border-right: 0;
    }

    .tabs {
        font-size: 10px;
    }

    .active-tab {
        margin-bottom: -1px;
    }
}
</style>
