<template>
    <!-- Impact Section -->
    <div class="impact mt-n2 py-200px pt-0 mb-5 px-xlg-100 px-sm-20 px-lg-30 d-flex flex-md-row flex-column align-items-center justify-content-center">
        <div class="impact-left col mt-md-n5 mt-0 mx-auto">
            <div class="wave-circle-outer">
                <img class="impact-bottom-img" src="@/assets/img/impact-bottom.png" alt="">
                <div class="impact-bottom-circle"></div>
                <div class="wave-circle-middle">
                    <img class="impact-top-img" src="@/assets/img/impact-top.png" alt="">
                    <div class="inner-circle">
                        <img src="@/assets/img/impact-center.png" class="mx-auto" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="impact-right col">
        <h3>A chance to make an impact</h3>
        <p>We have the power to make the needed change to provide us with the kind of future we want for ourselves; and all of this starts with equipping the youth.</p>
        <button>DISCOVER HOW <i class="fas fa-arrow-down"></i></button>
        </div>
    </div>
    <!-- Impact Section -->
</template>

<script>
export default {

}
</script>

<style scoped>
@media screen and (max-width:776px) {
        .impact-left{
            transform: scale(0.8);
            margin-left: -40px !important;
        }
}
.impact-right button{
position: relative !important;
}

.impact-right button::after{
content: url('./../../assets/img/twisted-arrow.png');
position: absolute;
top: 0;
/* transform: rotate(120deg); */
transform: scale(0.7);
}
</style>