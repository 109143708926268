<template>
    <div class="col-11 mx-auto text-center mb-5">
        <div class="col-8 mx-auto">
            For every 5 of your referrals who complete their registration, you
            get a chance to spin the wheel and win amazing prizes.
        </div>
        <div
            class="result font-weight-bold text-success"
            ref="winnings"
            v-if="won"
        >
            Congratulations!!! 😊<br />
            You have won {{ winnings }}.<br />
            You will be contacted within 24 hours for your gift.
        </div>
        <div
            class="result font-weight-bold text-info my-3"
            ref="winnings"
            v-if="lose"
        >
            Oops!!! You didn't win anything.😞<br />
            Refer more people to get another chance to spin & win.
        </div>
        <button
            class="spinMe my-5"
            v-if="qualified"
            ref="spinMe"
            @click="spinWheel"
        >
            Click to Spin
        </button>
    </div>
    <div class="col-12 mt-3 mb-5">
        <span class="mb-0 col-12 text-center d-block skip">
            <router-link :to="{ name: 'DashboardRefer' }" class="skip"
                >Back
            </router-link>
        </span>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import swal from "sweetalert2";

export default {
    data() {
        return {
            validReferral: 0,
            lottery: "",
            won: false,
            lose: false,
            winnings: "",
            qualified: false,
            qualify: "",
            countWin: 0,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        getNumberOfReferral() {
            axios
                .post("/user/referral", { user_id: this.user.user_id })
                .then((res) => {
                    this.loading = false;
                    this.validReferral = res.data.f_count;
                    if (this.validReferral >= 5) {
                        this.isQualify();
                    }
                });
        },
        isQualify() {
            axios
                .post("/gifts/check", { user_id: this.user.user_id })
                .then((res) => {
                    this.countWin = res.data.datte;
                    if (this.countWin != 0) {
                        this.qualify = this.validReferral / this.countWin;

                        if (this.qualify > 5) {
                            this.qualified = true;
                        } else {
                            this.qualified = false;
                        }
                    } else if (this.countWin == 0) {
                        this.qualified = true;
                    }
                });
        },
        spinWheel() {
            this.lottery = Math.floor(Math.random() * 100) + 1;
            this.$refs.spinMe.innerHTML =
                "<i class='fas fa-spinner fa-spin fa-2x'></i>";
            setTimeout(() => {
                axios
                    .post("/gifts", {
                        gift_id: this.lottery,
                        user_id: this.user.user_id,
                    })
                    .then((res) => {
                        if (res.data.status == true) {
                            this.winnings = res.data.gift[0].gift_name;
                            this.$refs.spinMe.innerText = "Spin Me";
                            this.won = true;
                            this.qualified = false;
                        } else {
                            this.winnings = "";
                            this.lose = true;
                            this.$refs.spinMe.innerText = "Spin Me";
                            this.qualified = false;
                        }
                    });
            }, 5000);
        },
    },
    created() {
        this.getNumberOfReferral();
    },
    // mounted(){
    //     this.isQualify();
    // }
};
</script>

<style scoped>
.spinMe {
    background-color: #08386d;
    color: #fff;
    font-size: 2rem;
    padding: 1rem 2rem;
    border-radius: 20px;
}
.spinMe:disabled,
.spinMe[disabled] {
    background: #e9e9e9;
    color: #4d4d4d !important;
    border: 2px solid #dedede !important;
    cursor: not-allowed;
}
</style>
