<template>
    <div class="mt-5 home-con">
        <!-- The hero section -->
        <Hero />
        <!-- What You Stand to Benefit -->
        <Benefit />
        <!-- What you stand to gain End-->

        <!-- The Testimonial section -->
            <!-- This section will only show if the testimonies are succesfully fetched -->
        <Testimonial />
            <!-- This section will only show if the testimonies are succesfully fetched -->
        <!-- The Testimonial section -->
        <Footprint/>
        <!-- Join the internship -->
        <Join />
        <Process/>
        <Subscribe/>
        <Cookie/>
    </div>
</template>

<script>
import Footer from "../../components/inc/Footer";
import Navbar from "../../components/inc/Navbar";
import Benefit from "./Benefit";
import Hero from "./Hero.vue";
import Join from "./Join";
import Testimonial from "./Testimonial";
import Subscribe from "./Subscribe";
import Process from "./Process";
import Cookie from "./Cookie";
import Footprint from "./Footprint";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Home",
    components: {
        Navbar,
        Footer,
        Hero,
        Benefit,
        Testimonial,
        Join,
        Footprint,
        Subscribe,
        Process,
        Cookie,
    },

    methods: {
        ...mapActions({
            fetchCourses: "auth/fetchCourses",
            getReferral: "auth/getReferral",
        }),
    },

    computed: {
        ...mapGetters({
            courses: "auth/courses",
        }),
    },
    mounted() {
        this.getReferral(this.$route.query.ref);
    },

    created() {
        if (this.courses.length === 0) {
            this.fetchCourses();
        }
    },
};
</script>

<style scoped>

</style>