<template>
      <div class="py-30 mt-5 col-12 mb-5" style="margin-top:120px !important">
        <div class="py-30 col-11 m-auto">
            <h1 class="heading-1">Side Hustle Privacy Policy</h1>
            <p class="mt-2 p-1 mb-5 sh-text-primary">Effective Date: September 3, 2021</p>
              <p class="">Side Hustle is dedicated to preserving your privacy and adhering to all applicable data protection regulations. This Privacy Policy describes how Side Hustle collects, receives, uses, stores, processes, and protects your personal information in connection with our Services, website, and other interactions with you. We also outline the options available to you regarding your information. Furthermore, we outlined your usage options, as well as your rights to view and rectify your personal information. You should not use the Websites if you do not agree with the data practices stated in this Privacy Policy.
              </p>

              <div class="custom-link font-weight-bold ml-md-5">
                <ul>
                  <li class="my-2"><a href="#what-info">What Information Do We Collect About You?</a></li>
                      <ul class="font-weight-normal my-2">
                        <li><a href="#personal-info">Personal Information</a></li>
                        <li><a href="#navigational-info">Navigational Information</a></li>
                      </ul>
                  <li class="my-2"><a href="#how-use-info">How do we Use Your Information?</a></li>
                  <li class="my-2"><a href="#security-data">Security of Your Personal Data</a></li>
                      <ul class="font-weight-normal my-2">
                        <li><a href="#bussiness-partnership">Business Partnerships</a></li>
                        <li><a href="law-enforcement">Law Enforcement</a></li>
                      </ul>
                  <li class="my-2"><a href="#children-policy">Children’s Privacy</a></li>
                  <li class="my-2"><a href="#retention">Retention of Personal Information</a></li>
                  <li class="my-2"><a href="#change-policy">Changes to This Privacy Policy</a></li>
                  <li class="my-2"><a href="#contact-us">Contact Us</a></li>
                </ul>
              </div>
                <div class="mt-5" id="what-info">
                  <h5>What Information Do We Collect About You?</h5>
                      <p>You may browse the Websites without disclosing any Personal Information about yourself. When you use the Websites or register for the internship, we ask for Personal Information about yourself, and we gather data usage information.
                      </p>
                          <div class="col-11 ml-auto">
                            <h6 id="personal-info">Personal Information</h6>
                                <p>
                                  Personal information is any information that may be used to personally identify you, such as your name, email address, company name, address, phone number, and other information about yourself or your business.
                                  <br>
                                  Side Hustle, on the other hand, will just ask for your first and last name, phone number, and email address.
                                  We collect personal information from you when you signup to be part of the Side Hustle Internship.
                                </p>
                            <h6 id="navigational-info">Navigational Information</h6>
                                <p>
                                  Navigational Information may include Your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages you visit on our website, the amount of time spent on the sites, unique device IDs, and other diagnostic information.
                                  <br>
                                  When you access the website via a mobile device, we automatically collect certain information, including but not limited to the type of mobile device you use, its unique ID, IP address, operating system, internet browser, and other diagnostic information.
                                </p>
                          </div>
                </div>
                <div id="how-use-info">
                  <h5>How do we Use Your Information?</h5>
                      <ul>
                            <li>We need to identify and authenticate our users in order to ensure that only authorized users can access the website and make modifications to their accounts.</li>
                            <li>To offer and manage our Service, including monitoring website usage.</li>
                            <li>To manage your registration as a website user. The Personal Data you supply may grant you access to certain Service features available to You as a registered user.</li>
                            <li>We shall contact You by email, phone calls, SMS, or other comparable means of electronic communication when necessary to provide updates or instructive messages about the functionality, products, or contractual services, including security upgrades.</li>
                            <li>We shall send you news, special offers, and information about other goods, services, and events that we provide that are related to those that you have already enquired about unless you have indicated a preference not to receive such info.</li>
                            <li>To respond to and answer Your inquiries.</li>
                            <li>We may share your personal information with our business partners in order to alert you to new products, services, or offers.</li>
                            <li>We may use Your information for a number of reasons, such as data analysis, identifying use trends, assessing the effectiveness of our promotional activities, and analyzing and enhancing our Service, goods, services, marketing, and your experience.</li>
                            <li>We may share Your personal information for any other reason with Your approval.</li>
                            <li>On our Websites, we publish customer testimonials and comments that may include Personal Information. We obtain permission through email before publicizing a customer's name and testimonial.</li>
                      </ul>
                </div>
                <div id="security-data">
                  <h5>Security of Your Personal Data</h5>
                        <p>Because the security of Your Personal Data is essential to Us, We endeavour to employ commercially reasonable measures to secure It, however, We cannot guarantee its ultimate security. Nevertheless, keep in mind that your personal information will be revealed in any of the following scenarios:
                        </p>
                    <div class="col-11 ml-auto">
                      <h6 id="bussiness-partnership">Business Partnerships </h6>
                          <p>Your Personal Data may be transferred if the Company goes through a merger, acquisition, or asset sale. We will notify You before transmitting Your Personal Information and subjecting it to a revised Privacy Policy.
                          </p>
                      <h6 id="law-enforcement">Law Enforcement</h6>
                          <p>Side Hustle may be obliged to release Your Personal Data in certain situations, such as where required by law or in response to lawful demands from governmental authorities (e.g. a court or a government agency).
                          <br>
                          Other legal requirements including but are not limited to the following:
                          </p>
                              <ul class="mt-0">
                                <li>Protection and defence of the Company's rights and property</li>
                                <li>Preventing or investigating potential misconduct in relation to the website</li>
                                <li>Protection of website users or the general public's personal safety</li>
                              </ul>
                    </div>
                </div>
                <div id="children-policy">
                  <h5>Children’s Privacy</h5>
                          <p>The Side Hustle Website is not intended or aimed at children under the age of 14, and we do not knowingly or intentionally collect personal information about children under the age of 14 unless we have a parental agreement. If this is not the case, we will delete such information from our website. If you suspect we have acquired personal information on a child under the age of 14, please notify us so that the information can be deleted.
                          </p>
                </div>
                <div id="retention">
                  <h5>Retention of Personal Information</h5>
                          <p>Side Hustle will only keep Your Personal Information for as long as it is required for the reasons outlined in this Privacy Policy. We will only store and use Your Personal Information if we have a legitimate business to attend to the extent necessary to comply with our legal obligations (for example, if we are required to keep your data to comply with applicable laws), settle disputes, and enforce our legal agreements and policies.
                          </p>
                          <p>The Company will also save Usage Data for internal analysis. Usage Data is typically retained for a shorter length of time unless it is required to improve the security or operation of Our Service, or unless We are legally required to retain this data for a longer period of time.
                          </p>
                </div>
                <div id="change-policy">
                  <h5>Changes to This Privacy Policy</h5>
                          <p>We reserve the right to amend this privacy statement at any time to reflect changes to our Services, applicable legislation, or other circumstances. If we make substantial changes, we will post a prominent notice on our website and/or Services, or we will tell you through email, and the Last Updated date at the top of this Privacy Policy will be updated. However, we strongly advise you to keep up to date by reading this policy on a regular basis. Changes to this Privacy Policy, on the other hand, become effective the moment they are placed on this page.
                          </p>
                </div>
                <div id="contact-us">
                  <h5>Contact Us</h5>
                        <p>If you have any questions, concerns, or criticism about our privacy policies, or if you have a disability and require this notice in a different format, please contact our Privacy team or Data Protection Officer at <a href="mailto:funke@sidehustle.ng">funke@sidehustle.ng</a>.
                        </p>
                </div>
        </div>
      </div>
</template>

<script>
export default {
 name: "Privacy",
}
</script>

<style scoped>
  a{
    color: unset;
  }
   h5{
    font-weight: 700;
    margin-top: 40px;
    color: #08386d;
  }
  h6{
    font-weight: 600;
    color: #08386d;
  }
  li{
    font-size: 14px;
    list-style: square;
  }
</style>