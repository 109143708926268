<template>
    <div class="col-11 col-md-7 jobberman-card py-4 px-2 rounded">
        <i
            @click="$emit('closeModal')"
            class="fas fa-times fa-2x ml-2 ml-md-5"
            style="cursor:pointer"
        ></i>
        <h5 class="text-center">
            Enroll for the Jobberman Soft-Skills Training
        </h5>
        <form
            @submit.prevent="applyJobberMan"
            class="d-flex flex-wrap justify-content-around align-items-end"
        >
            <div class="col-12 col-md-5 mx-1 my-2 d-flex flex-column">
                <label for="firstName">First Name *</label>
                <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    required
                    :value="user.first_name"
                    readonly
                />
            </div>
            <div class="col-12 col-md-5 mx-1 my-2 d-flex flex-column">
                <label for="lastName">Last Name *</label>
                <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    required
                    :value="user.last_name"
                    readonly
                />
            </div>
            <div class="col-12 col-md-5 mx-1 my-2 d-flex flex-column">
                <label for="email">Email Address *</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    :value="user.email"
                    readonly
                />
            </div>
            <div class="col-12 col-md-5 mx-1 my-2 d-flex flex-column">
                <label for="phone">Phone Number *</label>
                <input
                    type="tel"
                    name="phone"
                    id="phone"
                    required
                    :value="user.phone"
                    readonly
                />
            </div>
            <div class="col-12 col-md-5 mx-1 my-2 d-flex flex-column">
                <label for="gender">Gender *</label>
                <select name="gender" id="gender" v-model="gender" required>
                    <option
                        v-for="(gender, index) in genders"
                        :key="index"
                        :value="index"
                        >{{ gender }}</option
                    >
                </select>
            </div>
            <div class="col-12 col-md-5 mx-1 my-2 d-flex flex-column">
                <label for="location">Location (current) *</label>
                <select required name="location" v-model="location">
                    <option
                        v-for="(location, index) in locations"
                        :key="index"
                        :value="index"
                        >{{ location }}</option
                    >
                </select>
            </div>
            <div class="col-12 col-md-5 mx-1 my-2 d-flex flex-column">
                <label for="age">Age *</label>
                <select name="age" id="age" required v-model="age">
                    <option selected="selected" disabled
                        >Select Your Age Range</option
                    >
                    <option
                        v-for="(age, index) in ages"
                        :key="index"
                        :value="index"
                        >{{ age }}</option
                    >
                </select>
            </div>
            <div class="col-12 col-md-5 mx-1 my-2 d-flex flex-column">
                <label for="qualification_level"
                    >What is your highest educational qualification? *</label
                >
                <select
                    name="qualification_level"
                    id="qualification_level"
                    required
                    v-model="education"
                >
                    <option selected="selected" disabled
                        >Select Your Academic Qualification</option
                    >
                    <option
                        v-for="(qualification, index) in qualifications"
                        :key="index"
                        :value="index"
                        >{{ qualification }}</option
                    >
                </select>
            </div>
            <div
                class="col-12 mx-1 my-2  text-center d-flex align-items-start justify-content-center"
            >
                <input
                    type="checkbox"
                    name="terms"
                    id="terms"
                    class="mr-2 mt-1"
                    required
                />
                <label for="terms"
                    >I agree to join Jobberman's newsletter and get the latest
                    job listings and career insights</label
                >
            </div>
            <div class="col-12 mx-1 my-2 text-center">
                <button
                    style="background-color: #ff6200;"
                    class="py-2 px-3 rounded text-white font-weight-bold"
                    type="submit"
                    v-html="enrollBtn"
                ></button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import swal from "sweetalert2";

export default {
    data() {
        return {
            gender: "Select gender",
            location: "Select Location",
            age: "Select Age",
            education: "Select Qualification",
            ages: {},
            genders: {},
            locations: {},
            qualifications: {},
            enrollBtn: "ENROLL FOR FREE",
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
        getOptions() {
            axios
                .get(
                    "https://www.jobberman.com/api/v2/softskills/external-enrolment/options",
                    {
                        headers: {
                            Authorization:
                                "Bearer vjcfUBqLSF01J5v4npWrhXWlVBjYwT",
                        },
                    },
                )
                .then((res) => {
                    this.ages = res.data.ages;
                    this.genders = res.data.genders;
                    this.locations = res.data.locations;
                    this.qualifications = res.data.qualifications;
                });
        },
        applyJobberMan() {
            this.enrollBtn = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
            let data = {
                firstname: this.user.first_name,
                lastname: this.user.last_name,
                email: this.user.email,
                phone_number: this.user.phone.replace(/ /g, ""),
                gender: this.gender,
                age: this.age,
                highest_educational_qualification: this.education,
                location_based_id: this.location,
                terms_accepted: 1,
                housed_onboarding: true,
                housed_onboarding_url: "https://lms.terrahq.co/",
                prefered_platform: "sidehustle",
            };
            axios
                .post(
                    "https://www.jobberman.com/api/v2/softskills/external-enrolment",
                    data,
                    {
                        headers: {
                            Authorization:
                                "Bearer vjcfUBqLSF01J5v4npWrhXWlVBjYwT",
                        },
                    },
                )
                .then((res) => {
                    if (res.status == 200) {
                        axios
                            .post("user/jobberman/enroll", { status: 1 })
                            .then((res) => {
                                this.attempt().then(()=>{
                                    this.$emit("closeModal");
                                    swal.fire({
                                        title:
                                            "You are yet to take the Jobberman baseline test",
                                        text:
                                            "You need to take the Jobberman baseline test before you can proceed to watch the Jobberman lesson videos",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: "Take baseline Test",
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.$router.push({
                                                name: "QuizTake",
                                                params: {
                                                    course_id: 99,
                                                    isExternal: true,
                                                    isBaseline: 1
                                                },
                                            });
                                        } else if (result.isDenied) {
                                            return;
                                        }
                                    });
                                })
                                
                            });
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                    // if (
                    //     err.response.status == 422 &&
                    //     err.response.data.errors.email
                    // ) {
                    //     axios.get("user/jobberman/enroll").then((res) => {
                    //         console.log(res);
                    //         this.attempt();
                    //         this.$emit("closeModal");
                    //         swal.fire({
                    //             title:
                    //                 "You are yet to take the Jobberman baseline test",
                    //             text:
                    //                 "You need to take the Jobberman baseline test before you can proceed to watch the Jobberman lesson videos",
                    //             icon: "warning",
                    //             showCancelButton: true,
                    //             confirmButtonText: "Take baseline Test",
                    //         }).then((result) => {
                    //             if (result.isConfirmed) {
                    //                 this.$router.push({
                    //                     name: "QuizTake",
                    //                     params: {
                    //                         course_id: id,
                    //                         isExternal: true,
                    //                     },
                    //                 });
                    //             } else if (result.isDenied) {
                    //                 return;
                    //             }
                    //         });
                    //     });
                    // }
                });
        },

        /**
         * Update intern jobberman status on sidehustle end
         *
         * @returns {Object}
         */
        updateLearnerStatus(status) {
            axios
                .post("user/jobberman/enroll", { status })
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
        },
    },
    mounted() {
        this.getOptions();
    },
};
</script>

<style scoped>
.jobberman-card {
    background: #fefefe;
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 222222;
    border: 1px solid rgb(78, 85, 89);
    max-height: 85%;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.jobberman-card::-webkit-scrollbar {
    display: none;
}
.jobberman-card label {
    color: rgb(78, 85, 89);
    font-size: 16px;
    font-weight: 800;
}
.jobberman-card input,
.jobberman-card select {
    padding: 5px 12px;
    border-radius: 5px;
}
</style>
