<template>
    <div class="d-flex">
        <h5
            class="pl-3"
            style="font-weight: 300;font-size: 16px;line-height: 22px; color: #242424 !important;"
        >
            <div class="d-flex">
                <div class="mr-2" @click="$router.go(-1)">
                    <i class="fas fa-arrow-left"></i>
                </div>
                <h5 class="page-title">
                    Report
                </h5>
            </div>
        </h5>
    </div>

    <div class="col-md-7 mt-3 mt-lg-5">
        <h4 class="help-title">
            Help us identify how we can improve the SH community. What issue
            would you like to report?
        </h4>

        <form ref="reportForm" @submit.prevent="postReport">
            <div class="form-group">
                <input
                    type="text"
                    class="form-control report-post-input py-4 mt-5"
                    id="subject"
                    name="subject"
                    placeholder="Subject"
                    v-model="subject"
                />
                <h5
                    class="text-warn text-danger"
                    style="font-size: 12px;"
                    v-if="v$.subject.$error"
                >
                    Subject must be at least 5 characters
                </h5>
            </div>

            <div class="form-group">
                <select
                    style="font-size: 16px;font-weight: 500;color: rgb(93 92 92);"
                    name="country"
                    id="country"
                    class="custom-select-lg mt-3 col-12 d-block report-post-input"
                    v-model="department"
                >
                    <option value="Department" ref="disDep" disabled selected
                        >Department</option
                    >
                    <option
                        v-for="d in departments"
                        :key="d.id"
                        :value="d.name"
                        >{{ d.name }}</option
                    >
                </select>
                <span
                    v-if="v$.department.$error && departmentState"
                    class="text-warn text-danger"
                    style="font-size: 12px;"
                    >Please select a department</span
                >
            </div>

            <div class="form-group" v-if="department == 'Intern Abuse'">
                <input
                    type="text"
                    class="form-control report-post-input py-4 mt-2"
                    id="intern_id"
                    name="intern_id"
                    placeholder="Intern-id e.g SH-IT-1234567"
                    v-model="internID"
                    pattern="^SH-IT-[0-9]{7}"
                />
                <h5 class="text-warn text-success" style="font-size: 12px;">
                    Enter the Intern ID of the person you are reporting
                </h5>
            </div>

            <div class="form-group mb-0">
                <textarea
                    v-model="report"
                    resize="false"
                    rows="6"
                    class="form-control mt-3 report-post-input"
                    placeholder="Tell us about this issue"
                >
                </textarea>
                <h5
                    class="text-warn text-danger"
                    style="font-size: 12px;"
                    v-if="v$.report.$error"
                >
                    Report must be at least 10 characters
                </h5>
                <br />
            </div>
            <button type="submit" class="btn btn-primary">
                Make Report
            </button>
        </form>
    </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import NProgress from "nprogress";
import { required, requiredIf, minLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
const pickDepartment = (value) => value !== "Department";
export default {
    data() {
        return {
            v$: useValidate(),
            report: "",
            subject: "",
            departments: [],
            internID: "",
            department: "Department",
            departmentState: false,
        };
    },

    validations() {
        return {
            department: { required, pickDepartment },
            report: { required, minLength: minLength(10) },
            subject: { required, minLength: minLength(5) },
            internID: {
                required: requiredIf(this.department === "Intern Abuse"),
            },
        };
    },

    created() {
        this.getDepartments();
    },

    methods: {
        getDepartments() {
            NProgress.start();
            axios
                .get("getdepartment")
                .then((response) => {
                    this.departments = response.data.payload;
                    NProgress.done();
                })

                .catch(() => {
                    NProgress.done();
                });
        },
        postReport() {
            this.v$.$validate();
            if (!this.v$.$error && this.department != "Department") {
                this.load = false;
                this.departmentState = false;
                let data = {
                    report: this.report,
                    subject: this.subject,
                    intern_id: this.internID,
                    department: this.department,
                };
                axios
                    .post("reporting", data)
                    .then((response) => {
                        // this.department ="Department";
                        this.$refs.reportForm.reset();
                        console.log(this.$refs);
                        swal.fire(
                            "Reported",
                            "We have successfully received your report, thanks",
                            "success",
                        );
                    })
                    .catch((error) => {
                        swal.fire(
                            "Error",
                            "Cannot post your report now, Try again",
                            "error",
                        );
                    });
            } else {
                this.departmentState = true;
            }
        },
    },
};
</script>

<style scoped>
.help-title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #242424;
}
.report-post-input {
    border: 1px solid #a9a9a9;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff !important;
}

.btn-course-take {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: #616060;
    background: #d6d6d6;
    border: transparent !important;
    box-sizing: border-box;
    border-radius: 10px;
}
.text-warn {
    font-size: 12px;
    margin-top: 10px;
}
.btn-course-take-active {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: #fbfdff;
    background: #08386d;
    border: transparent !important;
    box-sizing: border-box;
    border-radius: 10px;
}
</style>
