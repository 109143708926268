import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";
import "../node_modules/nprogress/nprogress.css";
import axios from "axios";
import swal from "sweetalert2";
import VueSocialSharing from "vue-social-sharing";
import NProgress from "nprogress";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import Flutterwave from "vue-flutterwave";

axios.defaults.baseURL =
    // process.env.NODE_ENV == "development"
    //     ? "http://127.0.0.1:8001/api/"
    //     : "https://backend.terrahq.co/api/";
    "https://internship5-api.terrahq.co/api/";
// "https://api.sidehustle.ng/api/";
router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        var authUser = store.getters["auth/user"];
        if (authUser) {
            next();
        } else {
            swal.fire(
                "Authentication failed!",
                "Please Login or Create an account to continue",
                "error",
            );
            next({ name: "Login" });
        }
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});

require("./store/subscribe");
store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
    store.dispatch("auth/getSiteSetting");
    createApp(App)
        .use(store)
        .use(FloatingVue)
        .use(Flutterwave, {
            publicKey: "FLWPUBK-fd31004d4e84e23802001a6dbd325b0c-X",
        })
        .use(router)
        .use(VueSocialSharing)
        .use(VueGtag, {
            config: { id: "G-1QYH4NZQZR" },
            includes: [{ id: "AW-10778118617" }],
            router,
        })
        .mount("#app");
});
