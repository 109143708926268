<template>
    <div>
        <div class="card p-4 bg-white community-card compose mb-5">
            <div class="card-body p-0 pb-0">
                <div class="media">
                    <img
                        :src="
                            user.avatar === 'null'
                                ? 'https://sidehustle.ng/api/assets/img/profile_pic/avatar.jpg'
                                : user.avatar
                        "
                        class="mr-3 rounded-circle"
                        :alt="user.first_name"
                        width="50"
                        height="50"
                    />
                    <div class="media-body">
                        <input
                            class="form-control post-input2"
                            @click="open"
                            readonly
                            placeholder="Ask your question here"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="addNew">
            <div
                class="modal-dialog modal-dialog-centered"
                style="max-width:600px"
            >
                <div
                    class="modal-content px-3 px-lg-4"
                    style="border-radius: 20px"
                >
                    <div class="modal-header pl-0">
                        <h4 class="modal-title">Ask your question</h4>
                    </div>
                    <form @submit.prevent="postQuestion">
                        <div class="modal-body pb-0">
                            <img
                                :src="user.avatar"
                                class="mr-3 rounded-circle"
                                :alt="user.first_name"
                                width="70"
                                height="70"
                            />
                        </div>
                        <div class="modal-body">
                            <div class="form-group demo">
                                <textarea
                                    v-model="post"
                                    resize="false"
                                    rows="6"
                                    class="form-control post-input"
                                    placeholder="Ask your question here..."
                                    @keyup="check"
                                >
                                </textarea>
                            </div>
                        </div>
                        <div
                            class="modal-footer justify-content-end"
                            style="border-top:0px"
                        >
                            <button
                                type="submit"
                                class="btn btn-primary btn-course-take-active"
                                :disabled="post.length<5"
                            >
                                Ask <i class="fas fa-angle-right"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import swal from "sweetalert2";
import "v-tooltip/dist/v-tooltip.css";
import { Mentionable } from "vue-mention";

export default {
    data() {
        return {
            load: false,
            post: "",
            items: [],
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    props: {
        lesson: {
            required: true,
            type: [Object],
        },
    },

    // emits: ["posted_question"],

    components: { Mentionable },

    methods: {
        open() {
            $("#addNew").modal("show");
        },

        get(item) {
            this.selected.push(item);
        },
        async loadIssues(searchText = null) {
            this.loading = true;
            await axios
                .get("question/search", {
                    params: {
                        search: searchText,
                        course_id: this.lesson.course_id,
                        type: "lesson",
                    },
                })
                .then((response) => {
                    this.items = response.data.payload;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        check() {
            if (this.post.length > 0) {
                this.load = true;
            } else if (this.post.length < 1) {
                this.load = false;
            }
        },

        postQuestion() {
            this.load = false;
            let data = {
                question: this.post,
                course_id: this.user.course_id,
                lesson_id: this.lesson.id,
            };
            axios
                .post("question", data)
                .then((response) => {
                    (this.post = ""), (this.load = false);
                    $("#addNew").modal("hide");
                    this.$emit("posted", response.data.payload.question);
                })
                .catch((error) => {
                    this.load = true;
                    swal.fire(
                        "Error",
                        "Cannot post your question now, Try again",
                        "error",
                    ).then(() => {
                        $("#addNew").modal("hide");
                    });
                });
        },
    },
};
</script>

<style lang="css" scoped>
.modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 31px;

    /* identical to box height */

    color: #606060;
}
.community-card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}
.community-card.compose h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* line-height: 18px; */
    color: #a7a5a5;
}
.btn-course-take-active {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: #fbfdff;
    border: transparent !important;
    box-sizing: border-box;
    border-radius: 25px;
}
.community-card.post .media {
    margin-bottom: 20px;
}

.community-card.post .media .media-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
}
.post-input2 {
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 550;
    box-sizing: border-box;
    border-radius: 25px;
    height: 40px;
    margin-top: 5px;
}

.post-input {
    border: none;
    font-size: 14px;
    font-weight: 550;
    box-sizing: border-box;
    border-radius: 25px;
    height: auto;
    background: #fff !important;
}
.post-input:focus {
    border: none !important;
    box-shadow: none !important;
}
.btn-course-take {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: #616060;
    background: #d6d6d6;
    border: transparent !important;
    box-sizing: border-box;
    border-radius: 25px;
}
</style>
