<template>
    <div class="col-md-10 offset-md-1 mt-5 p-0">
        <div class="col-md-9 col-12 p-0 mx-auto">
            <div
                class="benefits-card2 pl-3 pt-4 pb-4 pr-3 resource text-center p-lg-5"
            >
                <div class="benefits-card-title2 mb-3 pl-2">
                    Acess Free Resources
                </div>
                <!-- <div class="benefits-card-details2 pl-2">During this internship, you will be tasked with joining teams, which gives you unlimited access to forming strong networks with like minds.</div> -->
                <h5 class="text-center">
                    Free resources are not available at the moment, check back
                    again later
                </h5>
                <div class="row mt-5 d-none">
                    <div class="col-md-3">
                        <img class="mb-4" src="@/assets/img/resources.png" />
                        <div
                            class="benefits-card-title2 mb-3 pl-2 resource-text"
                        >
                            Lorem of Frontend lorem
                        </div>
                        <div class="form-group p-0">
                            <a
                                class="btn btn-submit btn-block"
                                style="color:#fff; box-shadow:none"
                                disabled
                                v-html="buttonText"
                                href="#"
                            >
                            </a>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <img class="mb-4" src="@/assets/img/resources.png" />
                        <div
                            class="benefits-card-title2 mb-3 pl-2 resource-text"
                        >
                            Lorem of Frontend lorem
                        </div>
                        <div class="form-group p-0">
                            <a
                                class="btn btn-submit btn-block"
                                style="color:#fff; box-shadow:none"
                                disabled
                                v-html="buttonText"
                                href="#"
                            >
                            </a>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <img class="mb-4" src="@/assets/img/resources.png" />
                        <div
                            class="benefits-card-title2 mb-3 pl-2 resource-text"
                        >
                            Lorem of Frontend lorem
                        </div>
                        <div class="form-group p-0">
                            <a
                                class="btn btn-submit btn-block"
                                style="color:#fff; box-shadow:none"
                                disabled
                                v-html="buttonText"
                                href="#"
                            >
                            </a>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <img class="mb-4" src="@/assets/img/resources.png" />
                        <div
                            class="benefits-card-title2 mb-3 pl-2 resource-text"
                        >
                            Lorem of Frontend lorem
                        </div>
                        <div class="form-group p-0">
                            <a
                                class="btn btn-submit btn-block"
                                style="color:#fff; box-shadow:none"
                                disabled
                                v-html="buttonText"
                                href="#"
                            >
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mt--lg-4 mb-5">
                <span class="mb-0 col-12 text-center d-block skip">
                    <router-link :to="{ name: 'WelcomeDashboard' }" class="skip"
                        >Back
                    </router-link>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Counter from "../../../components/inc/Counter.vue";

export default {
    data() {
        return {
            buttonText: "Download",
            submitted: false,
            selectedText: "",
        };
    },

    components: {
        Counter,
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Dashboard";
        },
    },
};
</script>

<style scoped>
.benefits-card2.resource {
    background: #ebf5eb;
}

.benefits-card2.resource img {
    height: 50px;
    width: 50px;
}

.resource-text {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #a7a5a5;
}

/* .benefits-card2.course::after {
    background: url(../../../assets/img/line/line2.png) no-repeat;
    background-size: auto;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right:0px;
} */
</style>
