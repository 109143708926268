<template>
    <div class="row col-12 m-0 p-0 mb-5 d-flex flex-wrap">
        <div
            class="col-5 mb-3 d-flex flex-column justify-content-start course-card p-3 mr-4"
            v-for="(item, index) in courses"
            :key="index"
        >
            <div
                class="col-12 d-flex align-items-center justify-content-center course-card-icon"
            >
                <i class="fas fa-brain"></i>
            </div>
            <div class="course-card-title col-12 p-0 mt-2 mt-lg-4">
                {{ item.title }}
            </div>
            <div class="d-flex justify-content-between mt-auto mb-0">
                <div
                    class="course-card-arrow"
                    @click="goToLessons(item.id, item.title)"
                >
                    <i class="fas fa-arrow-right"></i>
                </div>
                <div
                    v-if="courses.length > 1 && week == 1"
                    class="course-card-arrow"
                    @click="deleteCourse(item.id, item.title)"
                >
                    <i class="fas fa-trash-alt"></i>
                </div>
            </div>
        </div>
        <div
            v-if="false && user.enroll_jobberman != 2"
            class="col-5 mb-3 d-flex flex-column justify-content-start course-card p-3 mr-4"
        >
            <div
                class="col-12 d-flex align-items-center justify-content-center course-card-icon"
            >
                <i class="fas fa-brain"></i>
            </div>
            <div class="course-card-title col-12 p-0 mt-2 mt-lg-4">
                Soft Skill Training
            </div>
            <div
                class="d-flex justify-content-between mt-auto mb-0"
                @click="
                    !isLoading &&
                        goToLessons(99, 'Jobberman Soft Skill Training')
                "
            >
                <div class="course-card-arrow">
                    <i class="fas fa-arrow-right" v-if="!isLoading"></i>
                    <small v-if="isLoading">...</small>
                </div>
            </div>
        </div>
        <div
            @click="showForm()"
            class="col-5 col d-flex flex-column justify-content-center course-card mr-4 text-white bg-light"
            v-if="
                courses.length < 2 &&
                    canAddNewCourse &&
                    week < 2 &&
                    canChangeCourse
            "
        >
            <div v-if="!show" class="d-flex flex-column justify-content-center">
                <img
                    class="mb-4 mx-auto"
                    src="@/assets/img/add_course.png"
                    height="55"
                    width="55"
                />
                <div class="text-center text-dark font-weight-bold">
                    Add Course
                </div>
            </div>
            <form @submit.prevent="submit()" v-if="show">
                <div class="mb-4">
                    <select
                        name="course"
                        id="course"
                        class="custom-select-lg course-select form-control col-12 d-block"
                        v-model="course"
                        @change="selected()"
                    >
                        <option value="See courses" disabled hidden
                            >See courses</option
                        >
                        <option
                            v-for="c in coursesList"
                            :key="c.id"
                            :value="c.id"
                        >
                            <!-- v-if="course.title != user.course.title" -->
                            {{ c.title }}
                        </option>
                    </select>
                </div>
                <button
                    type="submit"
                    class="btn btn-submit text-center btn-block"
                    style="color:#fff; box-shadow:none"
                    :disabled="!submitted"
                    v-html="buttonText"
                ></button>
            </form>
        </div>
        <jobberman
            v-if="!doneJobberman"
            @close-modal="doneJobberman = true"
        ></jobberman>
    </div>
</template>

<script>
import jobberman from "./../jobberman/index.vue";

import axios from "axios";
import swal from "sweetalert2";
import NProgress from "nprogress";
import { mapGetters, mapActions } from "vuex";

export default {
    props: ["courses"],
    components: {
        jobberman,
    },
    data() {
        return {
            buttonText: "Save",
            submitted: false,
            selectedText: "",
            coursesList: [],
            course: "See courses",
            show: false,
            update_course: false,
            courseToBeUpdated: "",
            doneJobberman: true,
            canChangeCourse: false,
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            settings: "auth/settings",
            week: "auth/currentWeek",
        }),
        canAddNewCourse() {
            if (this.settings) {
                return this.settings.add_course_status;
            } else {
                return false;
            }
        },
    },
    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
        checkChangeCourse() {
            axios.get("user/checkChangeCourse").then((res) => {
                console.log(res.data.status);
                if (res.data.status == false) {
                    this.canChangeCourse = false;
                } else {
                    this.canChangeCourse = true;
                }
            });
        },
        deleteCourse(id, title) {
            swal.fire({
                title: "Confirm Delete",
                text: `Are you sure you want to delete ${title} from your list of courses`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
            }).then((result) => {
                if (result.isConfirmed) {
                    let data = {
                        course_id: id,
                    };
                    axios.post("user/deleteCourse", data).then((res) => {
                        this.attempt();
                        this.checkChangeCourse();
                    });
                }
            });
        },

        async goToLessons(id, title) {
            if (id == 99) {
                if (this.user.enroll_jobberman == 0) {
                    this.isLoading = true;
                    const status = await this.jobbermanStatus();
                    if (status === undefined) {
                        swal.fire(
                            "Please try again",
                            "We are unable to verify your status right now",
                            "error",
                        );
                    }

                    if (status === false) {
                        this.doneJobberman = false;
                    }
                }
                if (this.user.enroll_jobberman == 1) {
                    this.isLoading = true;
                    let data = {
                        isExternal: true,
                        course_id: 99,
                        week: 1,
                    };
                    axios
                        .post("quiz/check", data)
                        .then((res) => {
                            this.isLoading = false;
                            if (res.data.payload == "Baseline not taken") {
                                swal.fire({
                                    title:
                                        "You are yet to take the Jobberman baseline test",
                                    text:
                                        "You need to take the Jobberman soft skills training baseline test before you can proceed to watch the Jobberman lesson videos",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Take baseline Test",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$router.push({
                                            name: "QuizTake",
                                            params: {
                                                course_id: 99,
                                                isExternal: true,
                                                isBaseline: 1,
                                            },
                                        });
                                    } else if (result.isDenied) {
                                        return;
                                    }
                                });
                            }
                            if (res.data.payload == "Take Endline") {
                                swal.fire({
                                    title:
                                        "You can proceed to take your Jobberman endline test",
                                    text:
                                        "You can proceed to take your Jobberman soft skills training endline test now",
                                    icon: "success",
                                    showCancelButton: true,
                                    confirmButtonText: "Take endline test",
                                    cancelButtonText: "Continue to Lessons",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$router.push({
                                            name: "QuizTake",
                                            params: {
                                                course_id: 99,
                                                isExternal: true,
                                                isBaseline: 2,
                                            },
                                        });
                                    } else if (result.isDismissed) {
                                        this.$router.push({
                                            name: "Lessons",
                                            params: {
                                                id: 99,
                                                title:
                                                    "Jobberman Soft Skill Training",
                                            },
                                        });
                                    }
                                    return;
                                });
                            }
                            if (res.data.payload == "Start Lessons") {
                                this.$router.push({
                                    name: "Lessons",
                                    params: {
                                        id: 99,
                                        title: "Jobberman Soft Skill Training",
                                    },
                                });
                            }
                        })
                        .catch((err) => {
                            this.isLoading = false;
                            console.log(err);
                        });
                }
                if (this.user.enroll_jobberman == 2) {
                    swal.fire({
                        title: "Already Taken Jobberman Soft Skill",
                        text:
                            "You have already taken your Jobberman Soft Skill ",
                        icon: "success",
                    });
                    return;
                }
            } else {
                this.$router.push({
                    name: "Lessons",
                    params: {
                        id,
                        title,
                    },
                });
            }
        },

        /**
         * Check intern status at jobberman
         *
         * @returns {<Promise>}
         */
        async jobbermanStatus() {
            try {
                const status = await axios.get(
                    `https://www.jobberman.com/api/v2/softskills/learners?email=${this.user.email}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer vjcfUBqLSF01J5v4npWrhXWlVBjYwT",
                        },
                    },
                );
                swal.fire({
                    title: "Already Taken Jobberman Soft Skill",
                    text:
                        "You have previously completed the Jobberman Soft Skill training.",
                    icon: "success",
                });
                this.updateLearnerStatus(2);
                this.attempt();
                this.isLoading = false;
                return true;
            } catch (error) {
                this.isLoading = false;
                if (error.response.status !== 401) {
                    return false;
                } else {
                    return undefined;
                }
            }
        },
        showForm() {
            this.show = true;
        },

        /**
         * Update intern jobberman status on sidehustle end
         *
         * @returns {Object}
         */
        updateLearnerStatus(status) {
            axios
                .post("user/jobberman/enroll", { status })
                .then((res) => {
                    console.log(res);
                    this.attempt();
                })
                .catch((err) => console.log(err));
        },
        selected() {
            this.submitted = true;
        },
        submit() {
            NProgress.start();
            this.submitted = true;
            this.buttonText = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
            let data = { course_id: this.course };
            axios
                .post("user/addNewCourse", data)
                .then((res) => {
                    this.attempt();
                    swal.fire("Success", "Course has been added", "success");
                    NProgress.done();
                    this.show = false;
                    this.buttonText = "Save";
                    this.checkChangeCourse();
                })
                .catch((err) => {
                    NProgress.done();
                    console.log(err.response.data.payload);
                    this.buttonText = "Proceed";
                    this.submitted = false;
                    swal.fire("Error", `${err.response.data.payload}`, "error");
                });
        },
    },
    created() {
        this.checkChangeCourse();
        NProgress.start();
        axios
            .get("courses")
            .then((res) => {
                let courses = res.data.payload;
                courses = courses.filter(
                    (item) => item.id !== this.user.course_id,
                );
                this.coursesList = courses;
                NProgress.done();
            })
            .catch((err) => {
                NProgress.done();
            });
    },
};
</script>
<style lang="css" scoped>
.course-card-icon {
    background: #fff;
    border-radius: 11px;
    max-width: 40px;
    max-height: 40px;
}
.course-card {
    background-color: #fdf1f4;
    background-image: url("./../../../../assets/img/line/line2.png");
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
    border-radius: 20px;
    min-height: 240px;
    max-width: 300px;
    cursor: pointer;
}
.course-card-arrow {
    padding: 5px 10px;
    border-radius: 50%;
    background: #f9d2dc;
    color: #e42a50;
}
.course-card:last-child {
    margin-right: 0 !important;
    background-color: #ecf5fd;
}
.course-card:last-child .course-card-arrow {
    padding: 5px 10px;
    border-radius: 50%;
    background: #d1e7fa;
    color: #08386d;
}
.course-card-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #242424;
    flex-basis: auto;
}
</style>
