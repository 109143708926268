<template>
    <div class="score-card p-3 px-4 d-flex justify-content-between flex-column">
        <div class="card-heading-new mb-5">
            Total Quiz Score
        </div>
        <div
            v-if="score > 0"
            class="d-flex flex-column justify-content-between flex-grow-1"
        >
            <div class="user-total-score text-center mb-auto">
                {{ score }}
            </div>
            <div class="score-text text-center">
                Your total quiz score so far
            </div>
        </div>
        <div v-else>
            <h6 v-show="loader" class="score-text">
                Score not available at the moment
            </h6>
            <div v-show="!loader" class="text-center mt-3 text-white">
                <i class="fas fa-spinner fa-spin fa-2x"></i>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "Score",
    data() {
        return {
            score: "",
            loader: true,
        };
    },
    mounted() {
        this.getScore();
    },

    methods: {
        async getScore() {
            this.loader = false;
            await axios
                .get("user/score")
                .then((response) => {
                    this.score = response.data.payload;
                    this.loader = true;
                })
                .catch(() => {
                    this.score = [];
                    this.loader = true;
                });
        },
    },
};
</script>

<style scoped>
.card-heading-new {
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    color: #ffffff;
    font-family: Nunito;
}
.user-total-score {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 52px;
    line-height: 33px;
    letter-spacing: 0.04em;
    color: #ffffff;
}
.score-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 19px;
    color: #ffffff;
}
</style>
