<template>
    <div class="px-3">
        <div class="d-flex">
            <div class="mr-2" @click="$router.go(-1)">
                <i class="fas fa-arrow-left"></i>
            </div>
            <h5
                class="pl-3"
                style="font-weight: 300;font-size: 16px;line-height: 22px; color: #242424 !important;"
            >
                FAQs
            </h5>
        </div>
        <div class="faqs-container p-0">
            <div
                class="col-md-12 col-12 d-flex flex-column flex-md-row align-items-start mt-3 mt-md-4 p-0"
            >
                <div class="row p-0 col-12 mx-auto">
                    <div class="card mb-5 help col-md-7 pl-0">
                        <div id="accordion" class="accordion p-0">
                            <div class="px-0 each-faq">
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse4"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What are badges?
                                    </a>
                                </div>
                                <div
                                    id="collapse4"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        Badges are additional achievements attached
                                        to your certificate.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div class="px-0 each-faq">
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse5"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How do I earn a badge?
                                    </a>
                                </div>
                                <div
                                    id="collapse5"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        You can earn a badge by completing
                                        challenges and executing tasks
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div class="px-0 each-faq">
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse6"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How many badges are available?
                                    </a>
                                </div>
                                <div
                                    id="collapse6"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        There are a total of five badges available
                                        to be earned. Each badge is attached to a
                                        specific task or challenge.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse7"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What are Airdrops?
                                    </a>
                                </div>
                                <div
                                    id="collapse7"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        Airdrops are random boxes containing
                                        different challenges to be completed.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse8"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How do I qualify for Airdrops?
                                    </a>
                                </div>
                                <div
                                    id="collapse8"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        Airdrops are open to everyone on every rank
                                        but only at specific times.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse9"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What is a rank?
                                    </a>
                                </div>
                                <div
                                    id="collapse9"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        Your rank is your position and is dependent
                                        on your streak bar
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse10"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How many ranks are there?
                                    </a>
                                </div>
                                <div
                                    id="collapse10"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        There are five ranks available ranging from
                                        Lieutenant to Field Marshall being the
                                        highest rank.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse11"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How do I increase my rank?
                                    </a>
                                </div>
                                <div
                                    id="collapse11"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        Your rank increases as your streak bar
                                        increases, so you increase your rank by
                                        increasing your streak bar
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse12"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What are stars?
                                    </a>
                                </div>
                                <div
                                    id="collapse12"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        Stars are rank indicators and they are
                                        determined by your rank.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse13"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How many stars are there?
                                    </a>
                                </div>
                                <div
                                    id="collapse13"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        There are five stars available, one for each
                                        rank. Your star automatically increases with
                                        your rank.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse14"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What are challenges?
                                    </a>
                                </div>
                                <div
                                    id="collapse14"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        Challenges are individual tasks you have to
                                        execute. There are different challenges tied
                                        to different ranks and their certain rewards
                                        for each completed challenge
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse15"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How do I qualify for challenges?
                                    </a>
                                </div>
                                <div
                                    id="collapse15"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        Only interns with three or more stars are
                                        qualified for challenges
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse16"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How many challenges are available?
                                    </a>
                                </div>
                                <div
                                    id="collapse16"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        There are three challenges (Weekly,
                                        Leaderboard challenge and General challenge)
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse17"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What do I win in challenges?
                                    </a>
                                </div>
                                <div
                                    id="collapse17"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        You can win prizes like cash prizes, data,
                                        Side Hustle T Shirts, Powerbanks, flash
                                        drive, smart watch, earbuds, movie tickets,
                                        Laptop bag and lots more.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse18"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What is a Streak bar?
                                    </a>
                                </div>
                                <div
                                    id="collapse18"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        A streak bar determines your rank. A streak
                                        bar is in two sections. One for lessons and
                                        the other for quizzes.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse19"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How do I increase my streak bar?
                                    </a>
                                </div>
                                <div
                                    id="collapse19"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        You can increase your steak bar up to 50% by
                                        taking your lessons. The other 50% is
                                        determined by your quiz result.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse20"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What is a negative streak bar?
                                    </a>
                                </div>
                                <div
                                    id="collapse20"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        A negative streak bar is when you have a
                                        negative quiz score. When you have a
                                        negative streak bar, you’ll be evicted from
                                        the internship.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div class="px-0 each-faq">
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse21"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How do I take my quiz?
                                    </a>
                                </div>
                                <div
                                    id="collapse21"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        To take a quiz, click on the “quiz” option
                                        on your dashboard, then click on “take quiz”
                                        from the available options to start your
                                        quiz.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div class="px-0 each-faq">
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse22"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How many Questions are in the Quiz?
                                    </a>
                                </div>
                                <div
                                    id="collapse22"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        There are a total of 20 questions for every
                                        week.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div class="px-0 each-faq">
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse23"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What are capstone projects?
                                    </a>
                                </div>
                                <div
                                    id="collapse23"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        Capstone projects are tasks where you get to
                                        make use of experiences and skills learnt to
                                        execute group projects.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div class="px-0 each-faq">
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse24"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How do I take my capstone project?
                                    </a>
                                </div>
                                <div
                                    id="collapse24"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        To take your capstones project, click on the
                                        “capstones” icon, click on “join group” in
                                        the next phase to join a group
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse25"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What is a leaderboard?
                                    </a>
                                </div>
                                <div
                                    id="collapse25"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        The leaderboard is a list of the top 100
                                        interns in the weekly challenge.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse26"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How do I qualify for the Leaderboard?
                                    </a>
                                </div>
                                <div
                                    id="collapse26"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        You must be a Field marshal, earned atleast
                                        5 badges and posted your profile online to
                                        be part of the leaderboard
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse27"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What is a mystery box?
                                    </a>
                                </div>
                                <div
                                    id="collapse27"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        Mystery box is a box containing gift items
                                        like jotters, data, cash, a shout out on our
                                        social media pages and an additional star.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div
                                class="px-0 each-faq"
                                v-if="user.mode === 'gamified'"
                            >
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse28"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        How do I get a mystery box?
                                    </a>
                                </div>
                                <div
                                    id="collapse28"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        To get a mystery box, you must have an A in
                                        your capstone project.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div class="px-0 each-faq">
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse29"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What happens when I’ve been reported?
                                    </a>
                                </div>
                                <div
                                    id="collapse29"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        When a report is submitted? The reported
                                        person has 48 hours to appeal, after which
                                        an investigation will be carried out.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div class="px-0 each-faq">
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse30"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What can get me evicted?
                                    </a>
                                </div>
                                <div
                                    id="collapse30"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        You can get evicted from the internship when
                                        you have a negative streak bar or when you
                                        are reported
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                            <div class="px-0 each-faq">
                                <div
                                    class="card-header collapsed mb-1 px-0"
                                    data-toggle="collapse"
                                    href="#collapse31"
                                >
                                    <a
                                        class="card-title help-link font-weight-bold px-0"
                                    >
                                        What happens when I’m reported?
                                    </a>
                                </div>
                                <div
                                    id="collapse31"
                                    class="card-bod collapse px-0 col-10"
                                    data-parent="#accordion"
                                >
                                    <p class="text-color">
                                        Every report is subject to review by the
                                        Side Hustle board, after which an
                                        investigation will be done. Interns found
                                        guilty will therefore be evicted if found
                                        guilty.
                                    </p>
                                </div>
                            </div>
                            <!-- Each Item -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
};
</script>

<style scoped>
.faqs-container {
    max-height: 100vh;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.faqs-container::-webkit-scrollbar {
    display: none;
}
.card,
.card-title {
    border-radius: 15px;
    border: none;
}
.each-faq {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 0;
}
.help {
    height: 500px;
}
.help-link {
    text-decoration: none;
    color: #aaa !important;
}

.text-color {
    color: #676565;
    font-size: 12px !important;
}

.accordion .card-header {
    cursor: pointer;
    border: none;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important; */
    background-color: white;
    font-size: 18px;
}

.accordion .card,
.accordion .card-body {
    border: none;
    background-color: white;
}

.accordion .card-header:after {
    font-family: "Font Awesome 5 Free";
    content: "\f107";
    color: #606060;
    float: right;
    font-size: 18px;
    font-weight: bold;
}

.accordion .card-header.collapsed:after {
    /* symbol for "collapsed" panels */
    font-family: "Font Awesome 5 Free";
    content: "\f105";
}
</style>
