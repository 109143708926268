<template>
    <div class="container-fluid start" style="background-color: #f5fafe;">
        <div class="row py-50">
            <div class="container">
                <h1>Contact</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Contact",
};
</script>
