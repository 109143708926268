const PNF = require("google-libphonenumber").PhoneNumberFormat;
// Get an instance of `PhoneNumberUtil`.
const PhoneNumberUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

const customValidation = (number, iso) => {
    const validNumber = PhoneNumberUtil.parseAndKeepRawInput(number, iso);
    const isValid = PhoneNumberUtil.isValidNumberForRegion(validNumber, iso);
    const newNumber = PhoneNumberUtil.format(validNumber, PNF.INTERNATIONAL);
    if (isValid) {
        return { status: isValid, phoneNumber: newNumber };
    } else {
        return { status: false };
    }
};

export default customValidation;
