<template>
    <div class="">
        <div class="d-flex ml-3">
            <div class="mr-2" @click="$router.go(-1)">
                <i class="fas fa-arrow-left"></i>
            </div>
            <h5 class="page-title">
                Messages
            </h5>
        </div>
        <div class="d-flex flex-md-row flex-column flex-wrap">
            <div class="col-md-4">
                <router-link :to="{ name: 'SH' }">
                    <div
                        class="media cat_wrapper py-4 px-3 mb-3"
                        :class="{ active: $route.name === 'SH' }"
                    >
                        <img
                            class="mr-3 cat_image"
                            src="@/assets/img/community.png"
                            width="50"
                            height="50"
                            alt="SH Community"
                        />
                        <div class="media-body cat_body">
                            <h5 class="mt-0 cat_heading mb-0">Community</h5>
                            <span class="cat_paragraph"
                                >Post from the Community...</span
                            >
                        </div>
                    </div>
                </router-link>
                <div v-for="group in groups" :key="group.id">
                    <router-link
                        :to="{
                            name: 'CommunityRoom',
                            params: {
                                group_name: group.group.name,
                                group_id: group.group.id,
                                course_title: group.course.title,
                            },
                        }"
                    >
                        <div
                            class="media cat_wrapper py-4 px-3 mb-3"
                            :class="{
                                active:
                                    $route.name === 'CommunityRoom' &&
                                    $route.params.group_name ==
                                        group.group.name,
                            }"
                        >
                            <img
                                class="mr-3 cat_image"
                                src="@/assets/img/community.png"
                                width="50"
                                height="50"
                                alt="SH Community"
                            />
                            <div class="media-body cat_body">
                                <h5 class="mt-0 cat_heading mb-0">
                                    {{ group.course.title }} Room
                                </h5>
                                <span class="cat_paragraph"
                                    >Posts from {{ group.group.name }}...</span
                                >
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="col-md-8 p-0">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
    data() {
        return {
            groups: [],
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        getGroups() {
            axios.get("capstone/user/groups").then((response) => {
                this.groups = response.data.payload.groups;
            });
        },
    },
    mounted() {
        this.getGroups();
    },
};
</script>

<style lang="css" scoped>
/* .community-container{
    margin-top: 150px !important;
} */

.page-title-mobile {
    margin-top: 100px !important;
}
.cat_wrapper {
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    background: #fff;
    cursor: pointer;
}

.cat_wrapper.active {
    border: 1px solid #044995;
    background: #f5faff;
}
.cat_paragraph {
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #3f3f3f;
}

.cat_heading {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #3f3f3f;
}
.cat_wrapper.active .cat_heading {
    font-weight: bold;
}
</style>
