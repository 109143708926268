<template id="countdown-template">
    <div class="countdown">
        <div class="digit text-center">
            <span class="mr-lg-3 mr-2">{{ days }} Days</span>

            <span class="mr-lg-3 mr-2">{{ hours }} Hours</span>

            <span class="mr-lg-3 mr-2">{{ minutes }} Minutes</span>

            <span class="mr-lg-3 mr-2">{{ seconds }} Seconds</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "Counter",
    mounted() {
        window.setInterval(() => {
            this.now = Number(Math.trunc(new Date().getTime() / 1000));
        }, 1000);
    },
    props: {
        date: String,
    },
    data() {
        return {
            now: Number(Math.trunc(new Date().getTime() / 1000)),
        };
    },
    computed: {
        dateInMilliseconds() {
            return Number(Math.trunc(Date.parse(this.date) / 1000));
        },
        seconds() {
            return Number((this.dateInMilliseconds - this.now) % 60);
        },
        minutes() {
            return Number(
                Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60,
            );
        },
        hours() {
            return Number(
                Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24,
            );
        },
        days() {
            return Number(
                Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24),
            );
        },
    },
};
</script>

<style scoped>
.countdown {
    display: flex;
    color: #08386d;
}

.block {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.digit span{
    font-family: GD Boing;
    font-style: normal;
    font-weight: bold;
    font-size: 28px !important;
    line-height: 39px !important;
    text-align: center;
    color: #52A350;

}

@media screen and (max-width:768px) {
    .digit span{
        font-family: GD Boing;
        font-style: normal;
        font-size: 14px !important;
        line-height: 23px !important;
        text-align: center;
        color: #52A350

    }
}
</style>
