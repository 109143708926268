<template>
    <div class="col-12 px-xlg-100 px-sm-20 px-lg-30 py-xlg-100">
        <div class="row justify-content-between px-5 px-lg-0">
            <div
                class="col-12 col-lg my-3 mx-lg-3 process-cards d-flex flex-column overflow-hidden p-0" data-aos="fade-left" data-aos-duration="5000" data-aos-delay="500"
            >
                <div>
                    <img
                        class="w-100"
                        src="@/assets/img/process/card-1.png"
                        alt=""
                    />
                </div>
                <div class="px-4 pt-4 pb-2 d-flex flex-column">
                    <div class="process-card-title">Internship</div>
                    <div class="process-card-text">
                        You get to pass through 6 weeks of world-class training
                        to learn soft & hard skills.
                    </div>
                    <div class="price-tag">
                        <img
                            src="@/assets/img/process/internship-fee.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div
                class="col-12 col-lg my-3 mx-lg-3 process-cards d-flex flex-column overflow-hidden p-0" data-aos="fade-left" data-aos-duration="5000" data-aos-delay="1500"
            >
                <div>
                    <img
                        class="w-100"
                        src="@/assets/img/process/card-2.png"
                        alt=""
                    />
                    <div class="optional-tag">Optional</div>
                </div>
                <div class="px-4 pt-4 pb-2 d-flex flex-column">
                    <div class="process-card-title">Certification</div>
                    <div class="process-card-text">
                        Get 2 certificates. 1 free certificate from Jobberman
                        and one from Side Hustle Internship.
                    </div>
                    <div class="price-tag">
                        <img
                            src="@/assets/img/process/certificate-fee.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div
                class="col-12 col-lg my-3 mx-lg-3 process-cards d-flex flex-column overflow-hidden p-0" data-aos="fade-left" data-aos-duration="5000" data-aos-delay="2500"
            >
                <div>
                    <img
                        class="w-100"
                        src="@/assets/img/process/card-3.png"
                        alt=""
                    />
                    <div class="optional-tag">Optional</div>
                </div>
                <div class="px-4 pt-4 pb-2 d-flex flex-column">
                    <div class="process-card-title">Bootcamp</div>
                    <div class="process-card-text">
                        Join a team of brilliant minds to build world class
                        products and a strong portfolio
                    </div>
                    <div class="price-tag">
                        <img
                            src="@/assets/img/process/bootcamp-fee.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="p-0 join-now mx-auto text-center d-flex justify-content-center mt-5">
            <router-link :to="{ name: 'Register' }">
                <button
                    class="px-4 py-2 cta-btn d-none d-lg-block mt-5 mt-lg-4"
                >
                    Apply as an Intern
                </button>
            </router-link>
        </div>
    </div>
</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'


export default {
    mounted() {
    AOS.init()
  },
};
</script>

<style scoped>
.cta-btn{
    color: #FFFFFF;
    background: #2F2F2F;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1.5rem;
    border-radius: 10px;
    transition: 800ms ease;
    background-color: #52A350;
}
.cta-btn:hover {
    background-color: #E1F0E0;
    color: #52A350;
}
.process-cards {
    background: #ffffff;
    border: 0.4px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 25px;
}
.optional-tag {
    position: absolute;
    top: 20px;
    right: 20px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    color: #0354ad;
    background: #ffffff;
    border-radius: 50px;
    padding: 5px 15px;
}
.price-tag {
    width: fit-content;
    align-self: flex-end;
    position: absolute;
    bottom: 5px;
}
.process-card-title {
    font-family: "GD Boing";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #2f2f2f;
    margin-bottom: 15px;
}

.process-card-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #898989;
    margin-bottom: 85px;
}
</style>
