<template>
    <div class="card quiz-card px-0 py-3 py-lg-4 radio">
        <div class="quiz-number mb-3 font-weight-bold">
            Question {{ index + 1 }}/ {{quizLength}}
        </div>
        <div class="quiz-question-header">
            <h3>
                {{ quiz.question }}
            </h3>
        </div>
        <div>
            <div class="form-group mt-3">
                <div class="mt-3 d-block">
                    <div
                        class="radio px-3 py-2 mt-3 d-block quiz-checker"
                        v-for="(option, index) in quiz.options"
                        :key="index"
                        ref="option"
                        @click="
                            quiz.answer === null
                                ? checkAnswer(option, quiz.id, index)
                                : () => {}
                        "
                        :class="{
                            'wrong-answer':
                                quiz.selected !== null &&
                                quiz.selected === option &&
                                quiz.answer !== option,
                            'correct-answer':
                                quiz.answer !== null && quiz.answer === option,
                            notify_selected:
                                selected === option && selectedOpt === quiz.id,
                        }"
                    >
                        <input
                            type="radio"
                            name="optradio"
                            :value="option"
                            :disabled="quiz.answer != null"
                        />
                        <span class="ml-4 d-inline-block pt-2">{{
                            option
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["quiz", "quizLength", "index", "course_id", "selectedOpt"],
    data() {
        return {
            selected: "",
        };
    },
    methods: {
        async checkAnswer(option, quiz_id, index) {
            this.selected = option;
            let data = {
                id: quiz_id,
                selected: option,
                course_id: this.course_id,
                index: index,
            };
            this.$emit("grade", data);
        },
    },
};
</script>

<style scoped>
.test {
    color: red;
}
.quiz-card {
    border: none !important;
}
.quiz-checker {
    cursor: pointer;
}
.notify_selected {
    color: white !important;
    background: #08386d !important;
}
.unanswered:hover {
    background-color: rgb(230, 230, 230);
}
.unanswered:active {
    background-color: rgb(138, 137, 137);
}

.quiz-btn {
    background: #08386d;
    border-radius: 25px;
    border: 1px solid #08386d;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    padding: 13px 20px;
    color: #ffffff;
}

/* Quiz Question */

.quiz-question-header h3 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #606060;
}

.radio {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 14px;
    line-height: 18px;
    /* color: #606060; */
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
}

.radio.selected {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #08386d;
}
.correct-answer {
    background-color: green;
    position: relative;
    color: #fff;
}
.correct-answer::after {
    content: "\2714";
    position: absolute;
    right: 0;
    padding-right: 10px;
    font-weight: bolder;
    color: #fff;
}
.wrong-answer {
    background-color: red;
    position: relative;
    color: #fff;
}
.wrong-answer::after {
    content: "\2718";
    position: absolute;
    right: 0;
    padding-right: 10px;
    font-weight: bolder;
    color: #fff;
}

.quiz-number h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #606060;
}
@media screen and (max-width: 700px) {
    .quiz-question-header h3 {
        font-size: 1.2rem;
    }
    .quiz-number h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        color: #606060;
    }
}
</style>
