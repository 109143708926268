<template>
    <div class="px-4 px-md-5 bg-white py-3">
        <div class="d-flex">
            <div @click="$router.go(-1)" class="course-card-arrow pr-3">
                <i class="fas fa-arrow-left"></i>
            </div>
            <h5 class="page-title" v-if="track">
                <span>Lessons</span> > {{ track }}
            </h5>
        </div>
        <div class="row">
            <div class="mt-4 col-lg-9 p-0">
                <!-- Lesson Card -->
                <div class="current-week pl-2" @click="selectWeek(currentWeek)">
                    Week {{ currentWeek }}
                </div>
                <div class="lessons-container row p-0 mx-0">
                    <div
                        class="lessons-container col-12 px-3 py-2 pt-4 m-0 d-flex flex-wrap justify-content-between"
                        v-if="lessons.length > 0"
                    >
                        <Topics
                            :lessons="lessons"
                            :watched="allWatchedLessons"
                        />
                    </div>
                    <div v-else class="p-3">
                        {{ loader ? "Fetching your lessons..." : msg }}
                    </div>
                </div>
                <!-- Lesson Card -->
            </div>
            <div class="d-none d-lg-block col-lg-3">
                <TakeQuiz :id="id" :currentWeek="currentWeek" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Topics from "./lesson-components/Topics.vue";
import TakeQuiz from "./lesson-components/Quiz.vue";
import swal from "sweetalert2";

export default {
    data() {
        return {
            loader: false,
            lessons: "",
            currentWeek: 1,
            track: "",
            weeks: [1, 2, 3, 4, 5, 6],
            msg: `No lessons found`,
            allWatchedLessons: [],
        };
    },
    components: {
        Topics,
        TakeQuiz,
    },
    props: {
        id: String,
        title: String,
    },
    computed: {
        ...mapGetters({
            week: "auth/currentWeek",
            user: "auth/user",
        }),
    },
    methods: {
        ...mapActions({
            getCurrentWeek: "auth/getCurrentWeek",
        }),
        async fetchLessons(week = this.currentWeek) {
            this.loader = true;
            try {
                let payload = {
                    course_id: this.id,
                    week,
                };
                let { data } = await axios.post("lesson", payload);
                this.lessons = data.payload;
                this.loader = false;
            } catch (error) {
                this.loader = false;
            }
        },
        async watchedLessons() {
            try {
                const { data } = await axios.post("watched", {
                    course_id: this.id,
                    week: this.currentWeek,
                    isExternal: this.id == 99 ? true : false,
                });
                try {
                    this.allWatchedLessons = JSON.parse(data.payload);
                    if (this.id ==  99) {
                        console.log(this.allWatchedLessons.length, this.lessons.length)
                        if (this.allWatchedLessons.length == 5 ) {
                            swal.fire({
                                    title:
                                        "You can now proceed to take your Jobberman endline test",
                                    text:
                                        "You can proceed to take your Jobberman soft skills training endline test now",
                                    icon: "success",
                                    showCancelButton: true,
                                    confirmButtonText: "Take endline test",
                                    cancelButtonText: "Continue to Lessons",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$router.push({
                                            name: "QuizTake",
                                            params: {
                                                course_id: 99,
                                                isExternal: true,
                                                isBaseline: 2,
                                            },
                                        });
                                    } else if (result.isDismissed) {
                                        this.$router.push({
                                            name: "Lessons",
                                            params: {
                                                id: 99,
                                                title:
                                                    "Jobberman Soft Skill Training",
                                            },
                                        });
                                    }
                                    return;
                                });
                        }
                    }
                } catch (error) {
                    this.allWatchedLessons = data.payload;
                    if (this.id ==  99) {
                        console.log(this.allWatchedLessons.length, this.lessons.length)
                        if (this.allWatchedLessons.length == 5) {
                            swal.fire({
                                    title:
                                        "You can proceed to take your Jobberman endline test",
                                    text:
                                        "You can proceed to take your Jobberman soft skills training endline test now",
                                    icon: "success",
                                    showCancelButton: true,
                                    confirmButtonText: "Take endline test",
                                    cancelButtonText: "Continue to Lessons",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$router.push({
                                            name: "QuizTake",
                                            params: {
                                                course_id: 99,
                                                isExternal: true,
                                                isBaseline: 2,
                                            },
                                        });
                                    } else if (result.isDismissed) {
                                        this.$router.push({
                                            name: "Lessons",
                                            params: {
                                                id: 99,
                                                title:
                                                    "Jobberman Soft Skill Training",
                                            },
                                        });
                                    }
                                    return;
                                });
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        selectWeek(wk) {
            if (wk <= this.week) {
                this.currentWeek = wk;
                this.fetchLessons(wk);
            } else {
                this.currentWeek = this.week;
                this.fetchLessons(this.week);
            }
        },
        getLesson(_lesson) {
            let lesson = JSON.stringify(_lesson);
            let lessons = JSON.stringify(this.lessons);
        },
    },
    mounted() {
        if (this.id == null) {
            this.$router.push({ name: "courses" });
        } else {
            if (this.id != undefined) {
                this.track = this.title;
                this.currentWeek = this.week;
                this.fetchLessons();
                this.watchedLessons();
            }
        }
    },
};
</script>

<style lang="css" scoped>
.lessons-container {
    background: #f4f5f5;
    /* background: green; */
    border-radius: 0px 0px 20px 20px;
}
.current-week {
    border-bottom: 1px solid #959595;
    font-weight: bold;
    font-size: 13px;
}
.other-weeks button {
    text-align: left;
    background: none;
    margin-left: 0;
    color: #545454;
    font-weight: 300;
    margin: 0;
}
.other-weeks {
    background: #fff;
    font-weight: 300;
    font-size: 13px;
}
.quiz-card {
    background-color: #fff;
    background-image: url("./../../../assets/img/line/line2.png");
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
    border-radius: 14px;
}
</style>
