<template>
    <navbar />
    <div>
        <img src="@/assets/img/404error.png" class="text-center" alt="">
        <h2 class="mt-3">
            Error 404! Page Not Found
        </h2>
        <p>
            Oh dear! We couldn't find that page!
        </p>
        <button @click="$router.go(-1)"><i class="fas fa-angle-left fa-1x"></i> Go Back</button>
    </div>
</template>

<script>
import Navbar from "../components/inc/Navbar.vue";
export default {
    components: { Navbar },
};
</script>

<style scoped>
div {
position: absolute;
text-align: center;
top: 50%;
left: 50%;
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
}
img{
max-width: 211px;
}
div h2{
font-family: 'GD Boing';
font-weight: 700;
font-size: 26px;
color: #52A350;
}
div p{
font-family: 'Nunito';
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #898989;
}
div button{
background: #E1F0E0;
border: 2px solid rgba(82, 163, 80, 0.2);
border-radius: 8px;
font-family: 'Nunito';
font-weight: 300;
font-size: 14px;
line-height: 25px;
color: #52A350;
padding: 5px 16px;
}
</style>
