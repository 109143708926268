<template>
    <span @click="$router.go(-1)">Capstone</span> >
    <span @click="$router.go(-1)">{{ course.title }}</span> > Projects
    <div
        class="col-md-9 col-12 d-flex flex-column flex-md-row align-items-start mt-3 mt-md-5 p-0"
    >
        <div class="col-md-12" v-if="!isLoading">
            <div class="card-header p-0 bg-white">
                <h4 class="card-title badge-card-title pb-2">{{ course.title }} Capstones</h4>
            </div>
            
            <div class="group py-4" v-if="projects.length > 0">
                <ul>
                    <li class="pt-4"
                        v-for="project in projects" 
                        :key="project.id">
                            <h5>Project Title: {{ project.title }}</h5>
                            <h5>Type: {{ project.type }}</h5>
                            <button
                                @click="seeDetails(project)" class="btn btn-primary">
                                    SEE PROJECT DETAILS <i class="fa-solid fa-arrow-right"></i>
                            </button>
                            <hr>
                    </li>
                    
                    <button @click="seeMembers(group)" class="btn btn-primary">
                            SEE OTHER GROUP MEMBERS <i class="fa-solid fa-arrow-right"></i>
                    </button>
                </ul>
            </div>
            <div class="group py-4" v-else>
                <h5>Sorry No Project Available at the Moment</h5>
            </div>
        </div>
        <div class="col-md-12" v-if="isLoading">
            <h3 class="">Loading <i class="fas fa-spinner fa-spin"></i></h3>
        </div>

        <div class="modal fade" id="ProjectDetails">
            <div
                class="modal-dialog modal-dialog-centered"
                style="max-width:900px"
            >
                <div
                    class="modal-content px-3 px-lg-4"
                >
                    <div class="modal-header pl-0">
                        <h4 class="modal-title">Capstone Project Details</h4>
                    </div>
                    <div class="modal-body">
                        <h5>Project Title: {{ selectProject.title }}</h5>
                        <h5>Type: {{ selectProject.type }}</h5>
                        <h6 class="mt-3">Project Description</h6><hr>
                        <h5 v-html="selectProject.description"></h5>
                    </div>
                     <div class="modal-footer" v-if="user.user_id == group_user_id">
                        <button class="btn btn-primary" @click="submitTask">Submit Capstone</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import axios from "axios";

export default {
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    data() {
        return {
            projects: [],
            isLoading: false,
            course: {},
            selectProject: {}
        };
    },
    props: {
        group: {
            type: [Number],
            required: true
        },
        course_id: {
            type: [Number],
            required: true
        },
        group_user_id: {
            type: [Number],
            required: true
        }
    },
    methods: {
        seeMembers(group){
            this.$router.push({
                name: "CapstoneMembers",
                params: {
                    group: group,
                },
            });
        },
        submitTask(){
            $('#ProjectDetails').modal('hide');
            this.$router.push({
                name: "CapstoneSubmit",
                params: {
                    group_user_id: this.group_user_id,
                    group: this.group,
                    project_id : this.selectProject.id
                },
            });
        },
        seeDetails(project){
            this.selectProject = project;
            $('#ProjectDetails').modal('show');
        },
        getProject(){
            this.isLoading = true;
            let data = {
                'course_id' : this.course_id
            };
            axios.post('/capstone/projects', data)
            .then((response) => {
                this.projects = response.data.payload.projects;
                this.course = response.data.payload.course;
                this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
            });
        }
    },
    mounted() {
        if (this.group === undefined) {
            this.$router.push({ name: "CapstoneIndex" });
        } else {
            this.getProject();
        }
    },
};
</script>

<style lang="css" scoped>
.card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}
#uploadJbCert {
    display: flex;
    align-items: center;
}
.cert_btn {
    background: #08386d;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 18px;
    margin-right: 15px;
    color: #ffffff;
    border: unset;
    width: max-content;
}
.cert_btn i {
    margin-left: 15px;
}
.grey {
    color: #606060;
}
.ash {
    color: #a7a5a5;
}

@media screen and (max-width: 768px) {
    .card {
        border-radius: 10px;
    }
    .cert_btn {
        font-size: 10px;
        font-weight: 600;
        padding: 5px 10px;
        margin-right: 3px;
        width: 70%;
    }
    .cert_btn i {
        margin-left: 8px;
    }
}

.group ul{
    padding: 0px;
}
.group ul li {
  list-style: none;
}
</style>
