<template>
    <div
        class="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">
                        Change to new course
                    </h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <new-course />
                </div>
                <div class="modal-footer">
                    <button type="button" class="dash-btn" data-dismiss="modal">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewCourse from "../../views/dashboard/NewCourse.vue";
export default {
    name: "modal",
    components: {
        NewCourse,
    },
};
NewCourse;
</script>

<style>
.dash-btn {
    font-size: 12px;
    font-weight: 550;
    border-radius: 25px;
    padding: 0.1rem 0.75rem;
    min-width: fit-content;
}
</style>
