<template>
    <div class="d-flex align-self-center mb-0 mt-4 justify-content-center">
        <button class="carousel-indicator" :class="{ 'carousel-indicator-active': currentIndex === index }" v-for="(item, index) in total" :key="index" @click="$emit('switch', index)"></button>
    </div>
</template>

<script>
export default {
        emits: ["switch"],
        props: ["total", "currentIndex"],
}
</script>

<style scoped>
.carousel-indicator{
    width: 7px;
    height: 7px;
    padding: 0;
    margin: 0 2px;
    background: #fff;
    border-radius: 50%;
    transition: ease 500ms;
}
.carousel-indicator-active{
    width: 15px;
    border-radius: 10px;
}
</style>