<template>
    <div class="row">
        <div class="col-12">
            <div class="card px-4">
                <NewCourse :isNew="false" />
            </div>
        </div>
    </div>
</template>

<script>
import NewCourse from "@/views/dashboard/NewCourse.vue";
import { mapGetters } from "vuex";
export default {
    components: { NewCourse },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    mounted() {
        if (!this.user.taken_baseline) {
            this.$router.push({ name: "Welcome" });
        }

        if (
            this.user.taken_baseline &&
            this.user.passed_test &&
            this.user.select_new_course
        ) {
            this.$router.push({ name: "Dashboard" });
        }
    },
};
</script>

<style></style>
