<template>
    <div class="px-3 px-md-5">
        <template v-if="talentEligible">
            <h2 class="talent-pool-form-header">
                Update your Profile to join the Talent Pool
            </h2>
            <form
                class="py-3 col-md-10 col-lg-7"
                @submit.prevent="updateTalentPoolInfo"
            >
                <!-- User Personal Details -->
                <fieldset>
                    <h4>Profile Information</h4>
                    <div class="row mt-4 no-gutters">
                        <div
                            class="form-group mb-4 col-12 col-lg-6 p-0 px-lg-2"
                        >
                            <input
                                type="text"
                                name="firstName"
                                required
                                class="form-control py-4"
                                id="firstName"
                                placeholder=" "
                                v-model="talent.first_name"
                            />
                            <label
                                for="firstName"
                                class="label font-weight-bold"
                                >First Name</label
                            >
                        </div>
                        <div
                            class="form-group mb-4 col-12 col-lg-6 p-0 px-lg-2"
                        >
                            <input
                                type="text"
                                required
                                name="lastName"
                                class="form-control py-4"
                                id="lastName"
                                placeholder=" "
                                v-model="talent.last_name"
                            />
                            <label for="lastName" class="label font-weight-bold"
                                >Last Name</label
                            >
                        </div>
                        <div class="form-group mb-4 col-12 p-0 px-lg-2">
                            <input
                                type="text"
                                required
                                name="title"
                                class="form-control py-4"
                                id="title"
                                placeholder="Ex: Product Designer, Full Stack Developer"
                                v-model="talent.title"
                            />
                            <label for="lastName" class="label font-weight-bold"
                                >Title</label
                            >
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div
                            class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                        >
                            <input
                                type="email"
                                class="form-control py-4"
                                id="email"
                                name="email"
                                readonly
                                v-model="talent.email"
                            />
                            <label for="email" class="label font-weight-bold"
                                >Email</label
                            >
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="form-group mb-4 col-12 p-0 px-lg-2">
                            <input
                                type="text"
                                name="phone"
                                required
                                class="form-control py-4"
                                id="phoneNumber"
                                placeholder=" "
                                v-model="talent.phone"
                            />
                            <label
                                for="phoneNumber"
                                class="label font-weight-bold"
                                >Phone Number</label
                            >
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="form-group mb-4 col-12 p-0 px-lg-2">
                            <input
                                type="text"
                                name="city"
                                required
                                class="form-control py-4"
                                id="city"
                                placeholder="Enter your current city"
                                v-model="talent.city"
                            />
                            <label for="city" class="label font-weight-bold"
                                >City</label
                            >
                            <ul
                                class="customSearchSkill"
                                v-if="talent.city.length > 0"
                            >
                                <template
                                    v-for="(r, index) of resultQuery"
                                    :key="index"
                                >
                                    <li
                                        @click="talent.city = r"
                                        v-if="index < 5 && talent.city !== r"
                                    >
                                        {{ r }}
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="form-group mb-4 col-12 p-0 px-lg-2">
                            <select
                                name="track"
                                id="track"
                                required
                                v-model="talent.track"
                                @change="getSkillsList(talent.track)"
                                class="py-2 form-control col-12 d-block"
                                style="height:46px;"
                            >
                                <option value="" disabled selected hidden
                                    >Select Track</option
                                >
                                <option
                                    v-for="track in courses"
                                    :key="track.id"
                                    :value="track.id"
                                    >{{ track.title }}</option
                                >
                            </select>
                        </div>
                    </div>
                </fieldset>
                <!-- User Personal Details -->
                <!-- User Summary -->
                <fieldset>
                    <h4>About</h4>
                    <div class="row no-gutters">
                        <div
                            class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                        >
                            <textarea
                                class="form-control py-4"
                                id="summary"
                                name="summary"
                                placeholder="Summay"
                                required
                                v-model="talent.summary"
                                rows="7"
                            ></textarea>
                        </div>
                    </div>
                </fieldset>
                <!-- User Summary -->
                <!-- User Work Experience -->
                <fieldset>
                    <h4>Work Experience</h4>
                    <template
                        v-for="(exp, index) in talent.experience"
                        :key="index"
                    >
                        <div class="row no-gutters">
                            <div
                                class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                            >
                                <input
                                    type="text"
                                    class="form-control py-4"
                                    id="job_title"
                                    name="job_title"
                                    placeholder=" "
                                    :required="
                                        exp.company !== '' ||
                                            exp.description !== ''
                                    "
                                    v-model="exp.title"
                                />
                                <label
                                    for="job_title"
                                    class="label font-weight-bold"
                                    >Job Title</label
                                >
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div
                                class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                            >
                                <input
                                    type="text"
                                    class="form-control py-4"
                                    id="company_name"
                                    name="company_name"
                                    placeholder=" "
                                    :required="
                                        exp.title !== '' ||
                                            exp.description !== ''
                                    "
                                    v-model="exp.company"
                                />
                                <label
                                    for="company_name"
                                    class="label font-weight-bold"
                                    >Company name</label
                                >
                            </div>
                        </div>
                        <div
                            class="row no-gutters"
                            :class="[
                                talent.experience.length > 1
                                    ? 'border-bottom border-light mb-4'
                                    : '',
                            ]"
                        >
                            <div
                                class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                            >
                                <textarea
                                    rows="7"
                                    placeholder="Job Responsiblities"
                                    class="form-control py-2"
                                    v-model="exp.description"
                                    name="job_responsibilities"
                                    id="job_responsibilities"
                                    :required="
                                        exp.company !== '' || exp.title !== ''
                                    "
                                ></textarea>
                            </div>
                        </div>
                        <div
                            v-if="index == talent.experience.length - 1"
                            @click="addField(exp, talent.experience)"
                            style="padding-left: 5px;font-family: 'Nunito';font-style: normal;font-weight: 700;font-size: 16px;line-height: 22px;color: #52A350;cursor:pointer;"
                        >
                            ADD
                        </div>
                    </template>
                </fieldset>
                <!-- User Work Experience -->
                <!-- User Education -->
                <fieldset>
                    <h4>Education</h4>
                    <template
                        v-for="(edu, index) in talent.education"
                        :key="index"
                    >
                        <div class="row no-gutters">
                            <div
                                class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                            >
                                <input
                                    type="text"
                                    class="form-control py-4"
                                    id="school"
                                    name="school"
                                    required
                                    v-model="edu.school"
                                />
                                <label
                                    for="school"
                                    class="label font-weight-bold"
                                    >Name of School</label
                                >
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div
                                class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                            >
                                <input
                                    type="text"
                                    class="form-control py-4"
                                    id="field_study"
                                    name="field_study"
                                    required
                                    v-model="edu.course"
                                />
                                <label
                                    for="field_study"
                                    class="label font-weight-bold"
                                    >Field of Study</label
                                >
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div
                                class="form-group position-relative flex-grow-1 mr-md-2 mb-4 p-0 px-lg-2"
                            >
                                <input
                                    type="number"
                                    min="1999"
                                    :max="edu.to"
                                    step="1"
                                    class="form-control py-4"
                                    id="yearfrom"
                                    name="yearfrom"
                                    required
                                    v-model="edu.from"
                                />
                                <label
                                    for="yearfrom"
                                    class="label font-weight-bold"
                                    >Entry Year</label
                                >
                            </div>
                            <div
                                class="form-group position-relative flex-grow-1 ml-md-2 mb-4 p-0 px-lg-2"
                            >
                                <input
                                    type="number"
                                    :min="edu.from"
                                    max="2099"
                                    step="1"
                                    class="form-control py-4"
                                    id="yearto"
                                    name="yearto"
                                    required
                                    v-model="edu.to"
                                />
                                <label
                                    for="yearto"
                                    class="label font-weight-bold"
                                    >Graduation Year</label
                                >
                            </div>
                        </div>
                    </template>
                </fieldset>
                <!-- User Education -->
                <!-- User Skills -->
                <fieldset>
                    <h4>Skills</h4>
                    <div class="row no-gutters">
                        <div
                            class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                        >
                            <div class="flex">
                                <div
                                    class="form-check form-check-inline"
                                    v-for="skill in skillsList"
                                    :key="skill.id"
                                >
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        :id="'skillschecker' + skill.id"
                                        name="skillschecker"
                                        :value="skill.title"
                                        v-model="selectedSkills"
                                        @click="
                                            pushSkillArray(
                                                skill.id,
                                                skill.title,
                                            )
                                        "
                                    />
                                    <label
                                        class="form-check-label"
                                        :for="'skillschecker' + skill.id"
                                        >{{ skill.title }}</label
                                    >
                                </div>
                            </div>
                            <!-- <ul
                                class="customSearchSkill"
                                v-if="findSkill.trim().length > 0"
                            >
                                <template
                                    v-for="skill in filterSkills"
                                    :key="skill.id"
                                >
                                    <li
                                        @click="
                                            pushSkillArray(
                                                skill.id,
                                                skill.title,
                                            )
                                        "
                                    >
                                        {{ skill.title }}
                                    </li>
                                </template>
                            </ul> -->
                            <small
                                v-if="talent.track == ''"
                                class="text-success mt-2"
                                >Please select your track to see list of skills
                                <!-- <i class="fas fa-ellipsis-h fa-beat-fade"></i
                            > -->
                            </small>
                        </div>
                    </div>
                </fieldset>
                <!-- User Skills -->
                <!-- User Availability -->
                <fieldset>
                    <h4>Availability Status</h4>
                    <div class="row no-gutters">
                        <div class="form-group mb-4 col-12 p-0 px-lg-2">
                            <select
                                name="availability"
                                id="availability"
                                required
                                v-model="talent.availability"
                                class="py-2 form-control col-12 d-block"
                                style="height:46px;"
                            >
                                <option value="" disabled selected hidden
                                    >Select Availability</option
                                >
                                <option value="Full-Time">Full-Time</option>
                                <option value="Part-Time">Part-Time</option>
                            </select>
                        </div>
                    </div>
                </fieldset>
                <!-- User Profile Picture -->
                <fieldset>
                    <h4>Upload Profile Picture</h4>
                    <div class="row no-gutters">
                        <div
                            class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                        >
                            <input
                                type="file"
                                class=""
                                id="profile_picture"
                                name="profile_picture"
                                placeholder=" "
                                hidden
                                ref="profile_pic"
                                accept="image/*"
                                @change="updateProfilePic(e)"
                            />
                            <label
                                for="profile_picture"
                                class="py-2 form-control col-12 d-block text-truncate"
                                style="height:46px;"
                                v-html="fileName"
                            >
                            </label>
                        </div>
                    </div>
                </fieldset>
                <!-- User Education -->
                <!-- Submit Button -->
                <div class="form-group mt-3 col-12 col-lg-12 p-0 px-lg-2">
                    <button
                        type="submit"
                        class="btn btn-submit py-2 px-4 btn-block"
                        style="color:#fff; box-shadow:none"
                        :disabled="submitted"
                        v-html="btnText"
                    ></button>
                </div>
                <!-- Submit Button -->
            </form>
        </template>
        <template v-else>
            <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i><br />
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import swal from "sweetalert2";

export default {
    data() {
        return {
            btnText: "Update Profile",
            fileName: "Select Image",
            talentEligible: false,
            submitted: false,
            locations: [],
            cities: [],
            categories: [],
            findSkill: "",
            skillsList: [],
            selectedSkills: [],
            talent: {
                first_name: "",
                middle_name: "",
                last_name: "",
                track: "",
                email: "",
                city: "",
                title: "",
                summary: "",
                experience: [
                    {
                        company: "",
                        title: "",
                        description: "",
                    },
                ],
                education: [
                    {
                        school: "",
                        course: "",
                        from: "",
                        to: "",
                    },
                ],
                level: "",
                availability: "",
                phone: "",
                skills: [],
                // file: null,
            },
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            courses: "auth/courses",
        }),
        resultQuery() {
            if (this.talent.city) {
                return this.cities.filter((item) => {
                    return this.talent.city
                        .toLowerCase()
                        .split(" ")
                        .every((v) => item.toLowerCase().includes(v));
                });
            } else {
                return this.cities;
            }
        },
        filterSkills() {
            return this.skillsList.filter((skill) => {
                return skill.title
                    .toLowerCase()
                    .includes(this.findSkill.toLowerCase());
            });
        },
    },
    mounted() {
        this.verifyTalent();
        this.getTalent();
        this.talent.last_name = this.user.last_name;
        this.talent.first_name = this.user.first_name;
        this.talent.email = this.user.email;
        this.talent.phone = this.user.phone;
        this.getCities();
    },
    methods: {
        async verifyTalent() {
            axios
                .get("/talent/verify")
                .then((res) => {
                    this.talentEligible = true;
                })
                .catch((err) => {
                    console.log(err);
                    this.$router.push({ name: "Dashboard" });
                });
        },
        async getTalent() {
            axios
                .get("/talent")
                .then((res) => {
                    let talent = res.data.payload.talent;
                    let skills = res.data.payload.skills;
                    let courses = res.data.payload.courses;
                    if (talent.length < 1) {
                        return;
                    }
                    this.talent.first_name = talent.first_name;
                    this.talent.last_name = talent.last_name;
                    this.talent.email = talent.email;
                    this.talent.city = talent.city;
                    this.talent.availability = talent.availability;
                    this.talent.summary = talent.summary;
                    this.talent.level = talent.level;
                    this.talent.title = talent.title;
                    this.talent.education = JSON.parse(talent.education);
                    if (this.talent.education.length > 0) {
                        this.talent.education.forEach((education) => {
                            const myDates = education.year.split("-");
                            education.from = myDates[0];
                            education.to = myDates[1];
                        });
                    }
                    this.talent.experience = JSON.parse(talent.experience);
                    // if (skills.length > 0) {
                    //     skills.forEach((element) => {
                    //         this.talent.skills.push(element.skill_id);
                    //     });
                    // }
                    if (courses.length > 0) {
                        let courseIDs = [];
                        this.courses.forEach((element) => {
                            courseIDs.push(element.id);
                        });
                        let sortCourseCategory = courses.filter((item) => {
                            return item.talents_category == talent.category_id;
                        });
                        let previouslySelectedTrack = sortCourseCategory.filter(
                            (item) => {
                                return item.id == courseIDs[0];
                            },
                        );
                        if (previouslySelectedTrack.length > 0) {
                            // this.talent.track = previouslySelectedTrack[0].id;
                        }
                        if (
                            previouslySelectedTrack == 0 &&
                            courseIDs.length > 1
                        ) {
                            let previouslySelectedTrack = sortCourseCategory.filter(
                                (item) => {
                                    return item.id == courseIDs[1];
                                },
                            );
                            // this.talent.track = previouslySelectedTrack[0].id;
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        updateProfilePic() {
            let file = this.$refs.profile_pic.files[0];
            this.talent.file = file;
            this.fileName = file.name;
        },
        async updateTalentPoolInfo() {
            if (this.talent.skills.length < 1) {
                swal.fire("Error", "Please select your skills", "error");
                return;
            }
            this.btnText = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
            var form_data = new FormData();
            const edu = this.talent.education.map((item) => {
                return {
                    school: item.school,
                    course: item.course,
                    year: item.from + "-" + item.to,
                };
            });
            this.talent.education = JSON.stringify(edu);
            this.talent.skills = JSON.stringify(this.talent.skills);
            this.talent.experience = JSON.stringify(this.talent.experience);
            for (const talent in this.talent) {
                if (Object.hasOwnProperty.call(this.talent, talent)) {
                    form_data.append(`${talent}`, this.talent[talent]);
                }
            }

            await axios
                .post("/talent", form_data, {
                    "Content-Type": "multipart/form-data",
                })
                .then((response) => {
                    swal.fire(
                        "Success",
                        "Your talent pool profile has been updated successfully",
                        "success",
                    );
                    this.$router.push({ name: "Dashboard" });
                })
                .catch((err) => {
                    this.talent.education = [];
                    this.talent.skills = [];
                    this.talent.experience = [];
                    swal.fire(
                        "Error",
                        "Unable to update your profile, check that all fields are field properly",
                        "error",
                    );
                    this.btnText = "Update Profile";
                });
        },
        pushSkillArray(id, title) {
            if (!this.talent.skills.includes(id)) {
                this.talent.skills.push(id);
                // this.sortSkills();
                // this.findSkill = "";
            } else {
                this.talent.skills = this.talent.skills.filter(
                    (item) => item !== id,
                );
            }
        },
        sortSkills() {
            if (this.talent.skills.length > 0) {
                let internSkill = [];
                this.talent.skills.forEach((skill) => {
                    internSkill.push(
                        this.skillsList.find((element) => element.id == skill),
                    );
                });
                return (this.selectedSkills = internSkill);
            } else {
                return;
            }
        },
        removeSkill(skill) {
            this.talent.skills = this.talent.skills.filter((item) => {
                return item !== skill;
            });
            this.sortSkills();
        },
        addField(value, fieldType) {
            fieldType.push({});
        },
        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },
        async getSkillsList(id) {
            await axios
                .post("/talent/skills", { course: id })
                .then((response) => {
                    this.skillsList = response.data.payload;
                    // if (this.talent.skills.length > 0) {
                    //     this.sortSkills();
                    // }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getCities() {
            await axios
                .post("https://countriesnow.space/api/v0.1/countries/cities", {
                    country: this.user.country,
                })
                .then((response) => {
                    this.cities = response.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.btn-submit {
    background: #52a350;
    border: 2px solid #52a350;
    border-radius: 10px;
}
.form-control {
    border: 2px solid #bbddbb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0 15px;
    font-weight: 300;
    font-size: 15px !important;
    line-height: 22px;
    color: #858585 !important;
    background: #fff !important;
}
.label {
    padding: 10px;
    pointer-events: none;
    position: absolute;
    left: 15px;
    top: 5px;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 1;
    background: #fff;
    font-style: normal;
    font-weight: 300;
    font-size: 15px !important;
    line-height: 22px;
    color: #b6b6b6 !important;
    background: #fff !important;
}

input:focus + .label,
input:not(:placeholder-shown) + .label {
    opacity: 1;
    z-index: 3;
    transform: scale(0.75) translateY(-70%) translateX(-14px);
    top: 0px;
    color: #52a350 !important;
}
fieldset {
    margin-bottom: 24px;
}
fieldset h4 {
    font-family: "GD Boing";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #575757;
    padding-left: 5px;
    margin-bottom: 24px;
}
.talent-pool-form-header {
    font-family: "GD Boing";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    text-align: center;
    color: #52a350;
    margin-bottom: 24px;
}
.customSearchSkill {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.customSearchSkill li {
    border: 1px solid #ddd; /* Add a border to all links */
    margin-top: -1px; /* Prevent double borders */
    background-color: #f6f6f6; /* Grey background color */
    padding: 12px; /* Add some padding */
    text-decoration: none; /* Remove default text underline */
    font-size: 18px; /* Increase the font-size */
    color: black; /* Add a black text color */
    display: block; /* Make it into a block element to fill the whole list */
    cursor: pointer;
}

.customSearchSkill li:hover:not(.header) {
    background-color: #eee;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background: white !important;
}
</style>
