<template>
    <div class="post-comment-box mt-4">
        <div class="media-body">
            <Mentionable
                :keys="['@']"
                filtering-disabled
                :items="items"
                @apply="get($event)"
                @open="loadIssues()"
                @search="loadIssues($event)"
            >
            <input
                class="form-control post-input"
                placeholder="Add your comment here, press enter to send, use @ to mention someone"
                v-model="comment"
                @keypress.enter="postComment"
            />
                <template #no-result>
                    <div class="dim">
                        {{ loading ? 'Loading...' : 'No result' }}
                    </div>
                </template>

                <template #item-@="{ item }">
                    <div class="user">
                        {{ item.username }}  <small class="dim">({{item.role == 'intern' ? item.user_id : item.role}})</small>
                    </div>
                </template>

            
            </Mentionable>
            <i class="posting pl-2" v-if="loading">Posting Comment ...</i>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import swal from "sweetalert2";
import "v-tooltip/dist/v-tooltip.css";
import { Mentionable } from "vue-mention";

export default {
    props: {
        post: [Object],
    },

    components: { Mentionable },

    data() {
        return {
            comment: "",
            loading: false,
            items: [],
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        get(item){
            this.selected.push(item);
        },
        async loadIssues (searchText = null) {
            this.loading = true
            await axios.get("question/search", {
                params: {
                    search : searchText,
                    room: 'sh',
                }
            }).then((response) => {
                this.items = response.data.payload
                this.loading = false
            }).catch(() => {
                this.loading = false
            });
        },
        postComment() {
            if (this.comment.length > 0) {
                this.loading = true;
                let data = {
                    comment: this.comment,
                    user_id: this.user.id,
                    post_id: this.post.id,
                };

                axios
                    .post("comment", data)
                    .then((response) => {
                        this.loading = false;
                        this.comment = "";
                        this.$emit("commentposted", response.data.payload);
                    })
                    .catch((error) => {
                        this.loading = false;
                        swal.fire(
                            "Error",
                            "Cannot post your comment now, Try again",
                            "error",
                        );
                    });
                // this.$emit('commentposted', 'jfj');
            }
        },
    },

    emits: ["commentposted"],
};
</script>

<style lang="css" scoped>
/* .community-container{
    margin-top: 150px !important;
} */
.posting {
    margin-top: 5px;
    font-size: 10px;
}
.page-title-mobile {
    margin-top: 100px !important;
}
.page-title-mobile-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #e7e0e0;
    margin-bottom: 15px;
}
.community-card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.community-con {
    background-color: transparent;
}

.community-con h4 {
    font-style: normal;
    font-weight: 550;
    font-size: 24px;
    line-height: 31px;
    color: #606060;
}

.community-con p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 16px;
    color: #606060;
}

.media-wrapper .media img {
    height: 50px;
    width: 50px;
}

.media-wrapper .media {
    margin-bottom: 20px;
}

.media-wrapper .media .media-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 15px; */
    color: #606060;
}

.media-wrapper .media .media-body p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.community-card.post .media {
    margin-bottom: 20px;
}

.community-card.post .media .media-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
}

.community-card.post .media .media-body p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px !important;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.community-card.post .media img {
    height: 30px;
    width: 30px;
}

.contribution-count {
    float: right;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.04em;

    color: #a7a5a5;
}

.contribution-count img {
    width: 15px !important;
    height: 13px !important;
}

.contribution-count2 {
    font-style: normal;
    /* font-weight: 600; */
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.04em;

    color: #a7a5a5;
}

.contribution-count2 img {
    width: 15px !important;
    height: 13px !important;
}

.community-card.compose h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* line-height: 18px; */
    color: #a7a5a5;
}

.post-input {
    background: #fff !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 25px;
    height: 40px;
    margin-top: 5px;
}

.post-input2 {
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 550;
    box-sizing: border-box;
    border-radius: 25px;
    height: 40px;
    margin-top: 5px;
}
.recent-head {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: #a7a5a5;
    position: relative;
}

.recent-head::before {
    bottom: 7px;
    content: "";
    height: 1px;
    left: 3px;
    position: absolute;
    width: 89%;
    background-color: #e9ecef;
}

.post-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #606060;
}

.comment-box-body {
    background: #f8fbfe;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
}

.comment-box-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
}

.comment-box-body h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.comment-box-body h4.time-ago {
    font-style: normal;
    font-weight: 300;
    font-size: 8px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #08386d;
}

.comment-box-body h4.comment-content {
    font-style: normal;
    font-weight: normal;
    /* margin-top: 3rem; */
    font-size: 10px;
    line-height: 16px;
    color: #606060;
}

.btn-load-comment {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #a7a5a5;
}
</style>
