import Dashboard from "@/views/dashboard/Dashboard";
import Finalize from "@/views/dashboard/conRegistration/finalize";
import First from "@/views/dashboard/conRegistration/first";
import Second from "@/views/dashboard/conRegistration/second";
import Third from "@/views/dashboard/conRegistration/third";
import Four from "@/views/dashboard/conRegistration/four";
import Complete from "@/views/dashboard/conRegistration/complete";
import Welcome from "@/views/dashboard/postRegistration/welcome";
import TakeNewCourse from "@/views/dashboard/TakeNewCourse";
import Index from "@/views/dashboard/Index";
import Reward from "@/views/Reward";
import Badges from "@/views/dashboard/Badges";
import Badge from "@/views/dashboard/Badge";
import Community from "@/views/dashboard/Community";
import Profile from "@/views/dashboard/profile/Profile";
import Certificate from "@/views/dashboard/Certificate";
import Help from "@/views/dashboard/Help";
import Lessons from "@/views/dashboard/lessons/Lessons";
import Lesson from "@/views/dashboard/lessons/Lesson";
import courses from "@/views/dashboard/lessons/courses";
import Quiz from "@/views/dashboard/quiz/Quiz.vue";
import SelectWeek from "@/views/dashboard/quiz/SelectWeek.vue";
import QuizStart from "@/views/dashboard/quiz/Start.vue";
import QuizTake from "@/views/dashboard/quiz/Take.vue";
import QuizPreview from "@/views/dashboard/quiz/Preview.vue";
import SingleBlog from "@/views/dashboard/SingleBlog.vue";

// Post Registration
import DashboardFirst from "@/views/dashboard/postRegistration/dashboard";
import ManageCourse from "@/views/dashboard/postRegistration/ManageCourses";
import DashboardRefer from "@/views/dashboard/postRegistration/Refer";
import Spin from "@/views/dashboard/postRegistration/Spin";
import Spinner from "@/views/dashboard/postRegistration/Spinner";
import DashboardResources from "@/views/dashboard/postRegistration/Resources";
import StartBaseline from "@/views/dashboard/postRegistration/StartBaseline";
import Baselinetest from "@/views/dashboard/postRegistration/BaselineTest";
import VerifyEMail from "@/views/dashboard/postRegistration/VerifyEmail";

//// capstones
import Capstone from "@/views/dashboard/capstones/Capstone";
import CapstoneIndex from "@/views/dashboard/capstones/Index";
import CapstoneProjects from "@/views/dashboard/capstones/Projects";
import CapstoneMembers from "@/views/dashboard/capstones/Members";
import CapstoneSubmit from "@/views/dashboard/capstones/Submit";

/// messages
import SH from "@/views/dashboard/messages/SH";
import CommunityRoom from "@/views/dashboard/messages/Room";
import SingleRoomBlog from "@/views/dashboard/messages/SingleRoomBlog";
import Report from "@/views/dashboard/Report/Report";
import Goodies from "@/views/dashboard/goodies/goodies";

// onboarding

import Onboard from "@/views/dashboard/onboarding/index";

// leaderboard
// import leaderboard from "@/views/dashboard/leaderboard/index";

//Payment
import Payment from "@/views/dashboard/payment/index";
import PayCategory from "@/views/dashboard/payment/payCategory";
import PayAmount from "@/views/dashboard/payment/payAmount";

// Talent Pool
import TalentPool from "@/views/dashboard/talentpool/index.vue";
import UnauthTalentPool from "@/views/talents/index.vue";
const dashboardRoutes = [
    {
        path: "/onboard",
        name: "Onboard",
        component: Onboard,
        meta: {
            requiresAuth: true,
            requiresText: true,
            requiresBaseline: true,
            requiresBloomm: true,
            title: "Onboarding",
        },
    },
    {
        path: "/talentpool",
        name: "UnauthTalentPool",
        component: UnauthTalentPool,
        meta: {
            title: "Talent Pool Form",
        },
    },
    {
        path: "/welcome",
        name: "WelcomeIndex",
        component: Welcome,
        children: [
            {
                path: "",
                name: "WelcomeDashboard",
                component: DashboardFirst,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    title: "Dashboard",
                },
            },
            {
                path: "/courses/manage",
                name: "ManageCourses",
                component: ManageCourse,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    title: "Courses",
                },
            },
            {
                path: "/refer",
                name: "DashboardRefer",
                component: DashboardRefer,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    title: "Refer and Earn",
                },
            },
            {
                path: "/spinner",
                name: "Spinner",
                component: Spinner,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    title: "Refer and Earn",
                },
            },
            {
                path: "/spin",
                name: "Spin",
                component: Spin,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    title: "Refer and Earn",
                },
            },
            {
                path: "/resources",
                name: "DashboardResources",
                component: DashboardResources,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    title: "Free Resources",
                },
            },
            {
                path: "/baseline/start",
                name: "StartBaseline",
                component: StartBaseline,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    onlyWeekOne: true,
                    title: "Baseline Test",
                },
            },
            {
                path: "/baseline/test",
                name: "Baselinetest",
                component: Baselinetest,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    onlyWeekOne: true,
                    title: "Baseline Test",
                },
            },
            {
                path: "/verify/email",
                name: "VerifyEmail",
                component: VerifyEMail,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    title: "Verify Email",
                },
            },
        ],
    },
    // {
    //     path: "/registration/completed",
    //     name: "Complete",
    //     component: Complete,
    //     meta: {
    //         requiresAuth: true,
    //         requiresText: true,
    //         title: "Registration Completed",
    //     },
    // },
    {
        path: "/finalize/registration/stage/",
        name: "Finalize",
        component: Finalize,
        children: [
            {
                path: "first",
                name: "First",
                component: First,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    title: "Complete Registration",
                },
            },
            {
                path: "second",
                name: "Second",
                component: Second,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    title: "Complete Registration",
                },
            },
            {
                path: "third",
                name: "Third",
                component: Third,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    title: "Complete Registration",
                },
            },

            {
                path: "four",
                name: "Four",
                component: Four,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    title: "Complete Registration",
                },
            },
        ],
    },
    // {
    //     path: "/reward/badge/:id",
    //     name: "Reward",
    //     component: Reward,
    //     meta: {
    //         title: "Awarded Badge",
    //         metaTags: [
    //             {
    //                 name: "og:title",
    //                 content: "Side Hustle Internship 5.0",
    //             },
    //             {
    //                 name: "og:url",
    //                 content: "https://internship.sidehustle.ng/reward/badge",
    //             },
    //             {
    //                 name: "og:description",
    //                 content: "Side Hustle Internship 5.0",
    //             },
    //             {
    //                 name: "og:image",
    //                 content:
    //                     "https://res.cloudinary.com/codeaddct10x/image/upload/v1645354286/internship/badges/quiz_master_A_pw6syd.png",
    //             },
    //         ],
    //     },
    // },

    {
        path: "/dashboard",
        name: "Index",
        component: Index,
        meta: {
            requiresAuth: true,
            requiresBloomm: true,
            requiresBaseline: true,
            passBaseline: true,
        },
        children: [
            {
                path: "",
                name: "Dashboard",
                component: Dashboard,
                meta: {
                    requiresAuth: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Dashboard",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "payment",
                name: "Payment",
                component: Payment,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Payment",
                    checkForTourist: false, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "payment/category",
                name: "PayCategory",
                component: PayCategory,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Payment",
                    checkForTourist: false, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "payment/pay",
                name: "PayAmount",
                component: PayAmount,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Payment",
                    checkForTourist: false, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "badges",
                name: "Badges",
                component: Badges,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Badges",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },
            // {
            //     path: "badges/:id",
            //     name: "Badge",
            //     component: Badge,
            //     meta: {
            //         requiresAuth: true,
            //         requiresText: true,
            //         takenBaseline: true,
            //         mustPassWeek: true,
            //         title: "View Badge",
            //         checkForTourist: false, //check if user can earn tourist badge when enter this route
            //     },
            // },
            {
                path: "community",
                name: "Community",
                component: Community,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Community",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
                children: [
                    {
                        path: "",
                        name: "SH",
                        component: SH,
                        meta: {
                            requiresAuth: true,
                            requiresText: true,
                            takenBaseline: true,
                            mustPassWeek: true,
                            title: "Community",
                            checkForTourist: false, //check if user can earn tourist badge when enter this route
                        },
                    },
                    {
                        path: "room/:group_name",
                        name: "CommunityRoom",
                        component: CommunityRoom,
                        props: (route) => ({
                            ...route.params,
                        }),
                        meta: {
                            requiresAuth: true,
                            requiresText: true,
                            takenBaseline: true,
                            mustPassWeek: true,
                            title: "Community",
                        },
                        checkForTourist: false, //check if user can earn tourist badge when enter this route
                    },
                    {
                        path: "sh/post/:id",
                        name: "SingleBlog",
                        component: SingleBlog,
                        meta: {
                            requiresAuth: true,
                            requiresText: true,
                            takenBaseline: true,
                            mustPassWeek: true,
                            title: "Post",
                            checkForTourist: false, //check if user can earn tourist badge when enter this route
                        },
                    },
                    {
                        path: "sh/room/:id",
                        name: "SingleRoomBlog",
                        component: SingleRoomBlog,
                        meta: {
                            requiresAuth: true,
                            requiresText: true,
                            takenBaseline: true,
                            mustPassWeek: true,
                            title: "Room Post",
                            checkForTourist: false, //check if user can earn tourist badge when enter this route
                        },
                    },
                ],
            },

            {
                path: "profile",
                name: "Profile",
                component: Profile,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Profile",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "certificate",
                name: "Certificate",
                component: Certificate,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Certificate",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "faq",
                name: "Help",
                component: Help,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Faqs",
                    checkForTourist: true,
                },
            },
            {
                path: "lessons",
                name: "Lessons",
                component: Lessons,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Lessons",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "courses",
                name: "courses",
                component: courses,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Lesson",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "Lesson",
                name: "Lesson",
                component: Lesson,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Lesson",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "quiz",
                name: "Quiz",
                component: Quiz,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Quiz",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "quiz/start",
                name: "QuizStart",
                component: QuizStart,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Start Quiz",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "quiz/select",
                name: "SelectWeek",
                component: SelectWeek,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    takenBaseline: true,
                    mustPassWeek: true,
                    title: "Select Week",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },

            {
                path: "quiz/take",
                name: "QuizTake",
                component: QuizTake,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Take Quiz",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },
            {
                path: "quiz/summary",
                name: "QuizPreview",
                component: QuizPreview,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Quiz Summary",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },

            {
                path: "capstone/course",
                name: "Capstone",
                component: Capstone,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Capstone Projects",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },

            {
                path: "capstone/projects",
                name: "CapstoneProjects",
                component: CapstoneProjects,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Capstone Projects",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },

            {
                path: "capstone/submit",
                name: "CapstoneSubmit",
                component: CapstoneSubmit,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Submit Capstone Project",
                },
            },

            {
                path: "capstone/members",
                name: "CapstoneMembers",
                component: CapstoneMembers,
                props: true,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Capstone Members",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },

            {
                path: "capstone",
                name: "CapstoneIndex",
                component: CapstoneIndex,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Capstone Projects",
                    checkForTourist: true, //check if user can earn tourist badge when enter this route
                },
            },

            {
                path: "report",
                name: "Report",
                component: Report,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Report",
                },
            },

            {
                path: "goodies",
                name: "Goodies",
                component: Goodies,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Goodies",
                },
            },
            // {
            //     path: "leaderboard",
            //     name: "Leaderboard",
            //     component: leaderboard,
            //     meta: {
            //         requiresAuth: true,
            //         requiresText: true,
            //         mustPassWeek: true,
            //         takenBaseline: true,
            //         title: "Leaderboard",
            //     },
            // },
            {
                path: "talentpool",
                name: "TalentPool",
                component: TalentPool,
                meta: {
                    requiresAuth: true,
                    requiresText: true,
                    mustPassWeek: true,
                    takenBaseline: true,
                    title: "Talent Pool",
                },
            },
        ],
    },
];

export default dashboardRoutes;
