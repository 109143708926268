<template>
    <div class="row">
        <div class="col-lg-8 mx-auto ">
            <div class="container">
                <form @submit.prevent="addQuiz">
                    <div class="row flex-column col-lg-7 mx-auto mt-5">
                        <div v-if="filled">
                            <div class="form-group mb-4">
                                <label for="course" class="font-weight-bold"
                                    >Select a course</label
                                >
                                <select
                                    name="course"
                                    id="course"
                                    required
                                    class="custom-select-sm col-12 d-block"
                                    v-model="course_id"
                                >
                                    <option value="">Select course</option>
                                    <option
                                        v-for="c in courses"
                                        :key="c.id"
                                        :value="c.id"
                                        >{{ c.title }}</option
                                    >
                                    <option value="0">Baseline </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="courseTitle"
                                    placeholder="Enter quiz title e.g Week one quiz for UIUX"
                                    v-model="title"
                                />
                            </div>

                            <div class="form-group mb-4">
                                <textarea
                                    class="form-control"
                                    id="description"
                                    rows="1"
                                    placeholder="Enter quiz description"
                                    v-model="description"
                                ></textarea>
                            </div>

                            <div class="form-group mb-4">
                                <select
                                    name="week"
                                    id="week"
                                    required
                                    class="custom-select-sm col-12 d-block"
                                    v-model="week"
                                >
                                    <option value="">Select week</option>
                                    <option value="1">Week 1</option>
                                    <option value="2">Week 2</option>
                                    <option value="3">Week 3</option>
                                    <option value="4">Week 4</option>
                                    <option value="5">Week 5</option>
                                    <option value="6">Week 6</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <label for="question"
                                >Enter your Quiz Question
                                {{ quizList.length + 1 }}</label
                            >
                            <!-- <textarea
                                class="form-control"
                                id="question"
                                rows="4"
                                v-model="question"
                            >
                            </textarea> -->
                            <QuillEditor theme="snow" contentType='html' toolbar="essential" v-model:content="question" />
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span
                                    class="input-group-text"
                                    id="basic-addon1"
                                >
                                    <input
                                        type="radio"
                                        name="answer"
                                        id="option_1"
                                        @click="getAnswer"
                                    />
                                </span>
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Enter first option here"
                                aria-label="Enter first option here"
                                aria-describedby="basic-addon1"
                                v-model="option_1"
                            />
                        </div>

                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span
                                    class="input-group-text"
                                    id="basic-addon2"
                                >
                                    <input
                                        type="radio"
                                        name="answer"
                                        id="option_2"
                                        @click="getAnswer"
                                    />
                                </span>
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Enter second option here"
                                aria-label="Enter second option here"
                                aria-describedby="basic-addon2"
                                v-model="option_2"
                            />
                        </div>

                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span
                                    class="input-group-text"
                                    id="basic-addon3"
                                >
                                    <input
                                        type="radio"
                                        name="answer"
                                        id="option_3"
                                        @click="getAnswer"
                                    />
                                </span>
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Enter third option here"
                                aria-label="Enter third option here"
                                aria-describedby="basic-addon3"
                                v-model="option_3"
                            />
                        </div>

                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span
                                    class="input-group-text"
                                    id="basic-addon4"
                                >
                                    <input
                                        type="radio"
                                        name="answer"
                                        id="option_4"
                                        @click="getAnswer"
                                    />
                                </span>
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Enter fourth option here"
                                aria-label="Enter fourth option here"
                                aria-describedby="basic-addon4"
                                v-model="option_4"
                            />
                        </div>

                        <button class="btn btn-primary" type="submit">
                            {{
                                quizList.length === 19
                                    ? "Submit Quiz"
                                    : "Add Quiz"
                            }}
                        </button>
                    </div>
                </form>

                <div class="row flex-column col-lg-8 mx-auto mt-5">
                    <p class="px-lg-4" v-show="question !== ''">
                        <span class="font-weight-bold d-inline-block pr-2"
                            >{{ quizList.length + 1 }}.</span
                        >
                        <span class="" v-html="question"></span>
                    </p>
                    <div class="col-12 px-5" style="">
                        <div class="row my-3" v-show="option_1 !== ''">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="options"
                                    id="new_option_1"
                                    :checked="answer === option_1"
                                />
                                <label
                                    class="form-check-label"
                                    :class="{
                                        'font-weight-bold': answer === option_1,
                                    }"
                                    for="new_option_1"
                                >
                                    {{ option_1 }}
                                </label>
                            </div>
                        </div>

                        <div class="row my-3" v-show="option_2 !== ''">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="options"
                                    id="new_option_2"
                                    :checked="answer === option_2"
                                />
                                <label
                                    class="form-check-label"
                                    :class="{
                                        'font-weight-bold': answer === option_2,
                                    }"
                                    for="new_option_2"
                                >
                                    {{ option_2 }}
                                </label>
                            </div>
                        </div>

                        <div class="row my-3" v-show="option_3 !== ''">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="options"
                                    id="new_option_3"
                                    :checked="answer === option_3"
                                />
                                <label
                                    class="form-check-label"
                                    :class="{
                                        'font-weight-bold': answer === option_3,
                                    }"
                                    for="new_option_3"
                                >
                                    {{ option_3 }}
                                </label>
                            </div>
                        </div>

                        <div class="row my-3" v-show="option_4 !== ''">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="options"
                                    id="new_option_4"
                                    :checked="answer === option_4"
                                />
                                <label
                                    class="form-check-label"
                                    :class="{
                                        'font-weight-bold': answer === option_4,
                                    }"
                                    for="new_option_4"
                                >
                                    {{ option_4 }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
//
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
    name: "Quiz",
    components: {
        QuillEditor,
    },
    data() {
        return {
            question: "",
            option_1: "",
            option_2: "",
            option_3: "",
            option_4: "",
            answer: "",
            description: "",
            title: "",
            course_id: "",
            quizList: [],
            answerList: [],
            week: "",
            index: "",
            filled: true,
        };
    },

    methods: {
        addQuiz(event) {
            if (this.checkFields()) {
                let options = [
                    this.option_1,
                    this.option_2,
                    this.option_3,
                    this.option_4,
                ];
                let arr = {
                    id: this.quizList.length + 1,
                    answer: this.answer,
                };
                let obj = {
                    id: this.quizList.length + 1,
                    question: this.question,
                    options: options,
                    _id: this.index,
                };
                this.quizList.push(obj);
                this.answerList.push(arr);
                if (this.quizList.length === 19) {
                    this.submitQuiz();
                    event.target.reset();
                    return;
                }
                event.target.reset();
                this.filled = false;
                this.clearFields();
            } else {
                swal.fire({
                    title: "Invalid",
                    text: "All fields are required",
                    icon: "error",
                });
            }
        },

        submitQuiz() {
            let data = {
                title: this.title,
                description: this.description,
                course_id: this.course_id,
                questions: this.quizList,
                answers: this.answerList,
                week: this.week,
            };
            axios
                .post("admin/add-quiz", data)
                .then((res) => {
                    if (res.data.status) {
                        swal.fire({
                            title: "Quiz Added",
                            text:
                                "Quiz has been added successfully. Do you want to add a new one?",
                            icon: "success",
                            confirmButtonText: "Yes",
                            showCancelButton: true,
                            cancelButtonText: "No",
                        }).then((e) => {
                            if (!e.isConfirmed) {
                                this.$router.push({ name: "Dashboard" });
                            }
                            this.course_id = "";
                            this.title = "";
                            this.description = "";
                            this.filled = true;
                            this.quizList = [];
                            this.answerList = [];
                            this.clearFields();
                        });
                    }
                })
                .catch((err) => {
                    swal.fire({
                        title: "Error",
                        text:
                            "An error has occured, make sure you fill in the required fields and try again",
                        icon: "error",
                    });
                });
        },

        checkFields() {
            if (
                this.option_1 !== "" &&
                this.option_2 !== "" &&
                this.option_3 !== "" &&
                this.option_4 !== "" &&
                this.answer !== "" &&
                this.course_id !== "" &&
                this.title !== "" &&
                this.description !== ""
            ) {
                return true;
            } else {
                return false;
            }
        },

        clearFields() {
            this.question = "";
            this.option_1 = "";
            this.option_2 = "";
            this.option_3 = "";
            this.option_4 = "";
            this.answer = "";
        },

        getAnswer(e) {
            switch (e.target.id) {
                case "option_1":
                    this.answer = this.option_1;
                    this.index = 1;
                    break;

                case "option_2":
                    this.answer = this.option_2;
                    this.index = 2;
                    break;

                case "option_3":
                    this.answer = this.option_3;
                    this.index = 3;
                    break;

                case "option_4":
                    this.answer = this.option_4;
                    this.index = 4;
                    break;

                default:
                    this.answer = "";
                    this.index = "";
                    break;
            }
        },

        ...mapActions({
            fetchCourses: "auth/fetchCourses",
        }),
    },

    computed: {
        ...mapGetters({
            courses: "auth/courses",
        }),
    },

    mounted() {
        if (this.courses.length === 0) {
            this.fetchCourses();
        }
    },
};
</script>
