<template>
    <section id="middlenav">
        <div class="wrapper">
            <div class="middlenav px-0">
                <div class="navbar-nav align-items-center">
                    <div>
                        <div class="navbar-brand py-4 m-auto">
                            <img
                                src="@/assets/img/logo-dark.png"
                                alt="logo"
                                class="nav-logo"
                                style="width:150px"
                            />
                        </div>
                    </div>
                    <div style="width:120px">
                        <div
                            class="nav-item"
                            :class="{ active: $route.name === 'Dashboard' }"
                        >
                            <router-link
                                :to="{ name: 'Dashboard' }"
                                class="nav-link d-flex"
                            >
                                <i class="fa-solid fa-house sh-text-light"></i>
                                <span
                                    class=" d-none d-md-inline-block sh-text-light ml-2"
                                    >Dashboard</span
                                >
                            </router-link>
                        </div>
                        <div
                            class="nav-item"
                            :class="{
                                active:
                                    $route.name === 'courses' ||
                                    $route.name === 'Lessons' ||
                                    $route.name == 'Lesson',
                            }"
                        >
                            <router-link
                                :to="{ name: 'courses' }"
                                class="nav-link d-flex align-items-center"
                            >
                                <i class="fa fa-book-open sh-text-light"></i>
                                <span
                                    class=" d-none d-md-inline-block sh-text-light ml-2"
                                    >Lessons</span
                                >
                            </router-link>
                        </div>
                        <div
                            class="nav-item"
                            :class="{
                                active:
                                    $route.name === 'Quiz' ||
                                    $route.name == 'QuizStart' ||
                                    $route.name == 'SelectWeek' ||
                                    $route.name === 'QuizTake',
                            }"
                        >
                            <router-link
                                :to="{ name: 'Quiz' }"
                                class="nav-link d-flex align-items-center"
                            >
                                <i class="fa fa-tasks sh-text-light"></i>
                                <span
                                    class=" d-none d-md-inline-block sh-text-light ml-2"
                                    >Quiz</span
                                >
                            </router-link>
                        </div>
                        <div
                            class="nav-item"
                            :class="{
                                active:
                                    $route.name === 'SH' ||
                                    $route.name === 'Community' ||
                                    $route.name == 'CommunityRoom',
                            }"
                        >
                            <router-link
                                :to="{ name: 'SH' }"
                                class="nav-link d-flex align-items-center"
                            >
                                <i class="fa fa-users sh-text-light"></i>
                                <span
                                    class=" d-none d-md-inline-block sh-text-light ml-2"
                                    >Messages</span
                                >
                            </router-link>
                        </div>
                        <div
                            class="nav-item"
                            :class="{
                                active:
                                    $route.name === 'Badge' ||
                                    $route.name === 'Badges',
                            }"
                        >
                            <router-link
                                :to="{ name: 'Badges' }"
                                class="nav-link d-flex align-items-center"
                            >
                                <i class="fa fa-ribbon sh-text-light"></i>
                                <span
                                    class=" d-none d-md-inline-block sh-text-light ml-2"
                                    >Badges</span
                                >
                            </router-link>
                        </div>
                        <div
                            class="nav-item"
                            :class="{ active: $route.name === 'Certificate' }"
                        >
                            <router-link
                                :to="{ name: 'Certificate' }"
                                class="nav-link d-flex align-items-center"
                                style="border-right:0"
                            >
                                <i class="fa fa-certificate sh-text-light"></i>
                                <span
                                    class=" d-none d-md-inline-block sh-text-light ml-2"
                                    >Certificate</span
                                >
                            </router-link>
                        </div>
                        <div
                            class="nav-item"
                            :class="{
                                active:
                                    $route.name === 'CapstoneIndex' ||
                                    $route.name === 'Capstone',
                            }"
                        >
                            <router-link
                                :to="{ name: 'CapstoneIndex' }"
                                class="nav-link d-flex align-items-center"
                                style="border-right:0"
                            >
                                <i class="fa fa-circle sh-text-light"></i>
                                <span
                                    class=" d-none d-md-inline-block sh-text-light ml-2"
                                    >Capstone</span
                                >
                            </router-link>
                        </div>

                        <div
                            class="nav-item"
                            v-if="user.mode === 'gamified'"
                            :class="{ active: $route.name === 'Goodies' }"
                        >
                            <router-link
                                :to="{ name: 'Goodies' }"
                                class="nav-link d-flex align-items-center"
                                style="border-right:0"
                            >
                                <i class="fa fa-gift sh-text-light"></i>
                                <span
                                    class=" d-none d-md-inline-block sh-text-light ml-2"
                                    >Goodies</span
                                >
                            </router-link>
                        </div>
                        <!-- || user.mode === 'gamified' -->
                        <div
                            class="nav-item"
                            v-if="false"
                            :class="{ active: $route.name === 'Leaderboard' }"
                        >
                            <router-link
                                :to="{ name: 'Leaderboard' }"
                                class="nav-link d-flex align-items-center"
                                style="border-right:0"
                            >
                                <i class="fas fa-chart-bar sh-text-light"></i>
                                <span
                                    class=" d-none d-md-inline-block sh-text-light ml-2"
                                    >Leaderboard</span
                                >
                            </router-link>
                        </div>
                        <div
                            class="nav-item"
                            :class="{ active: $route.name === 'Report' }"
                        >
                            <router-link
                                :to="{ name: 'Report' }"
                                class="nav-link d-flex align-items-center"
                                style="border-right:0"
                            >
                                <i
                                    class="fa fa-exclamation-circle sh-text-light"
                                ></i>
                                <span
                                    class=" d-none d-md-inline-block sh-text-light ml-2"
                                    >Report</span
                                >
                            </router-link>
                        </div>
                        <template v-if="week > 2">
                            <div
                                class="nav-item"
                                :class="{
                                    active:
                                        $route.name === 'Payment' ||
                                        $route.name === 'PayCategory' ||
                                        $route.name === 'PayAmount',
                                }"
                            >
                                <router-link
                                    :to="{ name: 'Payment' }"
                                    class="nav-link d-flex align-items-center"
                                    style="border-right:0"
                                >
                                    <i
                                        class="fas fa-money-check-alt sh-text-light"
                                    ></i>
                                    <span
                                        class=" d-none d-md-inline-block sh-text-light ml-2"
                                        >Payment</span
                                    >
                                </router-link>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
    name: "Navigationbar",
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
    },
};
</script>
