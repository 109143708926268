<template>
    <div class="d-flex pl-4">
        <div class="mr-2" @click="$router.go(-1)">
            <i class="fas fa-arrow-left"></i>
        </div>
        <h5
            class="pl-3"
            style="font-weight: 300;font-size: 16px;line-height: 22px; color: #242424 !important;"
        >
            Quiz
        </h5>
    </div>
    <div
        class="col-md-12 col-12 d-flex flex-column flex-md-row align-items-start mt-3 mt-md-5 mb-4"
    >
        <div class="col-md-12" v-if="quizAvailable && quiz_week <= week">
            <div class="col-md-8 p-0">
                <div class="quiz-card p-4">
                    <div class="row no-gutters justify-content-between">
                        <div class="col-md-6 quiz-card-header">
                            <h4>
                                QUIZ TITLE
                            </h4>
                            <p>{{ track }}</p>
                        </div>
                        <div
                            class="col-md-3 quiz-card-header mt-2 mt-lg-0 d-none"
                        >
                            <h4>DURATION</h4>
                            <p class="duration">30mins</p>
                        </div>
                        <div class="col-md-3 quiz-card-header">
                            <h4>WEEK {{ quiz_week }}</h4>
                        </div>
                    </div>
                    <div class="mt-4 quiz-card-header">
                        <h4>READ THE FOLLOWING INSTRUCTIONS CAREFULLY</h4>
                        <h3>
                            <span>1</span> Kindly ensure you have a stable
                            internet connection before you start this quiz
                        </h3>
                        <h3>
                            <span>2</span> You are strongly advised not to
                            refresh the page once the quiz starts
                        </h3>
                        <h3>
                            <span>3</span> You have only one shot at each
                            question
                        </h3>
                        <h3 class="d-none">
                            <span>4</span> The duration for the quiz is 30
                            minutes
                        </h3>

                        <h3>Go conquer the world champ!👍🏽</h3>
                    </div>
                    <div class="col-md-12 p-0 mt-5 mb-5 mb-lg-3">
                        <button
                            @click="take(quiz)"
                            type="submit"
                            class="btn btn-primary quiz-btn btn-block mt-lg-4"
                            style="margin-top:20px;padding: 12px 18px;background: #044995;border-radius: 5px;font-weight: 550;font-size: 10px;line-height: 11px;letter-spacing: 0.04em;color: #FFFFFF;"
                        >
                            START QUIZ <i class="fas fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-5 col-12" v-if="!quizAvailable && !loader">
            <p>{{ errorText }}</p>
            <div
                @click="goToLessons(c_id, track)"
                class="btn btn-primary px-5 py-1"
                v-if="
                    errorText ==
                        `You need to complete your ${track} lessons for week ${quiz_week} to take your quiz.`
                "
            >
                Take Lesson
            </div>
        </div>
        <div class="text-center mt-5 col-12" v-if="loader">
            <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i><br />
            <!-- Getting your quiz.... -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
import axios from "axios";
export default {
    data() {
        return {
            quizAvailable: false,
            errorText: "Sorry, you don't have any quiz for now😞",
            loader: true,
            quizzes: "",
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
    },
    props: ["c_id", "track", "quiz_week"],
    methods: {
        goToLessons(id, title) {
            this.$router.push({
                name: "Lessons",
                params: {
                    id,
                    title,
                },
            });
        },
        take(quiz) {
            swal.fire({
                title: "Start Quiz",
                text:
                    "Click 'Start Quiz Now' to start this quiz, you cannot quit after clicking continue",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#08386D",
                cancelButtonText: "Cancel",
                cancelButtonColor: "#353535",
                confirmButtonText: "Start Quiz Now",
            }).then((result) => {
                ///send request
                let data = {
                    course_id: this.c_id,
                    week: this.quiz_week,
                    checker: true,
                };
                if (result.value) {
                    axios
                        .post("quiz/get", data)
                        .then((res) => {
                            this.$router.push({
                                name: "QuizTake",
                                params: {
                                    course_id: this.c_id,
                                    quiz_week: this.quiz_week,
                                },
                            });
                        })
                        .catch((response) => {
                            swal.fire({
                                title: "You have taken your quiz",
                                text:
                                    "You have already taken your quiz for this week",
                                icon: "warning",
                            }).then((click) => {
                                this.$router.push({
                                    name: "Quiz",
                                });
                            });
                        });
                }
            });
        },
        checkQuiz() {
            let data = {
                course_id: this.c_id,
                week: this.quiz_week,
            };
            axios
                .post("quiz/check", data)
                .then((res) => {
                    if (res.data.status == true) {
                        this.quizAvailable = true;
                        this.loader = false;
                    } else if (res.data.status == false ) {
                        this.quizAvailable = false;
                        this.loader = false;
                        this.errorText =
                            "There is currently no quiz available for week " +
                            this.quiz_week;
                    } 
                })
                .catch((error) => {
                    this.quizAvailable = false;
                    if (error.response.status === 406) {
                        this.errorText =
                            "You need to complete your " +
                            this.track +
                            " lessons for week " +
                            this.week +
                            " to take your quiz.";
                        this.loader = false;
                        return;
                    }
                    this.errorText =
                        "You've already taken the week " +
                        this.quiz_week +
                        " quiz for " +
                        this.track;
                    this.loader = false;
                });
        },
    },
    mounted() {
        if (this.c_id === undefined) {
            this.$router.push({ name: "Quiz" });
        }
        this.checkQuiz();
    },
};
</script>

<style lang="css" scoped>
.page-title-mobile {
    margin-top: 100px !important;
}
.page-title-mobile-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #e7e0e0;
    margin-bottom: 15px;
}

.quiz-card {
    background: #ffffff;
}

.quiz-card-header h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #a7a5a5;
}

.quiz-card-header h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #606060;
    margin-top: 20px;
}

.quiz-card-header h3 span {
    background: #edebeb;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 50%;
    padding: 1px 6px;
}

.quiz-card-header p {
    font-style: normal;
    font-weight: 600;
    font-size: 12px !important;
    line-height: 15px;

    color: #08386d;
}

.duration {
    color: #606060 !important;
}

.quiz-btn {
    background: #08386d;
    border-radius: 25px;
    border: 1px solid #08386d;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.04em;
    padding: 13px 20px;
    color: #ffffff;
}
.contribution-count3 {
    float: right;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.04em;
    color: #a7a5a5;
    margin-top: -7px;
}

.btn-course-take {
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 13px !important;
    letter-spacing: 0.04em !important;
    color: #08386d;
    background: #f8fbfe;
    /* border: 1px solid #08386D !important; */
    box-sizing: border-box;
    border-radius: 25px;
    padding: 5px 10px !important;
}
</style>
