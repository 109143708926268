<template>
    <!-- Second Ambassador Section -->
    <!-- Ambassadors Pictures -->
    <div class="wave-2 px-5 d-flex flex-column align-items-center mb-5">
        <div class="mt-5 pt-4 d-flex justify-content-center flex-wrap">
        <img style="transform: rotate(8.22deg);" src="@/assets/img/ambassador-1.png" alt="Ambassador">
        <img style="transform: rotate(-7.52deg);" src="@/assets/img/ambassador-2.png" alt="Ambassador">
        <img style="transform: rotate(3.01deg);" src="@/assets/img/ambassador-3.png" alt="Ambassador">
        <img style="transform: rotate(-3.06deg);" src="@/assets/img/ambassador-4.png" alt="Ambassador">
        </div>
        <h3 class="text-center"><span style="color:#959595;">You want this? </span><span style="color:#52A350">Become an Ambassador <i class="fa-solid fa-arrow-right"></i></span></h3>
    </div>
    <!-- Ambassadors Pictures -->
    <!-- Second Ambassador Section -->
</template>

<script>
export default {

}
</script>

<style>

</style>