<template>
    <div class="modal-show">
        <div class="modal-container mx-auto">
            <div class="col-12">
                <div class="py-1 d-flex flex-column align-items-center">
                    <div
                        class="d-flex justify-content-center px-2 mt-2 col-12 col-md-6 mx-auto"
                    >
                        <video
                            id="onboardVideo"
                            height="400"
                            src="https://sidehistle.ng/onboarding.mp4"
                            type="video/mp4"
                            controls
                            controlsList="nodownload"
                        ></video>
                    </div>
                    <div class="mt-3">
                        <h5
                            class="text-success text-center font-weight-bold"
                            id="exampleModalLabel"
                        >
                            Select Preferred mode
                        </h5>
                    </div>
                    <div class="d-flex flex-column align-items-center mt-1">
                        <template v-if="!loading">
                            <button
                                type="button"
                                @click="selectMode('gamified')"
                                data-dismiss="modal"
                                class="onboard-btn text-center py-2 mx-auto mb-1 col-12"
                            >
                                <i class="fas fa-gamepad mr-2"></i> Gamified
                                Mode
                            </button>
                            <button
                                type="button"
                                @click="selectMode('normal')"
                                class="onboard-btn text-center py-2 mx-auto mb-4 col-12"
                            >
                                <i class="fas fa-compress mr-2"></i> Normal Mode
                            </button>
                        </template>
                        <template v-if="loading">
                            <button
                                type="button"
                                disabled
                                class="onboard-btn text-center py-2 mx-auto mb-4 col-12"
                            >
                                <i class="fas fa-spinner fa-spin fa-2x"></i>
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            user_mode: "",
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
        selectMode(user_mode) {
            this.loading = true;
            let data = {
                mode: user_mode,
            };
            axios
                .post("user/mode", data)
                .then((response) => {
                    this.attempt();
                    setTimeout(() => {
                        this.$router.push({
                            name: "Dashboard",
                        });
                    }, 2000);
                })
                .catch((err) => {});
        },
    },
};
</script>
<style scoped>
.modal-show {
    min-height: 100vh;
    min-width: 100vw;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
}
.modal-container {
    width: 65%;
    height: fit-content;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px 20px;
    background-image: url(./../../../assets/img/onbaord-modal-bg.png);
    border-radius: 25px;
}
.onboard-btn {
    background: #ffffff;
    border: 1px solid #52a350 !important;
    border-radius: 10px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #52a350;
    transition: ease-in-out 500ms;
}
.onboard-btn:hover {
    background: #52a350;
    border: 1px solid #fff !important;
    font-weight: normal;
    color: #fff;
}
#onboardVideo {
    width: 100%;
}
@media screen and (max-width: 776px) {
    .modal-container {
        width: 95%;
        padding: 20px 20px;
    }
}
</style>
