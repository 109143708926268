<template>
    <div class="d-flex">
        <div class="mr-2" @click="$router.go(-1)">
            <i class="fas fa-arrow-left"></i>
        </div>
        <h5 class="pl-3" style="font-weight: 300;font-size: 16px;line-height: 22px; color: #242424 !important;">Capstone</h5>
    </div>
    <div 
        class="p-0 col-md-9 col-12 d-flex flex-column flex-md-row align-items-start mt-5"
    >
        <div class="bg-white badge-card col-md-12" v-if="!isLoading">
            <div class="card-header p-0 bg-white">
                <h4 class="card-title badge-card-title pb-2">Submit Capstone </h4>
            </div>
            <div class="group py-4" v-if="submission.link && !submission.graded">
                <div class="alert alert-success">
                    You found an existing submission for this capstone project, but you can still update the submission link, since the mentor hasn't graded the project
                </div>
            </div>
            <div class="group py-4" v-if="submission.graded">
                <div class="alert alert-danger">
                    You cannot change the submission link because it has already been graded by the mentor
                </div>
            </div>
            <div class="group py-4 px-0" v-if="!submission.graded">
                <form @submit.prevent="submitTask()">
                    <div class="form-group col-lg-12 p-0">
                        <label for="lastName" class="font-weight-bold"
                            >Submission Link <small>(You will overide existing submission link when you resubmit the link)</small></label
                        >
                        <input
                            type="text"
                            required
                            name="link"
                            class="form-control py-4"
                            id="link"
                            placeholder="Enter your submission link here ..."
                            v-model="link"
                            @keyup="v$.link.$touch"
                            
                        />
                        <span
                            v-if="v$.link.$error"
                            class="text-danger font-weight-bold"
                            >{{ v$.link.$errors[0].$message }}</span
                        >
                    </div>

                    <div class="form-group col-lg-12 p-0">
                        <button
                            type="submit"
                            id="submitRegister"
                            class="btn btn-primary py-2 px-3 showButton btn-block"
                            style="color:#fff; box-shadow:none"
                            v-html="buttonText"
                        ></button>
                    </div>
                </form>
            </div>
        </div>
        <div class="p-md-5 p-3 col-md-12" v-if="isLoading">
            <h3 class="">Loading <i class="fas fa-spinner fa-spin"></i></h3>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import NProgress from "nprogress";
import useValidate from "@vuelidate/core";
import axios from "axios";
import { required } from "@vuelidate/validators";

export default {
    computed: {
        ...mapGetters({
            user: "auth/user",
           
        }),
    },
    
    data() {
        return {
            v$: useValidate(),
            isLoading: true,
            link: '',
            buttonText: "Submit Task",
            submission: {}
        };
    },
    validations() {
        return {
            link: { required },
        };
    },
    props: {
        group: {
            type: [Number],
            required: true
        },
        project_id: {
            type: [Number],
            required: true
        },
        group_user_id: {
            type: [Number],
            required: true
        }
    },
    methods: {
        getSubmittedTask(){
            this.isLoading = true;
            let data = {
                'project_id' : this.project_id,
                'group_id' : this.group,
            }

            axios.post('/capstone/getSubmission', data)
            .then((response) => {
                if(response.data.payload.submission){
                    this.submission = response.data.payload.submission;
                    this.link = response.data.payload.submission.link
                    this.isLoading = false;
                }else{
                    this.isLoading = false;
                }
                
            })
        },
        submitTask(){
            this.v$.$validate();
            if (!this.v$.$error) {
                this.buttonText = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
                let data = {
                    'project_id' : this.project_id,
                    'user_id' : this.group_user_id,
                    'group_id' : this.group,
                    'link' : this.link,
                }

                axios.post('/capstone/submit', data)
                .then((response) => {
                    this.submission = response.data.payload.submission
                    this.buttonText = "Update Task Link";
                    swal.fire(
                        "Success",
                        "Your Capstone Project has been submitted to your mentor",
                        "success",
                    );
                })
                .catch(() => {
                    swal.fire(
                        "Error",
                        "Sorry your capstone cannot be submitted now, then try again",
                        "error",
                    );
                    this.buttonText = "Submit Task"
                })
            }
        },
    },
    mounted() {
        if (this.group === undefined && this.group_user_id != this.user.user_id) {
            this.$router.push({ name: "CapstoneIndex" });
        }else{
            this.getSubmittedTask();
        }
    },
};
</script>

<style lang="css" scoped>
.card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}
#uploadJbCert {
    display: flex;
    align-items: center;
}
.cert_btn {
    background: #08386d;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 18px;
    margin-right: 15px;
    color: #ffffff;
    border: unset;
    width: max-content;
}
.cert_btn i {
    margin-left: 15px;
}
.grey {
    color: #606060;
}
.ash {
    color: #a7a5a5;
}

@media screen and (max-width: 768px) {
    .card {
        border-radius: 10px;
    }
    .cert_btn {
        font-size: 10px;
        font-weight: 600;
        padding: 5px 10px;
        margin-right: 3px;
        width: 70%;
    }
    .cert_btn i {
        margin-left: 8px;
    }
}

.group ul{
    padding: 0px;
}
.group ul li {
  list-style: none;
}
</style>
