<template>
    <div class="row">
        <div class="col-lg-8 mx-auto mb-5">
            <div class="container">
                <div class="alert alert-danger mt-5" v-if="error">
                    Fix the form
                </div>
                <form @submit.prevent="addLesson">
                    <div class="row flex-column col-lg-7 mx-auto mt-5">
                        <div class="form-group mb-4">
                            <label for="course" class="font-weight-bold"
                                >Select a course</label
                            >
                            <select
                                name="course"
                                id="course"
                                required
                                class="custom-select-sm col-12 d-block form-control"
                                v-model="course_id"
                            >
                                <option value="">Select course</option>
                                <option
                                    v-for="c in courses"
                                    :key="c.id"
                                    :value="c.id"
                                    >{{ c.title }}</option
                                >
                            </select>
                        </div>

                        <div class="form-group mb-4">
                            <label for="course" class="font-weight-bold"
                                >Title</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                name="title"
                                required
                                id="title"
                                placeholder="Enter Lesson title e.g Lesson one quiz for UIUX"
                                v-model="title"
                            />
                        </div>

                        <div class="form-group mb-4">
                            <label for="course" class="font-weight-bold"
                                >Video Google Drive Link</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                id="link"
                                name="link"
                                required
                                placeholder="E.g https://drive.google.com/file/d/1oSRq8_n4PfQsg6FNwbTbBI0Dpv4tpto6...."
                                @blur="convertTextToArray($event, 'link')"
                            />
                        </div>

                        <div class="form-group mb-4">
                            <label for="course" class="font-weight-bold"
                                >Description</label
                            >
                            <textarea
                                class="form-control"
                                id="description"
                                name="description"
                                required
                                rows="4"
                                placeholder="Enter video description"
                                v-model="description"
                            ></textarea>
                        </div>

                        <div class="form-group mb-4">
                            <label for="course" class="font-weight-bold"
                                >Week</label
                            >
                            <select
                                name="week"
                                id="week"
                                required
                                class="custom-select-sm col-12 d-block form-control"
                                v-model="week"
                            >
                                <option value="">Select week</option>
                                <option value="1">Week 1</option>
                                <option value="2">Week 2</option>
                                <option value="3">Week 3</option>
                                <option value="4">Week 4</option>
                                <option value="5">Week 5</option>
                                <option value="6">Week 6</option>
                            </select>
                        </div>

                        <div class="form-group mb-4">
                            <label for="course" class="font-weight-bold"
                                >Download Links</label
                            >
                            <textarea
                                class="form-control"
                                id="download"
                                rows="4"
                                name="download"
                                required
                                placeholder="Enter Download Links"
                                @blur="convertTextToArray($event, 'downloads')"
                            ></textarea>
                            <p class="font-weight-bold text-success">
                                Separate links with comma (,)
                            </p>
                        </div>

                        <div class="form-group mb-4">
                            <label for="course" class="font-weight-bold"
                                >External Links</label
                            >
                            <textarea
                                class="form-control"
                                required
                                id="external"
                                rows="4"
                                name="external"
                                placeholder="Enter External Links"
                                @blur="convertTextToArray($event, 'resources')"
                            ></textarea>
                            <p class="font-weight-bold text-success">
                                Separate links with comma (,)
                            </p>
                        </div>

                        <button class="btn btn-primary" type="submit">
                            {{ load ? "Adding ..." : "Add Lesson" }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import swal from "sweetalert2";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
    name: "Lesson",
    data() {
        return {
            load: false,
            error: false,
            description: "",
            title: "",
            video_link: "",
            video_id: "",
            download_links: "",
            external_links: "",
            course_id: "",
            week: "",
        };
    },

    methods: {
        ...mapActions({
            fetchCourses: "auth/fetchCourses",
        }),

        convertTextToArray(e, type) {
            let text = e.target.value;
            if (type === "link") {
                this.video_link = text;
                this.video_id = text.split("/")[5];
                return;
            }

            if (type === "downloads") {
                let te = text.replace(/\n/g, "");
                let t = te.split(",").filter((item) => item !== "");
                this.download_links = t;
                return;
            }

            if (type === "resources") {
                let te = text.replace(/\n/g, "");
                let t = te.split(",").filter((item) => item !== "");
                this.external_links = t;
                return;
            }
        },

        addLesson() {
            if (!this.checkFields) {
                this.error = true;
            } else {
                this.error = false;
                this.load = true;
                let data = {
                    title: this.title,
                    description: this.description,
                    video_link: this.video_link,
                    video_id: this.video_id,
                    course_id: this.course_id,
                    external_links: JSON.stringify(this.external_links),
                    download_links: JSON.stringify(this.download_links),
                    week: this.week,
                };
                axios
                    .post("lesson/add", data)
                    .then((res) => {
                        if (res.status === 200) {
                            swal.fire({
                                title: "Lesson Added",
                                text:
                                    "Lesson has been added successfully. Do you want to add a new one?",
                                icon: "success",
                                confirmButtonText: "Yes",
                                showCancelButton: true,
                                cancelButtonText: "No",
                            }).then((e) => {
                                if (!e.isConfirmed) {
                                    this.$router.push({ name: "Dashboard" });
                                } else {
                                    this.description = "";
                                    this.title = "";
                                    this.video_link = "";
                                    this.video_id = "";
                                    this.download_links = "";
                                    this.external_links = "";
                                }
                            });
                            this.load = false;
                        }
                    })
                    .catch((err) => {
                        swal.fire({
                            title: "Error",
                            text:
                                "An error has occured, make sure you fill in the required fields and try again",
                            icon: "error",
                        });
                        this.load = false;
                    });
            }
        },
        checkFields() {
            if (
                (this.description !== "" &&
                    this.title !== "" &&
                    this.video_link !== "" &&
                    this.video_id !== "" &&
                    this.download_links !== "" &&
                    this.external_links !== "" &&
                    this.course_id !== "",
                this.week !== "")
            ) {
                return true;
            } else {
                return false;
            }
        },
    },

    computed: {
        ...mapGetters({
            courses: "auth/courses",
        }),
    },

    mounted() {
        if (this.courses.length === 0) {
            this.fetchCourses();
        }
    },
};
</script>
