<template>
    <div
        class="progress-card p-3 px-4 d-flex flex-column justify-content-between"
    >
        <div>
            <div class="card-heading-new">
                Your Progress
                <br />
            </div>
            <small class="text-white">
                You’re currently in week {{ week }} of 6
            </small>
        </div>

        <div class="d-flex justify-content-between align-items-end ">
            <div class="champ pb-2">
                <div v-if="progress == 0">
                    Make some effort Champ 😏
                </div>
                <div v-if="progress > 0 && progress <= 10">
                    Well done Champ, but you can do better
                </div>
                <div v-if="progress > 10 && progress <= 25">
                    Way to go Champ! Let's do more
                </div>
                <div v-if="progress > 25 && progress <= 50">
                    Awesome work so far! Don't give up
                </div>
                <div v-if="progress > 50 && progress <= 70">
                    I knew you could do it! Let's do more
                </div>
                <div v-if="progress > 70 && progress <= 99">
                    We are almost there!
                </div>
                <div v-if="progress == 100">
                    Hurray 🎉🎉🎉 You are the real MVP
                </div>
            </div>
            <div class="ml-2">
                <circle-progress
                    :percent="Number(progress)"
                    background="#fff"
                    :border-width=5
                    :border-bg-width=5
                    empty-color="#F8F9FA"
                    class="progressClass"
                    :size=90
                    fill-color="#52a350"
                    :show-percent=true
                    :viewport=true
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
export default {
    name: "Progress",
    data() {
        return {
            progress: 0,
        };
    },
    computed: {
        ...mapGetters({
            week: "auth/currentWeek",
        }),
    },
    components: { CircleProgress },
    methods: {
        getUserProgress() {
            axios
                .get("/progress/tracker", {
                    params: {
                        week: this.week,
                    },
                })
                .then((res) => {
                    let total =
                        Number(res.data.payload.course_progress) +
                        Number(res.data.payload.extra_course_progress);
                    this.progress = total;
                    // axios
                    //     .get("total/week/progress", {
                    //         params: {
                    //             week: this.week,
                    //         },
                    //     })
                    //     .then((res) => {
                    //         this.totalProgress = res.data.payload;
                    //     });
                })
                .catch((response) => {});
        },
    },
    mounted() {
        this.getUserProgress();
    },
};
</script>

<style scoped>
.champ {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #ffffff;
}
.progressClass {
    font-weight: bold;
    font-size: 21.4286px;
    line-height: 30px;
    text-align: center;
    color: #3f3f3f;
}
.card-heading-new {
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    color: #ffffff;
}
</style>
