<template>
    <div
        class="col-md-12 col-12 d-flex flex-column flex-md-row align-items-start mt-3 mt-md-5 mb-4"
    >
        <div class="col-md-12 d-flex flex-md-row flex-column flex-wrap">
            <div class="col-12  p-0">
                <div class="card p-lg-5 bg-white badge-card">
                    <div class="card-body col-12 px-0 py-3">
                        <div class="text-center mt-5 col-12" v-if="loader">
                            <i
                                class="fa-solid fa-circle-notch fa-spin fa-3x"
                            ></i
                            ><br />
                            <p class="text-center">Generating image...</p>
                            <!-- Getting your quiz.... -->
                        </div>
                        <div class="mb-4 col-12 text-center" v-else>
                            <img
                                :src="badge && badge.file !== '' && badge.file"
                                alt="Side Hustle 5.0 Badge"
                                class="img-fluid"
                                style="width:300px"
                            />
                        </div>
                        <h6 class="text-center">Share badge via:</h6>
                        <div
                            id=""
                            class="row flex-md-row flex-no-wrap justify-content-center justify-content-md-between"
                            v-if="badge"
                        >
                            <ShareNetwork
                                v-for="network in networks"
                                :network="network.network"
                                :key="network.network"
                                :style="{ backgroundColor: network.color }"
                                :url="sharing.url"
                                :title="'Side Hustle Internship 5.0'"
                                :description="sharing.description"
                                :hashtags="sharing.hashtags"
                            >
                                <i :class="network.icon"></i>
                                <span>{{ network.name }}</span>
                            </ShareNetwork>
                            <a
                                class="btn-primary rounded d-flex justify-content-between align-items-center"
                                style="height:max-content; padding:0.6rem 1rem;"
                                :download="`${user.first_name +'_'+user.last_name}_badge.png`"
                                :href="badge && badge.file !== '' && badge.file"
                            >
                                <i class="fas fa-download px-2"></i>
                                <span>Download</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import swal from "sweetalert2";
import axios from "axios";
export default {
    data() {
        return {
            badge: {},
            loader: true,
            sharing: {
                url:
                    "https://lms.terrahq.co/reward.html?" +
                    this.$route.params.id,
                title: `Hi guys, I just earned a badge at the Side Hustle Internship Cohort 5.0.`,
                description: `Hi guys, I just earned a badge at the Side Hustle Internship Cohort 5.0. You can check it out`,
                hashtags: "SideHustle, SideHustleInternship",
            },
            networks: [
                {
                    network: "facebook",
                    name: "Facebook",
                    icon: "fab fah fa-lg fa-facebook-f",
                    color: "#1877f2",
                },
                {
                    network: "linkedin",
                    name: "LinkedIn",
                    icon: "fab fah fa-lg fa-linkedin",
                    color: "#007bb5",
                },
                {
                    network: "messenger",
                    name: "Messenger",
                    icon: "fab fah fa-lg fa-facebook-messenger",
                    color: "#0084ff",
                },
                {
                    network: "twitter",
                    name: "Twitter",
                    icon: "fab fah fa-lg fa-twitter",
                    color: "#1da1f2",
                },
                {
                    network: "whatsapp",
                    name: "Whatsapp",
                    icon: "fab fah fa-lg fa-whatsapp",
                    color: "#25d366",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
        canShare() {
            return window.navigator.canShare();
        },
    },
    methods: {
        // getBadge(id) {
        //     axios.get("/badges/" + id).then((response) => {
        //         this.badge = response.data.payload;
        //         this.loader = false;
        //     });
        // },
    },
    mounted() {
        // this.getBadge(this.$route.params.id);
    },
};
</script>

<style lang="css" scoped>
@media screen and (max-width: 776px) {
    .badge-img-container::before {
        content: "";
    }
    .badge-img-container::after {
        content: "";
    }
}

@media screen and (max-width: 576px) {
    .congratulations {
        font-size: 28px;
    }
    .just-earn {
        font-size: 12px;
    }
    .badge-img,
    .user-avatar {
        width: 88px;
    }
    .badge-img-container::before {
        content: "";
    }
    .badge-img-container::after {
        content: "";
    }
}
@media screen and (max-width: 360px) {
    .congratulations {
        font-size: 24px;
    }
    .just-earn {
        font-size: 12px;
    }
    .badge-img,
    .user-avatar {
        width: 50px;
    }
}
/*  */
a[class^="share-network-"] {
    padding: 8px 10px;
    flex: none;
    color: #ffffff;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
    text-decoration: none;
}
a i {
    margin-right: 10px;
}
#share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
}
.badge-card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}
.page-title-mobile {
    margin-top: 100px !important;
}
.page-title-mobile-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #e7e0e0;
    margin-bottom: 15px;
}
.badge-card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #606060;
}
.locked .card-body .card-text {
    font-size: 12px !important;
    margin-top: 10px;
}
.badges-item h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #a7a5a5;
}

.badges-item p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #a7a5a5;
}

.badges-item .card {
    border: none;
    box-shadow: none;
}

.badges-item .locked {
    opacity: 0.4;
}
.badges-item .card-text {
    font-size: 12px !important;
    font-weight: normal;
}
.badges-item .card-title {
    font-size: 14px !important;
    font-weight: 550;
}
.badges-item img {
    width: 84px;
    height: 90px;
}

.badge-card-title-locked {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}
</style>
