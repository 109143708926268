import { createRouter, createWebHistory } from "vue-router";
import pagesRoutes from "./pages";
import dashboardRoutes from "./dashboard";
import adminRoutes from "./admin";

//
import store from "../store";
import swal from "sweetalert2";

// Page not found
import NotFound from "@/views/NotFound";

const routes = [
    // pages with no auth
    ...pagesRoutes,

    //admin house
    ...adminRoutes,

    // dashboard for intern
    ...dashboardRoutes,

    {
        path: "/:catchAll(.*)",
        name: "Not Found",
        component: NotFound,
        meta: {
            title: "Page Not Found",
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { left: 0, top: 0 };
    },
});


//Can only take Baseline In Week 1
// router.beforeEach((to, from, next) => {
//     if (to.meta.onlyWeekOne) {
//         var week = store.getters["auth/currentWeek"];
//         if (week &&  week !== 1) {
//             store.dispatch("auth/signOut")
//             swal.fire({
//                 title: "Oops",
//                 html: "You can no longer take the baseline test as the internship has already passed the first week. Please join the waitlist for the next cohort <a target='_blank' href='https://forms.gle/qg9LbX6h8BxmWGVr6'>here</a>.",
//                 icon: "warning",
//                 confirmButtonText: "OK",
//             }).then((click) => {
//                 next({ name: "Home" });
//             });
//         } else {
//             next();
//         }
//     } else {
//         next();
//     }
// });

// Eviction Guard
router.beforeEach((to, from, next) => {
    var user = store.getters["auth/user"];
    if (user && user.active == 2) {
        store.dispatch("auth/signOut")
        swal.fire(
            "Sorry",
            "You have been evicted from this cohort of the internship. Join the waitlist for the next cohort here.",
            "warning",
        ).then((click) => {
            next({ name: "Login" });
        });
    } else {
        next();
    }
});

export default router;
