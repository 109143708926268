<template>
    <div class="px-3 pb-5">
        <div class="d-flex">
            <div class="mr-2" @click="$router.go(-1)">
                <i class="fas fa-arrow-left"></i>
            </div>
            <h5 class="page-title">
                Capstone
            </h5>
        </div>
        <div class="pl-0 mt-5 mb-5 col-12 d-flex flex-lg-row flex-column">
            <div class="p-0 m-0 col-12 col-lg-6">
                <div class="p-0" v-if="isLoading">
                    <h3 class="">
                        Loading <i class="fas fa-spinner fa-spin"></i>
                    </h3>
                </div>
                <div v-else>
                    <div class="capstone-team-card p-4" v-if="groups.length > 0" @click="showPro()" >
                        <div style="font-family: Nunito;font-weight: 600;font-size: 8px;color: #9E9E9E;">
                            CAPSTONE TEAM
                        </div>
                        <div class="capstone-team-name mb-5"
                             v-for="group in groups"
                             :key="group.id">
                            {{ group.group.name.toUpperCase() }}
                        </div>
                        <button class="mt-4 btn btn-primary col-12">
                            View Project <i class="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                    <div class="mt-3 d-flex justify-content-between flex-wrap" v-if="scores != '' && groups.length > 0 && week >1">
                        <template v-for="(score, index) in scores" :key="index">
                            <div
                                v-if="score.course_id == c_id"
                                class="p-3 mb-3"
                                style="background-color:#044995;border-radius: 12px;width:70%; max-width:300px"
                            >
                                <div>
                                    <div class="text-white">
                                        {{ score.course }}
                                    </div>
                                    <div
                                        class="d-flex justify-content-between align-items-end"
                                    >
                                        <div
                                            class="rating-text text-white d-flex flex-column"
                                            style="font-size:15px"
                                        >
                                            <p class="mt-3 mb-0">
                                                    <span v-if="score.score >= 80"
                                                        >You're are doing great 💪</span
                                                    >
                                                    <span v-if="score.score >= 60 && score.score < 80"
                                                        >You're are doing good 👍</span
                                                    >
                                                    <span v-if="score.score >= 50 && score.score < 60"
                                                        >You can do better 🤛</span
                                                    >
                                                    <span v-if="score.score >= 40 && score.score < 50"
                                                        >This ain't good enough
                                                    </span>
                                                    <span v-if="score.score >= 0 && score.score < 40"
                                                        >This ain't good enough</span
                                                    >
                                            </p>
                                            <star-rating
                                                :rating="score.score / 20"
                                                :read-only="false"
                                                :show-rating="0"
                                                :star-size="10"
                                                :padding="1"
                                            ></star-rating>
                                        </div>
                                        <div>
                                            <h6
                                                class="rating sh-text-secondary p-3 mt-4"
                                                style="border-radius: 100%; background: rgb(255, 255, 255) none repeat scroll 0% 0%; font-weight: 900; font-size: 20px; padding: 10px 15px !important;"
                                            >
                                                <span v-if="score.score >= 80"
                                                    >A</span
                                                >
                                                <span
                                                    v-if="
                                                        score.score >= 60 &&
                                                            score.score < 80
                                                    "
                                                    >B</span
                                                >
                                                <span
                                                    v-if="
                                                        score.score >= 50 &&
                                                            score.score < 60
                                                    "
                                                    >C</span
                                                >
                                                <span
                                                    v-if="
                                                        score.score >= 40 &&
                                                            score.score < 50
                                                    "
                                                    >D</span
                                                >
                                                <span
                                                    v-if="
                                                        score.score >= 0 &&
                                                            score.score < 40
                                                    "
                                                    >F</span
                                                >
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="p-0 m-0 col-12 col-lg-5 py-5 py-lg-0 mt-lg-0 ml-lg-5"
                 ref="projects"
                 v-show="showProject">
                <div v-if="projects != '' & week>1">
                    <div class="mb-4"
                        v-for="project in projects"
                        :key="project.id">
                        <h4 class="mb-0"
                            style="font-family: Nunito;font-size: 16px;color: #242424;" >
                            {{ project.title }}
                        </h4>
                        <h4 class="mt-0" style="font-family: Nunito;font-size: 12px;color: #242424;font-variant-caps: all-small-caps;">
                            {{ project.type }} Capstone
                        </h4>
                        <div style="font-family: Nunito;font-weight: 300;font-size: 12px;color: #242424;"
                             class="my-4"
                             v-html="project.description"></div>
                        <button v-if=" groups.length > 0 && user.user_id == groups[0].group.user_id"
                                @click=" submitTask( groups[0].user_id,project.id,groups[0].group_id,)"
                                class="col-12 py-3"
                                style="border-radius: 4px;background: #F3F6FA;border: 1px dashed #044995 !important;font-family: Nunito;font-weight: normal;font-size: 13px;color: #044995;"
                        >
                            Attach your team’s project
                        </button>
                    </div>
                </div>
                <div v-else>
                    <h4
                        class="mb-0"
                        style="font-family: Nunito;font-size: 16px;color: #242424;"
                    >
                        You currently do not have any capstone projects for this
                        track yet.
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import axios from "axios";
import StarRating from "vue-star-rating";

export default {
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
    },
    components: {
        StarRating,
    },
    data() {
        return {
            groups: [],
            isLoading: false,
            course: {},
            showProject: false,
            projects: "",
            scores: "",
        };
    },
    props: ["c_id"],
    methods: {
        showPro() {
            this.showProject = true;
        },
        scoresCap() {
            axios
                .get("/capstone/scores")
                .then((response) => {
                    this.scores = response.data.payload.scores;
                })
                .catch((err) => {});
        },
        seeProjects(group, group_user_id) {
            this.$router.push({
                name: "CapstoneProjects",
                params: {
                    group: group,
                    course_id: this.c_id,
                    group_user_id: group_user_id,
                },
            });
        },
        submitTask(groups_user_id, project_id, group) {
            this.$router.push({
                name: "CapstoneSubmit",
                params: {
                    group_user_id: groups_user_id,
                    group: group,
                    project_id: project_id,
                },
            });
        },
        joinGroup() {
            this.isLoading = true;
            let data = {
                course_id: this.c_id,
            };
            axios
                .post("/capstone/join", data)
                .then(() => {
                    this.getGroups();
                })
                .catch(() => {
                    swal.fire(
                        "Joining Failed",
                        "Cannot Join Any Group at the moment, try again",
                        "error",
                    ).then(() => {
                        this.isLoading = false;
                    });
                });
        },
        getGroups() {
            this.isLoading = true;
            let data = {
                course_id: this.c_id,
            };
            axios
                .post("/capstone/groups", data)
                .then((response) => {
                    this.groups = response.data.payload.groups;
                    this.isLoading = false;
                })
                .catch(() => {
                    swal.fire(
                        "Error Loading",
                        "Cannot Load groups, try again",
                        "error",
                    ).then(() => {
                        this.isLoading = false;
                    });
                });
        },
        getProject() {
            let data = {
                course_id: this.c_id,
            };
            axios
                .post("/capstone/projects", data)
                .then((response) => {
                    this.projects = response.data.payload.projects;
                    this.course = response.data.payload.course;
                })
                .catch(() => {});
        },
    },
    mounted() {
        if (this.c_id === undefined) {
            this.$router.push({ name: "CapstoneIndex" });
        } else {
            this.getGroups();
            this.getProject();
            this.scoresCap();
        }
    },
};
</script>
<style lang="css" scoped>
.capstone-team-card {
    background: #e8f3fd;
    background-image: url("./../../../assets/img/line/line2.png");
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
    border-radius: 10.4952px;
}
.capstone-project-topic {
    background: #e9ebed;
    border-radius: 10.4952px;
    background-image: url("./../../../assets/img/line/line2.png");
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
}
.capstone-team-name {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #242424;
}

.grey {
    color: #606060;
}
.ash {
    color: #a7a5a5;
}

.group ul {
    padding: 0px;
}
.group ul li {
    list-style: none;
}
</style>
