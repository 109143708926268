<template>
    <!-- Gain Section -->
    <div class="gain py-200px pt-0 d-flex flex-md-row flex-column-reverse align-items-center justify-content-center px-xlg-100 px-sm-20 px-lg-30">
      <div class="gain-left col">
        <h3>What 200+ youths have gained</h3>
        <p>We have the power to make the needed change to provide us with the kind of future we want for ourselves; and all of this starts with equipping the youth.</p>
        <button>Become an Ambassador</button>
      </div>
      <div class="gain-right col mb-4 mb-md-0">
        <!-- Testimony Section -->
        <div class="testimony-container" ref="testimonyContainer">
          <div class="testimony-card">
              <template v-for="(testimony,index) in testimonies" :key="index">
                <div class="testimonies d-flex flex-column justify-content-between" >
                  <div>
                    "I am grateful to the Side Hustle team. These awesome experiences helped me land my first tech role at about one month post internship"
                  </div>
                  <div class="testimony-details d-flex align-items-end">
                    <img src="@/assets/img/mentors/abbey.png" alt="">
                    <div>
                      <p>{{testimony.name}}</p>
                      <p>BOWEN UNIVERSITY</p>
                    </div>
                  </div>
                </div>
              </template>
          </div>
        </div>
        <!-- Testimony Section -->
      </div>
    </div>
    <!-- Gain Section -->
</template>

<script>
export default {
  data(){
      return{
          currentSlide:1,
          testimonies: [
        {
            "id": 1,
            "name": "Bright James",
            "cohort": "3.0",
            "testimony": "It's been a beautiful, exciting, unlearning and relearning three weeks, with one more to go. Side Hustle has had a significant impact on people's lives and has helped me progress in customer management over the last few weeks. There is so much that has been taught, from who a customer is,to how to absolutely delight and create a loyal customer group, and so much more.",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/bright_james.png",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 2,
            "name": "Emerole Justice",
            "cohort": "3.0",
            "testimony": "The internship has been fantastic, with extensive explanations on every step required to maintain your customers for loyalty. You should consider yourself fortunate to be a part of this program. I strongly advise you not to pass up this fantastic chance. And to Side Hustle, I want to express my gratitude for providing me with this incredible opportunity to expand my knowledge.",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/emerole_justice.png",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 3,
            "name": "Babalola Favour",
            "cohort": "3.0",
            "testimony": "I started the Side Hustle internship knowing very little about UI/UX and hoped to be much better by the end of the internship. Throughout the four weeks, the lessons, quizzes, and activities have kept me on my toes, broadening my knowledge of the subject. I'm a lot better today than I was when I first started.\n                ",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/favour_babalola.png",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 4,
            "name": "James Adewuyi",
            "cohort": "3.0",
            "testimony": "Side Hustle was a fantastic opportunity to enroll in an internship for me. Our Mentors and organizers were incredible and hardworking. Thanks to this internship, I am on the verge of having a real-life project in my portfolio. I've also learned the fundamentals of WordPress, from creating an outstanding blog to an E-commerce web store with the Elementor page builder. Thank you for this wonderful opportunity, Side Hustle. ",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/james_adewuyi.png",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 5,
            "name": "Mbode pamela Ukamaka",
            "cohort": "3.0",
            "testimony": "I've learnt a lot about customers and how to manage and understand them better. We were taught the importance of customer service policy as a way of prioritizing how customers can get the best of service. I will like to say a very big thank you to Side Hustle Internship for giving me this huge opportunity to learn and obtain great value. Kudos to our mentors for their efforts in making the class a memorable one.",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/mbode_pamela.png",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 6,
            "name": "OKARA, Merry",
            "cohort": "2.0",
            "testimony": "This was an excellent internship for someone with a strong desire but little experience in the field of customer service. The Side Hustle Internship provided me with the opportunity to learn new skills and expand my knowledge. This is my way of thanking Side Hustle for the chance and expressing my excitement for the Bootcamp.",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/okara_merry.png",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 8,
            "name": "Eziagor Chiagozie",
            "cohort": "1.0",
            "testimony": "I am so grateful to God and the entirety of the Side Hustle team for not only introducing me to the world of programming, but also giving me the room to work on live projects, experience first hand team work and collaboration. These awesome experiences helped me land my first tech role at about one month post internship.",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/eziagor2.png",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 9,
            "name": "Jessica Uviovo",
            "cohort": "1.0",
            "testimony": "I’m very excited to be one of the founders of Easy Learn Africa. Huge thanks to Side Hustle and the entire Easy Learn Africa team",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/jessica2.png",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 10,
            "name": "Ayo Moses",
            "cohort": "2.0",
            "testimony": "I was looking for an opportunity to enhance my product design skills. A place for me to work and learn in order to develop my skills and knowledge. Side Hustle provided me with the opportunity to launch my career as a product designer. The camp is tough for the faint of heart, but it is worthwhile to jump right in to begin your side hustle.\r\n",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/ayo2.png",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 16,
            "name": "Ruth Akagwu Soren",
            "cohort": "4.0",
            "testimony": "The Side Hustle internship has been enjoyable, and challenging. I particularly like the way the curriculum, course flow, and projects are organized and designed. \r\n",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/RuthSoren.png",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 17,
            "name": "Onyiuke Ijeoma",
            "cohort": "4.0",
            "testimony": "To be honest, it was well worth it. It was my first time working with data analytics, and I must say I learned a lot! Everything is just beautifully done, from the way the lessons are taught to the follow-up classes. I applaud the organizers.\r\n",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/IJEOMAONYIUKE.png",
            "created_at": null,
            "updated_at": null
        },
        {
            "id": 18,
            "name": "Clement Peter",
            "cohort": "4.0",
            "testimony": "My experience over the last six weeks has been both epic and demanding; I've always wondered if I'd be able to get jobs done and delivered under such tense conditions, but the Side Hustle internship has strengthened me.\r\n",
            "img": "https://backend.sidehustle.ng/assets/img/testimonies/ClementPeter.png",
            "created_at": null,
            "updated_at": null
        },
    ]
      }
  },
}
</script>

<style scoped>
.gain-left button{
position: relative !important;
}

.gain-left button::after{
content: url('./../../assets/img/twisted-arrow.png');
position: absolute;
top: 0;
transform: rotate(0deg) scale(0.7);
}
.testimony-card {
   /* width: 200px; */
   margin: 0 10px 0;
   animation-name: move;
   animation-duration: 20s;
   animation-iteration-count: infinite;
   animation-direction: up;
   animation-timing-function:linear;
   /* margin-top:100%; */
}
.testimony-card:hover {
   animation-play-state: paused;

}
@keyframes move {
   0% {
      transform:translateY(0);
   }
   100% {
      transform:translateY(-100%);
   }
}
.testimony-container{
overflow: scroll;
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}
.testimony-container::-webkit-scrollbar {
  display: none;
}

.testimony-card{
background-color: #fff;
height: 500px;
position: relative;

}
.testimony-container::before{
content: "";
width: 100%;
height: 100%;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
background: rgb(255,255,255);
background: linear-gradient(0deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0.5) 100%);
z-index: 2;
}
.testimonies{
background-image: url("./../../assets/img/ambassador-testimony-bg.png");
height: 50%;
background: #EEF0EE;
border-radius: 15px;
font-family: "Sofia Pro";
padding: 30px;
transition: 2s ease;
margin-bottom: 15px;
}
.testimonies .testimony-details{
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 38px;
color: #112211;
}
.testimonies .testimony-details img{
height: 50px;
margin-right: 10px;
}
.testimonies .testimony-details p{
margin: 0;
line-height: 1.1rem;
}

.one{
top: -35%;
}
.two{
top: 50%;
-ms-transform: translateY(-50%);
transform: translateY(-50%);
/* margin: 20px 0; */
}
.three{
bottom: -35%;
}
</style>