<template>
    <div>
                                <div class="achievement-card-title mb-2">
                                    Congratulations 🎉 {{ user.first_name }}
                                </div>
                                <div class="achievement-card-message">
                                    You’re a Top Contributor for this week
                                </div>
                                
                                 <button data-toggle="modal" data-target="#exampleModal"
                                    class="dash-btn mt-3"
                                    style="background: #52A350;color: #FFFFFF;"
                                ><i class="fa fa-share-alt"></i> Share
                                    Achievement</button>
                                                                       
                                 <!-- Modal -->
                                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                        <div class="modal-header">
                                            <h6>Share your achievement</h6>
                                           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="py-5 px-3 d-flex flex column justify-content-around share-btns" style="font-size:3rem;">
                                            <a :href="'whatsapp://send?text='+messages" data-action="share/whatsapp/share" target="_blank" class="" style=""><i class="fab fa-twitter-square"></i></a>
                                            <a :href="'whatsapp://send?text='+messages" data-action="share/whatsapp/share" target="_blank" class="" style=""><i class="fab fa-google-plus-square"></i></a>
                                            <a :href="'whatsapp://send?text='+messages" data-action="share/whatsapp/share" target="_blank" class="" style=""><i class="fab fa-whatsapp-square"></i></a>
                                            <a :href="'whatsapp://send?text='+messages" data-action="share/whatsapp/share" target="_blank" class="" style=""><i class="fab fa-instagram-square"></i></a>
                                            <a :href="'whatsapp://send?text='+messages" data-action="share/whatsapp/share" target="_blank" class="" style=""><i class="fab fa-facebook-square"></i></a>
                                        </div>
                                        
                                        </div>
                                    </div>
                                    </div>
                                
    </div>
</template>

<script>
export default {
        name: 'Accomplshments',
        props: {
            user: Object,
        },
        data(){
            return{
            messages:'Please join us on https://internship.sidehustle.ng/'
            }
        },
}
</script>

<style lang="css" scoped>
a{
    color: unset;
}
.share-btns i{
    transition: ease 200ms;
}
.share-btns i:hover{
 transform: scale(1.1);
}
.dash-btn {
    font-size: 12px;
    font-weight: 550;
    border-radius: 25px;
    padding: 0.1rem 0.75rem;
    min-width: fit-content;
}
.achievement-card-title {
    font-weight: 550;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #08386d;
}
.achievement-card-message {
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.04em;
    color: #92a7bf;
}
.achievement-card .see-badges {
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    color: rgba(8, 56, 109, 0.84);
}
@media screen and (max-width: 768px) {
    .card-headings {
        font-size: 14px;
        line-height: 28px;
    }
    .dash-btn {
        font-size: 8px;
        padding: 0.05rem 0.9rem;
    }
    .achievement-card-title {
        font-size: 11px;
        line-height: 16px;
    }
    .achievement-card-message {
        font-size: 8px;
        line-height: 12px;
    }
}
</style>