<template>
    <div
        class="streak-container card col-12 p-0"
        v-if="user.mode === 'gamified'"
    >
        <div class="card-body px-0" v-if="!isLoading">
            <h6 v-if="!streak">
                Streak Progress cannot be display at the moment
            </h6>
            <div v-else>
                <h4 class="streak-heading">YOUR STREAK</h4>
                <div class="media mt-2 d-flex align-items-center">
                    <div
                        class="d-flex-flex-column justify-content-center align-items-center position-relative"
                    >
                        <img
                            v-if="streak.Rank === 'Rookie'"
                            class="p-1 streak-rank-avatar"
                            src="@/assets/img/ranks/Rookie.png"
                            :alt="streak.Rank"
                        />
                        <img
                            v-if="streak.Rank === 'Lieutenant'"
                            class="p-1 streak-rank-avatar"
                            src="@/assets/img/ranks/Lieutenant.png"
                            :alt="streak.Rank"
                        />
                        <img
                            v-if="streak.Rank === 'Captain'"
                            class="p-1 streak-rank-avatar"
                            src="@/assets/img/ranks/Captain.png"
                            :alt="streak.Rank"
                        />
                        <img
                            v-if="streak.Rank === 'Colonel'"
                            class="p-1 streak-rank-avatar"
                            src="@/assets/img/ranks/Colonel.png"
                            :alt="streak.Rank"
                        />
                        <img
                            v-if="streak.Rank === 'General'"
                            class="p-1 streak-rank-avatar"
                            src="@/assets/img/ranks/General.png"
                            :alt="streak.Rank"
                        />
                        <img
                            v-if="streak.Rank === 'Field Marshal'"
                            class="p-1 streak-rank-avatar"
                            src="@/assets/img/ranks/Marshall.png"
                            :alt="streak.Rank"
                        />
                        <div
                            class="star-background mx-auto mt-n1 text-center position-relative"
                            style="width:fit-content;z-index:2"
                            v-if="streak.Rank"
                        >
                            <img
                                src="@/assets/img/streak-star.png"
                                height="8"
                                width="8"
                                :alt="streak.Rank"
                            />
                            <span class="text-white pl-1">
                                {{ streak.Star }}</span
                            >
                        </div>
                    </div>
                    <div class="media-body">
                        <h5 class="mt-0 streak-name pl-4">
                            {{ streak.Rank }} {{ user.first_name }}
                        </h5>
                        <div
                            class="progress mb-1 streak-progress position-relative"
                        >
                            <div
                                class="position-absolute text-center"
                                style="left:0;right:0;top:50%;bottom:0;font-weight:800;font-size:7px;color:#F2F5F2;"
                            >
                                CURRENT STREAK
                            </div>
                            <div
                                class="progress-bar streak-progress-bar"
                                role="progressbar"
                                v-bind:style="{ width: streak.Streak + '%' }"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>

                        <div class="d-flex pl-4 pt-1">
                            <star-rating
                                :rating="streak.Star"
                                :read-only="true"
                                :show-rating="false"
                                :star-size="15"
                                :padding="1"
                            ></star-rating>

                            <span class="streak-rank text-white p-2 px-2">{{
                                streak.Rank
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center py-4">
            <i class="fas fa-spinner fa-spin fa-2x"></i>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import StarRating from "vue-star-rating";

export default {
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
    },

    components: {
        StarRating,
    },

    mounted() {
        this.getStreak();
    },

    methods: {
        getStreak() {
            let data = {
                week: this.week,
            };
            axios
                .post("/newStreak", data)
                .then((res) => {
                    this.streak = res.data.payload;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
    },

    data() {
        return {
            isLoading: true,
            streak: {},
        };
    },
};
</script>

<style scoped>
.streak-rank-avatar {
    margin-bottom: -3px;
    background-color: #cde4fe;
    border: 3px solid #52a350;
    border-radius: 50%;
    z-index: 2;
    position: relative;
    height: 55px;
    width: 55px;
}
.streak-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #3f3f3f;
}
.streak-container {
    background: #ffffff !important;
    border: none;
    box-sizing: border-box;
    border-radius: 15px;
}

.streak-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #52a350;
}

.streak-rank {
    background: #52a350;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 1px;
    font-size: 10px;
    line-height: 11px;
}

.star-background {
    background: #044995;
    padding: 0px 8px;
    margin-left: 20px;
    box-shadow: 0px 5.94545px 7.92727px rgba(0, 0, 0, 0.2);
    border-radius: 1.98182px;
}

.streak-progress {
    margin-left: -3px;
    background: #ced0d3;
    height: 10px;
}

.streak-progress-bar {
    background: rgb(82, 163, 80);
    background: linear-gradient(
        0deg,
        rgba(82, 163, 80, 1) 10%,
        rgba(181, 218, 180, 1) 40%,
        rgba(174, 214, 173, 1) 60%,
        rgba(82, 163, 80, 1) 90%
    );
}

.streak-margin {
    margin-top: 40px;
}
</style>
