<template>
    <div class="px-3 mb-3">
        <annoucement />
    </div>
    <div class="px-3 mb-3">
        <div
            v-if="talentEligible"
            style="background: #F1F8F1;border-radius: 50px;"
            class="px-2 py-1 mb-2  text-center text-md-left"
        >
            <router-link :to="{ name: 'TalentPool' }"
                    class="text-dark"
                    style="font-size:12px;font-weight:bold"
                >
                    <span
                        class="bg-success py-1 text-light font-weight-bold mr-2 px-2 new d-none d-md-inline"
                        >NEW</span
                    >
                    Update your Talent Pool Profile Here
            </router-link>
        </div>
    </div>
    <div
        class="my-5 mx-3 px-3 dashboard-streak"
        v-if="user.mode === 'gamified'"
    >
        <StreakProgress />
    </div>
    <div
        class="d-flex flex-row justify-content-between pl-0 mb-3 mb-lg-5 px-3 px-lg-0 overflow-auto"
    >
        <div class="col-11 col-md-4 pb-3 pb-lg-0">
            <Progress />
        </div>
        <div class="col-11 col-md-4 pb-3 pb-lg-0">
            <Capstone />
        </div>
        <div class="col-11 col-md-4 pb-3 pb-lg-0">
            <Score />
        </div>
    </div>

    <h5
        class="pl-3"
        style="font-weight: 600;font-size: 28px;line-height: 22px; color: #606060 !important;"
    >
        Activity
    </h5>

    <div
        class="parent-container d-flex flex-column flex-md-row align-items-start mt-3 mt-md-4"
    >
        <div class="col-md-12 d-flex flex-md-row flex-column flex-wrap">
            <div
                class="col-lg-6 card mb-3 mb-lg-0 py-lg-3"
                style="box-shadow:none"
            >
                <!-- lessons card -->
                <ListOfClasses
                    :liveClasses="liveClasses"
                    :loader="loader"
                    :week="week"
                />
                <!-- end lessons card -->
            </div>

            <div class="col-lg-5 px-lg-4 p-0">
                <div
                    class="col-12 mb-5 mb-md-0 d-flex flex-column px-0 mr-md-3"
                >
                    <!-- quiz card -->
                    <div
                        class="col-12 card card-headings quiz-card py-lg-3"
                        style="flex-basis: 0;"
                    >
                        <!-- quiz card heading -->
                        <div
                            class="d-flex flex-md-row justify-content-between align-items-end mb-5"
                        >
                            <div class="card-headings">Quizzes</div>
                            <router-link
                                :to="{ name: 'Quiz' }"
                                v-if="allQuizzes.length === 0"
                                ><div
                                    class="dash-btn py-0 px-3"
                                    style="background: #ffffff;color: #08386D;border:1px #08386D solid"
                                >
                                    Take quiz
                                </div></router-link
                            >
                        </div>
                        <!-- end of quiz card heading -->
                        <div v-if="allQuizzes.length > 0">
                            <TakenQuizzes
                                v-for="(item, index) in allQuizzes"
                                :key="index"
                                :score="item"
                            />
                        </div>
                        <div v-else class="text-center mb-3">
                            <p v-show="!loader">
                                No quiz record 😊
                            </p>
                            <div v-show="loader">
                                <i class="fas fa-spinner fa-spin fa-2x"></i>
                            </div>
                        </div>
                    </div>
                    <!-- end of quiz card -->
                </div>
                <!--  -->
                <div
                    class="col-md-6 col-12 mb-5 mb-md-0 d-flex flex-column px-0"
                >
                    <!-- share archievement card -->
                    <div
                        class="col-12 card mb-5 achievement-card py-lg-3 d-none"
                        style="flex-basis: 0;"
                        ref="test"
                    >
                        <div
                            class="d-flex flex-row justify-content-between align-items-center mb-5"
                        >
                            <Accomplishment :user="user" />
                            <div class="">
                                <img
                                    src="@/assets/img/awards/award-1.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <router-link :to="{ name: 'Badges' }"
                            ><div class="see-badges">
                                See all badges
                            </div></router-link
                        >
                    </div>
                    <!-- end share rchievement card -->
                    <!-- sh community card -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import swal from "sweetalert2";
import ListOfClasses from "@/views/admin/liveClasses/ListOfClasses.vue";
import TakenQuizzes from "./quiz/AllQuizzesTaken.vue";
import Accomplishment from "../../components/dashboard/accomplishment.vue";
import StreakProgress from "@/components/dashboard/streaks/profileProgress.vue";
import Capstone from "../../components/dashboard/capstones/capstone.vue";
import Score from "../../components/dashboard/capstones/score.vue";
import Progress from "../../components/dashboard/capstones/progress.vue";
import Annoucement from "../../components/dashboard/Annoucement.vue";
export default {
    name: "Dashboard",
    data() {
        return {
            allQuizzes: [],
            liveClasses: [],
            loader: true,
            courses: [],
            bootcampStatus: {},
            talentEligible: false,
        };
    },
    components: {
        TakenQuizzes,
        ListOfClasses,
        Accomplishment,
        Capstone,
        Score,
        Progress,
        StreakProgress,
        Annoucement,
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
            test: "auth/courses",
        }),
    },

    methods: {
        async verifyTalent(){
            axios
            .get("/talent/verify")
            .then((res) => {
                this.talentEligible = true
            })
            .catch((err) => {
                console.log(err);
            });
        },
        async getLiveClasses() {
            await axios
                .post("classes/live", {
                    week: this.week,
                })
                .then((response) => {
                    this.liveClasses = response.data.payload;
                })
                .catch(() => {
                    this.liveClasses = [];
                });
        },
        ...mapActions({
            attempt: "auth/attempt",
        }),
        getAllScores() {
            let data = { user_id: this.user.user_id };
            axios
                .post("quiz/scores", data)
                .then((response) => {
                    this.allQuizzes = response.data.payload;
                })
                .catch(() => {
                    this.allQuizzes = [];
                });
        },
        previewQuiz(id) {
            let toPreview = this.allQuizzes.filter(
                (item) => item.quiz_id == id,
            );
            this.$router.push({
                name: "QuizPreview",
                params: {
                    quizDetails: JSON.stringify(toPreview),
                },
            });
        },
        getPaymentStatus() {
            axios
                .get("payment")
                .then((res) => {
                    let data = res.data.payload;
                    this.bootcampStatus = data.bootcamp;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },

    mounted() {
        this.getPaymentStatus();
        this.verifyTalent();
        this.getLiveClasses();
        this.getAllScores();
        setTimeout(() => {
            this.loader = false;
        }, 4000);
    },
};
</script>

<style scoped>
.new {
    border-radius: 25px;
    font-size: 9px;
}
.progress-card,
.capstone-card,
.score-card {
    background-image: url("./../../assets/img/backgrounds/testimony-bg.png");
    background-size: 250%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 220px;
}
.progress-card {
    background-color: #0354ad;
}
.dashboard-streak {
    background: #ffffff;
    border: 2px dashed #52a350;
    border-radius: 15px;
}
.capstone-card {
    background-color: #54a452;
}

.score-card {
    background-color: #f75d7c;
}
.card-headings {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #606060;
}

.dash-btn {
    font-size: 12px;
    font-weight: 550;
    border-radius: 25px;
    padding: 0.1rem 0.75rem;
    min-width: fit-content;
}
.dash-btn {
    font-size: 12px;
    font-weight: 550;
    border-radius: 25px;
    padding: 0.1rem 0.75rem;
    min-width: fit-content;
}
.capstone-rating .rating {
    font-weight: 550;
    font-size: 32px;
    color: #81b31a;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.achievement-card-title {
    font-weight: 550;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #08386d;
}
.achievement-card-message {
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.04em;
    color: #92a7bf;
}
.achievement-card .see-badges {
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    color: rgba(8, 56, 109, 0.84);
}
/*  */
@media screen and (max-width: 768px) {
    .card-headings {
        font-size: 14px;
        line-height: 28px;
    }
    .dash-btn {
        font-size: 8px;
        padding: 0.05rem 0.9rem;
    }
    .achievement-card-title {
        font-size: 11px;
        line-height: 16px;
    }
    .achievement-card-message {
        font-size: 8px;
        line-height: 12px;
    }
}
</style>
