<template>
    <div class="d-flex flex-row align-items-center justify-content-start mb-5">
        <div class="mr-5 card-headings">
            This week’s schedule
        </div>
        <div class="dash-btn" style="background: #E2EFFD;">
            WEEK {{ week ? week : 1 }}
        </div>
    </div>
    <!-- each lesson start -->
    <div v-if="liveClasses.length > 0">
        <div
            class="d-flex flex-md-row flex-column align-items-start mb-4 box"
            v-for="(item, index) in liveClasses"
            :key="index"
        >
            <div class="dash-btn mt-4" style="background: #EBF4D7;">
                {{ item.day }}
            </div>
            <div class="fish-line-hr d-none d-md-block"></div>
            <div class="fish-line-vt d-md-none"></div>
            <div class="lesson-box p-4" style="width:300px">
                <div class="lesson-topic mb-3">
                    {{ item.topic }}
                </div>
                <div class="topic-list mb-4">
                    <ul v-if="item.description.length > 0">
                        <li v-for="(list, _i) in item.description" :key="_i">
                            {{ list }}
                        </li>
                    </ul>
                </div>
                <div
                    class="d-flex flex-md-row flex-column justify-content-between align-items-start align-items-md-end"
                >
                    <!-- <div class="mentor-name mb-3 mb-md-0">
                        Mentor - {{ item.user.first_name }}
                        {{ item.user.last_name }}
                    </div> -->
                    <a target="_blank" :href="item.link"
                        ><div
                            class="dash-btn"
                            style="background: #08386D;color: #FFFFFF;"
                        >
                            Class Link >
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="text-center mb-3">
        <span v-show="!loader">
            No live classes yet 😊
        </span>
        <div v-show="loader">
            <i class="fas fa-spinner fa-spin fa-3x"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "ListOfClasses",
    props: {
        liveClasses: Array,
        loader: Boolean,
        week: Number,
    },
};
</script>

<style scoped>
.page-title-mob {
    font-weight: 500;
    transform: rotate(0deg);
    writing-mode: horizontal-tb;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #e7e0e0;
}
.fish-line-hr {
    border-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 25px;
    height: 0px;
    margin-left: 0;
    margin-top: 2.25rem;
}
.fish-line-vt {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: 25px;
    margin-left: 1.5rem;
}
.card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}
.card-headings {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #606060;
}
.dash-btn {
    font-size: 12px;
    font-family: Graphik;
    font-weight: 600;
    border-radius: 25px;
    padding: 0.3rem 0.85rem;
    min-width: fit-content;
}
.lesson-box {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.lesson-box .mentor-name {
    font-weight: normal;
    font-style: italic;
    font-size: 8px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a8a8a8;
}
.lesson-box .topic-list {
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #92a7bf;
}
.lesson-box .lesson-topic {
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: #08386d;
}
@media screen and (max-width: 768px) {
    .card-headings {
        font-size: 14px;
        line-height: 28px;
    }
}
</style>
