<template>
    <!-- FAQS -->
    <div class="faq-section py-200px pt-0">
        <h3 class="text-center mb-5">Frequently Asked Questions</h3>
        <div class="accordion col-md-8 col-12 m-auto" id="accordionExample">
        <div class="card">
            <p class="card-header collapsed p-0" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                Who can apply?
            </p>
            <p id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                The internship spans for 12 weeks in total. 6 weeks for the training and another 6 weeks for the bootcamp where interns get to make use of all they’ve learnt and work on real life tasks for businesses
            </p>
        </div>
        <div class="card">
            <p class="card-header collapsed p-0" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                How can I apply to be an ambassador?
            </p>
            <p id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                The internship spans for 12 weeks in total. 6 weeks for the training and another 6 weeks for the bootcamp where interns get to make use of all they’ve learnt and work on real life tasks for businesses
            </p>
        </div>
        <div class="card">
            <p class="card-header collapsed p-0" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                How long will the internship take?
            </p>
            <p id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                The internship spans for 12 weeks in total. 6 weeks for the training and another 6 weeks for the bootcamp where interns get to make use of all they’ve learnt and work on real life tasks for businesses
            </p>
        </div>
        </div>
    </div>
    <!-- FAQS -->
</template>

<script>
export default {

}
</script>

<style>

</style>