<template>
    <div class="col-md-10 offset-md-1 mt-5 p-0">
        <div class="col-md-7 col-12 p-0 mx-auto">
            <div
                class="benefits-card2 pl-3 pt-4 pb-4 pr-3 refer text-center p-lg-5"
            >
                <div class="benefits-card-title2 mb-3 pl-2">Refer & Earn</div>
                <h5 class="text-center">
                    <h3
                        @click="copier"
                        class=" mb-5 text-center px-0 referral"
                        style="font-weight:500;  font-size:14px; color:#08386D;"
                    >
                        <small
                            ><span
                                class="far fa-copy"
                                style="cursor:pointer"
                            ></span
                        ></small>
                        Referal Link:
                        <span ref="referralCode" style="cursor:pointer"
                            >https://internship.sidehustle.ng?ref={{
                                user.user_id
                            }}</span
                        >
                    </h3>
                    <p
                        class="d-inline-block sh-text-primary"
                        v-if="numberOfReferral > 0"
                    >
                        You've referred
                        <span class="font-weight-bold"
                            >{{ numberOfReferral }}
                            {{
                                numberOfReferral == 1 ? "person" : "people"
                            }}</span
                        >
                        and
                        <span
                            class="font-weight-bold"
                            v-if="numberOfReferralFiltered > 0"
                            >{{ numberOfReferralFiltered }}
                            {{
                                numberOfReferralFiltered == 1
                                    ? "person has"
                                    : "people have"
                            }}</span
                        >
                        <span class="font-weight-bold" v-else>No one has</span>
                        completed the registration process
                        <span class="d-block font-weight-bold">
                            Only those who complete their registrations will be
                            counted as valid referrals
                        </span>
                    </p>
                </h5>
                <div class="col-12 mt--lg-4">
                    <span
                        class="mb-0 col-12 text-center d-block skip font-weight-bold"
                        v-if="canSpin"
                        @click="spin"
                    >
                        Spin the Wheel
                    </span>
                </div>
            </div>
            <div class="col-12 mt--lg-4 mb-5">
                <span class="mb-0 col-12 text-center d-block skip">
                    <router-link :to="{ name: 'WelcomeDashboard' }" class="skip"
                        >Back
                    </router-link>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Counter from "../../../components/inc/Counter.vue";
import axios from "axios";
import swal from "sweetalert2";

export default {
    data() {
        return {
            loading: true,
            numberOfReferralFiltered: 0,
            numberOfReferral: 0,
            canSpin: false,
            wons: null,
        };
    },

    components: {
        Counter,
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        getNumberOfReferral() {
            axios
                .post("referral", { user_id: this.user.user_id })
                .then((res) => {
                    this.loading = false;
                    this.numberOfReferral = res.data.count;
                    this.numberOfReferralFiltered = res.data.f_count;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getNoOfWins() {
            try {
                const { data } = await axios.get("/check-win");
                this.wons = data.count;
                Math.floor(this.numberOfReferralFiltered / 5) - this.wons !== 0
                    ? (this.canSpin = true)
                    : (this.canSpin = false);
            } catch (error) {
                console.log(error);
            }
        },

        spin() {
            let level = this.wons;
            // console.log(level);
            if (level < 2) {
                this.$router.push({
                    name: "Spinner",
                    params: { level },
                });
            } else if (level <= 2 || level <= 6) {
                this.$router.push({
                    name: "Spinner",
                    params: { level: 2 },
                });
            } else if (level > 6) {
                this.$router.push({
                    name: "Spinner",
                    params: { level: 3 },
                });
            } else {
                this.$router.push({
                    name: "Spinner",
                    params: { level: 10 },
                });
            }
        },

        copier() {
            let text = this.$refs.referralCode.textContent;
            const el = document.createElement("textarea");
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            if (document.execCommand("copy")) {
                swal.fire({
                    title: "Copied",
                    text: "Your refferal code has been copied",
                    icon: "success",
                });
            }
        },
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Dashboard";
        },
    },

    mounted() {
        this.getNumberOfReferral();
        this.getNoOfWins();
    },
};
</script>

<style scoped>
.benefits-card2.refer {
    background: #fffbeb;
}

/* .benefits-card2.refer::after{
    background: url(../../../assets/img/line/line3.png) no-repeat;
    background-size: auto;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right:16px;
} */
</style>
