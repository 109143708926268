<template>
    <router-view></router-view>
</template>
<script>
export default {
    watch: {
        $route(to, from) {
            document.title = to.meta.title + " | Side Hustle Internship 5.0";
        },
    },
};
</script>
<style></style>
