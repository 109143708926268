<template>
    <d-nav></d-nav>
    <Modal1 />
    <!-- <Modal2 v-show="modal2" @close-modal="modal2 = false, modal1=true" /> -->
</template>

<script>
import DNav from "./../../../components/inc/DashboardNav.vue";
import Modal1 from "./onboard-modal.vue";
// import Modal2 from "./onboard-modal-2.vue";
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
import axios from 'axios';


export default {
    components: { DNav, Modal1 },
    data() {
        return {
            //
            access: false,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods: {
        ...mapActions({
            signOutAction: "auth/signOut",
        }),
        // getSiteSettings() {
        //     axios
        //         .get("site-settings")
        //         .then((res) => {
        //             //if site setting allow access until start date
        //             if (
        //                 res.data.payload.can_access &&
        //                 this.user.role != "admin"
        //             ) {
        //                 this.$router.push({
        //                     name: "WelcomeDashboard",
        //                 });
        //             }else{
        //                 this.access = true;
        //             }
        //         })
        //         .catch((err) => {
        //             this.$router.push({
        //                 name: "Home",
        //             });
        //         });
        // },
    },
    mounted() {
        // if (this.access == false) {
        //     this.$router.push({
        //         name: "WelcomeDashboard",
        //     });
        // }
        // this.getSiteSettings();
        if (this.user.mode !== null) {
            this.$router.push({
                name: "Dashboard",
            });
        }
        if (!this.user.course_id && !this.user.extra_course_id ) {
            this.$router.push({
                name: "First",
            });
        // }else if (this.user && this.user.stipend != 1 && this.user.bloom == null ) {
        //     this.$router.push({ name: "Third" });
        //     swal.fire({
        //         title: "Oops",
        //         html: "You are yet to complete your registration. Update your Bloomm account details to complete your registration",
        //         icon: "warning",
        //         confirmButtonText: "Enter Bloomm Details",
        //     })
        }else if (!this.user.taken_baseline) {
            this.$router.push({ name: "Baselinetest" });
            swal.fire({
                    title: "Oops",
                    html: "You are yet to take your baseline test. Take and pass your baseline test to access your dashboard.",
                    icon: "warning",
                    confirmButtonText: "Take Baseline",
                })
        }
    }
};
</script>

<style scoped>
.onboard-heading {
    font-family: "GD Boing";
    font-weight: bold;
    font-size: 36px;
    line-height: 52px;
    text-align: center;
    color: #242424;
}
#onboardVideo {
    width: 100%;
}
</style>
