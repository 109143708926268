<template>
    <div class="col-lg-8 mt-5 mt-lg-0 col-12">
        <div class="row mx-lg-3">
            <div class="col-12 col-md-6 mb-4">
                <div class="benefits-card p-4 text-center text-md-left">
                    <img
                        class="mb-4"
                        src="@/assets/img/benefits/certificate.png"
                    />
                    <div class="benefits-card-title mb-3">
                        Free soft skills scholarship
                    </div>
                    <div class="benefits-card-details">
                        All Side Hustle Internship finalists will be awarded a
                        soft skills certificate in addition to their Side Hustle
                        Internship certificate of completion.
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
                <div class="benefits-card p-4 text-center text-md-left">
                    <img class="mb-4" src='@/assets/img/benefits/network.png'>
                    <div class="benefits-card-title mb-3">Network with like minds</div>
                    <div class="benefits-card-details">During the Side Hustle Internship, you will be assigned capstone projects, which will provide you with unlimited opportunities to form strong networks with like-minded individuals and improve your communication skills.</div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
                <div class="benefits-card p-4 text-center text-md-left">
                    <img class="mb-4" src='@/assets/img/benefits/learn.png'>
                    <div class="benefits-card-title mb-3">Learn from the very best</div>
                    <div class="benefits-card-details">Learning is a wonderful experience, and learning from the best makes everything ten times easier. The Side Hustle Internship mentors and lead instructors are all here to make your Internship experience unforgettable.</div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
                <div class="benefits-card p-4 text-center text-md-left">
                    <img class="mb-4" src='@/assets/img/benefits/startup.png'>
                    <div class="benefits-card-title mb-3">Be a part of a fast growing startup</div>
                    <div class="benefits-card-details">After the bootcamp, all Side Hustle Internship finalists will have the opportunity to work for any fast-paced start-up.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BenefitCard",
};
</script>

<style scoped>
.benefits-card{
    background: #F4F5F7;
    background-image: url('./../../assets/img/backgrounds/benefits-card-bg.png');
    background-position:  top right;
    background-repeat: no-repeat;
    border-radius: 35px;
    height: 300px;  
}
.benefits-card-title{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.13rem;
    line-height: 1.5rem;
    color: #6E6E6E;
}
.benefits-card-details{
    font-family: 'Nunito', sans-serif;
    font-size: 0.8rem;
    line-height: 1rem;
    color: #898989;
    margin-bottom: 50px;
}
.benefits-card img{
    transform: scale(0.8);
}
</style>
