<template>
    <Navbar />
    <div class="container-fuild px-5" style="margin-top: 10rem;">
        <div class="col-lg-12">
            <div class="row ml-lg-4">
                <div class="col-12 p-0">
                    <div class="mt-lg-5 text-center">
                        <img src="@/assets/img/gits/11.gif" class="gif" />
                    </div>
                    <span
                        class="d-block px-0 mb-3 mt-4 col-12 text-center complete-text"
                    >
                        Congratulations!
                    </span>
                    <p class="mb-0 col-12 text-center d-block complete-p">
                        You’ve successfully completed your registration
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Navbar from "../../../components/inc/MutedNavbar.vue";
export default {
    components: { Navbar },

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Registration";
        },
    },

    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
        // get new user details and redirect users
        takeOff() {
            this.attempt();
            setTimeout(() => {
                this.$router.push("/welcome?complete=dashboard");
            }, 3000);
        },
    },

    mounted() {
        this.takeOff();
    },
};
</script>

<style src="@/assets/css/main.css"></style>
