<template>
    <div>
        <div class="content-section mt-5">
            <div
                class="media comment-box"
                v-for="comment in displayeditems"
                :key="comment"
            >
                <img
                    :src="
                        comment.user.avatar === 'null'
                            ? 'https://sidehustle.ng/api/assets/img/profile_pic/avatar.jpg'
                            : comment.user.avatar
                    "
                    class="mr-3 rounded-circle"
                    :alt="comment.user.first_name"
                    height="30"
                    width="30"
                />

                <div class="media-body">
                    <div class="comment-box-body">
                        <div class="d-flex flex-row align-items-baseline">
                            <h5 class="m-0">
                                {{
                                    comment.user.first_name +
                                        " " +
                                        comment.user.last_name
                                }}
                            </h5>
                            <p class="m-0 ml-1 text-uppercase">({{comment.user.role =='intern' ? comment.user.user_id : comment.user.role}})</p>
                        </div>
                        <h4 class="time-ago m-0">
                            {{ postDate(comment.created_at) }}
                        </h4>
                        <h4 class="comment-content mt-3">
                            {{ comment.comment }}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="post.comment.length>this.perPage" class="d-flex justify-content-center align-items-center flex-wrap mt-4">
            <button v-if="page<=pages.length && page>1" type="button" class="mr-2"  @click="page--"> Load previous comments </button>
            <button v-if="page<pages.length" type="button" class="ml-2"  @click="page++"> Load more comments </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
export default {
    props: {
        post: [Object],
    },

    data() {
        return {
            loading: true,
            page: 1,
            perPage: 5,
            pages: []
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
        displayeditems () {
            return this.paginate(this.post.comment);
        },
    },
    watch: {
        // 'post.comment' () {
        //     this.setPages();
        // },
        perPage () {
            this.setPages();
            this.paginate(this.post.comment);
        },
    },
    methods: {
        postComment() {
            this.loading = true;
        },
        postDate(postD) {
            return moment(postD)
                .startOf("minute")
                .fromNow();
        },
        setPages () {
            let numberOfPages = Math.ceil(this.post.comment.length / this.perPage);
            this.pages=[]
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },
        paginate (items) {
            let page = this.page;
            let perPage = this.perPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return  items.slice(from, to);
        }
    },
    mounted(){
        this.setPages();
    }
};
</script>

<style lang="css" scoped>
/* .community-container{
    margin-top: 150px !important;
} */

.page-title-mobile {
    margin-top: 100px !important;
}
.page-title-mobile-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #e7e0e0;
    margin-bottom: 15px;
}
.community-card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.community-con {
    background-color: transparent;
}

.community-con h4 {
    font-style: normal;
    font-weight: 550;
    font-size: 24px;
    line-height: 31px;
    color: #606060;
}

.community-con p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 16px;
    color: #606060;
}

.media-wrapper .media img {
    height: 50px;
    width: 50px;
}

.media-wrapper .media {
    margin-bottom: 20px;
}

.media-wrapper .media .media-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 15px; */
    color: #606060;
}

.media-wrapper .media .media-body p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.community-card.post .media {
    margin-bottom: 20px;
}

.community-card.post .media .media-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
}

.community-card.post .media .media-body p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px !important;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.community-card.post .media img {
    height: 30px;
    width: 30px;
}

.contribution-count {
    float: right;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.04em;

    color: #a7a5a5;
}

.contribution-count img {
    width: 15px !important;
    height: 13px !important;
}

.contribution-count2 {
    font-style: normal;
    /* font-weight: 600; */
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.04em;

    color: #a7a5a5;
}

.contribution-count2 img {
    width: 15px !important;
    height: 13px !important;
}

.community-card.compose h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* line-height: 18px; */
    color: #a7a5a5;
}

.post-input {
    background: #fff !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 25px;
    height: 40px;
    margin-top: 5px;
}

.post-input2 {
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 550;
    box-sizing: border-box;
    border-radius: 25px;
    height: 40px;
    margin-top: 5px;
}
.recent-head {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: #a7a5a5;
    position: relative;
}

.recent-head::before {
    bottom: 7px;
    content: "";
    height: 1px;
    left: 3px;
    position: absolute;
    width: 89%;
    background-color: #e9ecef;
}

.post-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #606060;
}

.comment-box-body {
    background: #f8fbfe;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
}

.comment-box-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
}

.comment-box-body h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.comment-box-body h4.time-ago {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #08386d;
}

.comment-box-body h4.comment-content {
    font-style: normal;
    font-weight: normal;
    /* margin-top: 3rem; */
    font-size: 14px;
    line-height: 16px;
    color: #606060;
}

.btn-load-comment {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #a7a5a5;
}
</style>
