<template>
    <div class="row col-12 m-0 p-0">
        <!-- Quiz Card -->
        <div class="p-0 col-12 mb-4 row mx-0">
            <div class="lesson-card px-3 py-4 col d-flex flex-column justify-content-between mr-4">
                <div
                    class="d-flex flex-column align-items-start justify-content-between mb-3 lesson-title mt-0"
                >
                    <div style="font-family: Nunito;font-style: normal;font-weight: 500;font-size: 13px;color: #242424;">Prototyping a Mobile and Web Design</div>
                    <div style="font-family: Nunito;font-style: normal;font-weight: 300;font-size: 10px;line-height: 11px;color: #505050;">Pick up where you left off</div>
                </div>
                <button
                    class="btn-block"
                    style="padding: 12px 18px;background: #044995;border-radius: 5px;font-weight: 550;font-size: 10px;line-height: 11px;letter-spacing: 0.04em;color: #FFFFFF;"
                >
                    Continue <i class="fas fa-arrow-right"></i>
                </button>
            </div>
            <div class="lesson-card px-3 py-4 col d-flex flex-column justify-content-between">
                <div
                    class="d-flex flex-column align-items-start justify-content-between mb-3 lesson-title mt-0"
                >
                    <div style="font-family: Nunito;font-style: normal;font-weight: 600;font-size: 13px;color: #242424;">Prototyping a Mobile and Web Design</div>
                    <div style="font-family: Nunito;font-style: normal;font-weight: 300;font-size: 10px;line-height: 11px;color: #505050;">Pick up where you left off</div>
                </div>
                <button
                    class="btn-block"
                    style="padding: 12px 18px;background: #044995;border-radius: 5px;font-weight: 550;font-size: 10px;line-height: 11px;letter-spacing: 0.04em;color: #FFFFFF;"
                >
                    Continue <i class="fas fa-arrow-right"></i>
                </button>
            </div>
        </div>
        <!-- End of Quiz Card -->
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.lesson-card{
background-color: #F9FAFA;
background-image: url('./../../../../assets/img/line/line2.png');
background-size: contain;
background-position:  top right;
background-repeat: no-repeat;
border-radius: 13.5772px
}
.lesson-card:last-child{
    margin-right: 0 !important;
}
</style>