/<template>
    <!-- Hero Section -->
    <div class="hero-section py-200px px-xlg-100 px-sm-20 px-lg-30">
      <h1>Join Us In Building Africa’s Workforce</h1>
      <p class="col-md-6 col-12 mx-auto mb-5">We have the power to make the needed change to provide us with the kind of future we want for ourselves; and all of this starts with equipping the youth.</p>
      <div class="join-form col-md-6 col-12 m-auto text-center px-0">
          <input type="email" placeholder="Enter your Email Address ">
          <button type="submit">Apply For Free</button>
      </div>
    </div>
    <!-- Hero Section -->   
</template>
<script>
export default {

}
</script>

<style scoped>
@media screen and (max-width:776px) {
  .hero-section{
    background-size: 100%;
  }
  .hero-section h1{
    font-size: 36px;
    line-height: 42px;

}
    .hero-section h1::after{
    content: url('./../../assets/img/twisted-arrow.png');
    position: absolute;
    top: 0;
    transform: rotate(290deg) scale(0.7);
    }
}
.hero-section h1{
position: relative;
}
.hero-section h1::after{
content: url('./../../assets/img/twisted-arrow.png');
position: absolute;
bottom: 0;
transform: rotate(290deg) scale(0.7);
}
</style>