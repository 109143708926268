<template>
    <div class="px-3 pb-2">
        <div class="d-flex pl-2">
            <h5 class="page-title">
                Payment
            </h5>
        </div>
        <div
            class="col-11 d-flex flex-column flex-md-row justify-content-between"
        >
            <div
                class="p-3 payment-cards"
                @click="
                    status.bootcamp.status === 'Incomplete'
                        ? completePayment('bootcamp', status.bootcamp.id)
                        : status.bootcamp.status === 'Paid'
                        ? () => {}
                        : payment('bootcamp')
                "
            >
                <div
                    class="d-flex align-items-center justify-content-center card-icon mb-3"
                >
                    <i class="fas fa-money-check"></i>
                </div>
                <div
                    class="d-flex justify-content-between align-items-center mb-1"
                >
                    <small class="font-weight-bold">Bootcamp Payment</small>
                    <small
                        v-if="status.bootcamp.status"
                        class="px-3 py-1 bg-white"
                        style="border-radius:75px"
                        >{{ status.bootcamp.status }}</small
                    >
                </div>
                <button
                    class="btn btn-primary col-12 mt-3"
                    v-if="status.bootcamp.status !== 'Paid'"
                >
                    {{
                        status.bootcamp.status === "Incomplete"
                            ? `Complete Payment`
                            : "Make Payment"
                    }}
                </button>
            </div>
            <div
                class="p-3 payment-cards payment-card-cert"
                @click="
                    status.certificate.status === 'Incomplete'
                        ? completePayment('certificate', status.certificate.id)
                        : status.certificate.status === 'Paid'
                        ? () => {}
                        : payment('certificate')
                "
            >
                <div class="d-flex justify-content-between">
                    <div
                        class="d-flex align-items-center justify-content-center card-icon mb-3"
                    >
                        <i class="fas fa-money-check d-block"></i>
                    </div>
                    <small class="py-0 px-3" v-if="courses.length > 1"
                        >1st Track</small
                    >
                </div>
                <div
                    class="d-flex justify-content-between align-items-center mb-1"
                >
                    <small class="font-weight-bold">Certificate Payment</small>
                    <small
                        v-if="status.certificate.status"
                        class="px-3 py-1 bg-white"
                        style="border-radius:75px"
                        >{{ status.certificate.status }}</small
                    >
                </div>
                <button
                    class="btn btn-primary col-12 mt-3"
                    v-if="status.certificate.status !== 'Paid'"
                >
                    {{
                        status.certificate.status === "Incomplete"
                            ? `Complete Payment`
                            : "Make Payment"
                    }}
                </button>
            </div>
            <div
                class="p-3 payment-cards payment-card-cert"
                v-if="courses.length > 1"
                @click="
                    status.extra_certificate.status === 'Incomplete'
                        ? completePayment(
                              'certificate',
                              status.extra_certificate.id,
                              true,
                          )
                        : status.extra_certificate.status === 'Paid'
                        ? () => {}
                        : payment('certificate', true)
                "
            >
                <div class="d-flex justify-content-between">
                    <div
                        class="d-flex align-items-center justify-content-center card-icon mb-3"
                    >
                        <i class="fas fa-money-check d-block"></i>
                    </div>
                    <small class="py-0 px-3">2nd Track</small>
                </div>
                <div
                    class="d-flex justify-content-between align-items-center mb-1"
                >
                    <small class="font-weight-bold">Certificate Payment</small>
                    <small
                        v-if="status.extra_certificate.status"
                        class="px-3 py-1 bg-white"
                        style="border-radius:75px"
                        >{{ status.extra_certificate.status }}</small
                    >
                </div>
                <button
                    class="btn btn-primary col-12 mt-3"
                    v-if="status.extra_certificate.status !== 'Paid'"
                >
                    {{
                        status.extra_certificate.status === "Incomplete"
                            ? `Complete Payment`
                            : "Make Payment"
                    }}
                </button>
            </div>
        </div>
    </div>
    <modal v-if="paymentStatus" :paymentSuccess="paymentSuccess"></modal>
</template>

<script>
import modal from "./transactionModal.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
    data() {
        return {
            paymentType: null,
            paymentSuccess: false,
            paymentStatus: null,
            status: {
                bootcamp: "Not Paid",
                certificate: "Not Paid",
                extra_certificate: "Not Paid",
            },
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
            courses: "auth/courses",
        }),
    },
    components: {
        modal,
    },
    watch: {
        $route(to, from) {
            if (!to.query.type) {
                this.paymentStatus = null;
            }
            this.getPaymentStatus();
        },
    },
    methods: {
        payment(paymentType, avail) {
            this.$router.push({
                name: "PayCategory",
                query: { type: paymentType, extra_course: avail },
            });
        },
        completePayment(type, id, avail) {
            this.$router.push({
                name: "PayAmount",
                params: {
                    type,
                    package: id,
                    isFull: 0,
                    extra_course: avail,
                },
            });
        },
        getPaymentStatus() {
            axios
                .get("payment")
                .then((res) => {
                    let data = res.data.payload;
                    this.status.bootcamp = data.bootcamp;
                    this.status.certificate = data.certificate;
                    this.status.extra_certificate = data.extra_certificate;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {
        if (this.week < 3) {
            this.$router.push({ name: "Dashboard" });
        }
        if (
            (this.$route.query.type && localStorage.ref) ||
            (this.$route.query.type && this.$route.query.id)
        ) {
            axios
                .post("payment/transaction", {
                    reference: localStorage.ref || this.$route.query.id,
                })
                .then((res) => {
                    this.paymentSuccess = res.data.payload;
                    this.paymentStatus = true;
                })
                .catch((err) => {
                    console.log(err);
                });
            localStorage.removeItem("ref");
        }
        this.getPaymentStatus();
    },
};
</script>

<style lang="css" scoped>
.card-icon {
    background: #fff;
    color: #73808c;
    border-radius: 11px;
    max-width: 40px;
    width: 30px;
    height: 30px;
    max-height: 40px;
}
.payment-cards {
    background-color: #e8f3fd;
    background-image: url(../../../assets/img/backgrounds/payment-bg.png);
    background-position-x: 0%;
    background-size: cover;
    border-radius: 15.7901px;
    margin-bottom: 21px;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 350px;
    margin: 0.5rem 1rem;
}
.payment-card-cert {
    background-color: #e9ebed;
}
.make-payment {
    background: #044995;
    border-radius: 5px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 16.1339px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}
.pay-installment {
    background: #f3f6fa;
    border: 1.24107px dashed #044995;
    border-radius: 5px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 16.1339px;
    line-height: 22px;
    text-align: center;
    color: #044995;
    cursor: pointer;
}
@media only screen and (max-width: 768px) {
    .payment-cards {
        width: 100%;
    }
}
</style>
