<template>
    <div>
        <!-- Overview -->
        <div v-show="openTab == 1">
            <Overview :lesson="lesson" :mentor="lesson.posted_by" />
        </div>
        <!-- Overview -->
        <!-- Chat Section -->
        <div v-show="openTab == 2">
            <ChatBox :lesson="lesson" />
        </div>
        <!-- Chat Section -->
        <!-- Resources -->
        <div v-show="openTab == 3">
            <Resources :links="lesson" />
        </div>
        <!-- Resources -->
    </div>
</template>

<script>
import Overview from "./Overview.vue";
import ChatBox from "./ChatBox.vue";
import Resources from "./Resources.vue";
export default {
    components: {
        Overview,
        ChatBox,
        Resources,
    },
    props: ["openTab", "lesson"],
    // mounted() {
    //     console.log(this.lesson);
    // },
};
</script>

<style></style>
