<template>
    <div class="px-3 pb-5">
        <div class="d-flex">
            <div
                class="mr-2"
                @click="
                    goToLessons(
                        currentLesson.course.id,
                        currentLesson.course.title,
                    )
                "
            >
                <i class="fas fa-arrow-left"></i>
            </div>
            <h5 class="page-title">
                <span>Lessons</span> >
                <span>{{
                    currentLesson ? currentLesson.course.title : ""
                }}</span>
                > {{ currentLesson ? currentLesson.title : "" }}
            </h5>
        </div>
        <div class="current-week d-none d-lg-block col-lg-9 d-lg-flex">
            <span class="d-block">Week {{ currentLesson.week }}</span>
        </div>
        <div class="row">
            <div class="mt-4 col-lg-9 p-0 lessons-container d-flex">
                <div
                    class="col-lg-3 d-lg-flex d-none flex-column other-topics"
                    v-if="false"
                >
                    <button
                        class="my-2 text-truncate"
                        :style="
                            currentLesson.id === item.id
                                ? 'background-color:#08386d; color: #fff'
                                : ''
                        "
                        v-for="item in allLessons"
                        :key="item.id"
                        @click="setCurrentLesson(item.id), watchedLessons()"
                    >
                        <i
                            class="fa-solid fa-circle-check"
                            v-if="
                                allWatchedLessons &&
                                    allWatchedLessons.includes(item.id)
                            "
                        ></i>
                        {{ item.title }}
                    </button>
                </div>
                <div class="col-12 col-lg-9 m-0 p-0 ">
                    <h4 class="text-left p-3">
                        Title: {{ currentLesson.title }}
                    </h4>
                    <div class="col-12 py-0 px-2 m-0 mb-5">
                        <div id="vimeoPlayer" class="mb-3"></div>
                        <div
                            class="d-flex justify-content-between align-items-center col-12 p-0 m-0 mb-4"
                            v-if="false"
                        >
                            <button
                                class="rounded text-white"
                                style="background-color: rgb(8, 56, 109);"
                                @click="previous(currentLesson.id)"
                            >
                                <i class="fas fa-backward"></i> Previous
                            </button>

                            <button
                                v-if="
                                    currentLesson &&
                                        currentLesson.id !==
                                            allLessons[allLessons.length - 1].id
                                "
                                class="rounded text-white"
                                style="background-color: rgb(8, 56, 109);"
                                @click="next(currentLesson.id)"
                            >
                                Next <i class="fas fa-forward"></i>
                            </button>
                        </div>
                    </div>
                    <div
                        class="col-12 d-flex justify-content-between p-3"
                        style="border-bottom:1px solid #00000026;color:#A8A8A8;cursor:pointer;"
                    >
                        <div
                            @click="toggleTab(1)"
                            :class="{ activeTab: openTab == 1 }"
                        >
                            <i class="fas fa-list"></i> Overview
                        </div>
                        <div
                            @click="toggleTab(2)"
                            :class="{ activeTab: openTab == 2 }"
                        >
                            <i class="fas fa-comments"></i> Q&A
                        </div>
                        <div
                            @click="toggleTab(3)"
                            :class="{ activeTab: openTab == 3 }"
                        >
                            <i class="fas fa-link"></i> Resources
                        </div>
                    </div>
                    <div class="mt-5 px-3" v-if="currentLesson !== ''">
                        <Tab
                            :openTab="openTab"
                            :mentor="currentLesson.posted_by"
                            :lesson="currentLesson"
                        />
                    </div>
                </div>
                <!-- Lesson Card -->
            </div>
            <div class="col-lg-3 d-none d-lg-block" v-if="currentLesson !== ''">
                <TakeQuiz :id="currentLesson.course_id" />
            </div>
        </div>
    </div>
</template>

<script>
import Tab from "./lesson-components/Tab.vue";
//
import { mapGetters } from "vuex";
import Mentor from "../../../components/dashboard/mentor";
import Question from "../../../components/dashboard/question";
import TakeQuiz from "./lesson-components/Quiz.vue";
import postAnswer from "../../../components/dashboard/postAnswer";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert2";
import Player from "@vimeo/player";

const Toast = swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", swal.stopTimer);
        toast.addEventListener("mouseleave", swal.resumeTimer);
    },
});

export default {
    components: {
        Mentor,
        Question,
        postAnswer,
        Tab,
        TakeQuiz,
    },
    name: "Lesson",
    data() {
        return {
            openTab: 1,
            currentLesson: "",
            questions: [],
            allLessons: [],
            allWatchedLessons: [],
            canDownloadLesson: false,
        };
    },
    props: ["lesson", "lessons"],
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
        jobberman() {
            if (this.currentLesson.course.id == 99) {
                return true;
            } else {
                return false;
            }
        },
    },

    methods: {
        instailizeVimeoPlayer() {
            // "https://vimeo.com/699541540/a2baec0f9e"
            this.player = new Player("vimeoPlayer", {
                id: this.currentLesson.video_link,
                responsive: true,
                width: 600,
            });
            const { savedLessonProgress } = this;
            this.player.on("play", async function() {
                savedLessonProgress();
            });
        },

        next(id) {
            let currentIndex = this.allLessons.findIndex(
                (item) => item.id === id,
            );

            if (currentIndex < this.allLessons.length - 1) {
                let index = currentIndex + 1;
                this.currentLesson = this.allLessons[index];
                this.player
                    .loadVideo(this.currentLesson.video_link)
                    .then(function(id) {
                        // The new video is loaded
                        // console.log(id);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                this.watchedLessons();
            } else {
                Toast.fire({
                    icon: "warning",
                    title: "This is the last video",
                });
            }
        },
        previous(id) {
            let currentIndex = this.allLessons.findIndex(
                (item) => item.id === id,
            );

            if (currentIndex > 0) {
                let index = currentIndex - 1;
                this.currentLesson = this.allLessons[index];
                this.player
                    .loadVideo(this.currentLesson.video_link)
                    .then(function(id) {
                        // The new video is loaded
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                this.watchedLessons();
            } else {
                Toast.fire({
                    icon: "warning",
                    title: "This is the first video",
                });
            }
        },
        //Switch the tabs
        toggleTab(tabNumber) {
            this.openTab = tabNumber;
        },
        postDate(postD) {
            return moment(postD)
                .startOf("hour")
                .fromNow();
        },
        async watchedLessons() {
            try {
                const { data } = await axios.post("watched", {
                    course_id: this.currentLesson.course_id,
                    week: this.week,
                    isExternal:
                        this.currentLesson.course_id == 99 ? true : false,
                });
                try {
                    this.allWatchedLessons = JSON.parse(data.payload);

                    // this.allWatchedLessons = JSON.parse(data.payload);
                    // console.log(this.allWatchedLessons);
                } catch (error) {
                    this.allWatchedLessons = data.payload;
                }
            } catch (error) {
                console.log(error);
            }
        },
        setCurrentLesson(id) {
            let lesson = this.allLessons.find((item) => item.id === id);
            this.currentLesson = lesson;
        },
        async savedLessonProgress() {
            try {
                const data = await axios.post("saveLesson", {
                    course_id: this.currentLesson.course_id,
                    week: this.week,
                    isExternal:
                        this.currentLesson.course_id == 99 ? true : false,
                    lesson_id: this.currentLesson.id,
                });
            } catch (error) {
                console.log(error);
            }
        },

        goToLessons(id, title) {
            this.$router.push({
                name: "Lessons",
                params: {
                    id,
                    title,
                },
            });
        },
    },

    mounted() {
        if (this.lesson == undefined) {
            this.$router.push({
                name: "Lessons",
            });
        } else {
            let _lesson = JSON.parse(this.lesson);
            let lessons = JSON.parse(this.lessons);
            this.currentLesson = _lesson;
            this.allLessons = lessons;
            this.instailizeVimeoPlayer();
            this.watchedLessons();
        }
    },
    beforeUnmount() {
        this.player.destroy().then(() => {});
    },
};
</script>
<style lang="css" scoped>
#lessonVideoPlayer {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
}
.current-week {
    border-bottom: 1px solid #959595;
}
.other-topics button {
    background: #f5faff;
    border: 1px solid #044995 !important;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 10px;
    line-height: 14px;
    color: #9c9c9c;
    padding: 10px 10px;
    text-align: left;
}

.activeTab {
    color: #044995;
    font-weight: 600;
}
</style>
