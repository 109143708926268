<template>
    <div class="py-200px pt-0 px-xlg-100 px-sm-20 px-lg-30">
        <!-- Why Become An Ambassador -->
        <div class="become-ambassador">
            <h3 class="text-center">Why become our ambassador?</h3>
            <div class="cards d-flex flex-wrap justify-content-between">
            <div class="card d-flex flex-column p-4">
                <i class="fa-solid fa-shield"></i>
                <h5>Internship opportunities at Terra</h5>
                <p>One from Side Hustle Internship, one from Jobberman and a financial literacy certificate from Dillali</p>
            </div>
            <div class="card d-flex flex-column p-4">
                <i class="fa-solid fa-shield"></i>
                <h5>Internship opportunities at Terra</h5>
                <p>One from Side Hustle Internship, one from Jobberman and a financial literacy certificate from Dillali</p>
            </div>
            <div class="card d-flex flex-column p-4">
                <i class="fa-solid fa-shield"></i>
                <h5>Internship opportunities at Terra</h5>
                <p>One from Side Hustle Internship, one from Jobberman and a financial literacy certificate from Dillali</p>
            </div>
            <div class="card d-flex flex-column p-4">
                <i class="fa-solid fa-shield"></i>
                <h5>Internship opportunities at Terra</h5>
                <p>One from Side Hustle Internship, one from Jobberman and a financial literacy certificate from Dillali</p>
            </div>
            </div>
        </div>
        <!-- Why Become An Ambassador -->
        <!-- You want this? Become an Ambassador -->
        <div class="why-become-ambassador d-flex flex-column align-items-center">
            <h3 class="text-center my-5"><span style="color:#959595;">You want this? </span><span style="color:#52A350">Become an Ambassador <i class="fa-solid fa-arrow-right"></i></span></h3>
            <div class="wave-circle-outer mb-5">
                <img class="impact-bottom-img" src="@/assets/img/impact-bottom.png" alt="">
                <div class="impact-bottom-circle"></div>
                <div class="wave-circle-middle">
                <img class="impact-top-img" src="@/assets/img/impact-top.png" alt="">
                <div class="inner-circle">
                    <p class="inner-circle d-flex align-items-center justify-content-center" style="height:300px;width:300px;">Want to make an impact like these guys?</p>
                </div>
                </div>
            </div>
            <button class="mt-5">Become an Ambassador</button>
        </div>
        <!-- You want this? Become an Ambassador -->
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@media screen and (max-width:776px) {
        .wave-circle-outer{
            transform: scale(0.7);
            margin-left: -30px !important;
        }
}
.why-become-ambassador h3{
position: relative !important;
}

.why-become-ambassador h3::after{
content: url('./../../assets/img/twisted-arrow.png');
position: absolute;
top: 0;
transform: rotate(0deg) scale(0.7);
}
</style>