<template>
    <div class="px-3 pb-2">
        <div class="d-flex pl-2">
            <h5 class="page-title">
                <span @click="$router.go(-1)" class="text-primary"
                    >Payment</span
                >
                > <span class=" text-capitalize">{{ order }} Payment</span>
            </h5>
        </div>
        <div class="col-lg-10 mx-auto mt-5">
            <img
                src="@/assets/img/partners/terrapay.svg"
                class="d-block mx-auto mb-3"
                alt="Terra Pay"
            />
            <h5 class="text-center font-weight-bold text-capitalize">
                {{ order }} Payment
            </h5>
            <p class="text-center" v-if="false">
                Deadline for payment : 15th Mar 2022
            </p>
            <div v-if="!charge && loading" class="text-center">
                <i class="fas fa-spinner fa-spin fa-2x"></i>
            </div>
            <div v-else>
                <div
                    class="d-flex flex-column flex-md-row justify-content-between align-items-stretch flex-wrap"
                    v-if="!selectedGateway"
                >
                    <label
                        :class="paymentType == 'full' ? 'selected' : ''"
                        for="fullPayment"
                        class="d-flex justify-content-start flex-column px-3 py-4 border rounded my-2 each-payment-option"
                    >
                        <div class="d-flex justify-content-between m-0 p-0">
                            <div>
                                <input
                                    type="radio"
                                    name="paymentType"
                                    id="fullPayment"
                                    @click="getType"
                                    value="full"
                                />
                                <span class="ml-2">Full Payment</span>
                            </div>
                            <div>
                                <small class="px-2 py-1 rounded bg-light text-success" v-if="discount">{{discount}}% off</small>
                                {{
                                    charge.gateway[0] == "PayPal"
                                        ? "$"
                                        : "&#8358;"
                                }}{{ charge.charges }}
                            </div>
                        </div>
                    </label>
                    <label
                        :class="paymentType == 'part' ? 'selected' : ''"
                        for="partPayment"
                        class="d-flex justify-content-between align-items-start px-3 py-4 border rounded my-2 each-payment-option"
                    >
                        <div>
                            <input
                                type="radio"
                                name="paymentType"
                                id="partPayment"
                                @click="getType"
                                value="part"
                            />
                            <span class="ml-2">Part Payment</span>
                        </div>
                        <div>
                            <small class="px-2 py-1 rounded bg-light text-success" v-if="discount">{{discount}}% off</small>
                            {{ charge.gateway[0] == "PayPal" ? "$" : "&#8358;"
                            }}{{ charge.charges }}
                        </div>
                    </label>
                    <div class="col-12 p-0 mt-5" v-if="paymentType === 'part'">
                        <p class="text-center font-weight-bold">
                            How much would you like to pay now?
                        </p>
                        <div
                            class="d-flex justify-content-center justify-content-lg-start align-items-center flex-wrap"
                            v-if="
                                order == 'bootcamp' &&
                                    charge.gateway[0] == 'PayPal'
                            "
                        >
                            <label
                                v-for="payment in bootCampPartPayment"
                                :key="payment"
                                :for="payment"
                                class="px-5 py-3 border rounded my-2 partPayment mx-2"
                                :class="
                                    payment < charge.charges
                                        ? 'd-block'
                                        : 'd-none'
                                "
                            >
                                <input
                                    type="radio"
                                    name="payment"
                                    :id="payment"
                                    :value="payment"
                                    v-model="partAmount"
                                />
                                {{
                                    charge.gateway[0] == "PayPal"
                                        ? "$"
                                        : "&#8358;"
                                }}{{ payment }}
                            </label>
                        </div>
                        <div
                            class="d-flex justify-content-center justify-content-lg-start align-items-center flex-wrap"
                            v-if="
                                order == 'certificate' &&
                                    charge.gateway[0] == 'PayPal'
                            "
                        >
                            <label
                                v-for="payment in certificatePartPayment"
                                :key="payment"
                                :for="payment"
                                class="px-5 py-3 border rounded my-2 partPayment mx-2"
                                :class="
                                    payment < charge.charges
                                        ? 'd-block'
                                        : 'd-none'
                                "
                            >
                                <input
                                    type="radio"
                                    name="payment"
                                    :id="payment"
                                    :value="payment"
                                    v-model="partAmount"
                                />
                                {{
                                    charge.gateway[0] == "PayPal"
                                        ? "$"
                                        : "&#8358;"
                                }}{{ payment }}
                            </label>
                        </div>
                        <div
                            class="d-flex justify-content-center justify-content-lg-start align-items-center flex-wrap"
                            v-if="
                                order == 'bootcamp' &&
                                    charge.gateway[0] == 'Flutterwave'
                            "
                        >
                            <label
                                v-for="payment in bootcampPartPaymentNGN"
                                :key="payment"
                                :for="payment"
                                :class="
                                    payment < charge.charges
                                        ? 'd-block'
                                        : 'd-none'
                                "
                                class="px-5 py-3 border rounded my-2 partPayment mx-2"
                            >
                                <input
                                    type="radio"
                                    name="payment"
                                    :id="payment"
                                    :value="payment"
                                    v-model="partAmount"
                                />
                                {{
                                    charge.gateway[0] == "PayPal"
                                        ? "$"
                                        : "&#8358;"
                                }}{{ payment }}
                            </label>
                        </div>
                        <div
                            class="d-flex justify-content-center justify-content-lg-start align-items-center flex-wrap"
                            v-if="
                                order == 'certificate' &&
                                    charge.gateway[0] == 'Flutterwave'
                            "
                        >
                            <label
                                v-for="payment in certificatePartPaymentNGN"
                                :key="payment"
                                :for="payment"
                                :class="
                                    payment < charge.charges
                                        ? 'd-block'
                                        : 'd-none'
                                "
                                class="px-5 py-3 border rounded my-2 partPayment mx-2"
                            >
                                <input
                                    type="radio"
                                    name="payment"
                                    :id="payment"
                                    :value="payment"
                                    v-model="partAmount"
                                />
                                {{
                                    charge.gateway[0] == "PayPal"
                                        ? "$"
                                        : "&#8358;"
                                }}{{ payment }}
                            </label>
                        </div>
                    </div>
                    <button
                        class="btn btn-primary col-12 mt-3"
                        @click="chooseGateway"
                        v-if="paymentType !== null"
                    >
                        Continue
                    </button>
                </div>

                <!-- if user has selected gateway -->
                <div class="col-12" v-if="selectedGateway">
                    <div
                        class="d-flex flex-column flex-md-row justify-content-center align-items-stretch flex-wrap"
                    >
                        <label
                            v-for="gate in available_gateways"
                            :key="gate"
                            class="px-5 py-3 border rounded my-2 partPayment mx-2"
                        >
                            <input
                                type="radio"
                                name="gayeway"
                                :id="gate"
                                :value="gate"
                                v-model="gateway"
                                class="mr-2"
                            />
                            <img
                                src="@/assets/img/flutter.svg"
                                alt="sellar"
                                style="width:100px"
                                v-if="gate == 'Flutterwave'"
                            />
                            <img
                                src="@/assets/img/selar.png"
                                alt="sellar"
                                style="width:45px"
                                v-if="gate == 'Sellar'"
                            />
                            <img
                                src="@/assets/img/paypal.png"
                                alt="paypal"
                                style="width:30px;"
                                v-if="gate == 'PayPal'"
                            />
                        </label>
                        <label
                            v-if="user.country === 'Nigeria' && false"
                            class="px-5 py-3 border rounded my-2 partPayment mx-2"
                        >
                            <input
                                type="radio"
                                name="gayeway"
                                value="transfer"
                                v-model="gateway"
                                class="mr-2"
                            />
                            <span>Bank Transfer</span>
                        </label>
                    </div>
                    <div v-if="gateway === 'transfer'" class="mt-3">
                        <ul class="text-left list-group col-md-8 mx-auto">
                            <li
                                v-for="(bank, index) in banks"
                                :key="index"
                                class="list-group-item d-flex justify-content-start flex-column"
                            >
                                <span class="mr-1"
                                    >Bank Name:
                                    <strong>{{ bank.bank }}</strong></span
                                >
                                <span class="mr-1"
                                    >Account Name:
                                    <strong>{{ bank.name }}</strong></span
                                >
                                <span class="mr-1"
                                    >Account Number:
                                    <strong>{{ bank.acctNumber }}</strong></span
                                >
                            </li>
                        </ul>
                    </div>
                    <button
                        class="btn btn-primary col-12 mt-3"
                        @click="makePayment"
                        v-if="gateway !== null"
                        v-html="
                            gateway === 'transfer'
                                ? 'I have made the transaction'
                                : buttonText
                        "
                    ></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import swal from "sweetalert2";


export default {
    data() {
        return {
            available_gateways:[
                'Sellar'
            ],
            order: this.$route.params.type,
            discount: null,
            package: this.$route.params.package,
            isFull: this.$route.params.isFull,
            extra_course: this.$route.params.extra_course,
            paymentType: null,
            partAmount: null,
            loading: true,
            charge: null,
            gateway: null,
            selectedGateway: false,
            buttonText: " Make Payment",
            finalAmount: null,
            bootCampPartPayment: [10, 15, 20, 25, 50, 75],
            certificatePartPayment: [5, 10, 15, 20],
            certificatePartPaymentNGN: [
                500,
                1000,
                1500,
                2000,
                2500,
                3000,
                3500,
                4000,
                4500,
                5000,
            ],
            bootcampPartPaymentNGN: [
                1000,
                2000,
                3000,
                4000,
                5000,
                6000,
                7000,
                8000,
                9000,
                10000,
            ],

            bootcampSellarLinks: [
                { amount: 1000, link: "https://selar.co/q4g3" },
                { amount: 2000, link: "https://selar.co/4b67" },
                { amount: 3000, link: "https://selar.co/p757" },
                { amount: 4000, link: "https://selar.co/jjcc" },
                { amount: 5000, link: "https://selar.co/ve2z" },
                { amount: 6000, link: "https://selar.co/vtnm" },
                { amount: 7000, link: "https://selar.co/zg8w" },
                { amount: 8000, link: "https://selar.co/dd9d" },
                { amount: 9000, link: "https://selar.co/9000" },
                { amount: 10000, link: "https://selar.co/4cpx" },
            ],
            certificateSellarLinks: [
                { amount: 500, link: "https://selar.co/1fq7" },
                { amount: 1000, link: "https://selar.co/ojiw" },
                { amount: 1500, link: "https://selar.co/en2u" },
                { amount: 2000, link: "https://selar.co/ws19" },
                { amount: 2500, link: "https://selar.co/ftqz" },
                { amount: 3000, link: "https://selar.co/ckdt" },
                { amount: 3500, link: "https://selar.co/8vcc" },
                { amount: 4000, link: "https://selar.co/ckdt" },
                { amount: 4500, link: "https://selar.co/23ha" },
                { amount: 5000, link: "https://selar.co/o1hq" },
            ],
            banks: [
                {
                    bank: "MINT",
                    name: "SIDE HUSTLE NIGERIA LIMITED",
                    acctNumber: 1101742023,
                },
                // {
                //     bank: "UBA",
                //     name: "Adedoyin Adeniyi",
                //     acctNumber: 2198092876,
                // },
                {
                    bank: "Wema bank",
                    name: "Side Hustle - Side Hustle Internship",
                    acctNumber: 7358208031,
                },
            ],
        };
    },

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods: {
        getType(e) {
            let value = e.target.value;
            if (value === "full") {
                this.paymentType = value;
                this.finalAmount = this.charge;
            } else {
                this.paymentType = value;
                this.finalAmount = this.charge.charges;
            }
        },

        chooseGateway() {
            this.selectedGateway = true;
        },
        retarget() {
            let data = {
                id: this.package,
                type: this.order,
                extra_course: this.extra_course,
            };
            axios
                .post("/payment/retarget", data)
                .then((response) => {
                    this.loadimg = false;
                    this.charge = response.data.payload;
                    this.discount = response.data.payload.discount;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        makePayment() {
            this.buttonText = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
            if (this.paymentType == "part") {
                this.finalAmount = this.partAmount;
            } else {
                this.finalAmount = this.charge.charges;
            }
            setTimeout(() => {
                if (this.gateway === "PayPal") {
                    this.paypal();
                }
                if (this.gateway === "Flutterwave") {
                    this.flutterwave();
                }
                if (this.gateway === "Sellar") {
                    this.sellar();
                }
                if (this.gateway === "transfer") {
                    this.bankTransfer();
                }
            }, 2000);
        },
        paypal() {
            let _package = this.package;
            let order = this.order;
            let amount = this.finalAmount;
            let completed = this.paymentType === "full" ? 1 : 0;
            let link = `${
                this.charge.link
            }&charges=${_package}&order=${order}&completed=${completed}${
                this.paymentType == "part" || this.isFull == "0"
                    ? `&amount=${amount}`
                    : ""
            }${this.extra_course ? "&extra_course=true" : ""}`;
            location.href = link;
            return;
        },
        flutterwave() {
            this.$launchFlutterwave({
                tx_ref: new Date().getTime().toString(),
                meta: {
                    charge_id: this.package,
                    type: this.order,
                    completed: this.paymentType === "full" ? "1" : "0",
                    extra_course: this.extra_course,
                },
                amount: this.finalAmount,
                currency: "NGN",
                payment_options: "card,mobilemoney,ussd",
                customer: {
                    email: this.user.email,
                    name: this.user.first_name + " " + this.user.last_name,
                },
                callback: function(data) {
                    axios
                        .post("payment/flutterwave", {
                            ref_id: data.transaction_id,
                        })
                        .then((res) => {
                            localStorage.setItem("ref", data.transaction_id);
                            window.location.href =
                                "https://internship5.terrahq.co/dashboard/payment?type=success";
                        })
                        .catch((err) => {
                            console.log(err);
                            this.paymentResponse = true;
                            this.paymentSuccess = false;
                        });
                },
                customizations: {
                    title: "Side Hustle",
                    description: "Payment for Side Hustle Bootcamp",
                    logo:
                        "https://internship.sidehustle.ng/img/logo-dark.114b4a7b.png",
                },
            });
        },
        sellar() {
            if (this.order == "bootcamp") {
                if (this.discount) {
                    swal.fire({
                            title: "Sorry",
                        text: 'Sorry, you can only make discount payments using Flutterwave payment gateway or Bank transfers',
                        icon: "warning",
                    })
                    this.buttonText = 'Make Payment'
                    return
                }
                let price = this.bootcampSellarLinks.find(
                    (item) => item.amount == this.finalAmount,
                );
                window.open(price.link, "_blank");
                this.$router.push({ name: "Payment" });
            }
            if (this.order == "certificate") {
                let price = this.certificateSellarLinks.find(
                    (item) => item.amount == this.finalAmount,
                );
                window.open(price.link, "_blank");
                this.$router.push({ name: "Payment" });
            }
        },
        bankTransfer() {
            window.open("https://forms.gle/k16eF3m8YamAKYPM8");
            this.$router.replace({ name: "Payment" });
        },
    },
    mounted() {
        if (this.order !== "bootcamp" && this.order !== "certificate") {
            this.$router.replace({ name: "Payment" });
        } else {
            this.retarget();
        }
    },
};
</script>

<style lang="css" scoped>
.each-payment-option {
    background: none;
    width: 48%;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #242424;
    cursor: pointer;
}
.each-payment-option:hover {
    background-color: #e8f3fd;
}
.partPayment {
    cursor: pointer;
}
.payment-cards {
    background-color: #e8f3fd;
    margin-bottom: 21px;
}
.selected {
    background: #e8f3fd !important;
}
@media screen and (max-width: 776px) {
    .each-payment-option {
        width: 100%;
    }
}
</style>
