<template>
    <div>
        <div class="px-0 px-lg-5 my-5">
            <div class="col-md-9">
                <div class="col-md-11 p-0">
                    <div class="mb-4">
                        <div class="row no-gutters">
                            <div class="col-md-2 p-0">
                                <div
                                    class="card quiz-card px-2 py-4 text-center score-board"
                                >
                                    <h4 class="text-center text-success">
                                        <strong>{{ score }}</strong>
                                    </h4>
                                    <h5 class="text-center text-show">
                                        <strong>SCORE</strong>
                                    </h5>
                                </div>
                            </div>
                            <div
                                class="col-lg-10 col-sm-12 p-0 pl-lg-5 mt-3 mt-lg-0"
                            >
                                <div class="card quiz-card py-4">
                                    <div class="row no-gutters">
                                        <div class="col-md-3 p-0">
                                            <div
                                                class=" text-center score-board score-padding"
                                            >
                                                <h4
                                                    class="text-center text-muted"
                                                >
                                                    <strong>
                                                        {{ questions.length }}
                                                    </strong>
                                                </h4>
                                                <h5
                                                    class="text-center text-show"
                                                >
                                                    QUESTIONS
                                                </h5>
                                            </div>
                                        </div>
                                        <div class="col-md-3 p-0">
                                            <div
                                                class=" text-center score-board score-padding"
                                            >
                                                <h4
                                                    class="text-center text-success"
                                                >
                                                    <strong>
                                                        {{ getRight }}
                                                    </strong>
                                                </h4>
                                                <h5
                                                    class="text-center text-show"
                                                >
                                                    CORRECT
                                                </h5>
                                            </div>
                                        </div>
                                        <div class="col-md-3 p-0">
                                            <div
                                                class=" text-center score-board score-padding"
                                            >
                                                <h4
                                                    class="text-center text-danger"
                                                >
                                                    <strong>
                                                        {{ missed }}
                                                    </strong>
                                                </h4>
                                                <h5
                                                    class="text-center text-show"
                                                >
                                                    WRONG
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="card quiz-card p-4 p-lg-5"
                        v-if="questions.length > 0"
                    >
                        <div class="quiz-question-header">
                            <h3>
                                <span class="">
                                    {{ questions[trackingIndex].id }}
                                </span>
                                <!-- Which of the following is NOT one of the
                                classsifications of Typefaces? -->
                                {{ questions[trackingIndex].question }}
                            </h3>
                        </div>

                        <form>
                            <div class="form-group mt-3">
                                <div
                                    class="radio px-4 py-3 mt-3"
                                    :class="
                                        item == answers[trackingIndex].answer
                                            ? 'correct'
                                            : item ==
                                              userChoice[trackingIndex].answer
                                            ? 'wrong'
                                            : ''
                                    "
                                    v-for="(item, index) in questions[
                                        trackingIndex
                                    ].options"
                                    :key="index"
                                >
                                    <label
                                        ><input
                                            type="radio"
                                            name="optradio"
                                        /><span class="ml-4">{{
                                            item
                                        }}</span></label
                                    >
                                </div>
                            </div>
                        </form>

                        <div class="col-md-8 p-0 mt-5 mb-5 mb-lg-3">
                            <button
                                @click="prevPreview"
                                class="btn btn-primary quiz-question-btn"
                                v-show="trackingIndex > 0"
                            >
                                <i class="fas fa-angle-left"></i> PREVIOUS
                            </button>
                            <button
                                @click="nextPreview"
                                class="btn btn-primary quiz-question-btn ml-4"
                                v-show="trackingIndex < questions.length - 1"
                            >
                                NEXT <i class="fas fa-angle-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import swal from "sweetalert2";

export default {
    data() {
        return {
            score: "",
            questions: [],
            answers: [],
            missed: "",
            trackingIndex: 0,
            getRight: "",
            quiz: [],
            userChoice: [],
        };
    },
    props: {
        quizDetails: String,
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        nextPreview() {
            if (this.trackingIndex < this.questions.length) {
                this.trackingIndex++;
            }
        },

        prevPreview() {
            if (this.trackingIndex !== 0) {
                this.trackingIndex--;
            }
        },
    },
    mounted() {
        if (this.quizDetails) {
            let quizProps = JSON.parse(this.quizDetails);
            this.answers = quizProps[0].quiz.answers;
            this.questions = quizProps[0].quiz.questions;
            this.score = quizProps[0].score;
            this.getRight = this.score / 5;
            this.missed = 20 - this.getRight;
            this.userChoice = JSON.parse(quizProps[0].answer);
        } else {
            this.$router.go(-1);
        }
    },
};
</script>

<style lang="css" scoped>
.page-title-mobile {
    margin-top: 100px !important;
}
.page-title-mobile-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #e7e0e0;
    margin-bottom: 15px;
}

.quiz-container {
    margin-top: 150px !important;
}
.quiz-card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.quiz-card-header h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.04em;

    color: #a7a5a5;
}

.quiz-card-header h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
    margin-top: 20px;
}

.quiz-card-header h3 span {
    background: #edebeb;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 50%;
    padding: 1px 6px;
}

.quiz-card-header p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;

    color: #08386d;
}

.duration {
    color: #606060 !important;
}

.quiz-btn {
    background: #08386d;
    border-radius: 25px;
    border: 1px solid #08386d;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    padding: 13px 20px;
    color: #ffffff;
}

/* Quiz Question */

.quiz-question-header h3 {
    font-style: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #606060;
}

.quiz-question-header h3 span {
    background: #e2effd;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 50%;
    padding: 4px 9px;
    color: #08386d;
    font-size: 16px;
    line-height: 18px;
}

.radio {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;

    /* identical to box height */

    color: #606060;
}

.radio.selected {
    background: #e2effd;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #08386d;
}

.radio.wrong {
    background: #fff5f5;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #fa0808;
}

.radio.correct {
    background: #f5fff5;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #52a350;
}

.quiz-question-btn {
    background: #ffffff !important;
    border-radius: 25px;
    border: 1px solid #606060 !important;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    padding: 13px 20px;
    color: #606060 !important;
}

.quiz-question-btn:hover {
    background: #ffffff !important;
    border: 1px solid #606060 !important;
    color: #606060 !important;
}

.quiz-number h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #606060;
}

@media screen and (max-width: 700px) {
    .quiz-number h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        color: #606060;
    }
}

.score-padding {
    border-right: 1px solid #dbdbdb;
}

.score-board h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 31px;
}

.score-board .text-show {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #d0cfce;
}

.score-board .text-muted {
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.04em;
    color: #d0cfce;
}
</style>
