<template>
    <div class="offset-md-1 col-md-10 mt-5 p-0">
        <div class="row mt-4 progress-con">
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show completed">1</span>
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show active">2</span>
                </div>
                <div
                    class="mt-2"
                    style="font-style: italic;background: #FFFBEB;box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);border-radius: 3px;font-size: 10px;color: #4F4F4F;padding:10px 10px;font:Nunito; position:absolute;left:0;right:0;"
                >
                    You’re doing just great! 😁
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show ">3</span>
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container"><span class="ball">4</span></div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 mx-auto mt-5 mb-5">
        <p
            class="text-left text-sm-center font-weight-bold d-block select-text"
        >
            Select a course that interests you
        </p>
    </div>

    <div class="col-md-4 col-8 mx-auto" v-if="category == 'programming'">
        <div class="benefits-card2 pl-3 pt-4 pb-4 pr-3 programming">
            <img class="mb-4" src="@/assets/img/benefits/network2.png" />
            <div class="benefits-card-title2 mb-3 pl-2">Engineering</div>
            <div class="benefits-card-details2 pl-2">
                Tracks in this category, which are related to software
                engineering and mobile application development, require the use
                of a laptop. These are tracks in which interns write code,
                design, build, and test websites or software applications to
                meet the needs of users.
            </div>
        </div>
    </div>

    <div class="col-md-4 col-8 mx-auto" v-if="category == 'design'">
        <div class="benefits-card2 pl-3 pt-4 pb-4 pr-3 design">
            <img class="mb-4" src="@/assets/img/benefits/network2.png" />
            <div class="benefits-card-title2 mb-3 pl-2">Design</div>
            <div class="benefits-card-details2 pl-2">
                This category includes tracks that involve developing materials,
                strategies, and techniques to promote, advertise, or sell
                products and services to a specific audience. They also
                necessitate the use of a laptop computer.
            </div>
        </div>
    </div>

    <div class="col-md-4 col-8 mx-auto" v-if="category == 'product'">
        <div class="benefits-card2 pl-3 pt-4 pb-4 pr-3 product">
            <img class="mb-4" src="@/assets/img/benefits/network2.png" />
            <div class="benefits-card-title2 mb-3 pl-2">Product</div>
            <div class="benefits-card-details2 pl-2">
                This category includes tracks that focus on conceptualizing,
                building, managing, and scaling products that users love while
                also considering the user interfaces and experience. They also
                necessitate the use of a laptop.
            </div>
        </div>
    </div>

    <div class="col-md-4 col-8 mx-auto" v-if="category == 'adminandmarketing'">
        <div class="benefits-card2 pl-3 pt-4 pb-4 pr-3 admin">
            <img class="mb-4" src="@/assets/img/benefits/network2.png" />
            <div class="benefits-card-title2 mb-3 pl-2">Marketing</div>
            <div class="benefits-card-details2 pl-2">
                This category includes tracks that involve instilling in interns
                a sense of responsibility for the success of any product/project
                assigned to them by strategically driving tactics to generate
                potential solutions to problems.They also necessitate the use of
                a laptop computer.
            </div>
        </div>
    </div>

    <div class="col-md-4 col-8 mx-auto" v-if="category == 'allCourses'">
        <div class="benefits-card2 pl-3 pt-4 pb-4 pr-3 admin">
            <img class="mb-4" src="@/assets/img/benefits/network2.png" />
            <div class="benefits-card-title2 mb-3 pl-2">All Courses</div>
            <div class="benefits-card-details2 pl-2">
                Don't know what category the track you are searching for is?
                Here you will find the list of all available tracks for this
                cohort of the Side Hustle Internship across Engineering,
                Marketing, Product and Design.
            </div>
        </div>
    </div>

    <form @submit.prevent="submit()">
        <div class="form-group mt-lg-5 col-12 col-lg-6 offset-lg-3 p-0">
            <select
                name="course"
                id="course"
                class="custom-select-lg course-select form-control col-12 d-block"
                v-model="course"
                @change="selected()"
            >
                <option value="See suggested courses" disabled hidden
                    >See suggested courses</option
                >
                <option
                    :value="course.id"
                    v-for="course in courses"
                    :key="course.id"
                    >{{ course.title }}</option
                >
            </select>
        </div>
        <div class="form-group mt-lg-4 col-12 col-lg-6 offset-lg-3 p-0">
            <button
                type="submit"
                class="btn btn-submit py-lg-2 px-4 btn-block"
                style="color:#fff; box-shadow:none"
                :disabled="!submitted"
                v-html="buttonText"
            ></button>
        </div>
    </form>
    <!-- floating whatsapp btn -->
    <a
        class="floating-cta text-center px-2 text-success"
        href="https://wa.me/message/HX6JWNMRVERVO1"
        target="_blank"
        ><i class="fab fa-whatsapp fa-3x" style="font-size:4rem"></i
        ><br />Chat</a
    >
    <div class="col-12 mt--lg-4 mb-5">
        <span class="mb-0 col-12 text-center d-block skip">
            <router-link :to="{ name: 'First' }" class="skip"
                >Back
            </router-link>
        </span>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
import NProgress from "nprogress";
import axios from "axios";

export default {
    data() {
        return {
            buttonText: "Proceed",
            submitted: false,
            course: "See suggested courses",
            category: this.$route.query.Category,
            courses: [],
        };
    },

    mounted() {
        if (
            this.category !== "programming" &&
            this.category !== "design" &&
            this.category !== "product" &&
            this.category !== "adminandmarketing" &&
            this.category !== "allCourses"
        ) {
            this.$router.replace({ path: "First" });
        }
    },

    created() {
        NProgress.start();
        axios
            .get("getCourseGrouping", {
                params: {
                    category: this.category,
                },
            })
            .then((res) => {
                this.courses = res.data.payload;
                NProgress.done();
            })
            .catch((err) => {
                NProgress.done();
            });
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Dashboard";
        },
    },

    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),

        selected() {
            this.submitted = true;
        },

        submit() {
            if (this.submitted) {
                NProgress.start();
                this.buttonText = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
                let data = {
                    course_id: this.course,
                };
                axios
                    .post("user/course/add", data)
                    .then((res) => {
                        this.attempt();
                        setTimeout(() => {
                            this.$router.push({
                                name: "WelcomeDashboard",
                            });
                        }, 1000);
                        NProgress.done();
                    })
                    .catch(() => {
                        NProgress.done();
                        this.buttonText = "Proceed";
                    });
            } else {
                swal.fire(
                    "Error",
                    "Please, pick a course of your interest",
                    "error",
                );
            }
        },
    },
};
</script>

<style scoped>
.benefits-card2.selected {
    border: 3px solid #52a350;
}
.benefits-card2 {
    width: 100%;
}
</style>
