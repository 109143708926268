<template>
<!-- This section will only show if the testimonies are successfully fetched -->
  <div v-if="loadTestimony" :style="{backgroundColor:testimonies_bg[current_bg]}" class="mx-0 testimonial-section pl-xlg-100 pr-xlg-100 px-sm-20 px-lg-30 d-flex flex-wrap flex-column py-xlg-100 justify-content-between align-items-center">
        <div class="text-center mb-0 mb-lg-5">
            <div class="testimonial-thumbnails-container-heading d-block mb-2">What others have gained</div>
            <div class="testimonial-thumbnails-container-text mt-3 col-lg-8 m-auto">Read all about how the Side Hustle internship has impacted other youths and helped them achieve their goals.</div>
        </div>
        <div class="testimonial-slider-container col-12 px-5 py-0 px-2">
            <div class="testimony-carousel col-lg-10 col-12 mx-auto d-flex flex-column justify-content-between mt-5 mt-lg-0">
                    <div class="carousel-inner p-0 m-0">
                        <div class="">
                            <TestimonyItem  v-for="testimony, index in testimonies" :key="index" :direction="direction" :index="index" :currentSlide="currentSlide"  :testimony="testimony"/>
                        </div>
                    </div> 
            </div>
            <CarouselIndicator :total="testimonies.length" :currentIndex="currentSlide" @switch="switchSlide($event)"/>
            <CarouselButtons @prev="prev" @next="next"/>
        </div>
        <!-- <div class="testimonial-thumbnails-container p-0 col-12 col-lg-5 d-none">
            <div class="text-center text-lg-left ">
                <div class="testimonial-thumbnails-container-heading d-block">What others have gained</div>
                <div class="testimonial-thumbnails-container-text mt-3">Read all about how the Side Hustle internship has impacted other youths and helped them achieve their goals.</div>
            </div>
            <TestimonyThumbnail :total="testimonies" :currentIndex="currentSlide" @switch="switchSlide($event)"/>
        </div> -->
  </div>
</template>

<script>
import TestimonyThumbnail from './Testimony/TestimonyThumbnail.vue';
import CarouselIndicator from './Testimony/CarouselIndicator.vue'
import CarouselButtons from './Testimony/CarouselButtons.vue';
import TestimonyItem from './Testimony/TestimonyItem.vue'
import axios from 'axios';


export default {
  name: "Testimonial", 
  components:{
    TestimonyThumbnail,
    CarouselButtons,
    CarouselIndicator,
    TestimonyItem,
    },
    emits: ["mouseenter", "mouseout"],
    computed: {
        transitionEffect() {
        return this.direction === "right" ? "slide-out" : "slide-in";
        },
    },

    data(){
        return{
            currentSlide: 0,
            slideInterval: null,
            direction:'' ,
            testimonies:{},
            testimonies_bg:['#00BE7E','#0354AD','#419BDA'],
            current_bg:0,
            loadTestimony: false,
            interval:5000,
        }
    },
    methods: {
        getTestimonies() {
                axios
                .get("testimonies")
                .then((res) => {
                    this.testimonies = res.data.testimony;
                    this.loadTestimony= true;
                })
                .catch((err) => {
                    this.loadTestimony= false;
                });
        },
        setCurrentSlide(index) {
            this.currentSlide = index;
            },
            setCurrentBg(io) {
                this.current_bg = io;
                },
            prev(step = -1) {
            const io =  this.current_bg > 0
                ? this.current_bg + step
                : this.testimonies_bg.length - 1;
            const index =
                this.currentSlide > 0
                ? this.currentSlide + step
                : this.testimonies.length - 1;
            this.setCurrentSlide(index);
            this.setCurrentBg(io);
            this.direction = "left";
            this.startSlideTimer();
            },
            _next(step = 1) {
            const io =  this.current_bg < this.testimonies_bg.length - 1
                ? this.current_bg + step
                : 0;
            const index =
                this.currentSlide < this.testimonies.length - 1
                ? this.currentSlide + step
                : 0;
            this.setCurrentSlide(index);
            this.setCurrentBg(io);
            this.direction = "right";
            },
            next(step = 1) {
            this._next(step);
            this.startSlideTimer();
            },
            startSlideTimer() {
            this.stopSlideTimer();
            this.slideInterval = setInterval(() => {
                this._next();
            }, this.interval);
            },
            stopSlideTimer() {
            clearInterval(this.slideInterval);
            },
            switchSlide(index) {
            const step = index - this.currentSlide;
            if (step > 0) {
                this.next(step);
            } else {
                this.prev(step);
            }
        },
    },
    mounted(){
            this.getTestimonies();
            this.startSlideTimer();
    },
    beforeUnmount() {
        this.stopSlideTimer();
    },
};
</script>


<style scoped>
.testimonial-section{
    background-image: url('./../../assets/img/backgrounds/testimony-bg.png');
    transition: background ease 2s;
}
.testimony-carousel{
   background: #FFFFFF;
    box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.08);
    border-radius: 30px 30px 30px 0;
    padding: 3rem 5rem;
}

.carousel-inner{
    position: relative;
    overflow: hidden;
    height: 250px;
   
}
.testimonial-thumbnails-container-heading{
    font-family: 'GD Boing', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 64px;
    color: #fff;
}
.testimonial-thumbnails-container-text{
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
}

@media screen and (max-width:768px) {
    .testimonial-thumbnails-container-heading{
    font-size: 24px;
    line-height: 34px;
}
    .testimonial-thumbnails-container-text{
    font-size: 16px;
    line-height: 26px;
    margin-top: 1rem;
}
.carousel-inner{
    position: relative;
    overflow: hidden;
    height: 300px;
   
}
.testimony-carousel{
    border-radius: 25px 25px 25px 0;
    padding: 1.5rem;
}

}
</style>