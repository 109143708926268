<template>
    <div
        class="col-12 card mb-5 capstone-rating"
    >
        <div class="card-headings py-lg-3">
            Capstone rating
        </div>
        <div v-if="capstones.length > 0">
            <div
                class="d-flex justify-content-between align-items-center"
                v-for="rating in capstones"
                :key="rating.id"
            >
                <div
                    class="rating-text d-flex flex-column"
                    style="font-size:18px"
                >
                    <small
                        >{{
                            rating.course
                                ? rating.course
                                : ""
                        }}

                    </small>  
                </div>
                <div>
                    <h6
                        class="rating sh-text-secondary p-3"
                        style="border-radius:50%;background:#F7FFE6"
                    >
                        <span v-if="rating.score >= 80">A</span>
                        <span
                            v-if="
                                rating.score >= 60 &&
                                    rating.score < 80
                            "
                            >B</span
                        >
                        <span
                            v-if="
                                rating.score >= 50 &&
                                    rating.score < 60
                            "
                            >C</span
                        >
                        <span
                            v-if="
                                rating.score >= 40 &&
                                    rating.score < 50
                            "
                            >D</span
                        >
                        <span
                            v-if="
                                rating.score >= 0 &&
                                    rating.score < 40
                            "
                            >F</span
                        >
                    </h6>
                </div>
            </div>
        </div>

        <div v-else>
            <h6
                v-show="capstoneloader"
                class="text-white"
                >
                No result available at the moment
            </h6>
            <div v-show="!capstoneloader" class="text-center mt-3 text-white">
                <i class="fas fa-spinner fa-spin fa-2x"></i>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name : 'Capstones',
    data() {
        return {
            capstones: {},
            capstoneloader: true
        }
    },
    methods: {
         async getCapstones() {
            this.capstoneloader = false;
            await axios.get("capstone/scores")
            .then((response) => {
                if(response.data.payload.scores.length > 0){
                    this.capstones = response.data.payload.scores;
                }else{
                    this.capstoneloader = true;
                }
            })
            .catch((err) => {
                this.capstone = 0;
                this.capstoneloader = false;
            })
            
        }
    },
    mounted() {
        this.getCapstones();
    },
}
</script>

<style scoped>
.card-headings {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #606060;
}
.dash-btn {
    font-size: 12px;
    font-weight: 550;
    border-radius: 25px;
    padding: 0.1rem 0.75rem;
    min-width: fit-content;
}
.dash-btn {
    font-size: 12px;
    font-weight: 550;
    border-radius: 25px;
    padding: 0.1rem 0.75rem;
    min-width: fit-content;
}
.capstone-rating .rating {
    font-weight: 550;
    font-size: 32px;
    color: #81b31a;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.achievement-card-title {
    font-weight: 550;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #08386d;
}
.achievement-card-message {
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.04em;
    color: #92a7bf;
}
.achievement-card .see-badges {
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    color: rgba(8, 56, 109, 0.84);
}
/*  */
@media screen and (max-width: 768px) {
    .card-headings {
        font-size: 14px;
        line-height: 28px;
    }
    .dash-btn {
        font-size: 8px;
        padding: 0.05rem 0.9rem;
    }
    .achievement-card-title {
        font-size: 11px;
        line-height: 16px;
    }
    .achievement-card-message {
        font-size: 8px;
        line-height: 12px;
    }
}
</style>
