<template>
    <Hero/>
    <PhotoTile/>
    <Impact/>
    <Gain/>
    <Become/>
    <Faq/>
    <Tile2/>
</template>

<script>
import Hero from "./Hero.vue"
import PhotoTile from "./Photo-Tile.vue"
import Impact from "./Impact.vue"
import Gain from "./Gain.vue"
import Become from "./Become.vue"
import Faq from "./Faqs.vue"
import Tile2 from "./Photo-Tile2.vue"
export default {
    components:{
        Hero,
        PhotoTile,
        Impact,
        Gain,
        Become,
        Faq,
        Tile2,
    }
}
</script>

<style src="@/assets/css/ambassadors.css">

</style>