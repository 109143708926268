<template>
    <div id="root">
        <div
            class="container-fluid py-xlg-150 join-container d-none d-lg-block"
        >
            <div class="py-50">
                <div class="row px-lg-5 px-2">
                    <div
                        class="join-the-next col-12 col-md-10 m-auto py-5 text-center"
                    >
                        <div class="join-the-next-heading mb-3">
                            Want to Join The Next Cohort?
                        </div>
                        <div class="join-the-text">
                            the journey to success begins
                            <span style="font-weight:bold">11th April</span>
                        </div>
                        <router-link :to="{ name: 'Register' }"
                            ><div class="px-4 mt-4 mx-auto py-2 cta-btn">
                                Apply as an Intern
                            </div></router-link
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="container py-lg-5 p-0 d-block d-lg-none">
            <div class="py-50">
                <div class="px-lg-5 px-2 mx-3">
                    <div
                        class="join-the-next col-12 col-md-10 m-auto py-5 text-center"
                    >
                        <div class="join-the-next-heading mb-2">
                            Want to Join The Next Cohort?
                        </div>
                        <div class="join-the-text mt-3">
                            <span
                                style="color:#fff; opacity: 0.5; font-size:16px"
                                >the journey to success begins </span
                            ><span
                                style="font-weight:bold; color:#fff; font-size:16px"
                                >11th April</span
                            >
                        </div>
                        <router-link to="/register"
                            ><button
                                class="px-4 mt-4 py-2 cta-btn"
                                style="font-weight:bold; font-size:16px; padding: 10px 50px !important;"
                            >
                                Apply now
                            </button></router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Join",
    components: {},
};
</script>

<style scoped>
.join-container {
    background-image: url("./../../assets/img/backgrounds/join-the-next-bg-ring.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    animation: anim1 20s infinite alternate-reverse;
}
@keyframes anim1 {
    0%{
        background-position-x: 0%;
        background-position-y: 0%;

    }
    15%{
        background-position-x: 100%;
        background-position-y: 40%;
    }
    25%{
        background-position-x: 30%;
        background-position-y: 100%;
    }
    40%{
        background-position-x: 70%;
        background-position-y: 0%;
    }
    55%{
        background-position-x: 0%;
        background-position-y: 80%;
    }
    75%{
        background-position-x: 10%;
        background-position-y: 100%;
    }
    90%{
        background-position-x: 100%;
        background-position-y: 10%;
    }
}
.join-the-next {
    background: linear-gradient(280.46deg, #08386d 5.62%, #054388 86.73%);
    box-shadow: 0px 12px 25px rgba(16, 89, 200, 0.27);
    color: #ffffff;
    border-radius: 50px;
    position: relative;
}
.join-the-next::before {
    content: "";
    background-image: url("./../../assets/img/backgrounds/join-the-next-bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 30%;
    height: 70px;
    top: -60px;
    left: 0;
}
.join-the-next::after {
    content: "";
    background-image: url("./../../assets/img/backgrounds/join-the-next-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    width: 30%;
    height: 50px;
    bottom: -80px;
    right: 0;
}
.join-the-next-heading {
    font-family: "GD Boing", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    line-height: 3rem;
}
.join-the-text {
    font-style: normal;
    font-size: 0.9rem;
}
.cta-btn {
    width: fit-content;
    background: #ffffff;
    border-radius: 10px;
    font-size: 0.9rem;
    color: #08386d;
    transition: 800ms ease;
}
.cta-btn:hover {
    background-color: #08386d;
    color: #ffffff;
    outline: 2px solid #fff;
}

@media screen and (max-width: 768px) {
    .join-container {
        padding-left: 10px;
        padding-right: 10px;
    }
    .join-the-next {
        border-radius: 25px;
    }
    .join-the-next::before {
        height: 0px;
    }
    .join-the-next::after {
        height: 0px;
    }
    .join-the-next-heading {
        font-size: 2rem;
        line-height: 2.5rem;
    }
    .join-the-text {
        font-size: 0.7rem;
    }
    .cta-btn {
        font-size: 0.7rem;
    }
}
</style>
