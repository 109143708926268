<template>
    <div class="mt-3 mt-md-5 p-0" v-if="quiz ? quiz.status : quiz">
        <!-- Quiz Card -->
        <div class="card-body quiz-card p-0 card col-12 mb-4">
            <div
                class="px-3 py-4 col-12 d-flex flex-column justify-content-between"
            >
                <p style="font-size: 16px !important;color: #242424;" class="">
                    Week {{ week }} Quiz
                </p>
                <!-- <p style="font-size: 12px !important;color: #242424;" class="">
                    Test your Knowledge
                </p> -->
                <button
                    @click="quizIntro(id)"
                    class="btn-block"
                    style="padding: 12px 18px;background: #044995;border-radius: 5px;font-weight: 550;font-size: 10px;line-height: 11px;letter-spacing: 0.04em;color: #FFFFFF;"
                >
                    Take Quiz <i class="fas fa-arrow-right"></i>
                </button>
            </div>
        </div>
        <!-- End of Quiz Card -->
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            quiz: null,
        };
    },
    computed: {
        ...mapGetters({
            week: "auth/currentWeek",
        }),
    },
    props: ["id"],
    methods: {
        quizIntro(id) {
            this.$router.push({
                name: "QuizStart",
                params: {
                    c_id: id,
                },
            });
        },
    },
    mounted() {
        axios
            .post("quiz/check", { course_id: this.id, week: this.week })
            .then((res) => {
                this.quiz = res.data;
            })
            .catch((err) => {});
    },
};
</script>

<style scoped>
.quiz-card {
    background-color: #fff;
    background-image: url("./../../../../assets/img/line/line2.png");
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
    border-radius: 14px;
    min-width: 250px;
    max-width: 300px;
}
</style>
