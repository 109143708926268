<template>
    <div class="container-fluid">
        <div class="col-8 mx-auto" @click="test">
            For every 5 referrals who complete their registration, you get a
            chance to spin the wheel <b>once</b> and win an amazing prize.
        </div>
        <Wheel
            :gift="gift"
            :data="data"
            @done="done"
            :imgParams="logo"
            @click="spinTheWheel"
            v-if="data.length > 0"
            ref="wheel"
        />
        <div
            class="result font-weight-bold text-center text-success"
            ref="winnings"
            v-if="prize !== null && prize.id !== 10"
        >
            Congratulations!!! 😊<br />
            You have won {{ prize.value }}.<br />
            You will be contacted within 24 hours for your gift.
        </div>
        <div
            class="result font-weight-bold text-center text-danger"
            ref="winnings"
            v-if="prize !== null && prize.id === 10"
        >
            Sorry!!! 🌛<br />
            Please try again.
        </div>

        <div class="col-12 mt-3 mb-5">
            <span class="mb-0 col-12 text-center d-block skip">
                <router-link
                    :to="{ name: 'DashboardRefer' }"
                    class="skip font-weight-bold"
                    >Back
                </router-link>
            </span>
        </div>
    </div>
</template>
<script>
import { Wheel } from "vue3-fortune-wheel";
import axios from "axios";
import "vue3-fortune-wheel/dist/library.css";
export default {
    components: {
        Wheel,
    },
    data() {
        return {
            gift: null,
            logo: {
                width: 80,
                height: 80,
                src: "https://sidehustle.ng/meta/favicon.ico",
            },

            data: [],
            canSpin: false,
            level: 0,
            prize: null,
        };
    },
    methods: {
        done(params) {
            this.prize = params;
            this.win(params.id);
        },
        spinTheWheel() {
            this.canSpin
                ? this.$refs.wheel.spin()
                : console.warn("You can not spin");
        },
        async win(id) {
            try {
                const { data } = await axios.post("add-win", { id });
            } catch (error) {
                console.log(error);
            }
        },
        async getGift() {
            let luckyNo = Math.floor(Math.random() * 50) + 1;
            try {
                let { data } = await axios.post("/spin", {
                    level: this.level || this.$route.params.level,
                    luckyNo,
                });
                this.gift = data.id;
            } catch (error) {
                console.log(error.response);
            }
        },
        getData() {
            axios
                .get("/gifts")
                .then((res) => {
                    this.data = res.data.gift;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {
        if (this.$route.params.level === undefined) this.$router.back();
        this.canSpin = true;
        this.level = this.$route.params.level;
        this.getGift();
        this.getData();
    },
};
</script>

<style>
#wheel {
    width: 100% !important;
    height: 500px !important;
}
.wheel_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
