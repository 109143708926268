import Home from "@/views/home/Home.vue";
import Container from "@/views/Container.vue";
import Login from "@/views/auth/sign-in/Login";
import Register from "@/views/auth/signup/Register";
import Faqs from "@/views/faq/Faqs";
// import Courses from "@/views/courses/Courses";
// import CourseDetail from "@/views/courses/CourseDetails";
import Contact from "@/views/contact/Contact";
import Reset from "@/views/auth/password/request-reset";
import ChangePassword from "@/views/auth/password/change-password";
import Privacy from "@/views/Privacy";
import TermsConditions from "@/views/TermsConditions";
import RewardBadge from '@/views/RewardBadge';
import Ambassador from '@/views/ambassadors/Home.vue';


const pagesRouter = [
    {
        path: "/",
        name: "Container",
        component: Container,
        children: [
            {
                path: "",
                component: Home,
                meta: {
                    title: "Home",
                },
            },
            {
                path: "home",
                name: "Home",
                component: Home,
                meta: {
                    title: "Home",
                },
            },
            {
                path: "privacy",
                name: "Privacy",
                component: Privacy,
                meta: {
                    title: "Privacy Policy",
                },
            },
            {
                path: "terms-conditions",
                name: "TermsConditions",
                component: TermsConditions,
                meta: {
                    title: "Participants' Code of Conduct for the Side Hustle Internship",
                },
            },
            {
                path: "contact",
                name: "Contact",
                component: Contact,
                meta: {
                    title: "Contact Us",
                },
            },
            {
                path: "ambassadors",
                name: "Ambassador",
                component: Ambassador,
                meta: {
                    title: "Ambassadors",
                },
            },
            {
                path: "faq",
                name: "Faqs",
                component: Faqs,
                meta: {
                    title: "FAQ",
                },
            },
            
            {
                path: "reward/badge/:id/:user_id",
                name: "RewardBadge",
                component: RewardBadge,
                meta: {
                    title: "Badge Reward",
                },
            },

            {
                path: "/about",
                name: "About",
                meta: {
                    title: "About",
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(/* webpackChunkName: "about" */ "@/views/About.vue"),
            },
        ],
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        meta: {
            title: "Register",
            linkExpired: false,
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: "Login",
        },
    },
    {
        path: "/reset",
        name: "Reset",
        component: Reset,
        meta: {
            title: "Reset Password",
        },
    },
    {
        path: "/reset-password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
            title: "Change Password",
        },
    }

    // {
    //     path: "/mint",
    //     name: "Mint",
    //     meta: {
    //         title: "Mint",
    //         requiresAuth: true,
    //     },
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import(/* webpackChunkName: "about" */ "@/views/Mint.vue"),
    // },
];

export default pagesRouter;
