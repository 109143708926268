<template>
    <div class="profile-info text-center">
        <div class="form-group row no-gutters flex-v-center mt-4">
            <!-- <div class="col-sm-4 p-0 mt-lg-4 mt-2 mx-0">
                <label for="another-input">PROFILE PICTURE</label>
            </div> -->
            <div class="col-md-8 p-0">
                <div class="media">
                    <!-- <i v-if="loader" style="width:80px; height:80px"> </i> -->
                    <div v-if="loader" class="pt-4 px-auto">
                        <i class="fas fa-spinner fa-spin fa-3x"></i>
                    </div>
                    <img
                        v-if="!loader"
                        :src="
                            user.avatar !== ''
                                ? user.avatar
                                : '@/assets/img/avatar.png'
                        "
                        class="mr-3 rounded-circle"
                        alt="user.first_name"
                        width="80"
                        height="80"
                    />
                    <!-- <img
                        v-if="!loader"
                        src="@/assets/img/avatar.png"
                        class="mr-3 rounded-circle"
                        alt="user.first_name"
                        width="80"
                        height="80"
                    /> -->
                    <div class="media-body ml-lg-5 ml-2 mt-2">
                        <label
                            for="img"
                            class="btn btn-outline-primary btn-course-take mt-lg-4 mt-3"
                        >
                            Change <i class="fas fa-angle-right"></i>
                        </label>
                        <input
                            id="img"
                            type="file"
                            ref="input"
                            name="image"
                            accept="image/*"
                            @change="setImage"
                            style="display:none"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="addImage6">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Crop Image</h4>
                    </div>
                    <div class="modal-body">
                        <div class="content">
                            <section class="cropper-area">
                                <div class="img-cropper">
                                    <vue-cropper
                                        ref="cropper"
                                        :aspect-ratio="3 / 3"
                                        :src="imgSrc"
                                        preview=".preview"
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <template v-if="!loader">
                            <a
                                href="#"
                                role="button"
                                @click.prevent="rotate(90)"
                            >
                                Rotate +90deg
                            </a>
                            <a
                                href="#"
                                role="button"
                                @click.prevent="rotate(-90)"
                            >
                                Rotate -90deg
                            </a>
                            <a
                                ref="flipX"
                                href="#"
                                role="button"
                                @click.prevent="flipX"
                            >
                                Flip X
                            </a>
                            <a
                                ref="flipY"
                                href="#"
                                role="button"
                                @click.prevent="flipY"
                            >
                                Flip Y
                            </a>

                            <a
                                href="#"
                                role="button"
                                @click.prevent="uploadImage()"
                            >
                                Upload Image
                            </a>
                        </template>
                        <template v-else>
                            <p class="text-center">Uploading...</p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import swal from "sweetalert2";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
    data() {
        return {
            loader: false,
            cropImg: "",
            imgSrc: "",
        };
    },
    props: {
        user: [Object],
    },
    components: {
        VueCropper,
    },
    methods: {
        cropImage() {
            this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        flipX() {
            const dom = this.$refs.flipX;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleX(scale);
            dom.setAttribute("data-scale", scale);
        },
        flipY() {
            const dom = this.$refs.flipY;
            let scale = dom.getAttribute("data-scale");
            scale = scale ? -scale : -1;
            this.$refs.cropper.scaleY(scale);
            dom.setAttribute("data-scale", scale);
        },
        move(offsetX, offsetY) {
            this.$refs.cropper.move(offsetX, offsetY);
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        setImage(e) {
            $("#addImage6").modal("show");
            const file = e.target.files[0];
            if (file.type.indexOf("image/") === -1) {
                alert("Please select an image file");
                return;
            }
            if (typeof FileReader === "function") {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.imgSrc = event.target.result;
                    // rebuild cropperjs with the updated source
                    this.$refs.cropper.replace(event.target.result);
                };
                reader.readAsDataURL(file);
            } else {
            }
        },
        ...mapActions({
            attempt: "auth/attempt",
        }),
        uploadImage(e) {
            this.loader = true;
            this.cropImage();
            let data = {
                img: this.cropImg,
            };
            axios
                .post("user/photo", data)
                .then((response) => {
                    if (response.status === 200) {
                        this.attempt();
                        swal.fire({
                            title: "Successful",
                            text:
                                "Your profile picture has been updated successfully",
                            icon: "success",
                        }).then(() => {
                            this.loader = false;
                            $("#addImage6").modal("hide");
                        });
                    } else {
                        swal.fire({
                            title: "Unsuccessful",
                            text:
                                "Your profile picture can not be updated successfully",
                            icon: "error",
                        }).then((e) => {
                            this.loader = false;
                            $("#addImage6").modal("hide");
                        });
                    }
                })
                .catch(() => {
                    swal.fire({
                        title: "Unsuccessful",
                        text: "An error has occured",
                        icon: "error",
                    }).then(() => {
                        this.loader = false;
                        $("#addImage6").modal("hide");
                    });
                });
        },
    },
};
</script>

<style lang="css" scoped>
.page-title-mobile-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #e7e0e0;
    margin-bottom: 15px;
}

.profile-card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.nav-pills .nav-link.active {
    font-style: normal;
    font-weight: 500;
    color: #606060;
    background: transparent;
    border-radius: 0px;
    position: relative;
}

.nav-pills .nav-link.active::before {
    bottom: -17px;
    content: "";
    height: 1px;
    left: 0%;
    position: absolute;
    width: 100%;
    background-color: #606060;
}

.nav-pills .nav-link {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #afafaf;
    border-radius: 0px;
}

.course-title h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #5a5a5a;
}

.course-title h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    color: #a8a8a8;
}

.course-title h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #606060;
}

.course-title h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #606060;
}

.circle-wrap {
    /* margin:auto; */
    width: 150px;
    height: 150px;
    background: #e6e2e7;
    border-radius: 50%;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
    width: 150px;
    height: 150px;
    position: absolute;
    border-radius: 50%;
}

.circle-wrap .circle .mask {
    clip: rect(0px, 150px, 150px, 75px);
}

.circle-wrap .circle .mask .fill {
    clip: rect(0px, 75px, 150px, 0px);
    background-color: #08386d;
}
.circle-wrap .circle .mask.full,
.circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    transform: rotate(126deg);
}

@keyframes fill {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(126deg);
    }
}

.circle-wrap .inside-circle {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #fff;
    line-height: 130px;
    text-align: center;
    margin-top: 10px;
    margin-left: 10px;
    position: absolute;
    z-index: 100;
    font-weight: 700;
    font-size: 2em;
}

.contribution-count3 {
    float: right;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    letter-spacing: 0.04em;

    color: #a7a5a5;
    margin-top: 5px;
}

.btn-course-take {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: #08386d;
    background: #f8fbfe;
    border: 1px solid #08386d !important;
    box-sizing: border-box;
    border-radius: 25px;
}

.profile-details .media img {
    height: 100px;
    width: 100px;
}

.profile-details .media {
    margin-bottom: 20px;
}

button i {
    color: #fff !important;
}

.profile-details.media-wrapper .media .media-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
}
.profile-details .media .media-body p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.profile-details label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    color: #a8a8a8;
}

.profile-details input {
    background: #f8f8f8;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 25px;
    font-size: 14px;
    line-height: 18px;
    color: #606060;
}

.profile-details button {
    background: #08386d;
    border: 1px solid #08386d;
    border-radius: 25px;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;

    color: #ffffff;
    padding: 10px 20px;
}

.change-password h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    color: #034894;
    cursor: pointer;
}

.nav-header-con {
    border-bottom: 1px solid #f2eded;
}

@media screen and (max-width: 700px) {
    .nav-pills .nav-link {
        font-style: normal;
        font-weight: 500;
        font-size: 16px !important;
        line-height: 20px !important;
        margin-right: 25px;
    }

    .profile-details label {
        font-weight: 500;
        font-size: 13px !important;
        line-height: 14px !important;
        letter-spacing: 0.04em;

        color: #a8a8a8;
    }

    .profile-details .media img {
        height: 56px !important;
        width: 56px !important;
        margin-top: 15px;
    }

    .btn-course-take {
        padding: 10px 15px !important;
    }

    .course-title h1 {
        font-size: 16px !important;
        line-height: 18px !important;
    }

    .contribution-count3 {
        float: none !important;
    }
}
</style>
