<template>
    <div class="d-flex">
        <h5
            class="pl-3"
            style="font-weight: 300;font-size: 16px;line-height: 22px; color: #242424 !important;"
        >
            <div class="d-flex">
                <div class="mr-2" @click="$router.go(-1)">
                    <i class="fas fa-arrow-left"></i>
                </div>
                <h5 class="page-title">
                    Goodies
                </h5>
            </div>
        </h5>
    </div>

    <div class="col-md-12 mt-3 mt-lg-5">
        <div class="row mb-5">
            <div
                class="col-10 mx-auto col-md-4 goodie text-center mt-4"
                @click="openchallenge"
            >
                <img
                    src="@/assets/img/challenge.png"
                    height="225"
                    style="width:90%"
                />
            </div>
            <div
                class="col-10 mx-auto col-md-4 goodie text-center mt-4"
                @click="openmystery"
            >
                <img
                    src="@/assets/img/mystery.png"
                    height="225"
                    style="width:90%"
                />
            </div>
            <div
                class="col-10 mx-auto col-md-4 goodie text-center mt-4"
                @click="openairdrop"
            >
                <img
                    src="@/assets/img/airdrop.png"
                    height="225"
                    style="width:90%"
                />
            </div>
        </div>
    </div>
    <ChanllengeModal
        v-if="showChanllengeModal"
        @close-modal="showChanllengeModal = false"
    />
    <AirdropModal
        v-if="showAirdropModal"
        @close-modal="showAirdropModal = false"
    />
    <MysteryModal
        v-if="showMysteryModal"
        @close-modal="showMysteryModal = false"
    />
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import ChanllengeModal from "./chanllengeModal.vue";
import AirdropModal from "./airdropModal.vue";
import MysteryModal from "./mysteryModal.vue";

export default {
    components: { ChanllengeModal, AirdropModal, MysteryModal },
    data() {
        return {
            showChanllengeModal: false,
            showAirdropModal: false,
            showMysteryModal: false,
        };
    },
    created() {},

    methods: {
        openchallenge() {
            this.showChanllengeModal = true;
        },

        openmystery() {
            this.showMysteryModal = true;
        },

        openairdrop() {
            this.showAirdropModal = true;
        },
    },
};
</script>

<style scoped>
.goodie {
    cursor: pointer;
}
</style>
