<template>
    <div class="d-flex pl-4">
        <h5
            style="font-weight: 300;font-size: 14px;line-height: 22px; color: #242424 !important;cursor:pointer;"
        >
            <div class="mr-2" @click="$router.go(-1)">
                <i class="fas fa-arrow-left"></i>
                <span>Capstone</span>
            </div>
        </h5>
    </div>

    <div
        class="col-md-12 d-flex flex-column flex-md-row align-items-start mt-3 mt-md- p-0"
    >
        <div class="row px-4 px-md-3 m-0" v-if="courses">
            <div
                class="col-md-4 mx-3 p-3 mb-4 d-flex flex-column justify-content-between course-card"
                v-for="(item, index) in courses"
                :key="index"
            >
            <div class="d-flex justify-content-between">
               <span class="d-block col-10">{{item.title}}</span> 
               <span @click="select(item.id)"
                 class="py-3 lesson-title"
               
               >{{index == 0 ? '1st':'2nd'}}</span> 
            </div>
            <button
                @click="select(item.id)"
                class="continue-btn py-3"
            >
                GO TO CAPSTONE
                <i class="fas fa-arrow-right"></i>
            </button>
           
               
            </div>
        </div>
        <div class="text-center mt-5 col-12" v-else>
            <i class="fas fa-circle-notch fa-spin fa-3x"></i><br />
            Getting your courses <i class="fas fa-spinner fa-spin"></i>
        </div>
    </div>
    <!-- <div class="col-md-6 mt-4 mx-0 p-0">
        <Capstones />
    </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import Capstones from "../../../components/dashboard/capstones/capstones.vue";

export default {
    components: {
        Capstones,
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            course_ids: "auth/course_ids",
            currentWeek: "auth/currentWeek",
            courses: "auth/courses",
        }),
    },
    methods: {
        select(id) {
            this.$router.push({
                name: "Capstone",
                params: {
                    c_id: id,
                },
            });
        },
    },
};
</script>

<style lang="css" scoped>
.continue-btn{
/* padding: 12px 18px; */
background: #044995;
border-radius: 5px;
font-weight: 550;
font-size: 10px;
line-height: 11px;
color: #FFFFFF;
}
.card-holder {
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    display: flex;
}
.course-card {
    background: #f9fafa;
    border: 1.4966px solid rgba(0, 0, 0, 0.1);
    border-radius: 14.966px;
    height: 200px;
}

.grey {
    color: #606060;
}
.ash {
    color: #a7a5a5;
}
.page-title {
    font-weight: 500;
    text-align: right;
    font-size: 40px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #e7e0e0;
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
}
.lesson-title p {
    font-weight: 550;
    font-size: 20px !important;
    color: #606060;
}
.lesson-title {
    padding: 5px !important;
    background: #b3e6b2;
    border-radius: 100%;
    font-weight: 550;
    font-size: 10px;
    height: 35px;
    display: grid;
    place-content: center;
    width: 35px;
    letter-spacing: 0.04em;
    color: #52a350;
}

@media screen and (max-width: 768px) {
    .card {
        border-radius: 10px;
    }
    .cert_btn {
        font-size: 10px;
        font-weight: 600;
        padding: 5px 10px;
        margin-right: 3px;
    }
    .cert_btn i {
        margin-left: 8px;
    }
    .page-title {
        font-weight: 500;
        transform: rotate(0deg);
        writing-mode: horizontal-tb;
        text-align: left;
        font-size: 16px;
    }
    .lesson-title p {
        font-size: 14px !important;
    }
}
</style>
