<template>
    <div class="px-3">
        <div class="d-flex">
            <router-link :to="{ name: 'Dashboard' }" class="text-dark">
                <div  class="course-card-arrow pr-3">
                    <i class="fas fa-arrow-left"></i>
                </div>
            </router-link>
            <h5 class="page-title">Lessons {{ lessons }}</h5>
        </div>
        <div class="row mx-0 mt-4">
            <div class="col-md-7 col-12 p-0">
                <!-- Progress Card -->
                <div class="progress-card p-4 d-flex justify-content-between">
                    <div>
                        <div
                            style="font-style: normal;font-weight: bold;font-size: 16px;line-height: 25px;color: #3F3F3F;"
                        >
                            Your progress
                        </div>
                        <div
                            style="font-style: normal;font-weight: 300;font-size: 14px;line-height: 22px;color: #3F3F3F;"
                        >
                            You’re currently in week {{ week }} of 6
                        </div>
                        <div
                            class="mt-4"
                            style="font-style: italic;font-weight: 300;font-size: 10px;line-height: 16px;color: #3F3F3F;"
                        >
                            <span v-if="progress == 0">
                                Make some effort Champ 😏
                            </span>
                            <span v-if="progress > 0 && progress <= 10">
                                Well done Champ, but you can do better
                            </span>
                            <span v-if="progress > 10 && progress <= 25">
                                Way to go Champ! Let's do more
                            </span>
                            <span v-if="progress > 25 && progress <= 50">
                                Awesome work so far! Don't give up
                            </span>
                            <span v-if="progress > 50 && progress <= 70">
                                I knew you could do it! Let's do more
                            </span>
                            <span v-if="progress > 70 && progress <= 99">
                                We are almost there!
                            </span>
                            <span v-if="progress == 100">
                                Hurray 🎉🎉🎉 You are the real MVP
                            </span>
                            {{ 6 - week }} weeks to go💪🏽💪😎😁💯
                        </div>
                    </div>
                    <circle-progress
                        :percent="Number(progress)"
                        background="#fff"
                        :border-width="5"
                        :border-bg-width="5"
                        empty-color="#F8F9FA"
                        class="progressClass"
                        :size="100"
                        fill-color="#52a350"
                        :show-percent="true"
                        :viewport="true"
                    />
                </div>
                <!-- Progress Card -->
                <!-- Intern Tracks Section -->
                <div class="mt-4">
                    <div
                        class="mb-3"
                        style="font-style: normal;font-weight: normal;font-size: 20px;line-height: 33px;color: #242424;"
                    >
                        Tracks
                    </div>
                    <tracks v-if="courses" :courses="courses"></tracks>
                    <div v-else>
                        Your courses are being loaded
                        <i class="fa-solid fa-atom fa-spin"></i>
                    </div>
                </div>
                <!-- Intern Tracks Section -->
                <!-- Intern Tracks Section -->
                <div class="mt-4 d-none">
                    <div
                        class="mb-3"
                        style="font-style: normal;font-weight: normal;font-size: 20px;line-height: 33px;color: #242424;"
                    >
                        Continue Lesson
                    </div>
                    <continue-courses></continue-courses>
                </div>
                <!-- Intern Tracks Section -->
            </div>
            <div class="col px-0 ml-lg-5 mb-5">
                <!-- quiz card -->
                <div
                    class="col-12 card card-headings quiz-card py-lg-3"
                    style="flex-basis: 0;"
                >
                    <!-- quiz card heading -->
                    <div
                        class="d-flex flex-md-row justify-content-between align-items-end mb-3"
                    >
                        <div class="card-headings">Quizzes</div>
                    </div>
                    <!-- end of quiz card heading -->
                    <div v-if="allQuizzes.length > 0">
                        <TakenQuizzes
                            v-for="(item, index) in allQuizzes"
                            :key="index"
                            :score="item"
                            @click="previewQuiz(item.quiz_id)"
                        />
                    </div>
                    <div v-else class="text-center mb-3">
                        <p>
                            No quiz record 😊
                        </p>
                    </div>
                </div>
                <!-- end of quiz card -->
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import NProgress from "nprogress";
import axios from "axios";
import ContinueCourses from "./lesson-components/ContinueLesson.vue";
import Tracks from "./lesson-components/Tracks.vue";
import CircleProgress from "vue3-circle-progress";
import TakenQuizzes from "./../quiz/AllQuizzesTaken.vue";

export default {
    components: {
        ContinueCourses,
        Tracks,
        CircleProgress,
        TakenQuizzes,
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            course_ids: "auth/course_ids",
            week: "auth/currentWeek",
            courses: "auth/courses",
        }),
    },
    data() {
        return {
            allQuizzes: [],
            lessons: "",
            userPro: "",
            progress: 0,
        };
    },
    methods: {
        async getAllScores() {
            let data = { user_id: this.user.user_id };
            await axios
                .post("quiz/scores", data)
                .then((response) => {
                    this.allQuizzes = response.data.payload;
                    this.lessons = localStorage.getItem("userLessons");
                })
                .catch(() => {
                    this.allQuizzes = [];
                });
        },
        getUserProgress() {
            axios
                .get("/progress/tracker", {
                    params: {
                        week: this.week,
                    },
                })
                .then((res) => {
                    let total =
                        Number(res.data.payload.course_progress) +
                        Number(res.data.payload.extra_course_progress);
                    this.progress = total;
                })
                .catch((response) => {});
        },
    },
    mounted() {
        NProgress.start();
        this.getAllScores();
        this.getUserProgress();
        NProgress.done();
    },
};
</script>

<style lang="css" scoped>
.progress-card {
    background: #e8f3fd;
    border-radius: 10px;
}
.quiz-card {
    background: #fffcf0;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}
.quiz-card .card-headings {
    font-family: Nunito;
    font-weight: 500;
    font-size: 24px;
    color: #606060;
}
</style>
