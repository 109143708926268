<template>
    <div class="px-3">
        <div class="d-flex">
            <div class="mr-2" @click="$router.go(-1)">
                <i class="fas fa-arrow-left"></i>
            </div>
            <h5 class="page-title">Certificates</h5>
        </div>
        <div class="mt-5">
            <div style="font-size:14px" class="mb-2">
                Your name
                <span class="font-weight-bold grey"
                    >“{{ user.first_name }} {{ user.last_name }}”</span
                >
                will appear on your certificates
            </div>
            <div style="font-size:12px">
                Edit your name in
                <router-link :to="{ name: 'Profile' }"
                    ><u style="color:#08386D;">Profile</u></router-link
                >
            </div>
        </div>
        <div class="mt-2">
            <div
                class="p-0 card col-md-4 col-12 bg-white mr-md-5 mb-4"
                v-if="jobbermanMessage != null"
            >
                <div
                    class="px-3 py-4 col-12 d-flex flex-column justify-content-between"
                >
                    <div class="mb-5 card-title mt-0">
                        <div class="p-0 m-0 card-title">
                            Jobberman Certificate
                        </div>
                    </div>
                    <span
                        :class="
                            ` ${
                                jobbermanCertificateAvailable
                                    ? 'card-btn btn-block text-center'
                                    : 'd-block text-center'
                            }`
                        "
                        @click="handleJobbermanCertDownload"
                        v-html="jobbermanMessage"
                    ></span>
                </div>
            </div>
        </div>
        <div
            class="col-12 d-flex flex-column align-items-start mt-3 mt-md-4 p-0"
            v-if="certificates.length > 0 && !isLoading"
        >
            <each-cert
                v-for="(certificate, index) in certificates"
                :key="index"
                :certificate="certificate"
            ></each-cert>
        </div>
        <div v-if="isLoading && certificates.length == 0" class="mt-5">
            <i class="fas fa-spinner fa-3x fa-spin"></i>
        </div>
        <div
            v-if="!isLoading && certificates.length == 0"
            class="col-12 d-flex flex-md-row flex-column p-0 my-4"
        >
            <div class="pt-3 pb-5 px-3 card col-md-4 col-12 bg-white">
                {{ errorMessage }}
            </div>
        </div>
        <div
            class="pt-3 pb-2 px-3 card col-md-4 col-12 bg-white mb-4 mt-2"
            v-if="showPaymentButton && qualifiedCert"
        >
            {{ balanceCertificateDetails }}
            <router-link :to="{ name: 'Payment' }">
                <button class="btn btn-primary card-btn py-3 mb-0 mt-4 ">
                    {{ payForCertificateMessage }}
                </button>
            </router-link>
        </div>
    </div>
</template>

<script>
import eachCert from "./EachCertificate.vue";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
    components: {
        eachCert,
    },
    data() {
        return {
            showPaymentButton: false,
            balanceCertificateDetails: "",
            payForCertificateMessage: "",
            certificates: [],
            jobbermanCertificateAvailable: false,
            jobbermanCertificateFile: null,
            jobbermanMessage: undefined,
            isLoading: true,
            errorMessage: "You have not made payments for certificate.",
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
        qualifiedCert() {
            if (
                this.errorMessage ==
                    "You're not eligible to download this certificate. Your total quiz score is less than 350" ||
                this.errorMessage ==
                    "You're not eligible to download this certificate. You did not take your week 6 quiz"
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        async getPaymentStatus() {
            axios
                .get("payment")
                .then((res) => {
                    let data = res.data.payload;
                    if (
                        data.certificate &&
                        data.certificate.status !== "Paid"
                    ) {
                        this.showPaymentButton = true;
                        if (data.certificate.status == "Incomplete") {
                            let id = data.certificate.id;
                            let certPackage;
                            if (id == 1) {
                                certPackage = "Basic";
                            }
                            if (id == 2) {
                                certPackage = "Standard";
                            }
                            if (id == 3) {
                                certPackage = "Premium";
                            }
                            this.balanceCertificateDetails =
                                this.user.course.title +
                                " (" +
                                certPackage +
                                " certificate)";
                            this.payForCertificateMessage =
                                "Kindly balance up to download your course certificate";
                        }
                        if (data.certificate.status == "Not Paid") {
                            this.payForCertificateMessage =
                                "Kindly make payment to download your course certificate";
                        }
                    }
                    if (
                        data.extra_certificate &&
                        data.extra_certificate.status !== "Paid"
                    ) {
                        this.showPaymentButton = true;
                        if (data.extra_certificate.status == "Incomplete") {
                            let id = data.extra_certificate.id;
                            let certPackage;
                            if (id == 1) {
                                certPackage = "Basic";
                            }
                            if (id == 2) {
                                certPackage = "Standard";
                            }
                            if (id == 3) {
                                certPackage = "Premium";
                            }
                            this.balanceCertificateDetails =
                                this.user.extra_course.title +
                                " (" +
                                certPackage +
                                " certificate)";
                            this.payForCertificateMessage =
                                "Kindly balance up to download your second certificate";
                        }
                        if (data.extra_certificate.status == "Not Paid") {
                            this.payForCertificateMessage =
                                "Kindly make payment to download your second certificate";
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        checkQualifyCertificate() {
            //  Check if user has paid for certificate
            axios
                .get("/certificate")
                .then((response) => {
                    this.isLoading = false;
                    this.certificates = response.data.payload;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.errorMessage = err.response.data.payload;
                    console.log(err.response.data.payload);
                });
        },
        async getJobbermanCertificate() {
            await axios
                .get("/certificate/jobberman")
                .then((response) => {
                    this.jobbermanCertificateAvailable = true;
                    this.jobbermanMessage = "Download";
                    this.jobbermanCertificateFile = response.data.payload.file;
                })
                .catch((err) => {
                    this.jobbermanCertificateAvailable = false;
                    this.jobbermanCertificateFile = null;
                    this.jobbermanMessage = err.response.data.payload;
                });
        },
        async handleJobbermanCertDownload() {
            if (this.jobbermanMessage != "Download") {
                return;
            }
            let file = this.jobbermanCertificateFile; // convert the file to blob for download
            // return
            let filename =
                this.user.last_name + "_" + this.user.first_name + "_Jobberman"; //name to save the file as
            try {
                if (window.navigator.msSaveOrOpenBlob) {
                    // IE10+
                    window.navigator.msSaveOrOpenBlob(file, filename);
                } else {
                    // Others
                    var a = document.createElement("a");
                    a.href = file;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function() {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
                this.downloadJobbermanCertificate();
            } catch (error) {
                this.downloadJobbermanCertificate();
            }
        },
        async downloadJobbermanCertificate() {
            await axios
                .get("/certificate/jobberman/download")
                .then()
                .catch((err) => {
                    console.log(err);
                });
        },
    },

    mounted() {
        this.checkQualifyCertificate();
        this.getJobbermanCertificate();
        this.getPaymentStatus();
    },
};
</script>

<style lang="css" scoped>
.card .card-title {
    font-size: 18px !important;
    color: #242424;
}
.card .sub-text {
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    color: #7a7a7a;
}
.card .card-btn {
    padding: 12px 18px;
    background: #044995;
    border-radius: 5px;
    font-weight: 550;
    font-size: 10px;
    line-height: 11px;
    color: #ffffff;
}
.card .card-btn-success {
    padding: 12px 18px;
    background: #fff;
    border: 0.747761px solid #044995;
    border-radius: 5px;
    font-weight: 550;
    font-size: 10px;
    line-height: 11px;
    color: #044995;
}

.card {
    background-color: #f9fafa;
    background-image: url("./../../assets/img/line/line2.png");
    background-size: contain;
    border: 1.4966px solid rgba(0, 0, 0, 0.1);
    background-position: top right;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border-radius: 14.966px;
    box-shadow: none;
}

.cert_btn i {
    margin-left: 15px;
}
.grey {
    color: #606060;
}
.ash {
    color: #a7a5a5;
}
.lesson-title p {
    font-weight: 550;
    font-size: 20px !important;
    color: #606060;
}
.lesson-title button {
    padding: 5px !important;
    background: #b3e6b2;
    border-radius: 100%;
    font-weight: 550;
    font-size: 10px;
    letter-spacing: 0.04em;
    color: #52a350;
}

@media screen and (max-width: 768px) {
    .card {
        border-radius: 10px;
    }
    .cert_btn {
        font-size: 10px;
        font-weight: 600;
        padding: 5px 10px;
        margin-right: 3px;
    }
    .cert_btn i {
        margin-left: 8px;
    }
    .page-title {
        font-weight: 500;
        transform: rotate(0deg);
        writing-mode: horizontal-tb;
        text-align: left;
        font-size: 16px;
    }
    .lesson-title p {
        font-size: 14px !important;
    }
    .lesson-title button {
        padding: 2px 15px;
        min-width: fit-content;
        border-radius: 25px;
        font-size: 6px;
    }
}
</style>
