<template>
    <div
        style="font-size: 14px !important;color: #A7A5A5;"
        class="mb-3"
        v-if="mentors.length > 0"
    >
        {{ mentors.length > 1 ? "MENTORS" : "MENTOR"}}
    </div>
    <div class="d-flex mb-3 mb-lg-4" v-for="mentor in mentors" :key="mentor.id">
        <img
            class="mr-3 rounded-circle"
            :src="mentor.avatar"
            style="height:50px; width:50px"
            :alt='mentor.first_name + " " + mentor.last_name'
        />
        <div>
            <p
                class="m-0"
                style="color: #606060;font-size: 14px;"
            >
                <b>{{ mentor.first_name + " " + mentor.last_name }}</b>
            </p>
            <p
                class="m-0"
                style="font-size:10px !important;letter-spacing: 0.04em;color: #A7A5A5;"
            >
                {{ mentor.course }}
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {

    props:{
        mentors: [Array]
    },

    data() {
        return {
            loading : true
        }
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

};
</script>

<style lang="css" scoped>
img {
    fill: red;
}
p {
    font-size: unset;
    line-height: unset;
}

.lesson-video {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 20px 20px;
}

.video-title {
    font-weight: 550;
    padding-left: 3rem;
    color: #606060;
}

.active-tab {
    font-weight: 550;
    font-size: 12px;
    color: #08386d;
    border-bottom: #08386d 0.6px solid;
    margin-bottom: 0px;
}

.tabs {
    font-size: 12px;
    cursor: pointer;
    color: #a8a8a8;
    border-bottom: 0.6px solid rgba(0, 0, 0, 0.15);
}

.lesson-description {
    font-weight: 550;
    font-size: 20px;
    color: #606060;
}

.lesson-time {
    font-weight: 400;
    font-size: 10px !important;
    color: #606060;
}

.lesson-mentor {
    border-right: 0.6px solid rgba(0, 0, 0, 0.15);
}

.chat-question {
    font-size: 10px;
    line-height: 14px;
}

.comments {
    background: #fdfdfd;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 8px;
}

.intern-name {
    font-weight: 500;
    font-size: 12px !important;
    line-height: 15px;
    color: #606060;
}

.intern-id {
    font-size: 8px !important;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #a7a5a5;
}

.extra-resources p {
    font-size: 12px !important;
}
/*  */
/*  */
/* Mobile Responsive Styling */
@media screen and (max-width: 768px) {
    .page-title {
        font-weight: 500;
        transform: rotate(0deg);
        writing-mode: horizontal-tb;
        text-align: left;
        font-size: 16px;
    }

    .video-title {
        padding-left: 1rem;
        font-size: 11px;
    }

    .lesson-description {
        font-weight: 500;
        font-size: 16px !important;
        line-height: 18px;
        color: #606060;
    }

    .lesson-mentor {
        border-bottom: 0.6px solid rgba(0, 0, 0, 0.15);
        border-right: 0;
    }

    .tabs {
        font-size: 10px;
    }

    .active-tab {
        margin-bottom: -1px;
    }
}
</style>
