<template>
    <div class="d-flex pl-4">
        <div class="mr-2" @click="$router.go(-1)">
            <i class="fas fa-arrow-left"></i>
        </div>
        <h5
            class="pl-3"
            style="font-weight: 300;font-size: 16px;line-height: 22px; color: #242424 !important;"
        >
            Quiz
        </h5>
    </div>
    <div class="pl-4 mt-3">
        <div class="mb-0">
            <h5 class="mb-0">Available quizzes for {{ track }}</h5>
        </div>
        <br />
        <p v-if="loader && !errorMessage && !wk">
            <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
        </p>
        <button v-if="wk && !loader"
            @click="quizIntro(c_id, track, wk)"
            class="my-2 mx-2 bg-success  text-light rounded"
        >
            Week {{ wk }}
        </button>
        <p v-if="!loader && errorMessage">{{errorMessage}}</p>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
import axios from "axios";
export default {
    data() {
        return {
            wk: null,
            loader: true,
            errorMessage: null
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
    },
    props: ["c_id", "track"],
    methods: {
        quizIntro(id, title, wk) {
            this.$router.push({
                name: "QuizStart",
                params: {
                    c_id: id,
                    track: title,
                    quiz_week: wk,
                },
            });
        },
        checkForAvailableQuiz(){
            let data = {
                course_id: this.c_id,
                week: this.week,
            };
            axios
                .post("quiz/check", data)
                .then((res) => {
                    this.wk = res.data.payload.week
                    this.loader = false
                })
                .catch((error) => {
                    console.log(error)
                    this.loader = false
                    this.errorMessage = 'Quiz Not Available'
                });
        }
    },
    mounted() {
        if (this.c_id === undefined) {
            this.$router.push({ name: "Quiz" });
        }
        this.checkForAvailableQuiz()
    },
};
</script>

<style lang="css" scoped></style>
