<template>
    <div class="offset-md-1 col-md-10 mt-5 p-0">
        <div class="row mt-4 progress-con">
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show active">1</span>
                </div>
                <div
                    class="mt-2"
                    style="font-style: italic;background: #F1F8F1;box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);border-radius: 3px;font-size: 10px;color: #4F4F4F;padding:10px 10px;font:Nunito; position:absolute;left:0;right:0;"
                >
                    Hey Champ! you can do this! 💪
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show">2</span>
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show ">3</span>
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container"><span class="ball">4</span></div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 mx-lg-auto mt-5 mb-4 mb-lg-5 p-0">
        <p
            class="font-weight-bold d-block select-text text-left text-sm-center interest"
        >
            Choose a track
        </p>
    </div>
    <div class="row">
        <div class="col-md-3 col-6  mb-3">
            <div
                class="benefits-card2 pl-3 pt-4 pb-4 pr-3 programming"
                :class="{ selected: selectedText == 'programming' }"
                @click="programming()"
            >
                <img
                    class="mb-4 d-none d-lg-block"
                    src="@/assets/img/benefits/network2.png"
                />
                <div class="benefits-card-title2 mb-3 pl-lg-2">Engineering</div>
                <div class="benefits-card-details2 pl-lg-2">
                    Tracks in this category, which are related to software
                    engineering and mobile application development, require the
                    use of a laptop. These are tracks in which interns write
                    code, design, build, and test websites or software
                    applications to meet the needs of users.
                </div>
            </div>
        </div>

        <div class="col-md-3 col-6  mb-3">
            <div
                class="benefits-card2 pl-3 pt-4 pb-4 pr-3 design"
                :class="{ selected: selectedText == 'design' }"
                @click="design()"
            >
                <img
                    class="mb-4 d-none d-lg-block"
                    src="@/assets/img/benefits/network3.png"
                />
                <div class="benefits-card-title2 mb-3 pl-lg-2">Design</div>
                <div class="benefits-card-details2 pl-lg-2">
                    This category includes tracks that involve developing
                    materials, strategies, and techniques to promote, advertise,
                    or sell products and services to a specific audience. They
                    also necessitate the use of a laptop computer.
                </div>
            </div>
        </div>

        <div class="col-md-3 col-6  mb-3">
            <div
                class="benefits-card2 pl-3 pt-4 pb-4 pr-3 product"
                :class="{ selected: selectedText == 'product' }"
                @click="product()"
            >
                <img
                    class="mb-4 d-none d-lg-block"
                    src="@/assets/img/benefits/network4.png"
                />
                <div class="benefits-card-title2 mb-3 pl-lg-2">Product</div>
                <div class="benefits-card-details2 pl-lg-2">
                    This category includes tracks that focus on conceptualizing,
                    building, managing, and scaling products that users love
                    while also considering the user interfaces and experience.
                    They also necessitate the use of a laptop.
                </div>
            </div>
        </div>
        <div class="col-md-3 col-6">
            <div
                class="benefits-card2 pl-3 pt-4 pb-4 pr-3 admin"
                :class="{ selected: selectedText == 'adminandmarketing' }"
                @click="admin()"
            >
                <img
                    class="mb-4 d-none d-lg-block"
                    src="@/assets/img/benefits/network5.png"
                />
                <div class="benefits-card-title2 mb-3 pl-lg-2">Marketing</div>
                <div class="benefits-card-details2 pl-lg-2">
                    This category includes tracks that involve instilling in
                    interns a sense of responsibility for the success of any
                    product/project assigned to them by strategically driving
                    tactics to generate potential solutions to problems.They
                    also necessitate the use of a laptop computer.
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 my-5 d-flex justify-content-center">
        <span class="mb-0 text-center font-weight-bold mx-auto skip" @click="allCourses()">
            See All Courses
        </span>
    </div>
      <!-- floating whatsapp btn -->
        <a
            class="floating-cta text-center px-2 text-success"
            href="https://wa.me/message/HX6JWNMRVERVO1"
            target="_blank"
            ><i class="fab fa-whatsapp fa-3x" style="font-size:4rem"></i
            ><br />Chat</a
        >
    <div class="form-group mt-lg-5 col-12 col-lg-6 offset-lg-3 p-0">
        <button
            type="submit"
            class="btn btn-submit py-lg-2 px-4 btn-block"
            style="color:#fff; box-shadow:none"
            :disabled="!submitted"
            v-html="buttonText"
            @click="submit"
        ></button>
    </div>
    <!-- <div class="col-12 mt--lg-4 mb-5">
        <span class="mb-0 col-12 text-center d-block skip" >
            <a href="#" class="skip">Skip for now </a>
        </span>
    </div> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
import axios from "axios";

export default {
    data() {
        return {
            buttonText: "Proceed",
            submitted: false,
            selectedText: "",
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Dashboard";
        },
    },

    methods: {
        programming() {
            this.selectedText = "programming";
            this.submitted = true;
        },
        design() {
            this.selectedText = "design";
            this.submitted = true;
        },
        product() {
            this.selectedText = "product";
            this.submitted = true;
        },
        admin() {
            this.selectedText = "adminandmarketing";
            this.submitted = true;
        },
        allCourses() {
            this.selectedText = "allCourses";
            this.submitted = true;
            this.submit()
        },

        submit() {
            if (this.submitted) {
                this.$router.push({
                    path: "Second",
                    query: { Category: this.selectedText },
                });
            } else {
                swal.fire("Error", "Please, select a category", "error");
            }
        },
    },
};
</script>

<style scoped>
.benefits-card2.selected {
    border: 3px solid #52a350;
}
.benefits-card2 {
    height: 100%;
}
.skip{
    cursor: pointer;
}
</style>
