<template>
    <div class="m-0 p-0">
        <form @submit.prevent="handleUpdates" class="edit-profile">
        <!-- <form @submit.prevent="editProfile" class="edit-profile"> -->
            <input
                class="input-fields"
                type="text"
                v-model="first_name"
                @keyup="editChange=true"
                aria-readonly="true"
            />
            <input
                class="input-fields"
                type="text"
                v-model="last_name"
                @keyup="editChange=true"
                aria-readonly="true"
            />            
            <input
                class="input-fields"
                type="email"
                v-model="email"
                @keyup="editChange=true"
                aria-readonly="true"
            />
            <input
                class="input-fields"
                type="password"
                v-model="old_pw"
                aria-readonly="true"
                @keyup="editChange=true"
                placeholder="Enter your old password"
            />  
            <input
                class="input-fields"
                type="password"
                v-model="new_pw"
                aria-readonly="true"
                @keyup="editChange=true"
                placeholder="Enter your new password"
            />  
            <button class="col-12 mt-3 update-profile-btn" :disabled="!editChange">Submit</button>
        </form>
    </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";
import profilepic from "@/components/dashboard/profilePic.vue";

export default {
    components: { profilepic },
    data() {
        return {
            last_name: "",
            first_name:"",
            email: "",
            old_pw: "",
            new_pw:"",
            editChange: false,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
        handleUpdates(){
            this.updateEmail()
            this.editProfile()
        },
        async updateEmail() {
            let data = {
                email: this.email
            };
            try {
                let response = await axios.post("user/email/update", data);
            } catch (error) {
                //
            }
        },
        async editProfile() {
            let data = {
                first_name: this.first_name,
                last_name: this.last_name,
            };
            if (this.old_pw.length > 5) {
                data.oldPassword = this.old_pw;
                data.password = this.new_pw;
                data.password_confirm = this.new_pw;
            }
            try {
                let response = await axios.post("user/update", data);
                if (response.status === 200 && response.data.status) {
                    this.attempt(response.data.payload.token);
                    swal.fire({
                        title: "Updated",
                        text: "Profile has been updated successfully",
                        icon: "success",
                    });
                }
            } catch (error) {
                swal.fire({
                    title: "Failed",
                    text:
                        "Your profile can not be updated now, please try again",
                    icon: "error",
                });
            }
        },
    },
    mounted(){
        this.last_name = this.user.last_name;
        this.first_name = this.user.first_name;
        this.email = this.user.email;
    }
};
</script>

<style scoped>
.edit-profile .input-fields {
display: block;
width: 100%;
background: #ffffff !important;
border: 1px solid #d5d8d5;
border-radius: 8px;
font-family: Nunito;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: #5f5f5f !important;
margin-bottom: 10px;
padding: 5px 15px;
}
.update-profile-btn{
background: #044995;
border-radius: 8px;
color: #fff;
padding:8px 0;
}
.update-profile-btn:disabled{
background: #E9E9E9;
border: 2px solid #DEDEDE;
color: #242424;
padding: 8px 0;
}
</style>
