<template>
    <div class="offset-md-1 col-md-10 mt-5 p-0">
        <div class="row mt-4 progress-con">
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show completed">1</span>
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show completed">2</span>
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show active">3</span>
                </div>
                <div
                    class="mt-2"
                    style="font-style: italic;background: #ECF6FD;box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);border-radius: 3px;font-size: 10px;color: #4F4F4F;padding:10px 10px;font:Nunito; position:absolute;left:0;right:0;"
                >
                    You’re almost there, don’t give up now😎
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container"><span class="ball">4</span></div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 mx-auto mt-5 p-0">
        <p
            class="text-left text-sm-center font-weight-bold d-block select-text mb-1 mb-lg-3"
        >
            Create a BloommNow account
        </p>
        <p class="text-left text-sm-center boomm-about">
            Please follow the link to download and create a BloommNow account in
            order to complete your registration. BloommNow is our financial
            partner for this cohort of the internship, which allows us to offer
            this internship to all applicants for free.
        </p>
        <!-- floating whatsapp btn -->
        <a
            class="floating-cta text-center px-2 text-success"
            href="https://wa.me/message/3L72YMURS3U7D1"
            target="_blank"
            ><i class="fab fa-whatsapp fa-3x" style="font-size:4rem"></i
            ><br />Chat</a
        >
    </div>

    <div class="form-group mt-5 col-12 col-lg-6 offset-lg-3 p-0">
        <button
            v-if="false"
            type="submit"
            class="btn btn-download-boom py-lg-2 px-4 btn-block btn-success"
            style="color:#fff !important; box-shadow:none"
            target="_black"
            @click="submit()"
        >
            Download your Bloomm bank app and Create an account
        </button>
        <router-link
            :to="{ name: 'Onboard' }"
            class="btn btn-download-boom py-lg-2 px-4 btn-block btn-success"
            style="color:#fff !important; box-shadow:none"
            target="_black"
            v-if="true"
        >
            Skip
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import axios from "axios";

export default {
    data() {
        return {
            buttonText: "Proceed",
            submitted: false,
            selectedText: "",
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods: {
        // submit() {
        //     window.open("https://bit.ly/3K8s7IH", "_blank");
        //     this.$router.push({ path: "Four", query: { continue: "true" } });
        // },
    },

    mounted() {
        if (this.user.course_id == null || this.user.course_id == "") {
            this.$router.push({ path: "Second" });
        }
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Dashboard";
        },
    },
};
</script>

<style scoped>
.benefits-card2.selected {
    border: 3px solid #52a350;
}

.boomm-about {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #898989;
}

.btn-skip-boom {
    font-family: "Nunito", sans-serif;
    background: #e9e9e9 !important;
    color: #242424 !important;
    border: 2px solid #dedede !important;
    box-sizing: border-box;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    .boomm-about {
        font-size: 10px !important;
        line-height: 14px;
    }

    .btn-download-boom {
        font-size: 12px;
        line-height: 16px;
    }
    .btn-skip-boom {
        font-size: 12px;
        line-height: 16px;
    }
    .btn-block {
        padding: 20px 5px !important;
    }
}
</style>
