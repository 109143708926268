<template>
    <div class="row">
        <div class="col-lg-8 mx-auto ">
            <div class="container">
                <form @submit.prevent="addClass">
                    <div class="row flex-column col-lg-7 mx-auto mt-5">
                        <div>
                            <div class="form-group mb-4">
                                <label for="course" class="font-weight-bold"
                                    >Select a course</label
                                >
                                <select
                                    name="course"
                                    id="course"
                                    required
                                    class="custom-select-lg col-12 d-block"
                                    v-model="course_id"
                                >
                                    <option value="">Select course</option>
                                    <option
                                        v-for="c in courses"
                                        :key="c.id"
                                        :value="c.id"
                                        >{{ c.title }}</option
                                    >
                                </select>
                            </div>

                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="courseTitle"
                                    placeholder="Enter class title e.g Prototyping a Mobile and Web Design"
                                    v-model="title"
                                />
                            </div>
                            <div class="form-group mb-4">
                                <label for="question"
                                    >Description
                                </label>
                                <textarea
                                    class="form-control"
                                    id="question"
                                    rows="4"
                                    v-model="description"
                                    placeholder="Tell us about the video"
                                ></textarea>
                            </div>

                            <div class="form-group">
                                <label for="link"
                                    >Enter Link to recorded class video
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="link"
                                    v-model="link"
                                    placeholder="E.g https://drive/f/fjhidiodifodfidf/djf"
                                />
                            </div>

                            <div class="form-group mb-4">
                                <select
                                    name="day"
                                    id="day"
                                    required
                                    v-model="day"
                                    class="custom-select-lg col-12 d-block"
                                >
                                    <option value="">Select Day</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                    <option value="Sunday">Sunday</option>
                                </select>
                            </div>

                            <div class="form-group mb-4">
                                <select
                                    name="week"
                                    id="week"
                                    required
                                    class="custom-select-lg col-12 d-block"
                                    v-model="week"
                                >
                                    <option value="">Select week</option>
                                    <option value="1">Week 1</option>
                                    <option value="2">Week 2</option>
                                    <option value="3">Week 3</option>
                                    <option value="4">Week 4</option>
                                    <option value="5">Week 5</option>
                                    <option value="6">Week 6</option>
                                </select>
                            </div>
                        </div>

                        <button class="btn btn-primary" type="submit">
                            Add
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import swal from "sweetalert2";

export default {
    data() {
        return {
            title: "",
            description: "",
            link: "",
            day: "",
            course_id: "",
            week: "",
        };
    },

    computed: {
        ...mapGetters({
            courses: "auth/courses",
        }),
    },

    methods: {
        ...mapActions({
            fetchCourses: "auth/fetchCourses",
        }),

        async addClass() {
            if (
                this.title !== "" &&
                this.description !== "" &&
                this.link !== "" &&
                this.day !== "" &&
                this.course_id !== "" &&
                this.week !== ""
            ) {
                let data = {
                    title: this.title,
                    description: this.description,
                    link: this.link,
                    day: this.day,
                    course_id: this.course_id,
                    week: this.week,
                };
                try {
                    let response = await axios.post("admin/add-record-class", data);
                    if (response.status === 200 && response.data.status) {
                        swal.fire({
                            title: "Class Added",
                            text:
                                "Live Class has been added successfully. Do you want to add a new one?",
                            icon: "success",
                            confirmButtonText: "Yes",
                            showCancelButton: true,
                            cancelButtonText: "No",
                        }).then((e) => {
                            if (!e.isConfirmed) {
                                this.$router.push({ name: "Dashboard" });
                            } else {
                                this.title = "";
                                this.description = "";
                                this.link = "";
                                this.day = "";
                                this.course_id = "";
                                this.week = "";
                            }
                        });
                    }
                } catch (error) {
                    swal.fire({
                        title: "Error",
                        text:
                            "An error has occured, make sure you fill in the required fields and try again",
                        icon: "error",
                    });
                }
            } else {
                swal.fire({
                    title: "Error",
                    text: "All fields are required",
                    icon: "error",
                });
            }
        },
    },

    mounted() {
        if (this.courses.length === 0) {
            this.fetchCourses();
        }
    },
};
</script>

<style></style>
