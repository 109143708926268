<template>
    <div
        class="container-fluid px-1 sh-text-primary start"
        style="background-color: #fff;"
    >
        <Navbar />
        <div class="div-row">
            <div class="side p-5 ml-lg-5">
                <div class="mt-5">
                    <h2 class="hero-heading mt-4 pt-5 ">
                        Become a Side Hustle Intern
                    </h2>
                    <!-- <div style="font-size:14px">
                        If you previously registered on the Side Hustle
                        Internship platform, please enter your information once
                        more as you will need to re-register for each cohort.
                    </div> -->
                </div>
                <form class="py-3" @submit.prevent="register">
                    <fieldset>
                        <div class="row mt-4 no-gutters">
                            <div
                                class="form-group mb-4 col-12 col-lg-6 p-0 px-lg-2"
                            >
                                <input
                                    type="text"
                                    name="firstName"
                                    required
                                    class="form-control py-4"
                                    id="firstName"
                                    placeholder=" "
                                    v-model="first_name"
                                    @keyup="v$.first_name.$touch"
                                />
                                <label
                                    for="firstName"
                                    class="label font-weight-bold"
                                    >First Name</label
                                >
                                <span
                                    v-if="v$.first_name.$error"
                                    class="text-danger font-weight-normal"
                                    ><i class="fas fa-exclamation-triangle"></i> First name is required</span
                                >
                            </div>
                            <div
                                class="form-group mb-4 col-12 col-lg-6 p-0 px-lg-2"
                            >
                                <input
                                    type="text"
                                    required
                                    name="lastName"
                                    class="form-control py-4"
                                    id="lastName"
                                    placeholder=" "
                                    v-model="last_name"
                                    @keyup="v$.last_name.$touch"
                                />
                                <label for="lastName" class="label font-weight-bold"
                                    >Last Name</label
                                >
                                <span
                                    v-if="v$.last_name.$error"
                                    class="text-danger font-weight-normal"
                                    ><i class="fas fa-exclamation-triangle"></i> Last name is required</span
                                >
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div
                                class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                            >
                                <input
                                    type="email"
                                    class="form-control py-4"
                                    id="email"
                                    name="email"
                                    placeholder=" "
                                    v-model="email"
                                    @keyup="v$.email.$touch"
                                    @blur="checkEmail"
                                />
                                <label for="email" class="label font-weight-bold"
                                    >Email</label
                                >
                                <span
                                    v-if="v$.email.$error"
                                    class="text-danger font-weight-normal"
                                    ><i class="fas fa-exclamation-triangle"></i> Enter a valid email address</span
                                >
                                <span
                                    v-show="emailState && !v$.email.$error"
                                    class="text-danger font-weight-normal"
                                    ><i class="fas fa-exclamation-triangle"></i> Email already registered to an account</span
                                >
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div
                                class="form-group mb-4 col-12 col-lg-6 p-0 px-lg-2"
                            >
                                <input
                                    type="text"
                                    name="phone"
                                    required
                                    class="form-control py-4"
                                    id="phoneNumber"
                                    placeholder=" "
                                    v-model="phone"
                                    @blur="checkPhone"
                                />
                                <label
                                    for="phoneNumber"
                                    class="label font-weight-bold"
                                    >Phone Number</label
                                >
                                <span
                                    v-show="v$.phone.$error"
                                    class="text-danger font-weight-normal"
                                    ><i class="fas fa-exclamation-triangle"></i> Please enter a valid phone number</span
                                >
                                <span
                                    v-if="phoneState"
                                    class="text-danger font-weight-normal d-block"
                                    ><i class="fas fa-exclamation-triangle"></i> Phone number already registered to an account</span
                                >
                            </div>
                            <div
                                class="form-group mb-4 col-12 col-lg-6 p-0 px-lg-2"
                            >
                                <div class="row country-div m-0">
                                    <!-- <div class="col-md-3 col-3">
                                        <country-flag :country='country' size='normal'/>
                                    </div> -->
                                    <div class="col-md-12 col-12 p-0">
                                        <select
                                            name="country"
                                            id="country"
                                            required
                                            class="custom-select-lg col-12 d-block"
                                            v-model="country"
                                            v-if="countries.length > 0"
                                            @blur="checkPhone"
                                        >
                                            <option
                                                value="Country"
                                                disabled
                                                selected
                                                >Country of Residence</option
                                            >
                                            <option
                                                v-for="c in countries"
                                                :key="c.nicename"
                                                :value="c.nicename"
                                                >{{ c.nicename }}</option
                                            >
                                        </select>
                                    </div>
                                </div>
                                <span
                                    v-if="v$.country.$error"
                                    class="text-danger font-weight-bold"
                                    ><i class="fas fa-exclamation-triangle"></i>Please select a country</span
                                >
                                <span
                                    v-show="countryState"
                                    class="text-danger font-weight-normal"
                                    >Please select your country</span
                                >
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div
                                class="form-group mb-4 col-12 col-lg-6 p-0 px-lg-2"
                            >
                                <select
                                    name="education"
                                    id="education"
                                    required
                                    class="custom-select-lg form-control col-12 d-block"
                                    v-model="education"
                                    @change="v$.education.$touch"
                                >
                                    <option
                                        value="Level of Education"
                                        disabled
                                        hidden
                                        >Level of Education</option
                                    >
                                    <option value="Undergraduate"
                                        >Undergraduate</option
                                    >
                                    <option value="Recent Graduate"
                                        >Recent Graduate</option
                                    >
                                    <option value="Youth Corp Member"
                                        >Youth Corp Member</option
                                    >
                                    <option value="Post NYSC">Post NYSC</option>
                                </select>
                                <span
                                    v-if="v$.education.$error"
                                    class="text-danger font-weight-normal"
                                    ><i class="fas fa-exclamation-triangle"></i> Please select your educational level</span
                                >
                            </div>
                            <div
                                class="form-group mb-4 col-12 col-lg-6 p-0 px-lg-2"
                            >
                                <select
                                    name="hear_about_us"
                                    id="hear_about_us"
                                    class="custom-select-lg form-control col-12 d-block"
                                    v-model="hear_about_us"
                                >
                                    <option
                                        value="How did you hear about us?"
                                        disabled
                                        hidden
                                        >How did you hear about us?</option
                                    >
                                    <option value="Facebook">Facebook</option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Whatsapp">Whatsapp</option>
                                    <option value="Google">Google</option>
                                    <option value="Telegram">Telegram</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div
                                class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                            >
                                <div class="position-relative">
                                    <input
                                        id="password"
                                        :type="isPassword ? 'text' : 'password'"
                                        class="form-control py-4"
                                        name="password"
                                        placeholder=" "
                                        v-model="password"
                                        @keyup="v$.password.$touch"
                                    />
                                    <label
                                        for="password"
                                        class="label font-weight-bold"
                                        >Create Password</label
                                    >
                                    <div
                                        class="input-group-prepend"
                                        style="position: absolute;right: 10px;top: 0px;bottom: 0px;cursor: pointer;"
                                        @click="showPassword"
                                    >
                                        <span
                                            class="input-group-text"
                                            style="background:transparent;border:none;z-index:5"
                                            ><i :class="icon"></i
                                        ></span>
                                    </div>
                                </div>
                                <span
                                    v-if="v$.password.$error"
                                    class="text-danger font-weight-normal"
                                    ><i class="fas fa-exclamation-triangle"></i> Password is required</span
                                >
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div
                                class="form-group mb-4 col-12 col-lg-12 p-0 px-lg-2"
                            >
                                <div class="position-relative">
                                    <input
                                        :type="isPassword2 ? 'text' : 'password'"
                                        class="form-control py-4"
                                        id="cpassword"
                                        placeholder=" "
                                        v-model="password_confirm"
                                        @keyup="v$.password_confirm.$touch"
                                    />
                                    <label
                                        for="cpassword"
                                        class="label font-weight-bold"
                                        >Confirm Password</label
                                    >
                                    <div
                                        class="input-group-prepend"
                                        style="position: absolute;right: 10px;top: 0px;bottom: 0px;cursor: pointer;"
                                        @click="showPassword2"
                                    >
                                        <span
                                            class="input-group-text"
                                            style="background:transparent;border:none;z-index:5"
                                            ><i :class="icon2"></i
                                        ></span>
                                    </div>
                                </div>
                                <span
                                    v-if="v$.password_confirm.$error"
                                    class="text-danger font-weight-normal"
                                    ><i class="fas fa-exclamation-triangle"></i> Password does not match</span
                                >
                                <span
                                    v-else-if="password_confirm == password && password.length>5"
                                    class="text-success font-weight-normal pt-3 mt-5"
                                    ><i class="fas fa-check-circle"></i> Password match</span
                                >
                        
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="form-group mb-3 p-0  col-12 col-lg-12 p-0">
                                <label class="checkcontainer mt-4"
                                    >I agree to Side Hustle’s
                                    <router-link :to="{ name: 'TermsConditions' }"
                                        ><span
                                            class="font-weight-normal text-success"
                                            >Terms and Conditions</span
                                        ></router-link
                                    >
                                    and
                                    <router-link :to="{ name: 'Privacy' }"
                                        ><span
                                            class="font-weight-normal text-success"
                                            >Privacy Policy</span
                                        ></router-link
                                    >
                                    <input
                                        type="checkbox"
                                        required
                                        v-model="agree"
                                        checked="checked"
                                    />
                                    <span class="checkmark"></span>
                                </label>
                                <span
                                    v-if="v$.agree.$error"
                                    class="text-danger font-weight-bold"
                                    >Please select this field</span
                                >
                            </div>
                            <div
                                class="form-group mt-3 col-12 col-lg-12 p-0 px-lg-2"
                            >
                                <button
                                    type="submit"
                                    class="btn btn-submit py-2 px-4 btn-block"
                                    style="color:#fff; box-shadow:none"
                                    v-html="buttonText"
                                    :disabled="submitted"
                                ></button>
                            </div>
                            <div class="row no-gutters">
                                <div class="form-group col-12 col-lg-12 p-0">
                                    <p class="text-center mt-3" style="color:grey">
                                        Already have an account?
                                        <router-link
                                            class="text-success"
                                            :to="{ name: 'Login' }"
                                            >Login here</router-link
                                        >
                                    </p>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="main d-none d-lg-block">
                <!-- <div class="log-container">
                    <div class="log-vertical-center"></div>
                </div> -->
                <div class="col-7 mx-auto mt-5 pt-5">
                    <img
                        class="img-fluid"
                        src="@/assets/img/reg.png"
                        style="margin-top: 100px;"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NProgress from "nprogress";
import swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import axios from "axios";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import Navbar from "../../../components/inc/SecondaryNavbar.vue";
import phoneValidator from "../../../services/phoneValid";
// custom vuelidate validation func
const pickCountry = (value) => value !== "Country";
const pickEducation = (value) => value !== "Level of Education";
const pickHear = (value) => value !== "How did you hear about us?";

export default {
    name: "Register",
    components: {
        Navbar,
    },

    data() {
        return {
            v$: useValidate(),
            icon: "fa fa-eye",
            icon2: "fa fa-eye",
            isPassword: false,
            isPassword2: false,
            email: "",
            countries: [],
            emailState: false,
            password: "",
            password_confirm: "",
            submitted: false,
            courses: [],
            agree: false,
            country: "Country",
            countryState: false,
            first_name: "",
            last_name: "",
            phone: "",
            phoneState: false,
            phoneError: "",
            education: "Level of Education",
            referral: "",
            course: "",
            hear_about_us: "How did you hear about us?",
            stipend: "",
            user_id: "",
            buttonText: "Join the Internship",
            canRegister: true,
        };
    },
    computed: {
        ...mapGetters({
            cou: "auth/course_id",
            user: "auth/user",
            getReferral: "auth/getReferral",
            settings: "auth/settings",
        }),
    },

    watch: {
        async countries() {
            if (this.countries.length < 0) {
                try {
                    let { data } = await axios.get("countries");
                    return data.payload;
                } catch (error) {
                    return [];
                }
            }
        },
    },

    validations() {
        return {
            email: { required, email },
            password: { required, minLength: minLength(6) },
            password_confirm: { required, sameAs: sameAs(this.password) },
            first_name: { required },
            last_name: { required },
            phone: { required },
            phoneState: { sameAs: sameAs(false) },
            education: { required, pickEducation },
            hear_about_us: { required, pickHear },
            country: { required, pickCountry },
            agree: { required },
        };
    },

    methods: {
        // Custom form validations
        //  axios.post("/validate", data).then((res) => {
        checkPhone() {
            if (this.country === "Country" || this.country === "") {
                return;
            }
            let selectedCountry = this.countries.find(
                (item) => item.nicename === this.country,
            );
            if (this.phone.length === 0) {
                this.phoneError = "Phone number field can not be empty";
                this.phoneState = true;
                return;
            }
            let res = phoneValidator(this.phone, selectedCountry.iso);
            if (res.status) {
                this.phone = res.phoneNumber;
                axios
                    .post("/validate", { type: "phone", phone: this.phone })
                    .then((res) => {
                        if (res.data.status === false) {
                            this.phoneState = false;
                        } else {
                            this.phoneError =
                                "Phone number has been registered";
                            this.phoneState = true;
                        }
                    });
            } else {
                this.phoneError = "Please enter a valid phone number";
                this.phoneState = true;
            }
        },
        // get all courses
        async getSelection() {
            await NProgress.start();
            await axios
                .get("selection")
                .then((response) => {
                    this.courses = response.data.courses;
                    this.countries = response.data.countries;
                    NProgress.done();
                })

                .catch(() => {
                    NProgress.done();
                });
        },

        ...mapActions({
            attempt: "auth/attempt",
        }),

        // Custom form validation
        checkEmail() {
            let data = { email: this.email, type: "email" };
            axios.post("/validate", data).then((res) => {
                this.emailState = res.data.status;
            });
        },

        // Show and Hide Password
        showPassword() {
            switch (this.icon) {
                case "fa fa-eye":
                    this.icon = "fa fa-eye-slash";
                    this.isPassword = true;
                    break;
                case "fa fa-eye-slash":
                    this.icon = "fa fa-eye";
                    this.isPassword = false;
                default:
                    this.icon = "fa fa-eye";
                    this.isPassword = false;
                    break;
            }
        },
        showPassword2() {
            switch (this.icon2) {
                case "fa fa-eye":
                    this.icon2 = "fa fa-eye-slash";
                    this.isPassword2 = true;
                    break;
                case "fa fa-eye-slash":
                    this.icon2 = "fa fa-eye";
                    this.isPassword2 = false;
                default:
                    this.icon2 = "fa fa-eye";
                    this.isPassword2 = false;
                    break;
            }
        },

        register() {
            //if registration has closed - show the user a modal
            if (this.settings && this.settings.registration_status == false) {
                swal.fire({
                    title: "Registration has closed",
                    html:
                        "The registration for the Side Hustle internship 5.0 has closed. Kindly join the 6.0 waitlist <a href='https://docs.google.com/forms/d/e/1FAIpQLSfUr8CDu8lS4cxzp3nyQGHlTPH9rbP0lMFCm1Pptc47IlNLmA/viewform'>here</a>. Thank You",
                    icon: "warning",
                    showConfirmButton: false,
                }).then((click) => {
                    this.$router.push({ name: "Home" });
                });
                return;
            }
            this.v$.$validate();
            if (!this.v$.$error && this.country != "country") {
                this.submitted = true;
                this.buttonText = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
                let data = {
                    email: this.email,
                    password: this.password,
                    country: this.country,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    phone: this.phone,
                    education: this.education,
                    referral: this.referral,
                    hear_about_us: this.hear_about_us,
                };

                axios
                    .post("/register", data)
                    .then((response) => {
                        if (response.data.message === "success") {
                            this.attempt(response.data.token).then(() => {
                                this.$router.push({
                                    name: "First",
                                });
                            });
                        } else {
                            this.submitted = false;
                            swal.fire(
                                "Error",
                                "Your registration can not be processed, please try again",
                                "error",
                            );
                        }
                    })
                    .catch((error) => {
                        this.submitted = false;
                        this.buttonText = "Join Internship ";
                        swal.fire(
                            "Error",
                            "Server error, we are currently working on resolving this error. Thanks",
                            "error",
                        );
                    });
            } else {
                this.submitted = false;
                this.buttonText = "Join Internship ";
                swal.fire(
                    "Error",
                    "The form has not been filled properly, please check and try again",
                    "error",
                );
            }
        },

        //get registration status and if registration has closed - show the user a modal
        getSiteSettings() {
            if (this.settings && this.settings.registration_status == false) {
                swal.fire({
                    title: "Registration has closed",
                    html:
                        "The registration for the Side Hustle internship 5.0 has closed. Kindly join the 6.0 waitlist <a href='https://docs.google.com/forms/d/e/1FAIpQLSfUr8CDu8lS4cxzp3nyQGHlTPH9rbP0lMFCm1Pptc47IlNLmA/viewform'>here</a>. Thank You",
                    icon: "warning",
                    showConfirmButton: false,
                }).then((click) => {
                    this.$router.push({ name: "Home" });
                });
                return;
            }
        },
    },

    mounted() {
        this.getSiteSettings();
    },
    created() {
        if (this.user) {
            this.$router.push({ name: "Dashboard" });
        }
        this.getSelection();
        //
        if (this.getReferral !== "") {
            this.referral = this.getReferral;
        }
    },
};
</script>

<style scoped>
.log-container {
    height: 200px;
    position: relative;
}

.log-vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.div-row {
    display: flex;
}

.side {
    flex: 40%;
    /* height: 100vh; */
    background-color: #fff;
}
.main {
    flex: 60%;
    padding-bottom: 50px;
    /* height: 100vh; */
    background: #fff;
}
.hero-heading {
    font-family: "GD Boing", sans-serif;
    font-weight: 600;
    font-size: 3.3rem;
    line-height: 4rem;
    color: #52a350;
}
.btn-submit {
    background: #52a350;
    border: 2px solid #52a350;
    border-radius: 10px;
}
.main::after {
    background: url(../../../assets/img/creat.png) no-repeat;
    background-size: auto;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0px;
    right: 0px;
}

.row.country-div {
    border: 2px solid #bdb;
    box-sizing: border-box;
    border-radius: 10px;
}

.flag {
    margin-top: 5px !important;
}

.form-control {
    border: 2px solid #bbddbb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0 15px;
    font-weight: 300;
    font-size: 15px !important;
    line-height: 22px;
    color: #858585 !important;
    background: #fff !important;
}

.form-check-label {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #858585;
}

.custom-select-lg {
    height: 50px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px !important;
    line-height: 22px;
    color: #b6b6b6 !important;
}

#country {
    background: transparent;
    border: 1px solid transparent;
}

.start {
    margin-top: 0px;
}
.in-p {
    margin: 20px 0;
    position: relative;
    display: inline-block;
}
.label {
    padding: 10px;
    pointer-events: none;
    position: absolute;
    left: 15px;
    top: 5px;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 1;
    background: #fff;
    font-style: normal;
    font-weight: 300;
    font-size: 15px !important;
    line-height: 22px;
    color: #b6b6b6 !important;
    background: #fff !important;
}

input:focus + .label,
input:not(:placeholder-shown) + .label {
    opacity: 1;
    z-index: 3;
    transform: scale(0.75) translateY(-70%) translateX(-14px);
    top: 0px;
    color: #52a350 !important;
}

.checkcontainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.checkcontainer {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    color: #858585;
    margin-left: 13px;
}

.checkcontainer a {
    color: #52a350;
    text-decoration: none;
}

.checkcontainer a:hover {
    text-decoration: none;
}

/* Hide the browser's default checkbox */
.checkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #52a350;
    border-radius: 2px;
    background-color: #fff;
}

/* On mouse-over, add a grey background color */
.checkcontainer:hover input ~ .checkmark {
    border: 2px solid #52a350;
    border-radius: 2px;
    background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkcontainer input:checked ~ .checkmark {
    border: 2px solid #52a350;
    border-radius: 2px;
    background-color: #52a350;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkcontainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkcontainer .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
    .hero-heading {
        font-family: "GD Boing", sans-serif;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2rem;
        color: #52a350;
    }
}
</style>
