<template>
    <div class="container-fluid col-12 px-xlg-100 px-sm-20 px-lg-30 pt-3 pt-md-3 pt-lg-0 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center hero">
        <div class="col-12 col-md-6 col-lg-8 mt-md-0 mt-2 pt-md-5 pt-1 d-flex flex-column justify-content-between align-items-start px-0">
                <div class="col-12 p-0 mt-3 mt-lg-3">
                    <div class="hero-alert px-3 px-md-2 py-2 pr-3 mx-auto mx-md-0 text-center"  v-if="settings.registration_status != 1">
                        <span class="bg-success py-1 text-light font-weight-bold mr-2 px-2 new d-none d-md-inline">NEW</span> Side Hustle 6.0 coming soon. <a href="https://forms.gle/qg9LbX6h8BxmWGVr6" target="_blank" class="text-success">Join Waitlist <i class="fas fa-arrow-right"></i></a>
                    </div>
                    <h1 class="hero-heading ml-0 p-0 mt-4 text-center text-sm-left">Building Africa’s Workforce</h1>
                    <p class="hero-text col-lg-8 mt-3 ml-0 p-0 text-center text-sm-left mt-5 mt-lg-4">
                        The kind of future we want for ourselves is easily attainable, but we must put forth 10X effort, beginning with equipping ourselves with the required in-demand skills to put us on the radar of employers all over the world.
                    </p>
                    <div class="p-0 join-now d-none d-lg-block">
                        <router-link :to="{name: 'Register'}">
                            <button
                                class="px-4 py-2 cta-btn d-block mt-5 mx-auto mx-lg-0 mt-lg-4"
                            >
                                Apply as an Intern
                            </button>
                        </router-link>
                    </div>
                </div>
                <div class="partners mt-4 col-12 p-0 d-none d-md-block">
                    <img class="m-0 p-0" style="max-width:25%" src='@/assets/img/partners/bloom.png'>
                    <img class="m-0 p-0" style="max-width:25%" src='@/assets/img/partners/jobberman.png'>
                    <img class="m-0 p-0" style="max-width:25%" src='@/assets/img/partners/toptalent.png'>
                </div>
            </div>
        <div
            class="container p-0 hero-img text-center text-sm-left mt-5"
            style=""
        >
            <img src="@/assets/img/hero-img2.png" class="row mt-lg-3" />
            <div class="jjj"></div>
            <div class="hero-img-name p-2">
                <div
                    class=""
                    style="color: #898989;font-size:0.65rem;line-height:1rem;font-weight:bold;"
                >
                   Micheal Adediran
                </div>
                <div
                    class="d-flex align-items-center mx-0 px-0"
                    style="color: #ADABAB;;font-size:0.55rem;line-height:1rem;"
                >
                    <country-flag country="ng" size="small" />
                    <span class="m-0">Lagos, Nigeria</span>
                </div>
            </div>
            <div
                class="hero-img-story p-2"
                style="font-size: 0.65rem;line-height: 1rem;color: #898989;"
            >
                “Joining the Side Hustle Internship is the best thing that
                happened to me this year”
            </div>
        </div>
        <div class="partners mt-5 col-12 p-0 pt-4 d-flex align-items-center justify-content-start d-md-none">
                    <div class="m-0 p-0" style="max-width:25%"><img class="" src='@/assets/img/partners/bloom.png'></div>
                    <div class="m-0 p-0" style="max-width:25%"><img class="" src='@/assets/img/partners/jobberman.png'></div>
                    <div class="m-0 p-0" style="max-width:25%"><img class="" src='@/assets/img/partners/toptalent.png'></div>
        </div>
        <!-- </div> -->
    </div>
</template>

<script>
import {  mapGetters } from "vuex";
import CountryFlag from "vue-country-flag-next";
export default {
    name: "Hero",
    computed: {
        ...mapGetters({
            settings: "auth/settings",
        }),
    },
    components: {
        CountryFlag,
    },
};
</script>

<style scoped>
.hero-alert{
background: #EFF1F6;
border-radius: 50px;
width: fit-content;
font-size: 14px;
}
.hero-alert .new{
border-radius: 25px;
font-size:9px
}
.arrow-1 {
    width: 50px;
    height: 1rem;
    display: inline-flex;
}
.arrow-1::before {
    content: "";
    background: currentColor;
    width: 25px;
    clip-path: polygon(
        0 45%,
        60% 45%,
        60% 30%,
        70% 50%,
        60% 70%,
        60% 55%,
        0 55%
    );
    animation: a1 1s infinite alternate-reverse;
}
@keyframes a1 {
    90%,
    100% {
        flex-grow: 1;
    }
}
/*  */
.hero {
    background-color: transparent;
    background-image: url('./../../assets/img/backgrounds/hero-bg.png');
    background-position: top right;
    background-size: 50%;
    background-repeat: no-repeat;
    /* padding-bottom: 100px; */
}

.hero-heading {
    font-family: "GD Boing", sans-serif;
    font-size: 70px;
    line-height: 85px;
    font-weight: 700;
    letter-spacing: 0.01em;
    color: #0E1B0D;
}
.hero-text {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #0E1B0D;
}
.cta-btn {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #e1f0e0;
    border-radius: 10px;
    transition: 800ms ease;
    background-color: #52A350;
}
.cta-btn:hover {
    background-color: #E1F0E0;
    color: #52A350;
}

.partners img {
    transform: scale(0.8);
}
.hero-img {
    height: 400px;
    max-width: fit-content;
    position: relative;
}
.hero-img img {
    height: 100%;
}
.jjj {
    content: "";
    background: #e1f0e0;
    border-radius: 50px;
    position: relative;
    bottom: -10px;
    right: 0;
    max-width: 100%;
    height: 50px;
    animation: a2 12000ms ease infinite;
}

@keyframes a2 {
    0%,
    100% {
        width: 50px;
        margin-left: unset;
        margin-right: auto;
    }
    20%,
    80% {
        width: 100%;
        margin-right: auto;
        margin-left: unset;
    }
    30%,
    70% {
        width: 100%;
        margin-left: auto;
        margin-right: unset;
    }
    50% {
        width: 50px;
        margin-left: auto;
        margin-right: unset;
    }
}
.hero-img-name {
    position: absolute;
    bottom: 25%;
    left: -13%;
    background: #fff;
    box-shadow: 8px 12px 50px rgba(56, 114, 54, 0.29);
    border-radius: 10px;
    padding: 5px 20px 5px 10px !important;
    animation: a3 ease 20000ms infinite;
}
.hero-img-story {
    position: absolute;
    bottom: 8%;
    right: -8%;
    width: 60%;
    background: linear-gradient(
        101.11deg,
        rgba(231, 242, 253, 0.88) 0%,
        #e7f2fd 86.7%
    );
    box-shadow: 8px 8px 18px rgba(56, 57, 55, 0.08);
    border-radius: 10px;
    animation: a4 ease 20000ms infinite;
}
@keyframes a3 {
    0%,
    100% {
        bottom: 35%;
        left: -15%;
    }
    25% {
        bottom: 25%;
        left: -30%;
    }
    50% {
        bottom: 15%;
        left: -25%;
    }
    75% {
        bottom: 25%;
        left: -15%;
    }
}
@keyframes a4 {
    0%,
    100% {
        bottom: 25%;
        right: -15%;
    }
    25% {
        bottom: 15%;
        right: -30%;
    }
    50% {
        bottom: 5%;
        right: -25%;
    }
    75% {
        bottom: 15%;
        right: -15%;
    }
}

@media only screen and (max-width: 578px) {
    .join-now {
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .hero-heading {
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .partners img {
        transform: scale(0.5);
        margin: 0;
        padding: 0;
    }

    .hero-img-name {
        bottom: 25%;
        left: -30%;
    }
    .hero-img-story {
        bottom: 8%;
        right: -30%;
        width: 60%;
    }
}
</style>
