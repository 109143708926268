<template>
    <div>
        <div class="card p-4 bg-white community-card compose">
            <div class="card-header p-0 bg-white">
                <div class="input-group search-group">
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <i class="fas fa-search mr-3"></i>
                        </div>
                    </div>
                    <input
                        type="text"
                        @keyup="search"
                        v-model="searchPostText"
                        class="form-control search-btn"
                        placeholder="Search for anything"
                    />
                </div>
            </div>

            <div class="card-body p-0 pt-5 pb-0">
                <div class="media">
                    <img
                        :src="
                            user.avatar
                                ? user.avatar
                                : '@/assets/img/avatar.png'
                        "
                        class="mr-3 rounded-circle"
                        :alt="user.first_name"
                        width="50"
                        height="50"
                    />
                    <div class="media-body">
                        <input
                            class="form-control post-input2"
                            @click="open"
                            readonly
                            placeholder="Ask your question here"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="addNew">
            <div
                class="modal-dialog modal-dialog-centered"
                style="max-width:600px"
            >
                <div
                    class="modal-content px-3 px-lg-4"
                    style="border-radius: 20px"
                >
                    <div class="modal-header pl-0">
                        <h4 class="modal-title">Ask your question</h4>
                    </div>
                    <form @submit.prevent="postQuestion">
                        <div class="modal-body pb-0">
                            <img
                                :src="
                                    user.avatar
                                        ? user.avatar
                                        : '@/assets/img/avatar.png'
                                "
                                class="mr-3 rounded-circle"
                                :alt="user.first_name"
                                width="70"
                                height="70"
                            />
                        </div>
                        <div class="modal-body">
                            <div class="form-group demo">
                                <Mentionable
                                    :keys="['@']"
                                    filtering-disabled
                                    :items="items"
                                    @apply="get($event)"
                                    @open="loadIssues()"
                                    @search="loadIssues($event)"
                                >
                                    <textarea
                                        v-model="post"
                                        @keyup="check"
                                        resize="false"
                                        rows="6"
                                        class="form-control post-input"
                                        placeholder="What would you like to ask the community? Use @ to mention"
                                    >
                                    </textarea>

                                    <template #no-result>
                                        <div class="dim">
                                            {{
                                                loading
                                                    ? "Loading..."
                                                    : "No result"
                                            }}
                                        </div>
                                    </template>

                                    <template #item-@="{ item }">
                                        <div class="user">
                                            {{
                                                item.username
                                            }}
                                            <span class="dim">
                                                {{item.role}}
                                            </span>
                                        </div>
                                    </template>
                                </Mentionable>
                            </div>
                        </div>
                        <div
                            class="modal-footer justify-content-end"
                            style="border-top:0px"
                        >
                            <button
                                type="submit"
                                class="btn btn-primary btn-course-take-active"
                                v-show="load"
                            >
                                Ask <i class="fas fa-angle-right"></i>
                            </button>
                            <button
                                type="submit"
                                disabled
                                class="btn btn-primary btn-course-take"
                                v-show="!load"
                            >
                                Ask <i class="fas fa-angle-right"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import swal from "sweetalert2";
import "v-tooltip/dist/v-tooltip.css";
import { Mentionable } from "vue-mention";
export default {
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    components: { Mentionable },

    emits: ["posted"],

    methods: {
        search() {
            this.$emit("searchPostNewText", this.searchPostText);
        },
        get(item) {
            this.selected.push(item);
        },
        async loadIssues(searchText = null) {
            this.loading = true;
            await axios
                .get("question/search", {
                    params: {
                        search: searchText,
                        room: "sh",
                    },
                })
                .then((response) => {
                    this.items = response.data.payload;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        open() {
            $("#addNew").modal("show");
        },

        check() {
            if (this.post.length > 0) {
                this.load = true;
            } else if (this.post.length < 1) {
                this.load = false;
            }
        },

        postQuestion() {
            this.load = false;
            let data = {
                post: this.post,
                user_id: this.user.id,
                selected: this.selected,
            };
            axios
                .post("post", data)
                .then((response) => {
                    (this.post = ""), (this.load = false);
                    $("#addNew").modal("hide");
                    this.$router.push({
                        name: "SingleBlog",
                        params: { id: response.data.payload.id },
                    });
                })
                .catch((error) => {
                    this.load = true;
                    let message = "";
                    if (error.response.status === 500) {
                        message =
                            "Post can not be made now, kindly exercise patience as are we trying to resolve it. Thanks";
                    } else {
                        message =
                            "Post can not be made now, kindly check if you have a good network and try again. Thanks";
                    }
                    swal.fire("Error", message, "error").then(() => {
                        $("#addNew").modal("hide");
                    });
                });
        },
    },
    data() {
        return {
            load: false,
            post: "",
            items: [],
            loading: false,
            selected: [],
            searchPostText: "",
        };
    },
};
</script>

<style lang="css" scoped>
.user {
    cursor: pointer;
}
.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background: #fff !important;
    padding: 0px !important;
    border: 0px !important;
    text-align: center;
    white-space: nowrap;
}
.search-btn {
    border: 0px;
    background: #fff !important;
}

.form-control.search-btn:focus {
    box-shadow: none !important;
}
.modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 31px;

    /* identical to box height */

    color: #606060;
}
.community-card {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}
.community-card.compose h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* line-height: 18px; */
    color: #a7a5a5;
}

.community-card.post .media {
    margin-bottom: 20px;
}

.community-card.post .media .media-body h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #606060;
}
.post-input2 {
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 550;
    box-sizing: border-box;
    border-radius: 25px;
    height: 40px;
    margin-top: 5px;
}

.post-input {
    border: none;
    font-size: 14px;
    font-weight: 550;
    box-sizing: border-box;
    border-radius: 25px;
    height: auto;
    background: #fff !important;
}
.post-input:focus {
    border: none !important;
    box-shadow: none !important;
}
.btn-course-take {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: #616060;
    background: #d6d6d6;
    border: transparent !important;
    box-sizing: border-box;
    border-radius: 25px;
}
.btn-course-take-active {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: #fbfdff;
    /* background: #007bff; */
    border: transparent !important;
    box-sizing: border-box;
    border-radius: 25px;
}

.demo {
    margin: 24px 0;
}

.input {
    width: 100%;
    border: #ccc 1px solid;
    border-radius: 6px;
    resize: vertical;
    min-height: 42px;
    padding: 12px;
    box-sizing: border-box;
    line-height: 1.2em;
    font-size: inherit;
}

.issue {
    padding: 4px 6px;
    border-radius: 4px;
    cursor: pointer;
}

.mention-selected .issue {
    background: rgb(139, 212, 255);
}

.issue .number {
    font-family: monospace;
}

.dim {
    color: #666;
}

.preview {
    font-family: monospace;
    white-space: pre-wrap;
    margin-top: 12px;
    padding: 12px;
    background: #f8f8f8;
    border-radius: 6px;
}
</style>
