<template>
    <div class="offset-md-1 col-md-10 mt-5 p-0">
        <div class="row mt-4 progress-con">
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show completed">1</span>
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show completed">2</span>
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball progress-show completed">3</span>
                </div>
            </div>
            <div class="col-md-3 col-3 text-center">
                <div class="ball-container">
                    <span class="ball active ">4</span>
                </div>
                <div
                    class="mt-2"
                    style="font-style: italic;background: #F8FAF8;box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);border-radius: 3px;font-size: 10px;color: #4F4F4F;padding:10px 10px;font:Nunito; position:absolute;left:0;right:0;"
                >
                    We’re very proud of you! Well done💯
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 mx-auto mt-5 mb-3 mb-lg-5 p-0">
        <p
            class="text-left text-sm-center font-weight-bold d-block select-text"
        >
            Input your BloommNow account number
        </p>
    </div>

    <form @submit.prevent="submit()">
        <div class="form-group mt-lg-5 col-12 col-lg-6 offset-lg-3 p-0">
            <input
                name="account"
                id="account"
                type="text"
                placeholder="BloommNow account number"
                class="course-select account-input form-control col-12 d-block"
                v-model="account"
                style="text-align: center;"
                :class="{ error: error == true }"
                autocomplete="off"
                @input="checkInput"
            />
        </div>

        <div class="form-group col-12 col-lg-12 mt-lg-5 mt-4 p-0">
            <button
                type="submit"
                class="btn btn-submit py-lg-2 px-4 btn-block"
                style="color:#fff; box-shadow:none"
                :disabled="!submitted"
                v-html="buttonText"
            ></button>
        </div>
    </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
import axios from "axios";

export default {
    data() {
        return {
            buttonText: "Complete Registration",
            submitted: false,
            selectedText: "",
            account: "",
            error: false,
            success: false,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    mounted() {
        if (this.user.course_id == null) {
            this.$router.push({ path: "First" });
        }
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Dashboard";
        },
    },

    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
        checkInput() {
            if (this.account.trim().length == 10) {
                this.submitted = true;
            } else {
                this.submitted = false;
            }
        },
        submit() {
            this.buttonText = "Validating your Account number...";
            axios
                .get(
                    `https://api.bloommbank.com/v1/inquiry/${this.account.trim()}`,
                    {
                        headers: {
                            "x-api-key":
                                "ZW6HmWtt2U5HAVB4QpRCkaHwFSFxF9jb3gCJrpZb",
                        },
                    },
                )
                .then((res) => {
                    this.buttonText = "Saving your Account number...";
                    axios
                        .post("/user/verify/account", {
                            account: this.account.trim(),
                        })
                        .then((response) => {
                            this.attempt();
                            swal.fire(
                                "Saved",
                                "Account number has been saved",
                                "success",
                            ).then((click) => {
                                this.$router.replace({
                                    name: "Onboard",
                                });
                            });
                        })
                        .catch((err) => {
                            this.buttonText = "Complete Registration";
                            this.success = false;
                            this.error = true;
                            switch (
                                err &&
                                (err.response ? err.response.status : null)
                            ) {
                                case 422:
                                    swal.fire(
                                        "Error",
                                        "Invalid account number, please check and try again",
                                        "error",
                                    );
                                    break;

                                case 409:
                                    swal.fire(
                                        "Error",
                                        "Account has been registered",
                                        "error",
                                    );
                                    break;
                                default:
                                    swal.fire(
                                        "Error",
                                        "We are unable to verify your account number right now, this is due to network error. Please check your network and try again",
                                        "error",
                                    );
                                    break;
                            }
                        });
                })
                .catch((err) => {
                    this.buttonText = "Complete Registration";
                    this.success = false;
                    this.error = true;
                    switch (
                        err &&
                        (err.response ? err.response.status : null)
                    ) {
                        case 400:
                            swal.fire(
                                "Error",
                                "Invalid account number, please check and try again",
                                "error",
                            );
                            break;
                        default:
                            swal.fire(
                                "Error",
                                "We are unable to verify your account number right now, this is due to network error. Please check your network and try again",
                                "error",
                            );
                            break;
                    }
                });
        },
    },
};
</script>

<style scoped>
.benefits-card2.selected {
    border: 3px solid #52a350;
}

.boomm-about {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #898989;
}

.error {
    border: 2px solid rgb(139, 4, 4);
}

.error:focus {
    border-color: rgb(139, 4, 4);
    border: 1px solid rgb(139, 4, 4);
    -webkit-box-shadow: 0 0 0 1.5px rgb(139, 4, 4);
    box-shadow: 0 0 0 1.5px rgb(139, 4, 4);
    border: none;
}

/* Chrome, Safari, Edge, Opera */
.account-input::-webkit-outer-spin-button,
.account-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.account-input[type="number"] {
    -moz-appearance: textfield;
}
</style>
