<template>
    <div>
        <div class="px-0 px-lg-5 my-5" v-if="quiz.length > 0">
            <div class="">
                <div class="p-0">
                    <div class="mb-4 mb-lg-5">
                        <div
                            class="progress"
                            style="border-radius: 25px;height:8px;"
                        >
                            <div
                                class="progress-bar"
                                style="background:#044995;height:8px;"
                                role="progressbar"
                                :style="`width:${progressBar}%`"
                                :aria-valuenow="progressBar"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                    </div>

                    <div class="card quiz-card px-4 py-3 py-lg-4 px-lg-5">
                        <div class="quiz-number mb-3">
                            <h4>
                                <strong
                                    >Question
                                    {{ quiz.length > 0 && quizIndex + 1 }}/10
                                </strong>
                            </h4>
                        </div>
                        <div class="quiz-question-header">
                            <h3
                                v-html="quiz[0].questions[quizIndex].question"
                            ></h3>
                        </div>

                        <div>
                            <div class="form-group mt-3">
                                <template
                                    v-for="(step, index) in quiz[0].questions[
                                        quizIndex
                                    ].options"
                                >
                                    <!-- " -->
                                    <!-- WILL NOT DISPLAY AN EMPTY STRING OPTION - FOR CASES WHERE THE AVAILABLE OPTIONS ARE LESS THAN FOUR(4) -->
                                    <div v-if="step != ' '" :key="index">
                                        <label
                                            class="radio px-3 py-2 mt-3 d-flex align-items-center"
                                            style="cursor:pointer"
                                            @click="
                                                getChoice(
                                                    step,
                                                    index + 1,
                                                    quiz[0].questions[quizIndex]
                                                        .id,
                                                )
                                            "
                                        >
                                            <input
                                                type="radio"
                                                name="optradio"
                                                :value="step"
                                                :ref="setItemRef"
                                            />
                                            <span class="ml-2 pt-0">{{
                                                step
                                            }}</span>
                                        </label>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-8 p-0 mt-5 mb-5 mb-lg-3">
                            <button
                                @click="
                                    nextQuestion(
                                        quiz[0].questions[quizIndex].id,
                                        quiz[0].questions[quizIndex]._id,
                                    )
                                "
                                class="quiz-question-btn"
                                :class="
                                    quizIndex === 9
                                        ? 'btn-success'
                                        : 'btn-primary'
                                "
                            >
                                {{ quizIndex === 9 ? "SUBMIT " : "NEXT " }}
                                <i class="fas fa-angle-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-5 mx-0" v-else>
            <i class="fas fa-circle-notch fa-spin fa-3x"></i><br />
            Fetching questions....
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";

export default {
    data() {
        return {
            progressBar: 5,
            quiz: [],
            quizIndex: 0,
            optradios: [],
            indexChecker: "",
            choice: [],
            param: [],
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        ...mapActions({
            attempt: "auth/attempt",
            signOutAction: "auth/signOut",
        }),
        setItemRef(el) {
            if (el) {
                this.optradios.push(el);
            }
        },

        async getQuiz() {
            axios
                .get("get/baseline")
                .then((res) => {
                    this.quiz.push(res.data.payload);
                    this.quiz[0].questions = JSON.parse(res.data.payload.questions)
                    this.quiz[0].questions
                        .map((x) => [Math.random(), x])
                        .sort(([a], [b]) => a - b)
                        .map(([_, x]) => x);
                })
                .catch((err) => console.log(err));
        },
        getChoice(answer, index, id) {
            this.indexChecker = index;
            let data = {
                answer,
                id,
            };
            if (this.choice.length === 0) {
                this.choice.push(data);
            } else {
                let choosen = this.choice.find((item) => item.id === data.id);
                if (choosen) {
                    choosen.answer = answer;
                } else {
                    this.choice.push(data);
                }
            }
        },

        resetOptions() {
            this.optradios.forEach((element) => {
                element.checked = false;
            });
        },

        nextQuestion(id, index) {
            let choosen = this.choice.find((item) => item.id === id);
            if (choosen) {
                if (index === this.indexChecker) {
                    let data = {
                        id,
                        correct: this.indexChecker,
                    };
                    if (this.param.length === 0) {
                        this.param.push(data);
                    } else {
                        let choosen = this.param.find(
                            (item) => item.id === data.id,
                        );
                        if (choosen) {
                            choosen.correct = this.indexChecker;
                        } else {
                            this.param.push(data);
                        }
                    }
                }
                if (this.quizIndex === 9) {
                    this.submitQuiz();
                } else {
                    this.resetOptions();
                    this.progressBar += 11.11111;
                    if (this.quizIndex < this.quiz[0].questions.length) {
                        this.quizIndex++;
                    }
                }
            } else {
                swal.fire({
                    title: "No Option Selected",
                    text: "Please select an option to continue",
                    icon: "error",
                });
            }
        },

        async submitQuiz() {
            try {
                let data = {
                    quiz_id: this.quiz[0].id,
                    param: this.param.length,
                    choice: JSON.stringify(this.choice),
                };
                let response = await axios.post("baseline/submit", data);
                if (response.data.status) {
                    if (this.param.length * 10 < 60) {
                        this.signOutAction();
                        swal.fire({
                            title: "Sorry",
                            html:
                                "You did not meet the 60% cut-off mark for the baseline test. Please join the waitlist for the next cohort  <a target='_blank' href='https://forms.gle/qg9LbX6h8BxmWGVr6'>here</a>.",
                            icon: "warning",
                        }).then((e) => {
                            this.$router.push({ name: "Login" });
                        });
                    } else {
                        this.attempt();
                        setTimeout(() => {
                            swal.fire({
                                title: "Submitted",
                                text:
                                    "Your test has been submitted successfully",
                                icon: "success",
                            }).then((e) => {
                                this.$router.push({ name: "Dashboard" });
                            });
                        }, 3000);
                    }
                } else {
                    swal.fire({
                        title: "Error",
                        text: "You have already taken this test.",
                        icon: "error",
                    });
                }
            } catch (error) {
                if (error.response.status === 403) {
                    swal.fire({
                        title: "Only one attempt",
                        text: "You have already taken this test.",
                        icon: "error",
                    }).then((e) => {
                        this.$router.push({ name: "Dashboard" });
                    });
                } else {
                    swal.fire({
                        title: "Error",
                        text:
                            "Your test cannot be submitted right now, this is mostly caused by network. Please try submitting again",
                        icon: "error",
                    });
                }
            }
        },
    },

    mounted() {
        setTimeout(() => {
            this.getQuiz();
        }, 3000);
    },
};
</script>

<style lang="css" scoped>
.quiz-card {
    background: #ffffff;
    border: 2px solid #044995;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.quiz-btn {
    background: #08386d;
    border-radius: 25px;
    border: 1px solid #08386d;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    padding: 13px 20px;
    color: #ffffff;
}

/* Quiz Question */

.quiz-question-header h3 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #606060;
}

.radio {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #606060;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
}

.radio.selected {
    background: #e2effd;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #08386d;
}

.quiz-question-btn {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    padding: 13px 20px;
    color: #fff;
    background: #e9e9e9;
    border: 2px solid #dedede;
    box-sizing: border-box;
    border-radius: 10px;
}

.quiz-number h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #606060;
}

@media screen and (max-width: 700px) {
    .quiz-question-header h3 {
        font-size: 1.2rem;
    }
    .quiz-number h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
        color: #606060;
    }
}
</style>
