<template>
    <div
        class="capstone-card p-3 px-4 d-flex flex-column justify-content-between"
    >
        <div class="card-heading-new">
            Capstone Rating
        </div>
        <div v-if="capstone > 0">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <div
                    class="rating-text text-white d-flex flex-column"
                    style="font-size:1rem"
                >
                    <p class="mt-5">
                        <span v-if="capstone >= 80"
                            >You're are doing great 💪</span
                        >
                        <span v-if="capstone >= 60 && capstone < 80"
                            >You're are doing good 👍</span
                        >
                        <span v-if="capstone >= 50 && capstone < 60"
                            >You can do better 🤛</span
                        >
                        <span v-if="capstone >= 40 && capstone < 50"
                            >This ain't good enough
                        </span>
                        <span v-if="capstone >= 0 && capstone < 40"
                            >This ain't good enough</span
                        >
                    </p>
                    <star-rating
                        :rating="capstone >= 80 ? 100 : capstone / 20"
                        :read-only="false"
                        :show-rating="false"
                        :star-size="15"
                        :padding="1"
                    ></star-rating>
                </div>
                <div>
                    <div
                        class="rating sh-text-secondary p-3 mt-4 "
                        style="border-radius: 100%; background: rgb(255, 255, 255) none repeat scroll 0% 0%; font-weight: 900; font-size: 1.5rem; height:60px; width:60px; display:grid; place-content:center"
                    >
                        <span v-if="capstone >= 80">A</span>
                        <span v-if="capstone >= 60 && capstone < 80">B</span>
                        <span v-if="capstone >= 50 && capstone < 60">C</span>
                        <span v-if="capstone >= 40 && capstone < 50">D</span>
                        <span v-if="capstone >= 0 && capstone < 40">F</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <h6
                v-show="capstoneloader"
                class="text-white"
                style="font-size:1rem"
            >
                No result available at the moment
            </h6>
            <div v-show="!capstoneloader" class="text-center mt-3 text-white">
                <i class="fas fa-spinner fa-spin fa-2x"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import StarRating from "vue-star-rating";
export default {
    name: "Capstone",
    data() {
        return {
            capstone: 0,
            capstoneloader: true,
        };
    },
    components: {
        StarRating,
    },
    mounted() {
        this.getCapstones();
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
    },
    methods: {
        async getCapstones() {
            if (this.week < 2) {
                this.capstoneloader = true;
            } else {
                await axios
                    .get("capstone/avarage")
                    .then((response) => {
                        if (response.data.payload.avaragescore > 0) {
                            this.capstone = response.data.payload.avaragescore;
                        } else {
                            this.capstoneloader = true;
                        }
                    })
                    .catch((err) => {
                        this.capstone = 0;
                        this.capstoneloader = false;
                    });
            }
        },
    },
};
</script>

<style scoped>
.card-heading-new {
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    color: #ffffff;
}

.vue-star-rating-rating-text {
    display: none !important;
}
</style>
