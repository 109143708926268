<template>
        <div class="col-12 d-flex flex-md-row flex-column p-0 border-bottom mb-4">
            <!--  -->
            <div
                class="p-0 card col-md-4 col-12 bg-white mr-md-5 mb-4"
            >
                <div class="px-3 py-4 col-12 d-flex flex-column justify-content-between">
                    <div class="mb-5 card-title mt-0">
                        <div class="p-0 m-0 card-title">Download Side Hustle Certificate</div>
                        <small class="font-weight-bold">{{certificate.type}} Certificate</small><br />
                        <small>Track : {{certificate.title}}</small>
                    </div>
                    <button class="btn-block card-btn" @click="getCertificateData(certificate.course_id)" v-html='buttonText'>
                        
                    </button>
                </div>
            </div>
            <!--  -->
            <div class="p-0 mx-0" v-if="certificate.charge_id === 2 || certificate.charge_id === 3">
                <div v-if="showLink && link">
                    <div class="my-3 d-flex p-0 mx-0" v-if="link">
                        <span class="text-truncate col-7 p-0 mr-1" @click="copier" ref="verificationLink" style="cursor:pointer">{{link}}   </span>
                         <i @click="copier" class="fas fa-copy" style="cursor:pointer"></i>
                    </div>
                    <div  v-if="certificate.charge_id === 3">
                        <a href="https://join.slack.com/t/sidehustle-9hg7349/shared_invite/zt-1ac58jev4-ThalyTOpbf3AwxJ__eKHJw" target="_blank" rel="noopener noreferrer">Click here to join the Talent Pool</a>
                    </div>
                </div>
                <div v-if="loadingLink && showLink && !link">
                    <i class="fas fa-spinner fa-3x fa-spin"></i>
                </div>
            </div>
        </div>
</template>

<script>
import { jsPDF } from "jspdf";
import swal from 'sweetalert2'
import { mapGetters } from "vuex";
import axios from 'axios';


export default {
    components: {
      
    },
    data() {
        return{
            file: null,
            link: null,
            size:125, // size of the QR Code
            buttonText: 'Download Certificate',
            showLink : false,
            loadingLink : true
        }
    },
    props:{
        certificate:{
            type: Object,
        }
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods:{
        showCertificateLinks(){
            if (this.certificate.charge_id === 1) {
                return
            }
            this.loadingLink = true
            this.showLink = true
            let data = {
                user_id: this.user.user_id,
                course_id: this.certificate.course_id
            }
            axios.post("/certificate", data).then((response) => {
                if (response.data.payload.link) {
                    this.link = response.data.payload.link
                    this.loadingLink = false
                }
            }).catch((err)=>{ 
                console.log(err)
            })
        },
        copier() {
            // copy verification link to clipboard
            let text = this.$refs.verificationLink.textContent;
            const el = document.createElement("textarea");
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
            if (document.execCommand("copy")) {
                swal.fire({
                    title: "Copied",
                    text: "Your certificate verification link has been copied",
                    icon: "success",
                });
            }
        },
        getCertificateData(id){
            this.buttonText= `<i class="fas fa-spinner fa-spin fa-2x"></i>`
            let data = {
                user_id: this.user.user_id,
                course_id: id
            }
            axios.post("/certificate", data).then((response) => {
                this.isLoading = false
                if (response.data.payload.dataurl) {
                    this.file = response.data.payload.dataurl
                }
                if (response.data.payload.link) {
                    this.link = response.data.payload.link
                }
                if (response.data.payload.file) {
                    this.file = response.data.payload.file
                }
                this.handleCertDownload()
            }).catch((err)=>{ 
                swal.fire({
                    title: "Error",
                    text: err.response.data.payload,
                    icon: "warning",
                });
                this.buttonText = 'Download Certificate'
            })
        },
        async handleCertDownload(){
            
            if (this.certificate.charge_id === 1) { 
                // if user pay for basic
                let file = "data:image/png;base64," + this.file // convert the file to blob for download
                let filename = this.user.last_name + '_' + this.user.first_name + '_' + this.user.user_id //name to save the file as
                    if (window.navigator.msSaveOrOpenBlob){
                        // IE10+
                        window.navigator.msSaveOrOpenBlob(file, filename);
                    } else { 
                        // Others
                        var a = document.createElement("a")
                        a.href =  file;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        setTimeout(function() {
                            document.body.removeChild(a);
                            window.URL.revokeObjectURL(url);  
                        }, 0); 
                    }
                this.updateDownloadCount()
                this.buttonText = 'Download Certificate'
            }else{
                //if user pay for standard or premium
                this.downloadPDF()
            }
        },
        downloadPDF(){
            const doc = new jsPDF(
                {
                    orientation: 'landscape',
                    unit: "in", // unit (inches)
                    format: [34.375, 24.29] //size of the image
                    // format: [51.5625, 36.4375] //size of the image
                }
            );
            let fileName = this.user.last_name + '_' + this.user.first_name + '_' + this.user.user_id //name to save the file as
            let img = new Image()
            img.src = "data:image/png;base64," + this.file
            doc.addImage(img,0, 0)
            setTimeout(() => {
                this.buttonText = 'Download Certificate'
            }, 2000);
            const newBlob = new Blob([doc.output('blob')], { type: 'application/octet-stream' })
            let url = URL.createObjectURL(newBlob)
            var a = document.createElement("a")
            a.href =  url;
            a.download = fileName + '.pdf';
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);  
            }, 0); 
            this.updateDownloadCount()
        },
        async updateDownloadCount(){
            //  Store that the user have download the certificate
            let data = {
                user_id: this.user.user_id,
                course_id: this.certificate.course_id,
            }
            let response = await axios.post("certificate/download", data)
        },
    },
    mounted(){
        this.showCertificateLinks()
    }
}
</script>

<style scoped>
.disabledDownload {
    background-color: #F9FAFA;
    opacity: 0.7;
    border: 1.49405px solid rgba(0, 0, 0, 0.1);
}

.disabledDownload .card-btn {
    background: #BCBEC0;
    color: #242424;
    cursor: not-allowed;
}

.card .card-title{
    font-size: 18px !important;
    color: #242424;
}
.card .sub-text{
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    color: #7A7A7A;
}
.card .card-btn {
 padding: 12px 18px;
 background: #044995;
 border-radius: 5px;
 font-weight: 550;
 font-size: 10px;
 line-height: 11px;
 color: #FFFFFF;
}
.card .card-btn-success{
 padding: 12px 18px;
 background: #fff;
 border: 0.747761px solid #044995;
 border-radius: 5px;
 font-weight: 550;
 font-size: 10px;
 line-height: 11px;
 color: #044995;
}

.card {
  background-color: #F9FAFA;
  background-image: url('./../../assets/img/line/line2.png');
  background-size: contain;
  border: 1.4966px solid rgba(0, 0, 0, 0.1) ;
  background-position:  top right;
  background-repeat: no-repeat;
  box-sizing: border-box ;
  border-radius: 14.966px ;
  box-shadow: none ;
}

.cert_btn i {
    margin-left: 15px;
}
.grey {
    color: #606060;
}
.ash {
    color: #a7a5a5;
}
.lesson-title p {
    font-weight: 550;
    font-size: 20px !important;
    color: #606060;
}
.lesson-title button {
    padding: 5px !important;
    background: #B3E6B2;
    border-radius: 100%;
    font-weight: 550;
    font-size: 10px;
    letter-spacing: 0.04em;
    color: #52A350;
}

@media screen and (max-width: 768px) {
    .card {
        border-radius: 10px;
    }
    .cert_btn {
        font-size: 10px;
        font-weight: 600;
        padding: 5px 10px;
        margin-right: 3px;
    }
    .cert_btn i {
        margin-left: 8px;
    }
    .page-title {
        font-weight: 500;
        transform: rotate(0deg);
        writing-mode: horizontal-tb;
        text-align: left;
        font-size: 16px;
    }
    .lesson-title p {
        font-size: 14px !important;
    }
    .lesson-title button {
        padding: 2px 15px;
        min-width: fit-content;
        border-radius: 25px;
        font-size: 6px;
    }
}

</style>