<template>
    <div
        class="d-flex each-quiz py-4 flex-md-row flex-column justify-content-between"
        style="cursor:pointer"
    >
        <div class="mb-2 mb-md-0">
            <div class="week mb-1 font-weight-bold">Week {{ score.week }}</div>
            <div class="topic">
                {{ score.course.title }}
            </div>
        </div>
        <div class="d-flex flex-md-row align-items-center">
            <div
                class="dash-btn quiz-score-title px-2 py-1 mr-md-2 order-2"
                style="background: #DBFEDA;color: ##606060;"
                v-if="score.score > 69 && score.score <= 100"
            >
                LEGEND <i class="fa fa-star"></i>
            </div>
            <div
                class="dash-btn quiz-score-title px-2 py-1 mr-md-2 order-2"
                style="background: #ECFFC4;color: ##606060;"
                v-else-if="score.score > 39 && score.score <= 69"
            >
                GREAT
            </div>
            <div
                class="dash-btn quiz-score-title px-2 py-1 mr-md-2 order-2"
                style="background:#FFEEEE;color: ##606060;"
                v-else
            >
                FAILED
            </div>
            <div class="quiz-score mr-2 mr-md-0 order-1 order-md-2">
                <span
                    v-if="score.score > 69 && score.score <= 100"
                    style="color:#52A350"
                >
                    {{ score.score }}
                </span>
                <span
                    v-else-if="score.score > 39 && score.score <= 69"
                    style="color:#606060;"
                >
                    {{ score.score }}
                </span>
                <span v-else style="color:#FC7070;">
                    {{ score.score }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TakenQuizzes",
    props: {
        score: Object,
    },
};
</script>

<style lang="css" scoped>
.card-headings {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #606060;
}
.dash-btn {
    font-size: 12px;
    font-weight: 550;
    border-radius: 25px;
    padding: 0.1rem 0.75rem;
    min-width: fit-content;
}
.dash-btn {
    font-size: 12px;
    font-weight: 550;
    border-radius: 25px;
    padding: 0.1rem 0.75rem;
    min-width: fit-content;
}
.quiz-card .each-quiz {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.quiz-card .each-quiz:last-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.quiz-card .topic {
    font-weight: normal;
    font-size: 8px;
    line-height: 14px;
    letter-spacing: 0.04em;
    color: #92a7bf;
}
.quiz-card .week {
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: #08386d;
}
.quiz-card .quiz-score-title {
    font-weight: 550;
    font-size: 8px;
    line-height: 9px;
    letter-spacing: 0.04em;
    color: #606060;
}
.quiz-card .quiz-score {
    font-weight: 550;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.04em;
}
@media screen and (max-width: 768px) {
    .card-headings {
        font-size: 14px;
        line-height: 28px;
    }
    .dash-btn {
        font-size: 8px;
        padding: 0.05rem 0.9rem;
    }
}
</style>
