<template>
    <nav
        class="navbar navbar-light navbar-expand-sm py-2 fixed-top"
        style="z-index:9999"
        :class="[changeNavColor ? 'newBg' : 'oldBg']"
    >
        <div class="container-fluid px-lg-4 pt-lg-3 mx-lg-5">
            <a href="https://lms.terrahq.co/" class="navbar-brand p-0">
                <img
                    src="@/assets/img/logo-dark.png"
                    alt="logo"
                    class="nav-logo"
                    style="width:150px"
                />
            </a>
            <button
                class="navbar-toggler float-right"
                type="button"
                data-toggle="collapse"
                data-target="#navbar"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="navbar">
                <ul
                    class="navbar-nav ml-auto px-2 pl-lg-0 d-flex align-items-md-center align-items-start"
                    v-if="currentRouteName == 'Login'"
                >
                    <li class="nav-item mr-5 mr-md-2 py-2" v-if="!user">
                        <router-link :to="{ name: 'Register' }">
                            <button class="nav-employer-cta px-4 py-2 mr-2">
                                Join Internship
                            </button>
                        </router-link>
                    </li>
                    <li class="nav-item mr-5 mr-md-2 py-2" v-else>
                        <button
                            class="nav-employer-cta px-4 py-2 mr-2"
                            @click="logout"
                        >
                            Sign out
                        </button>
                    </li>
                </ul>
                <ul
                    class="navbar-nav ml-auto px-2 pl-lg-0 d-flex align-items-md-center align-items-start"
                    v-if="currentRouteName == 'Register'"
                >
                    <li class="nav-item mr-5 mr-md-2 py-2" v-if="!user">
                        <router-link to="/login">
                            <button class="nav-employer-cta px-4 py-2 mr-2">
                                Log in
                            </button>
                        </router-link>
                    </li>
                    <li class="nav-item mr-5 mr-md-2 py-2" v-else>
                        <button
                            class="nav-employer-cta px-4 py-2 mr-2"
                            @click="logout"
                        >
                            Sign out
                        </button>
                    </li>
                </ul>
                <ul
                    class="navbar-nav ml-auto px-2 pl-lg-0 d-flex align-items-md-center align-items-start"
                    v-if="currentRouteName == 'Reset'"
                >
                    <li class="nav-item mr-5 mr-md-2 py-2" v-if="!user">
                        <router-link to="/login">
                            <button class="nav-employer-cta px-4 py-2 mr-2">
                                Log in
                            </button>
                        </router-link>
                    </li>
                    <li class="nav-item mr-5 mr-md-2 py-2" v-else>
                        <button
                            class="nav-employer-cta px-4 py-2 mr-2"
                            @click="logout"
                        >
                            Sign out
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import swal from "sweetalert2";

export default {
    name: "Navbar",
    data() {
        return {
            changeNavColor: false,
            canBeLoggedIn: false,
            navMobile: false,
        };
    },
    beforeMount() {
        window.addEventListener("scroll", this.handleScroll);
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),

        currentRouteName() {
            return this.$route.name;
        },
    },
    methods: {
        handleScroll() {
            if (window.pageYOffset > 20) {
                this.changeNavColor = true;
            } else {
                this.changeNavColor = false;
            }
        },
        ...mapActions({
            signOutAction: "auth/signOut",
        }),

        logout() {
            swal.fire({
                title: "Leaving Already",
                text: "Are you sure, you want to logout?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#dc3545",
                cancelButtonColor: "#353535",
                confirmButtonText: "Logout!",
            }).then((result) => {
                ///send request
                if (result.value) {
                    this.signOutAction().then(() => {
                        this.$router.replace({ name: "Login" });
                    });
                }
            });
        },
    },

    mounted() {
        if (this.user) {
            this.canBeLoggedIn = true;
        } else {
            this.canBeLoggedIn = false;
        }
    },
};
</script>

<style scoped>
.container {
    transition: 1000ms ease;
}
.newBg {
    background-color: #fff;
    /* background-color: #08386D; */
}
.onScroll {
    color: #52a350 !important;
    font-weight: 500;
}
.oldBg {
    background-color: transparent;
}
.navbar {
    border: none !important;
}
.btnStyled {
    border: 2px solid #21d536 !important;
    font-weight: 500;
}

.nav-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #52a350 !important;
    cursor: pointer;
}
.nav-internship-cta {
    background: #52a350;
    border: 2px solid #52a350 !important;
    color: #ffffff;
    font-size: 1rem;
    border-radius: 10px;
}
.nav-employer-cta {
    background: #e1f0e0;
    font-size: 1rem;
    border-radius: 10px;
    color: #52a350;
    border: 2px solid rgba(82, 163, 80, 0.2) !important;
}
.nav-bar-cta:hover {
    border: 2px solid transparent;
    color: #08386d;
    background: #b6d86f;
}
@media screen and (max-width: 776px) {
    .nav-bar-links {
        text-align: left;
        margin: 10px 0;
        /* display: none; */
    }
    .navbar {
        border-bottom: 2px solid #b6d86f !important;
    }
}
</style>
