<template>
    <div
        class="px-xlg-100 px-sm-20 px-lg-30 pt-1 pt-md-3 pt-lg-4 mb-5 faq-page"
    >
        <div class="mt-5 pt-5 px-0">
            <div class="container px-0 mx-0">
                <div class="row px-1 px-lg-3">
                    <div class="mb-5 col-12 px-4 p-lg-0">
                        <h2 class="heading-1 font-weight-bold faq-heading">
                            Frequently Asked Questions
                        </h2>
                        <!-- <p class="sh-text-primary">
                            See some of the questions asked frequently
                        </p> -->
                    </div>
                    <div class="col-12 col-lg-8 px-4 p-lg-0">
                        <div id="accordion" class="accordion p-0">
                            <div class="card px-0">
                                <div class="faq-each">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse1"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                        >
                                            Is the internship remote?
                                        </a>
                                    </div>
                                    <div
                                        id="collapse1"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            The Side Hustle Internship is an
                                            online internship that allows all
                                            participants, regardless of
                                            location, to actively participate in
                                            the internship
                                        </p>
                                    </div>
                                </div>

                                <div class="faq-each">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse2"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                        >
                                            How long is the internship?
                                        </a>
                                    </div>
                                    <div
                                        id="collapse2"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            The internship is for 6 weeks, after
                                            which there is an optional bootcamp
                                            for finalists for another 6 weeks
                                        </p>
                                    </div>
                                </div>
                                <div class="faq-each">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse3"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                            >How to join Side Hustle
                                            Internship</a
                                        >
                                    </div>
                                    <div
                                        id="collapse3"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            To apply as an intern on Side
                                            Hustle, visit
                                            <router-link :to="{ name: 'Home' }">
                                                www.sidehustle.ng/internship
                                            </router-link>
                                            and click on the “Apply as an
                                            intern” button on the home page
                                        </p>
                                    </div>
                                </div>
                                <div class="faq-each">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse6"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                            >Is Side Hustle Internship free?</a
                                        >
                                    </div>
                                    <div
                                        id="collapse6"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            The Side Hustle Internship is free
                                            for all applicants.
                                        </p>
                                    </div>
                                </div>
                                <div class="faq-each d-none">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse4"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                            >Can I apply to join more than one
                                            cohort of the internship?</a
                                        >
                                    </div>
                                    <div
                                        id="collapse4"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            Yes, all participants can
                                            participate in multiple cohorts of
                                            the internship
                                        </p>
                                    </div>
                                </div>
                                <div class="faq-each">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse5"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                            >Can I get jobs from Side Hustle
                                            after the Internship?</a
                                        >
                                    </div>
                                    <div
                                        id="collapse5"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            All finalists are entitled to get 2
                                            certificates. 1 free certificate
                                            from Jobberman and one from Side
                                            Hustle Internship.
                                        </p>
                                    </div>
                                </div>
                                <div class="faq-each d-none">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse13"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                            >Can I apply for more than one
                                            internship?</a
                                        >
                                    </div>
                                    <div
                                        id="collapse13"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            Interns are permitted to take a
                                            maximum of two tracks during each
                                            cohort. However, you are not
                                            required to apply on the website
                                            more than once. You will be able to
                                            add an additional course to your
                                            dashboard after completing your
                                            registration.
                                        </p>
                                    </div>
                                </div>
                                <div class="faq-each">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse7"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                        >
                                            Is the Side Hustle Internship
                                            certificate free?
                                        </a>
                                    </div>
                                    <div
                                        id="collapse7"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            The Side Hustle Internship
                                            certificate isn’t free.
                                        </p>
                                    </div>
                                </div>

                                <div class="faq-each">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse9"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                            >How much is the Side Hustle
                                            Internship certificate?
                                        </a>
                                    </div>
                                    <div
                                        id="collapse9"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            The certificate of completion costs
                                            2,500 NGN, which is an equivalent of
                                            5 USD.
                                        </p>
                                    </div>
                                </div>
                                <div class="faq-each">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse10"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                            >What is the bootcamp about?
                                        </a>
                                    </div>
                                    <div
                                        id="collapse10"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            The bootcamp provides all interested
                                            Side Hustle Internship finalists
                                            with the opportunity to join a team
                                            working on a startup and collaborate
                                            on world-class projects that will be
                                            used in the real world, from start
                                            to finish.<br />
                                            The bootcamp phase of the internship
                                            is optional for all finalists and
                                            costs 5000 NGN, which is an
                                            equivalent of 10 USD.<br />
                                            Some of the past projects from the
                                            bootcamp include:<br />
                                            Waytojapa.com <br />
                                            Easylearnafrica.com <br />
                                            Ivorycloudify.com <br />
                                            Studypadi.com <br />
                                            Hustlevest.com <br />
                                            Tradelexi.com<br />
                                        </p>
                                    </div>
                                </div>
                                <div class="faq-each">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse11"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                            >Will jobs be given to interns?
                                        </a>
                                    </div>
                                    <div
                                        id="collapse11"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            This is an internship to equip
                                            youths to increase their chances of
                                            becoming employable. We will share
                                            job opportunities with our interns.
                                            However, this internship is not an
                                            employment scheme.
                                        </p>
                                    </div>
                                </div>
                                <div class="faq-each">
                                    <div
                                        class="card-header collapsed mb-1 px-0"
                                        data-toggle="collapse"
                                        href="#collapse12"
                                    >
                                        <a
                                            class="card-title custom-link font-weight-bold px-0"
                                            >Where will the internship take
                                            place?
                                        </a>
                                    </div>
                                    <div
                                        id="collapse12"
                                        class="card-body collapse px-0"
                                        data-parent="#accordion"
                                    >
                                        <p class="">
                                            For effective communication, the
                                            internship will take place on the
                                            Side Hustle Internship mobile
                                            application as well as the telegram
                                            group.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Faqs",
};
</script>

<style scoped>
.faq-page {
    background-color: #ffffff;
    background-image: url("./../../assets/img/backgrounds/hero-bg.png");
    background-position: top right;
    background-size: 70%;
    background-repeat: no-repeat;
}
.faq-heading {
    font-family: "GD Boing", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 63px;
    color: #52a350;
}
.faq-each {
    background: #e1f0e0;
    border: 2px solid #bbddbb;
    border-radius: 10px;
    padding: 12px 20px;
    margin-bottom: 1rem;
}
p {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 1rem !important;
}
</style>
