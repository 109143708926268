<template>
    <div class="notification row col-12 flex-column" v-if="!isLoading">
        <h4 style="font-size:18px">Notifications</h4>
        <div v-if="notifications.length > 0">
            <div
                class="flex-column"
                v-for="notification in notifications"
                :key="notification.id"
            >
                <div 
                    class="notify d-flex align-items-center" @click="markRead(notification.id, 'SH')"
                    v-if="notification.type == 'RoomComment'"
                >
                    <img
                        :src="notification.data.user.avatar"
                        :alt="notification.data.user.first_name"
                        class="rounded-circle d-block mr-2"
                        style="width:40px; height:40px"
                    />
                    <p class="notification-text p-2">
                        <span class="font-weight-bold d-block">
                            {{ notification.data.action }}
                        </span>
                        {{ notification.data.comment.comment }}
                    </p>
                </div>

                <div
                    class="notify d-flex align-items-center"
                    v-if="notification.type == 'Answer'" @click="markRead(notification.id, 'SH')"
                >
                    <img
                        :src="notification.data.user.avatar"
                        :alt="notification.data.user.first_name"
                        class="rounded-circle d-block mr-2"
                        style="width:40px; height:40px"
                    />
                    <p class="notification-text p-2">
                        <span class="font-weight-bold d-block">
                            {{ notification.data.action }}
                        </span>
                        {{ notification.data.answer.answer }}
                    </p>
                </div>

                <div
                    class="notify d-flex align-items-center"
                    v-if="notification.type == 'Badge'" @click="markRead(notification.id, 'Badges')"
                >
                    <p class="notification-text p-2 font-weight-bold">
                        <i class="fa-solid fa-shield text-primary pr-2"></i>
                        <span>{{ notification.data.action }}</span>
                    </p>
                </div>

                <div
                    class="notify d-flex align-items-center"
                    v-if="notification.type == 'NewMessage'" @click="markRead(notification.id, 'SH')"
                >
                    <p class="notification-text p-2 font-weight-bold">
                        <i class="fas fa-bullhorn text-primary pr-2"></i>
                        <span>{{ notification.data.action }}</span>
                    </p>
                </div>
            </div>
            <a class="text-center d-block" href="#" @click="markAllAsRead">{{
                textMark
            }}</a>
        </div>
        <div v-else class="mt-auto col-12 p-0">
            <h5 style="font-size:12px !important">No new Notification found</h5>
        </div>
    </div>
    <div class="notification row col-12" v-if="isLoading">
        <br />
        <i class="fas fa-spinner fa-spin fa-2x"></i>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
    name: "Notification",
    data() {
        return {
            isLoading: false,
            notifications: [],
            textMark: "Mark all as Read",
        };
    },
    emits: ["notify"],
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    watch: {
        $route(to, from) {
            this.getNotifications();
            this.$emit("notify", this.notifications.length);
        },
    },

    methods: {
        getNotifications() {
            this.isLoading = true;
            axios
                .get("user/notifications/unread")
                .then((response) => {
                    this.notifications = response.data.payload;
                    this.isLoading = false;
                    this.$emit("notify", this.notifications.length);
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
        markAllAsRead() {
            this.textMark = `<i class="fas fa-spinner fa-spin"></i>`;
            axios
                .post("user/notifications/markAllAsRead")
                .then(() => {
                    this.getNotifications();
                    swal.fire(
                        "Marked!",
                        "Notifications has been marked as read",
                        "success",
                    );
                    this.textMark = "Mark as Read";
                })
                .catch(() => {
                    this.textMark = "Mark as Read";
                });
        },
        markRead(id, routeName) {
            axios
                .post("user/notifications/markAsRead", {id})
                .then((response) => {
                    //
                    this.getNotifications();
                    this.$router.push({ name: routeName });
                    console.log(response)
                })
                .catch((err) => {
                    //
                    console.log(err);
                });
        },
        sendNotificationCount() {
            return this.notifications.length;
        },
    },
    mounted() {
        this.getNotifications();
    },
};
</script>

<style scoped>
.text-primary {
    color: #08386d !important;
}
.notification {
    width: 550px;
    z-index: 10 !important;
    margin-left: -10rem;
    padding: 2rem 0 2rem 1rem;
    /* height: 400px; */
    overflow-y: scroll;
    background: #ffffff !important;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
/*  */
-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.notification::-webkit-scrollbar {
  display: none;
}
.notification-text {
    font-size: 0.75rem !important;
}
.notify{
    cursor: pointer;
}
@media screen and (max-width: 767px) {
    .notification {
        width: 70vw;
        margin-left: auto;
        margin-right: 15px;
    }
    .no-bg {
        border: none !important;
    }
}
</style>
