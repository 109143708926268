<template>
    <div class="px-3">
        <div class="d-flex">
            <div class="mr-2" @click="$router.go(-1)">
                <i class="fas fa-arrow-left"></i>
            </div>
            <!-- <audio controls autoplay hidden loop>
                <source src="https://res.cloudinary.com/ioluwamichael/video/upload/v1648066098/internship/sample-12s_bokd6f.mp3" type="audio/mp3">
            </audio> -->
            <h5
                class="pl-3"
                style="font-weight: 300;font-size: 16px;line-height: 22px; color: #242424 !important;"
            >
                Quiz 
            </h5>
        </div>
        <div>
            <div class="my-5" v-if="quizzes !== ''">
                <div class="col-md-8 p-0">
                    <div v-for="(quiz, index) in quizzes" :key="index">
                        <EachQuiz
                            :quiz="quiz"
                            :index="index"
                            :quizLength=quizzes.length
                            :course_id="course_id"
                            :selectedOpt="selectedOpt"
                            @grade="gradeQuiz"
                        />
                    </div>
                    <button
                        @click="finishQuiz()"
                        class="btn-success btn mt-3 ml-1"
                        style="border-radius:8px"
                        v-if="answered === quizzes.length"
                    >
                        Finish
                    </button>
                </div>
            </div>
            <div v-if="quizzes === ''">
                Loading...
            </div>
            <div v-if="errorText !== ''">
                {{ errorText }}
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
import EachQuiz from "./EachQuiz.vue";
import { encrypt, decrypt } from "@/services/encrypt";

export default {
    components: { EachQuiz },
    data() {
        return {
            user_choice: "",
            quizzes: "",
            errorText: "",
            selectedOpt: null,
            choice: [],
            counter: 0,
        };
    },
    // props: ["course_id", "isExternal"],
    props: {
        course_id: Number | String,
        quiz_week: Number | String,
        isExternal: Boolean | String,
        isBaseline: Boolean | String,
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
        answered() {
            return this.quizzes.filter((item) => item.selected !== null).length;
        },
    },

    methods: {
        getQuiz() {
            let data = {
                course_id: this.course_id,
                week: JSON.parse(this.isExternal ? this.isExternal : "false")
                    ? 1
                    : this.quiz_week,
                isExternal: JSON.parse(
                    this.isExternal ? this.isExternal : "false",
                ),
            };

            axios
                .post("quiz/get", data)
                .then((res) => {
                    if (res.data.payload.quiz.length < 1) {
                        this.errorText =
                            "No quiz found - please check back later";
                        return;
                    }

                    if (res.data.payload.isNew) {
                        let data = res.data.payload.quiz;
                        this.quizzes = data.map((item) => {
                            return { ...item, answer: null, selected: null };
                        });
                    } else {
                        try {
                            this.quizzes = JSON.parse(
                                res.data.payload.quiz.quiz,
                            );
                        } catch (err) {
                            this.quizzes = res.data.payload.quiz.quiz;
                        }
                    }
                })
                .catch((response) => {
                    console.log(response);
                });
        },

        async setQuizTracker() {
            let data = {
                course_id: this.course_id,
                week: this.week,
                quiz: JSON.stringify(this.quizzes),
            };
            axios
                .post("quiz/tracker", data)
                .then((res) => {})
                .catch((err) => {
                    console.log(err);
                });
        },
        gradeQuiz(data) {
            if (!navigator.onLine) {
                swal.fire(
                    "Network Error",
                    "Hey Champ, you seems to be disconnected from network, Kindly check",
                    "error",
                );
                return;
            }
            let quiz = this.quizzes.find((item) => item.id === data.id);
            quiz.answer = quiz.options[quiz._id - 1];
            quiz.selected = data.selected;
            if (quiz._id === data.index + 1) {
                this.counter <= this.quizzes.length && this.counter++;
            }
            this.choice.push({
                selected: quiz.selected,
                id: quiz.id,
                options: quiz.options,
                _id: quiz._id,
                question: quiz.question,
            });
        },
        ...mapActions({
            attempt: "auth/attempt",
            signOutAction: "auth/signOut",
        }),
        finishQuiz() {
            const answerAll = this.quizzes.filter(
                (item) => item.selected !== null,
            ).length;
            if (answerAll < this.quizzes.length) {
                swal.fire({
                    title: "Finish your Quiz",
                    text:
                        "You need to answer all the questions before you can submit?",
                    icon: "warning",
                });
            } else {
                axios
                    .post("/quiz/finish", {
                        week: this.quiz_week,
                        course_id: this.course_id,
                        isExternal: this.course_id == 99 ? true : false,
                        choice: this.choice,
                        counter: this.counter,
                    })
                    .then((res) => {
                        if (this.course_id == 99) {
                            if (this.isBaseline == 1) {
                                    swal.fire({
                                        title: "Success",
                                    text: 'You have successfully taken the Jobberman soft skills training baseline test. You can now proceed to take your lessons',
                                    icon: "success",
                                }).then(() =>{
                                    this.attempt();
                                    this.$router.push({
                                        name: "Lessons",
                                                params: {
                                                    id : 99,
                                                    title : 'Soft Skills Training',
                                                },
                                            });
                                })
                            }
                            if (this.isBaseline == 2) {
                                    swal.fire({
                                        title: "Congratulations",
                                    text: 'You have successfully completed the Jobberman soft skills training endline test. You will get a mail shortly',
                                    icon: "success",
                                }).then(() =>{
                                    this.attempt();
                                    this.$router.push({
                                        name: "Dashboard"
                                    });
                                })
                            }
                        }else{
                                this.$router.push({
                                    name: "Dashboard",
                            });
                        }
                        return;
                    })
                    .catch((err) => {
                        swal.fire({
                            title: "Error",
                            text: err.response.data.payload,
                            icon: "error",
                        });
                    });
            }
        },
    },
    mounted() {
        if (
            this.course_id == undefined ||
            this.course_id == null ||
            this.course_id == ""
        ) {
            this.$router.go(-1);
        }
        this.getQuiz();
    },
};
</script>

<style lang="css" scoped></style>
