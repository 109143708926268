<template>
    <div class="col-md-10 offset-md-1 mt-lg-5">
        <h4
            class="text-md-center text-left font-weight-bolder"
            v-if="!user.taken_baseline"
        >
            Take Baseline Test to complete your registration
        </h4>
        <div class="row mt-5 justify-content-md-center ">
            <div
                class="col-md-3 col-6 mb-3"
                v-if="!user.taken_baseline"
                :class="!user.taken_baseline ? 'mx-auto mx-md-0' : ''"
            >
                <div class="">
                    <router-link :to="{ name: 'Baselinetest' }">
                        <div
                            class="benefits-card2 pl-3 pt-4 pb-4 pr-3 programming text-left text-sm-center"
                        >
                            <img
                                class="mb-4 d-none d-lg-block"
                                style="margin: 0 auto"
                                src="@/assets/img/benefits/network2.png"
                            />
                            <div class="benefits-card-title2 mb-3">
                                Take Baseline Test
                            </div>
                            <div class="benefits-card-details2">
                                Before you can complete your application, all
                                prospective Interns must take a baseline test
                                during registration to test your general
                                knowledge.
                            </div>
                            <button class="baseline-btn mt-4">
                                Proceed to Test
                            </button>
                        </div>
                    </router-link>
                </div>
            </div>

            <!-- <div class="col-md-3 col-6  mb-3" v-if="user.taken_baseline">
                <router-link :to="{ name: 'DashboardRefer' }">
                    <div
                        class="benefits-card2 pl-3 pt-4 pb-4 pr-3 design text-left text-sm-center"
                    >
                        <img
                            class="mb-4 d-none d-lg-block "
                            style="margin: 0 auto"
                            src="@/assets/img/benefits/network3.png"
                        />
                        <div class="benefits-card-title2 mb-3">
                            Refer & Earn
                        </div>
                        <div class="benefits-card-details2">
                            After completing your registration, you can refer
                            people to the internship and earn rewards if they
                            use your referral link.
                        </div>
                    </div>
                </router-link>
            </div> -->

            <div class="col-md-3 col-6  mb-3" v-if="user.taken_baseline">
                <router-link :to="{ name: 'ManageCourses' }">
                    <div
                        class="benefits-card2 pl-3 pt-4 pb-4 pr-3 admin text-left text-sm-center"
                    >
                        <img
                            class="mb-4 d-none d-lg-block"
                            style="margin: 0 auto"
                            src="@/assets/img/benefits/network5.png"
                        />
                        <div class="benefits-card-title2 mb-3">
                            Manage Courses
                        </div>
                        <div class="benefits-card-details2">
                            You will also have the option to add an additional
                            course after being accepted into the internship
                            program, as well as change your chosen course if you
                            so desire.
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

        <p class="text-center">
            <a
                class="text-center py-2 text-primary"
                href="https://t.me/+oB5cUmieZPBkYTlk"
                target="_blank"
            >
                Click here to join our Telegram group for updates
            </a>
        </p>
        <span
            class="d-block px-0  mt-lg-3 mt-2 col-12 text-center intern-text text-muted"
        >
            INTERNSHIP STARTS IN
        </span>

        <div class="d-flex justify-content-center mb-4">
            <Counter date="Mon, 11 Apr 2022 00:00:00 GMT"></Counter>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Counter from "../../../components/inc/Counter.vue";
import axios from "axios";

export default {
    data() {
        return {
            buttonText: "Proceed",
            submitted: false,
            selectedText: "",
        };
    },

    components: {
        Counter,
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    mounted() {
        this.$router.push({
                name: "Dashboard",
            });
    },
    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Dashboard";
        },
    },
};
</script>

<style scoped>
.baseline-btn {
    padding: 12px 24px;
    background: #044995;
    border-radius: 10px;
    color: #fff;
    font-family: Nunito;
    font-size: 14px;
}

.baseline-btn:disabled,
.baseline-btn[disabled] {
    background: #e9e9e9;
    color: #4d4d4d !important;
    border: 2px solid #dedede !important;
    cursor: not-allowed;
}
.benefits-card2 {
    height: 100%;
}
.benefits-card2.selected {
    border: 3px solid #52a350;
}

.benefits-card2 img {
    transform: scale(0.8);
    width: 43.08px;
    height: 43.08px;
}
.refer-btn {
    border: 1px solid #044995 !important;
    border-radius: 10px;
    padding: 0.7rem 1rem;
    color: #044995;
    background: transparent;
}
</style>
