<template>
    <div class="col-12 px-0">
        <!-- Mentor -->
        <div>
            <div style="font-size: 12px;color: #242424;" class="mb-2">
                MENTOR
            </div>
            <div class="d-flex align-items-center">
                <div style="width:60px" class="pr-3">
                    <img
                        :src="mentor ? mentor.avatar : '@/img/avatar.png'"
                        :alt="mentor ? mentor.avatar : '@/img/avatar.png'"
                        class="img-fluid block rounded-circle"
                    />
                </div>
                <div>
                    <div
                        style="font-weight: 600;font-size: 16px;color: #242424;"
                    >
                        {{
                            mentor
                                ? mentor.first_name + " " + mentor.last_name
                                : ""
                        }}
                    </div>
                    <div
                        style="font-weight: 200;font-size: 12px;color: #242424;"
                    >
                        {{ lesson ? lesson.course.title : "" }}
                    </div>
                </div>
            </div>
        </div>
        <!-- Mentor -->
        <!-- Duration -->
        <!-- <div
            class="py-2 my-3"
            style="border-top: 1px solid rgba(0, 0, 0, 0.15);border-bottom: 1px solid rgba(0, 0, 0, 0.15);"
        >
            <span style="font-weight:600;font-size: 14px;color: #242424;"
                >Duration:
            </span>
            <span style="font-weight: 200;font-size: 12px;color: #242424;">
                3 mins
            </span>
        </div> -->
        <!-- Duration -->
        <!-- Lesson Description -->
        <div>
            <div class="mt-3" style="font-weight: 600;font-size: 16px;color: #242424;">
                Lesson description
            </div>
            <div
                style="font-weight: 300;font-size: 12px;color: #242424;margin-top:0px"
            >
                {{ lesson ? lesson.description : "" }}
            </div>
            <div style="font-weight: 300;font-size: 11px;color: #242424;">
                {{ lesson ? fomatedData : "" }}
            </div>
        </div>
        <!-- Lesson Description -->
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: ["mentor", "lesson"],
    computed: {
        fomatedData() {
            return moment(this.lesson.created_at, "DD-MM-YYYY").format("LLLL");
        },
    },
};
</script>

<style></style>
