<template>
    <!-- Ambassadors Pictures -->
    <div class="wave d-flex justify-content-center flex-wrap mx-5">
        <img style="transform: rotate(8.22deg);" src="@/assets/img/ambassador-1.png" alt="Ambassador">
        <img style="transform: rotate(-7.52deg);" src="@/assets/img/ambassador-2.png" alt="Ambassador">
        <img style="transform: rotate(3.01deg);" src="@/assets/img/ambassador-3.png" alt="Ambassador">
        <img style="transform: rotate(-3.06deg);" src="@/assets/img/ambassador-4.png" alt="Ambassador">
    </div>
    <!-- Ambassadors Pictures -->
</template>

<script>
export default {

}
</script>

<style>

</style>