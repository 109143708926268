<template>
    <div
        class="container-fluid px-1 sh-text-primary start"
        style="background-color: #f5fafe;"
    >
        <div class="row py-100">
            <div class="container px-4 reg ">
                <div class="px-3 heading-center">
                    <h2 class="heading-1">New Password</h2>
                </div>
                <form class="reg_form" @submit.prevent="reset">
                    <div class="d-none">
                        <div class="px-5 px-lg-3">
                            <a href="#">
                                <img
                                    class="img-fluid"
                                    src="@/assets/img/google.svg"
                                    alt="Side Hustle Logo"
                                    style="width:200px"
                            /></a>

                            <a href="#" class=" d-inline-block pt-2">
                                <img
                                    class="img-fluid"
                                    src="@/assets/img/facebook.svg"
                                    alt="Side Hustle Logo"
                                    style="width:200px"
                            /></a>
                        </div>
                    </div>

                    <div class="form-group mb-5 col-12 col-lg-6">
                        <label for="password" class="font-weight-bold"
                            >Create Password</label
                        >
                        <div class="input-group mb-3 input-group-sm">
                            <input
                                :type="isPassword ? 'text' : 'password'"
                                class="form-control py-4"
                                name="password"
                                placeholder="Create a password you can remember"
                                v-model="password"
                                @keyup="v$.password.$touch"
                            />
                            <div
                                class="input-group-prepend"
                                @click="showPassword"
                            >
                                <span class="input-group-text"
                                    ><i :class="icon"></i
                                ></span>
                            </div>
                        </div>
                        <span
                            v-if="v$.password.$error"
                            class="text-danger font-weight-bold"
                            >{{ v$.password.$errors[0].$message }}</span
                        >
                    </div>

                    <div class="form-group mb-5 col-12 col-lg-6">
                        <label for="cpassowrd" class="font-weight-bold"
                            >Confirm Password</label
                        >
                        <input
                            type="password"
                            class="form-control py-4"
                            id="cpassword"
                            placeholder="Confirm your password"
                            v-model="password_confirm"
                            @keyup="v$.password_confirm.$touch"
                        />
                        <span
                            v-if="v$.password_confirm.$error"
                            class="text-danger font-weight-bold"
                            >It must be same with your password</span
                        >
                    </div>

                    <div class="form-group mb-5 col-12 col-lg-6">
                        <button
                            type="submit"
                            class="btn btn-primary py-2 px-4 btn-block"
                            style="color:#fff; box-shadow:none"
                            :disabled="submitted"
                        >
                            Continue <i class="fa fa-arrow-right"></i>
                        </button>
                    </div>
                    <p class="mt-3 px-3 heading-center">
                        Already have an account?
                        <router-link
                            to="/login"
                            class="custom-link font-weight-bold"
                            >Sign in</router-link
                        >
                    </p>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import axios from "axios";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Register",
    data() {
        return {
            code: this.$route.query.code,
            icon: "fa fa-eye",
            isPassword: false,
            email: "",
            emailState: false,
            password: "",
            password_confirm: "",
            submitted: false,
            v$: useValidate(),
        };
    },
    computed: {
        ...mapGetters({
            cou: "auth/course_id",
            user: "auth/user"
        }),
    },

    validations() {
        return {
            password: { required, minLength: minLength(6) },
            password_confirm: { required, sameAs: sameAs(this.password) },
        };
    },

    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),

        // Show and Hide Password
        showPassword() {
            switch (this.icon) {
                case "fa fa-eye":
                    this.icon = "fa fa-eye-slash";
                    this.isPassword = true;
                    break;
                case "fa fa-eye-slash":
                    this.icon = "fa fa-eye";
                    this.isPassword = false;
                default:
                    this.icon = "fa fa-eye";
                    this.isPassword = false;
                    break;
            }
        },
        reset() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.submitted = true;
                let data = {
                    password: this.password,
                    code: this.code
                };
                axios
                    .post("/change/password", data)
                    .then((response) => {
                        if (response.data.message === "success") {
                            this.attempt(response.data.token);
                            swal.fire(
                                "Success",
                                "Password has been changed, Login now",
                                "success",
                            ).then(() => {
                                this.$router.push({ name: "Login" });
                            });
                        } 
                        else {
                            this.submitted = false;
                            swal.fire(
                                "Invalid link",
                                "The reset link already expired, try requesting a new reset link",
                                "error",
                            );
                        }
                    })
                    .catch((error) => {
                        this.submitted = false;
                        swal.fire(
                            "Invalid link",
                            "The reset link already expired, try requesting a new reset link",
                            "error",
                        );
                    });
            } else {
                this.submitted = false;
                swal.fire(
                    "Invalid link",
                    "The reset link already expired, try requesting a new reset link",
                    "error",
                );
            }
        },
    },

    created(){
        if(this.user){
            this.$router.push({ name: "Welcome" });
        }

        if(!this.$route.query.code){
            this.$router.push({ name: "Login" });
        }
    }
};
</script>

<style></style>
