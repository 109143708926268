const CryptoJS = require("crypto-js");
const KEY = "Side Hustle";
export const encrypt = (data) => {
    const dataString = JSON.stringify(data);
    // Encrypt
    const result = CryptoJS.AES.encrypt(dataString, KEY).toString();
    return result;
};

export const decrypt = (data) => {
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(data, KEY);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    const result = JSON.parse(originalText);
    return result;
};
