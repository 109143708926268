import Swal from "sweetalert2";
const errorHandler = (error) => {
    console.log(error.message);
    if (error.message === "Network Error") {
        Swal.fire({
            title: error.message,
            text:
                "A network error has occurred, kindly check if you are connected to the internet",
            icon: "error",
        });
    } else {
        const { data, status, statusText } = error.response;
        if (status == 403) {
            Swal.fire({
                title: "Sorry",
                html:
                    "You did not meet the 60% cut-off mark for the baseline test. Please join the waitlist for the next cohort  <a target='_blank' href='https://forms.gle/qg9LbX6h8BxmWGVr6'>here</a>.",
                icon: "warning",
            });
            return;
        } else if (status == 423) {
            Swal.fire({
                title: "Blocked",
                text: data.payload,
                icon: "warning",
            });
            return;
        } else {
            Swal.fire({
                title: statusText,
                text: data.payload,
                icon: "error",
            });
        }
    }
};

export default errorHandler;
