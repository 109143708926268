<template>
    <div class="px-3 pb-2">
        <div class="d-flex pl-2">
            <h5 class="page-title">
                <span @click="$router.go(-1)" class="text-primary"
                    >Payment</span
                >
                > <span class=" text-capitalize">{{ order }} Payment</span>
            </h5>
        </div>
        <div class="col-lg-10 mx-auto mt-5">
            <img
                src="@/assets/img/partners/terrapay.svg"
                class="d-block mx-auto mb-3"
                alt="Terra Pay"
            />
            <h5 class="text-center font-weight-bold text-capitalize">
                {{ order }} Payment
            </h5>
            <p class="text-center">Select Preferred {{ order }} package</p>
            <div v-if="!packages && loading" class="text-center">
                <i class="fas fa-spinner fa-spin fa-2x"></i>
            </div>
            <div v-else>
                <div
                    class="d-flex flex-column flex-md-row col-12 justify-content-between align-items-center flex-wrap p-0"
                >
                    <label
                        :for="packageType.id"
                        v-for="packageType in packages"
                        :key="packageType.id"
                        class="
                            d-flex justify-content-between align-items-center px-3 py-4 border rounded my-2 each-payment-option
                        "
                        :class="
                            paymentCategory == packageType.id ? 'selected' : ''
                        "
                    >
                        <span
                            v-if="packageType.level === 'Standard'"
                            class="preferred"
                            >Most Preferred</span
                        >
                        <div>
                            <input
                                type="radio"
                                name="paymentCategory"
                                v-bind:id="packageType.id"
                                v-model="paymentCategory"
                                :value="packageType.id"
                            />
                            <span class="ml-2">{{ packageType.level }}</span>
                        </div>
                        <div v-if="order == 'bootcamp'">
                            <small class="px-2 py-1 rounded bg-light text-success" v-if="discount">{{discount}}% off</small>
                            {{ gateway == "PayPal" ? "$" : "&#8358;"
                            }}{{
                                packageType.bootcamp || packageType.bootcamp_2nd
                            }}
                        </div>
                        <div v-if="order == 'certificate'">
                            {{ gateway == "PayPal" ? "$" : "&#8358;"
                            }}{{
                                packageType.certificate ||
                                    packageType.certificate_2nd
                            }}
                        </div>
                    </label>
                </div>
                <div
                    class="col-12 my-4 p-0"
                    v-if="paymentCategory !== null"
                    v-html="
                        paymentCategory == 1
                            ? packages[0][`${order}_description`]
                            : paymentCategory == 2
                            ? packages[1][`${order}_description`]
                            : packages[2][`${order}_description`]
                    "
                ></div>
                <div class="col-12 p-0" v-if="paymentCategory !== null">
                    <button
                        class="btn btn-primary col-12 mt-3"
                        @click="selectAmount"
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
    data() {
        return {
            order: this.$route.query.type,
            paymentCategory: null,
            gateway: null,
            packages: null,
            discount: null,
            loading: true,
            extra_course: this.$route.query.extra_course,
            packageDescription: null,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods: {
        selectAmount() {
            this.$router.push({
                name: "PayAmount",
                params: {
                    type: this.order,
                    package: this.paymentCategory,
                    isFull: 1,
                    extra_course: this.extra_course,
                },
            });
        },
        getPackages() {
            axios
                .get("/payment/charges")
                .then((response) => {
                    this.loadimg = false;
                    this.packages = response.data.payload.charges;
                    this.gateway = response.data.payload.gateway;
                    this.discount = response.data.payload.discount;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {
        if (this.order !== "bootcamp" && this.order !== "certificate") {
            this.$router.replace({ name: "Payment" });
        } else {
            this.getPackages();
        }
    },
};
</script>

<style lang="css" scoped>
.each-payment-option {
    width: 33%;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    color: #242424;
    position: relative;
}
.each-payment-option:hover {
    background-color: #e8f3fd;
}

.each-payment-option .preferred {
    position: absolute;
    right: 0;
    top: 0;
    /* background: #08386d; */
    border-radius: 15px;
    color: #08386d;
    padding: 5px;
    font-size: 0.7rem;
    font-weight: bolder;
}

.selected {
    background: #e8f3fd !important;
}

.payment-cards {
    background-color: #e8f3fd;
    margin-bottom: 21px;
}
@media screen and (max-width: 776px) {
    .each-payment-option {
        width: 100%;
    }
}
</style>
