<template>
    <div class="footprint col-12 text-center py-xlg-100 px-xlg-100 px-sm-20 px-lg-30">
        <div class="footprint-text">
            We’ve got our footprint
        </div>
        <div class="my-md-5 my-3 col-12 footprint-logos ">
            <div
                class="d-flex col-11 col-md-9 mx-auto justify-content-md-between justify-content-center align-items-center flex-wrap"
            >
                <div class="">
                    <img
                        class="d-none"
                        src="@/assets/img/partners/keytrust.png"
                    />
                </div>
                <div class="">
                    <img class="" src="@/assets/img/partners/reina.png" />
                </div>
                <div class="">
                    <img class="" src="@/assets/img/partners/babangona.svg" />
                </div>
                <div class="">
                    <img class="" src="@/assets/img/partners/trinipicks.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Home",
};
</script>
<style scoped>
.footprint-text {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    color: #08386d;
}
.footprint-logos img {
    /* margin: 0 10px; */
    transform: scale(0.6);
    cursor: pointer;
}

@media screen and (max-width: 778px) {
    .footprint-logos img {
        transform: scale(0.4);
        margin: 0;
        padding: 0;
    }
}
</style>
