<template>
    <div class="col-md-10 offset-md-1 mt-5 p-0">
        <div class="col-md-7 col-12 p-0 mx-auto">
            <div
                class="benefits-card2 pl-3 pt-4 pb-4 pr-3 course text-center p-lg-5"
            >
                <div v-if="!showForm">
                    <div class="benefits-card-title2 mb-3">
                        Verify Your Email
                    </div>
                    <h6 class="text-center">
                        You must verify your email address before you can
                        proceed to take the baseline test.
                    </h6>
                    <p class="text-center py-3">
                        A verification code will be sent to
                        <b>{{ user.email }}</b>
                    </p>
                    <div
                        class="form-group col-12 col-lg-10 mt-3 offset-lg-1 p-0"
                    >
                        <button
                            type="submit"
                            class="btn btn-submit py-lg-2 px-4 btn-block"
                            style="color:#fff; box-shadow:none"
                            v-html="buttonText"
                            :disabled="submitted"
                            @click="sendCode()"
                        ></button>
                    </div>
                    <p class="text-center">
                        <i
                            >Kindly check your spam/promotions box if the email
                            doesn't enter your inbox</i
                        >
                    </p>
                </div>

                <form @submit.prevent="submit()" v-if="showForm">
                    <div class="benefits-card-title2 mb-3">
                        Verify Your Email
                    </div>
                    <p class="text-center">
                        A verification code has been sent to
                        <b>{{ user.email }}</b
                        >. Enter the verification code
                    </p>
                    <div
                        class="form-group mt-lg-5 col-12 col-lg-6 offset-lg-3 p-0"
                    >
                        <input
                            name="code"
                            id="code"
                            type="text"
                            placeholder="Enter Verification Code"
                            class="course-select account-input form-control col-12 d-block"
                            v-model="code"
                            style="text-align: center;"
                            :class="{ error: error == true }"
                            autocomplete="off"
                        />
                    </div>

                    <div class="form-group col-12 col-lg-12 mt-lg-3 mt-4 p-0">
                        <button
                            type="submit"
                            class="btn btn-submit py-lg-2 px-4 btn-block"
                            style="color:#fff; box-shadow:none"
                            :disabled="!submitted2"
                            v-html="buttonText2"
                        ></button>
                    </div>
                    <p class="text-center py-2">
                        <i
                            >Kindly check your spam/promotions box if the email
                            doesn't enter your inbox</i
                        >
                    </p>
                    <span class="mb-0 col-12 text-center d-block skip  cursor">
                        <h4 class="skip" @click="resentCode()">Resend Code</h4>
                    </span>
                </form>
            </div>

            <div class="col-12 mt--lg-4 mb-5">
                <span class="mb-0 col-12 text-center d-block skip">
                    <router-link :to="{ name: 'WelcomeDashboard' }" class="skip"
                        >Back
                    </router-link>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import NProgress from "nprogress";
import swal from "sweetalert2";

export default {
    data() {
        return {
            buttonText: "Send Verification code",
            buttonText2: "Verify Account",
            selectedText: "",
            submitted: false,
            submitted2: true,
            courses: [],
            course: "See courses",
            show: false,
            showForm: localStorage.form ? JSON.parse(localStorage.form) : false,
            code: "",
        };
    },

    methods: {
        submit() {
            localStorage.removeItem("form");
            if (this.code == "") {
                swal.fire(
                    "Invalid Code",
                    "Enter the verification code sent to your email address",
                    "error",
                );
            } else {
                this.buttonText2 = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
                let data = {
                    code: this.code,
                };
                axios
                    .post("user/email/verify", data)
                    .then((response) => {
                        if (response.data.status == true) {
                            swal.fire(
                                "Verified",
                                "Your email account has been verified",
                                "success",
                            ).then(() => {
                                window.location = "/welcome";
                            });
                        } else {
                            swal.fire(
                                "Error",
                                "Verification code has expired, try again",
                                "error",
                            );
                            this.buttonText2 = "Verify Account";
                        }
                    })
                    .catch((error) => {
                        swal.fire(
                            "Error",
                            "Verification code has expired, try again",
                            "error",
                        );
                        this.buttonText2 = "Verify Account";
                    });
            }
        },
        resentCode() {
            this.showForm = false;
            localStorage.setItem("form", false);
        },
        sendCode() {
            this.submitted = true;
            this.buttonText = `<i class="fas fa-spinner fa-spin fa-2x"></i>`;
            axios
                .post("user/email/code")
                .then((response) => {
                    if (response.data.status) {
                        swal.fire(
                            "Sent",
                            "A verification code has been sent to your email address",
                            "success",
                        ).then(() => {
                            this.showForm = true;
                            localStorage.setItem("form", true);
                            this.submitted = false;
                            this.buttonText = "Send Verification code";
                            // this.$router.push({ name: "Dashboard" });
                        });
                    } else {
                        this.submitted = false;
                        this.buttonText = "Send Verification code";
                    }
                })
                .catch((err) => {
                    this.submitted = false;
                    this.buttonText = "Send Verification code";
                });
        },
    },

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Dashboard";
        },
    },
};
</script>

<style scoped>
.benefits-card2 {
    cursor: inherit;
}
.fa-pen,
.cursor {
    cursor: pointer;
}
.fa-pen:hover {
    color: green;
}
.benefits-card2.course {
    background: #fdffe7;
}

.benefits-card2.course img {
    height: 100px;
    width: 100px;
}

.btn-submit {
    background: #044995;
    border: 2px solid #044995 !important;
    box-sizing: border-box;
    border-radius: 10px;
    color: #fff !important;
}

/* .benefits-card2.course::after {
    background: url(../../../assets/img/line/line2.png) no-repeat;
    background-size: auto;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right:0px;
} */
</style>
