<template>
    <Navbar />
    <div class="container-fuild px-lg-5 px-2" style="margin-top: 6rem;">
        <div class="col-lg-10 mx-auto">
            <div class="row ml-lg-4">
                <div class="col-12 px-2">
                    <span class="mb-0 col-12 mt-5 text-left text-sm-center d-block greetings p-0" >Hi, {{ user.first_name }}
                    </span>
                    <span
                        class="d-block px-0 mb-3 mt-lg-4 mt-2 col-12 text-left text-sm-center complete-text">
                        Complete your registration
                    </span>
                    <router-view /> 
                </div>
            </div>
        </div>        
    </div>
    <!-- floating whatsapp btn -->
    <a
        class="floating-cta text-center px-2 text-success"
        href="https://wa.me/message/3L72YMURS3U7D1"
        target="_blank"
        ><i class="fab fa-whatsapp fa-3x" style="font-size:4rem"></i
        ><br />Chat</a
    >
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Navbar from "../../../components/inc/InfoNavbar.vue";
export default {
    components: { Navbar },

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    watch: {
        $route(to, from) {
            document.title = to.meta.title || "Registration";
        },
    },
};
</script>
<style>

.greetings{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 12px;
    color: #414141;
}
.complete-text{
    font-family:'GD Boing', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #52A350;
}


.skip{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #8B8989;
}
.btn-submit:disabled,
.btn-submit[disabled]{
    background: #E9E9E9;
    color: #242424 !important;
    border: 2px solid #DEDEDE !important;
    cursor:not-allowed;

}

.btn-submit{
    font-family: 'Nunito', sans-serif;
    background: #52A350;
    border: 2px solid #52A350 !important;
    box-sizing: border-box;
    border-radius: 10px;
    color: #FFF !important;
}

.btn-download-boom{
    font-family: 'Nunito', sans-serif;
    background: #52A350;
    background: #E9E9E9;
    color: #242424 !important;
    border: 2px solid #DEDEDE !important;
    box-sizing: border-box;
    border-radius: 10px;
}

.benefits-card2{
    margin-bottom: 30px;
    background-position:  top right;
    background-repeat: no-repeat;
    border-radius: 20px;  
    cursor: pointer;
}
.benefits-card2.programming{
    background: #ECF5FD;
}

.benefits-card2.programming::after {
    background: url(../../../assets/img/line/line2.png) no-repeat;
    background-size: auto;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right:16px;
}

.benefits-card2.design{
    background: #FFFBEB;
}

.benefits-card2.design::after{
    background: url(../../../assets/img/line/line3.png) no-repeat;
    background-size: auto;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right:16px;
}

.benefits-card2.product{
    background: #EBF5EB;
}

.benefits-card2.product::after{
    background: url(../../../assets/img/line/line4.png) no-repeat;
    background-size: auto;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right:16px;
}

.benefits-card2.admin{
    background: #FDF1F4;
}

.benefits-card2.admin::after{
    background: url(../../../assets/img/line/line5.png) no-repeat;
    background-size: auto;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    right:16px;
}
.benefits-card-title2{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14.7366px;
    line-height: 14px;
    color: #242424;
}
.benefits-card-details2{
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    font-size: 10.2023px;
    line-height: 16px;
    color: #505050;
}
.benefits-card2 img{
    transform: scale(0.8);
    width: 43.08px;
    height: 43.08px;
}
.select-text{
    font-family: 'Nunito', sans-serif;
    /* font-family:'GD Boing', sans-serif; */
    font-style: normal;
    font-weight: bold;
    font-size: 24px !important;
    line-height: 24px;
    color: #242424;
}
.ball-container{
    display: flex;
    align-items: center;
    justify-content: center
}
.ball{
    width: 48.06px;
    height: 48.06px;
    font-size: 25.666px;
    border-radius: 46.7032px;
    padding: 4px;
    display: block;
    margin: 0px;
    background: #F1F2F1;
    border: 1.86813px solid #A5A5A5;
}
.ball.progress-show::after {
    content: "";
    width: 70%;
    height: 4px;
    position: absolute;
    top: 50%;
    left: 65%;
    background-color: #DEDEDE;

}


.ball.progress-show.completed::after {
    background-color: #52A350;
}

.ball.active{
    border: 1.86813px solid #52A350;
    color: #52A350;
    background-color: #fff;
}
.ball.completed{
    background-color: #52A350;
    border: 1.86813px solid #52A350;
    color: #fff;
}

.course-select{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 2px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 10px;
    color: #9D9D9D;
}

@media screen and (max-width:768px) {
    .complete-text{
        font-size: 20px !important;
        line-height: 28px !important;
        letter-spacing: 0.01em;
    }

    .progress-con{
        margin-left: -30px;
        margin-right: -30px;
    }

    .ball{
        width: 30.84px !important;
        height: 30.84px !important;
        font-size: 16px !important;
        padding: 3px !important;
    }

    .ball.progress-show::after {
        width: 60% !important;
        left: 70% !important;

    }

    .select-text{
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .benefits-card-title2{
        font-size: 10px !important;
        line-height: 15px !important;
    }
}

@media only screen and (max-width: 578px) {
    .select-text{
        font-size: 16px !important;
        line-height: 24px !important;
    }
}

</style>


<style src="@/assets/css/main.css"></style>
