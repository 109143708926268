<template>
    <div class="col-12 px-0">
        <question @posted="addQuestion" :lesson="lesson"></question> 
        <div v-if="questions.length > 0">
            <div v-for="question in questions" :key="question.id">
                <!-- {{ question }} -->
                <div
                    class="d-flex flex-column justify-content-between mb-3 col-md-12 m-auto col-12 py-3"
                    style=""
                >
                    <div class="col-md-12 m-auto p-0">
                        <div class="d-flex">
                            <img
                                class="mr-3 rounded-circle"
                                :src="
                                    question.user.avatar === 'null'
                                        ? 'https://sidehustle.ng/api/assets/img/profile_pic/avatar.jpg'
                                        : question.user.avatar
                                "
                                style="height:50px; width:50px"
                                :alt="question.user.first_name"
                            />
                            <div>
                                <p class="m-0 grey mb-0 intern-name mt-2">
                                    <b
                                        >{{ question.user.first_name }}
                                        {{ question.user.last_name }}</b
                                    >
                                </p>

                                <div
                                    class="col-12 m-0 p-0"
                                    style="font-size:8px;color:#08386D"
                                >
                                    {{ postDate(question.created_at) }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 m-0 p-0 mt-3 chat-question grey">
                            {{ question.question }}
                        </div>
                        <div
                            class="col-12 m-0 p-0 mt-2"
                            style="font-size:12px;color:#08386D"
                        >
                            {{
                                question.answer.length > 0
                                    ? question.answer.length + " Answers"
                                    : "Be the first to answer"
                            }}
                        </div>
                    </div>
                </div>
                <div class="col-12 m-0 mt-4 p-0">
                    <div class="col-md-11 col-12 p-0 m-auto">
                        <post-answer
                            :question="question"
                            @answerposted="getQuestion"
                        ></post-answer>
                        <div
                            class="col-12 mb-3 ml-auto mr-auto d-flex"
                            v-for="answer in question.answer"
                            :key="answer.id"
                        >
                            <img
                                class="mr-3 mt-3 rounded-circle"
                                :src="
                                    answer.user.avatar === 'null'
                                        ? 'https://sidehustle.ng/api/assets/img/profile_pic/avatar.jpg'
                                        : answer.user.avatar
                                "
                                style="height:25px; width:25px"
                                alt=""
                            />
                            <div class="p-2 flex-grow-1 comments" style="">
                                <div class="d-flex">
                                    <div>
                                        <p class="m-0 grey mb-0 intern-name">
                                            <b
                                                >{{ answer.user.first_name }}
                                                {{ answer.user.last_name }}</b
                                            >
                                        </p>
                                        <p class="m-0 ash intern-id">
                                            {{ answer.user.user_id }}
                                        </p>
                                        <div
                                            class="col-12 m-0 p-0"
                                            style="font-size:8px;color:#08386D"
                                        >
                                            {{ postDate(answer.created_at) }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-12 m-0 p-0 mt-2 chat-question grey"
                                >
                                    {{ answer.answer }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr
                    class="col-md-12 m-0 p-0 d-none d-md-block"
                    style="border-top: 0.4px solid rgba(0, 0, 0, 0.15)"
                />
            </div>
        </div>
        <div v-else>
            <h6
                v-show="!loading"
                class="text-center text-muted"
                style="font-size:14px"
            >
                No questions - Be the first to ask something
            </h6>
            <div v-show="loading" class="text-center mt-3">
                <i class="fas fa-spinner fa-spin fa-2x"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Question from "@/components/dashboard/question";
import postAnswer from "@/components/dashboard/postAnswer";
import axios from "axios";
import moment from "moment";

export default {
    props: ["lesson"],
    name: "Q&A",
    components: {
        Question,
        postAnswer,
    },
    data() {
        return {
            questions: [],
            loading: true,
        };
    },
    watch: {
        lesson() {
            this.getQuestion();
        },
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods: {
        postDate(postD) {
            return moment(postD).format("h:mma, MMMM Do YYYY");
        },
        addQuestion(question) {
            this.questions.unshift(question);
        },
        getQuestion() {
            this.loading = true;
            axios
                .get("question/allQuestions", {
                    params: {
                        lesson_id: this.lesson.id,
                    },
                })
                .then((response) => {
                    this.loading = false;
                    this.questions = response.data.payload.questions;
                })
                .catch((err) => {
                    this.loading = false;
                    this.questions = [];
                });
        },
    },
    created() {
        this.getQuestion();
    },
};
</script>

<style></style>
