<template>
    <div class="announcement mx-0" v-if="annoucement !== null">
        <div>
            <h5 class="text-left font-weight-bold title">
                <i class="fa-solid fa-bell text-success fa-beat"></i>
                Announcement
            </h5>
            <div class="text-left d-block content">
                <div
                    class="p-0  mb-4"
                    v-for="a in annoucement"
                    :key="a.id"
                    v-html="a.description"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "Annoucment",
    data() {
        return {
            annoucement: null,
            open: false,
        };
    },
    mounted() {
        axios
            .get("annoucement")
            .then((response) => {
                if (response.data.payload.length > 0) {
                    this.annoucement = response.data.payload;
                } else {
                    this.annoucement = null;
                }
            })
            .catch(() => {
                this.annoucement = null;
            });
    },
};
</script>

<style scoped>
.announcement {
    width: 100%;
    height: fit-content;
    border-radius: 15px;
    padding: 0px;
    z-index: 3;
    background-color: #ebf5ea;
    border: 1px solid #52a350;
    position: relative;
    background-image: url(../../assets/img/backgrounds/announcement-bg.png);
    background-position: center;
    background-repeat: no-repeat;
}
.announcement .title {
    background-color: #deefdc;
    padding: 20px;
    font-weight: 600;
    border-radius: 15px 15px 0px 0px;
}
.announcement .content {
    background-color: transparent;
    padding: 10px 20px 20px;
    border-radius: 0px 15px;
}
</style>
