<template>
    <nav
        class="nav navbar-light navbar-expand fixed-top"
        id="dashboard-top-nav"
    >
        <div
            class="nav-logo-btn d-flex d-lg-none align-items-center py-3"
            v-if="$route.name !== 'Onboard'"
        >
            <div class="dropdown custom-dropdown">
                <a
                    class="nav-link d-flex align-items-center dropdown-toggle"
                    type="button"
                    id="mobileSideNav"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i class="fa fa-bars pt-2" style="font-size: 1.5rem;"></i>
                </a>
                <div
                    class="dropdown-menu no-bg"
                    aria-labelledby="mobileSideNav"
                    style="border:none"
                >
                    <div class="" id="navbar">
                        <div class="dash-mob-nav">
                            <div class="d-flex mb-4">
                                <a
                                    class="nav-link d-flex align-items-center dropdown-toggle"
                                    type="button"
                                >
                                    <i class="fa fa-bars fa-1x"></i>
                                </a>
                                <router-link to="/" class="navbar-brand">
                                    <img
                                        src="@/assets/img/logo-dark.png"
                                        alt="logo"
                                        class="nav-logo"
                                    />
                                </router-link>
                            </div>
                            <div class="nav-item">
                                <router-link
                                    :to="{ name: 'Dashboard' }"
                                    class="nav-link d-flex"
                                    :class="{
                                        active: $route.name === 'Dashboard',
                                    }"
                                >
                                    <i class="fa-solid fa-house"></i>
                                    <span class=" d-inline-block ml-2"
                                        >Dashboard</span
                                    >
                                </router-link>
                            </div>
                            <div class="nav-item">
                                <router-link
                                    :to="{ name: 'courses' }"
                                    :class="{
                                        active:
                                            $route.name === 'courses' ||
                                            $route.name === 'Lessons' ||
                                            $route.name == 'Lesson',
                                    }"
                                    class="nav-link d-flex align-items-center"
                                >
                                    <i class="fa fa-book-open"></i>
                                    <span class=" d-md-inline-block ml-2"
                                        >Lessons</span
                                    >
                                </router-link>
                            </div>
                            <div class="nav-item">
                                <router-link
                                    :to="{ name: 'Quiz' }"
                                    :class="{
                                        active:
                                            $route.name === 'Quiz' ||
                                            $route.name == 'QuizStart' ||
                                            $route.name == 'SelectWeek' ||
                                            $route.name === 'QuizTake',
                                    }"
                                    class="nav-link d-flex align-items-center"
                                >
                                    <i class="fa fa-tasks"></i>
                                    <span class=" d-inline-block ml-2"
                                        >Quiz</span
                                    >
                                </router-link>
                            </div>
                            <div class="nav-item">
                                <router-link
                                    :to="{ name: 'SH' }"
                                    :class="{
                                        active:
                                            $route.name === 'SH' ||
                                            $route.name === 'Community' ||
                                            $route.name == 'CommunityRoom',
                                    }"
                                    class="nav-link d-flex align-items-center"
                                >
                                    <i class="fa fa-users"></i>
                                    <span class=" d-inline-block ml-2"
                                        >Messages</span
                                    >
                                </router-link>
                            </div>
                            <div class="nav-item">
                                <router-link
                                    :to="{ name: 'Badges' }"
                                    :class="{
                                        active:
                                            $route.name === 'Badges' ||
                                            $route.name === 'Badge',
                                    }"
                                    class="nav-link d-flex align-items-center"
                                >
                                    <i class="fa fa-ribbon"></i>
                                    <span class=" d-inline-block ml-2"
                                        >Badges</span
                                    >
                                </router-link>
                            </div>
                            <div class="nav-item">
                                <router-link
                                    :to="{ name: 'Certificate' }"
                                    :class="{
                                        active: $route.name === 'Certificate',
                                    }"
                                    class="nav-link d-flex align-items-center"
                                    style="border-right:0"
                                >
                                    <i class="fa fa-certificate"></i>
                                    <span class=" d-inline-block ml-2"
                                        >Certificate</span
                                    >
                                </router-link>
                            </div>
                            <div
                                class="nav-item"
                                :class="{
                                    active: $route.name === 'CapstoneIndex',
                                }"
                            >
                                <router-link
                                    :to="{ name: 'CapstoneIndex' }"
                                    class="nav-link d-flex align-items-center"
                                    style="border-right:0"
                                >
                                    <i class="fa fa-circle"></i>
                                    <span class=" d-inline-block ml-2"
                                        >Capstone</span
                                    >
                                </router-link>
                            </div>

                            <div
                                class="nav-item"
                                v-if="user.mode === 'gamified'"
                                :class="{ active: $route.name === 'Goodies' }"
                            >
                                <router-link
                                    :to="{ name: 'Goodies' }"
                                    class="nav-link d-flex align-items-center"
                                    style="border-right:0"
                                >
                                    <i class="fa fa-gift"></i>
                                    <span class=" d-inline-block ml-2"
                                        >Goodies</span
                                    >
                                </router-link>
                            </div>
                            <!-- user.mode === 'gamified' -->
                            <div
                                class="nav-item"
                                v-if="false"
                                :class="{
                                    active: $route.name === 'Leaderboard',
                                }"
                            >
                                <router-link
                                    :to="{ name: 'Leaderboard' }"
                                    class="nav-link d-flex align-items-center"
                                    style="border-right:0"
                                >
                                    <i
                                        class="fas fa-chart-bar sh-text-light"
                                    ></i>
                                    <span
                                        class=" d-inline-block sh-text-light ml-2"
                                        >Leaderboard</span
                                    >
                                </router-link>
                            </div>
                            <div
                                class="nav-item"
                                :class="{ active: $route.name === 'Report' }"
                            >
                                <router-link
                                    :to="{ name: 'Report' }"
                                    class="nav-link d-flex align-items-center"
                                    style="border-right:0"
                                >
                                    <i class="fa fa-exclamation-circle"></i>
                                    <span class=" d-inline-block ml-2"
                                        >Report</span
                                    >
                                </router-link>
                            </div>
                            <template v-if="week > 2">
                                <div
                                    class="nav-item"
                                    :class="{
                                        active: $route.name === 'Payment',
                                    }"
                                >
                                    <router-link
                                        :to="{ name: 'Payment' }"
                                        class="nav-link d-flex align-items-center"
                                        style="border-right:0"
                                    >
                                        <i class="fas fa-money-check-alt "></i>
                                        <span class=" d-inline-block ml-2"
                                            >Payment</span
                                        >
                                    </router-link>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$route.name === 'Onboard'" class="align-self-center pt-3">
            <div class="navbar-brand">
                <img
                    src="@/assets/img/logo-dark.png"
                    alt="logo"
                    class="nav-logo"
                />
            </div>
        </div>
        <div class="navbar-nav ml-auto user-nav-details pl-lg-100 pr-lg-5">
            <div class="col-12">
                <ul
                    class="navbar-nav ml-auto pt-3 d-flex flex-row justify-content-end border-left-rounded align-items-center"
                >
                    <li class="nav-link custom-nav-link-color mx-1 mx-md-0">
                        <router-link :to="{ name: 'Help' }">
                            <i
                                class="fa fa-question-circle"
                                style="font-size: 1rem;"
                                :class="{ active: $route.name === 'Help' }"
                                aria-hidden="true"
                            ></i>
                        </router-link>
                    </li>
                    <li class="nav-link custom-nav-link-color mx-1 mx-md-0">
                        <div class="dropdown custom-dropdown">
                            <a
                                class="nav-link d-flex align-items-center dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i
                                    :class="
                                        `${
                                            hasNotification
                                                ? 'fas fa-bell fa-beat'
                                                : 'fas fa-bell'
                                        }`
                                    "
                                    style="font-size: 1rem;"
                                ></i>
                            </a>
                            <div
                                class="dropdown-menu no-bg"
                                aria-labelledby="dropdownMenuButton"
                                style="border:none"
                            >
                                <notification @notify="notify"></notification>
                            </div>
                        </div>
                    </li>
                    <li
                        class="nav-item d-flex align-items-center  mx-3 mx-md-0"
                    >
                        <span
                            style="font-size:12px;line-height:16px"
                            class="custom-nav-link-color d-none d-md-block px-3 font-weight-lighter"
                        >
                            Hi,
                            <span class="font-weight-bold">
                                {{ user.first_name }}</span
                            >
                            <br />
                            {{ user.user_id }}
                        </span>
                        <div class="dropdown">
                            <div
                                class="dropdown-toggle"
                                id="profileDropdown"
                                data-toggle="dropdown"
                                role="button"
                                style="cursor:pointer; border:2px solid #fff;border-radius:50%"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <img
                                    :src="
                                        user.avatar !== ''
                                            ? user.avatar
                                            : '@/assets/img/avatar.png'
                                    "
                                    :alt="user.first_name"
                                    class="rounded-circle"
                                    style="width:50px; height:50px"
                                />
                            </div>
                            <div
                                class="dropdown-menu"
                                aria-labelledby="profileDropdown"
                            >
                                <router-link
                                    :to="{ name: 'Profile' }"
                                    v-if="$route.name !== 'Onboard'"
                                >
                                    <span
                                        class="dropdown-item nav-link px-2 logout"
                                        >Profile</span
                                    >
                                </router-link>

                                <span
                                    style="cursor:pointer"
                                    class="dropdown-item nav-link px-2 logout"
                                    @click="logout"
                                    >Log-out</span
                                >
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <modal />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import swal from "sweetalert2";
import profilepic from "@/components/dashboard/profilePic";
import notification from "@/components/dashboard/Notification";
import Modal from "./Modal.vue";
export default {
    name: "DashboardNav",
    data() {
        return {
            hasNotification: false,
        };
    },
    components: {
        profilepic,
        Modal,
        notification,
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            week: "auth/currentWeek",
        }),
    },
    methods: {
        logout() {
            swal.fire({
                title: "Leaving Already",
                text: "Are you sure, you want to logout?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#dc3545",
                cancelButtonColor: "#353535",
                confirmButtonText: "Logout!",
            }).then((result) => {
                ///send request
                if (result.value) {
                    this.signOutAction()
                        .then(() => {
                            this.$router.push({ name: "Login" });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            });
        },
        ...mapActions({
            signOutAction: "auth/signOut",
        }),
        notify(no) {
            if (no > 0) {
                this.hasNotification = true;
            } else {
                this.hasNotification = false;
            }
        },
    },
};
</script>
<style lang="css" scoped>
.dropdown-item:active {
    background-color: #fff;
}
.nav-link {
    color: #a8a8a8;
}
.active {
    color: #08386d !important;
    font-weight: 600;
}
#navbar {
    position: absolute;
    top: -55px;
    left: -20px;
    width: 60vw;
    height: 100vh;
    min-height: 100vh;
}
.dash-mob-nav {
    width: 100%;
    min-width: 100%;
    padding-top: 10px;
    padding-left: 20px;
}
.nav-logo-btn {
    z-index: 2;
}

.active-nav {
    font-weight: 600;
    color: red !important;
}
.dash-mob-nav {
    width: 70%;
    height: 100%;
    background: #ffffff;
}
.mobile-nav-wrapper {
    position: absolute;
    min-width: 100vw;
    max-width: 100vw;
    background: rgba(0, 0, 0, 0.49);
    height: 100vh;
    top: 0%;
    left: 0%;
}
.no-bg {
    background: none !important;
}
.dropdown-toggle::after {
    display: none;
}
.notification {
    width: 550px;
    z-index: 10 !important;
    margin-left: -10rem;
    padding: 2rem 0 2rem 1rem;
    /* height: 400px; */
    overflow-y: scroll;
    background: #ffffff !important;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}
.notification-text {
    font-size: 0.8rem !important;
}
.navbar-expand .navbar-nav {
    flex-direction: column;
}
.logout {
    color: rgba(0, 0, 0, 0.9) !important;
}

@media only screen and (max-device-width: 767px) {
    .notification {
        width: 70vw;
        margin-left: auto;
        margin-right: auto;
    }
    .no-bg {
        border: none !important;
    }
    #dashboard-top-nav {
        z-index: 9999;
    }
}
</style>
